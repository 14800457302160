<div class="progress mx-auto">
  <span class="progress-left">
    <span [class]="'progress-bar ' + percentageColor" [style]="leftStyle"></span>
  </span>
  <span class="progress-right">
    <span [class]="'progress-bar ' + percentageColor" [style]="rightStyle"></span>
  </span>
  <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
    <div class="fw-bold percent-text">{{ percent }}%</div>
  </div>
</div>
