import { Inject, Injectable } from '@angular/core';
import { CriteriaNavigateService } from 'apps/clubhub/src/app/ignite/services';
import { ContextState, IBasketItem, IContext, IQuestionAnswerViewModel } from 'apps/clubhub/src/app/models';
import { INode, OutcomeResponseContextResult } from 'apps/shared/src/models';

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  public basketItems: IBasketItem[] = [];

  public constructor(@Inject(CriteriaNavigateService) private criteriaNavigateService) {}

  public get anyCompletedItems(): boolean {
    return this.completedItems.length > 0;
  }

  public get completedItems(): IBasketItem[] {
    return this.basketItems.filter((x) => x.contexts.length > 0 && x.contexts.find((c) => c.complete));
  }

  public get answerIds(): (number | undefined)[] {
    return this.completedItems.flatMap((x) =>
      x.contexts
        .filter((c) => c.questionsAndAnswers?.length > 0)
        .map((c) => c.questionsAndAnswers[c.questionsAndAnswers.length - 1].answer.id),
    );
  }

  public clearBasket(): void {
    this.basketItems = [];
    this.criteriaNavigateService.state = {};
  }

  public checkBasketItemExists(lenderId: string | null): void {
    if (lenderId && !this.currentBasketItem) {
      this.criteriaNavigateService.navigate('issues', {
        queryParams: { lenderId: lenderId },
      });
    }
  }

  private get currentBasketItem(): IBasketItem | undefined {
    return this.basketItems.find((x) => x.contexts.length === 0 || x.contexts.some((c) => !c.complete));
  }

  public get currentContext(): IContext | undefined {
    return this.currentBasketItem?.contexts.find((x) => !x.complete);
  }

  public addBasketItem(issue: INode): void {
    this.basketItems = [...this.completedItems];

    this.basketItems.push({
      issue,
      contexts: [],
    });
  }

  public removeBasketItem(issueId: number) {
    const issue = this.getBasketItem(issueId);
    if (issue) {
      issue.contexts = [];
    }
  }

  public removeBasketItemByIndex(outcome: OutcomeResponseContextResult) {
    this.basketItems.forEach(basketItem => {
      basketItem.contexts = basketItem.contexts.filter(x => x.context.id !== outcome.contextId);
    });
  }

  public addContexts(contextGroupName: string, contexts: INode[]): void {
    if (!this.currentBasketItem) {
      return;
    }

    this.currentBasketItem.contextGroupName = contextGroupName;
    this.currentBasketItem.contexts = [
      ...contexts.map((context) => ({
        context,
        questionsAndAnswers: [],
        complete: false,
        autoAdded: false,
      })),
    ];
  }

  public addQuestionAndAnswer(question: INode, answer: INode, complete = false): void {
    if (!this.currentContext) {
      return;
    }

    this.currentContext.questionsAndAnswers.push({
      question,
      answer,
    });

    this.currentContext.complete = complete;
  }

  public removeQuestionAndAnswer(): void {
    if (!this.currentContext) {
      return;
    }
    this.currentContext.questionsAndAnswers.pop();
  }

  public removeContext(contextId: number | undefined, issueId: number, index: number | null = null) {
    const basketItem = this.getBasketItem(issueId);
    if (!basketItem) {
      return;
    }

    if (index !== null) {
      basketItem.contexts = basketItem.contexts.filter((_, i) => i !== index);
    } else {
      basketItem.contexts = basketItem.contexts.filter((item) => item.context.id !== contextId);
    }
  }

  public checkBasketItemExistsByIssue(contextId: number, issueId: number): ContextState {
    const issueItem = this.getBasketItem(issueId);
    if (!issueItem) {
      return ContextState.IssueRemoved;
    }
    const contextItem = this.getContextItem(contextId, issueItem);
    return contextItem ? ContextState.NoChange : ContextState.ContextRemoved;
  }

  public checkBasketItemExistsCount(contextId: number, issueId: number): number {
    const issueItem = this.getBasketItem(issueId);
    if (!issueItem) {
      return 0;
    }
    const contextItem = this.getContextItems(contextId, issueItem);
    return contextItem.length;
  }

  public addQuestionAndAnswerMulti(
    issueId: number | undefined,
    context: INode,
    questionsAndAnswers: IQuestionAnswerViewModel[],
    autoAdded = false,
  ): void {
    if (issueId) {
      this.getBasketItem(issueId)?.contexts.push({
        context,
        complete: true,
        questionsAndAnswers,
        autoAdded: autoAdded,
      });
    }
  }

  public previousVisible(contextId: number, issueId: number) {
    return (this.getBasketContextItem(contextId, issueId)?.questionsAndAnswers?.length ?? 0) > 0;
  }

  public getContext(contextId: number, issueId: number): IContext | undefined {
    return this.getBasketContextItem(contextId, issueId);
  }

  private getBasketContextItem(contextId: number, issueId: number) {
    const basketItem = this.getBasketItem(issueId);
    return this.getContextItem(contextId, basketItem);
  }

  private getContextItem(contextId: number, basketItem: IBasketItem | undefined) {
    return basketItem?.contexts?.find((item) => item.context.id === contextId);
  }

  private getContextItems(contextId: number, basketItem: IBasketItem) {
    return basketItem?.contexts?.filter((item) => item.context.id === contextId);
  }

  public getBasketItem(issueId: number) {
    return this.basketItems.find((item) => item.issue.id === issueId);
  }
}
