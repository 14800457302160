<div class="refresh-now-app-banner">
  <div class="refresh-now-app-banner-content">
    <div>{{ bannerText }}</div>
    <button id="refreshNowBtn"
            type="button"
            class="btn btn-2022--secondary btn-edit h-38"
            (click)="refreshNow()">
      Refresh now
    </button>
  </div>
</div>
