import { Injectable, Injector } from '@angular/core';
import { IdentityServerDataService } from '@msslib/services/identityserver-data.service';
import { Observable, firstValueFrom, map, tap } from 'rxjs';
import { AuthorizeService } from './authorize.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import type {
  IEmailVerificationService,
  SendVerificationEmailResponse,
  VerificationFlowResult,
  VerifyCodeResponse,
} from '@msslib/models/email-verification';
import type { Replace } from '@msslib/helpers';
import {
  EmailVerificationModalComponent,
} from '@msslib/components/email-verification-modal/email-verification-modal.component';
import { JwtHelperService } from './jwt-decode';
import { roles } from '@msslib/constants/roles';

const verificationTokenStorageKey = 'emailVerificationToken';

@Injectable({
  providedIn: 'root',
})
export class EmailVerificationService implements IEmailVerificationService {

  private _expiresAt: Date | null = null;
  private _requestEmailAgainAt: Date | null = null;

  public constructor(
    private authService: AuthorizeService,
    private modalService: NgbModal,
    private jwtService: JwtHelperService,
    private injector: Injector,
  ) { }

  public get verificationToken(): string | null {
    const token = sessionStorage.getItem(verificationTokenStorageKey)
      ?? localStorage.getItem(verificationTokenStorageKey);

    // If token is not valid for the currently logged in user, do not return it
    return token && this.isTokenValid(token)
      ? token
      : null;
  }

  public get hasVerificationCodeExpired() {
    return this._expiresAt !== null && this._expiresAt.getTime() <= Date.now();
  }

  public get canRequestVerificationEmail() {
    return this._requestEmailAgainAt === null || this._requestEmailAgainAt.getTime() <= Date.now();
  }

  public get canRequestVerificationEmailIn() {
    return Math.round(Math.max(0, this._requestEmailAgainAt === null
      ? 0
      : (this._requestEmailAgainAt.getTime() - Date.now()) / 1000));
  }

  private get dataService(): IdentityServerDataService {
    // This has to be injected because the base abstract DataService (that IdentityServerDataService extends) needs to
    // inject this service. Therefore, if we tried to inject the ISDS in this service, it would cause a circular ref.
    return this.injector.get(IdentityServerDataService);
  }

  public async assertEmailVerified(): Promise<void> {
    // If the current user is an SSO user AND there is no verification token setup, begin the flow.
    // Non-SSO users are always verified as their email address must be confirmed before they're able to log in
    const isSsoUser = await firstValueFrom(this.authService.isSsoUser$);
    if (isSsoUser && !this.authService.hasRole(roles.ssoTesting) && !this.verificationToken) {
      await this.beginEmailVerificationFlow();
    }
  }

  public async beginEmailVerificationFlow(): Promise<void> {
    this.sendVerificationEmail().subscribe();
    const result = await this.modalService.open(EmailVerificationModalComponent, {
      size: 'md',
    }).result as VerificationFlowResult;
    if (result.shouldSave) {
      this.persistVerificationToken();
    }
  }

  public sendVerificationEmail(): Observable<SendVerificationEmailResponse> {
    return this.dataService.post<Replace<SendVerificationEmailResponse, Date, string>>(
      'UserEmailVerification/Request', {}, null, { showErrorToast: false, logErrorToConsole: false })
      .pipe(
        map(res => ({ expiresAt: new Date(res.expiresAt), requestAgainAt: new Date(res.requestAgainAt) })),
        tap(({ expiresAt, requestAgainAt }) => {
          this._expiresAt = expiresAt;
          this._requestEmailAgainAt = requestAgainAt;
        }),
      );
  }

  public submitVerificationCode(code: string): Observable<VerifyCodeResponse> {
    return this.dataService.post<VerifyCodeResponse>(
      'UserEmailVerification/Verify', { code }, null, { showErrorToast: false })
      .pipe(tap(res => {
        if (res.success) {
          sessionStorage.setItem(verificationTokenStorageKey, res.verificationToken);
          localStorage.removeItem(verificationTokenStorageKey);
        }
      }));
  }

  public persistVerificationToken(): void {
    if (this.verificationToken) {
      localStorage.setItem(verificationTokenStorageKey, this.verificationToken);
    }
  }

  public unsetVerificationToken(): void {
    sessionStorage.removeItem(verificationTokenStorageKey);
    localStorage.removeItem(verificationTokenStorageKey);
  }

  /** Determines if the given token is valid for the currently authenticated user at the current time. */
  private isTokenValid(token: string): boolean {
    const tokenObj = this.jwtService.decodeToken(token);
    return !this.jwtService.isTokenExpired(token)
      && !!this.authService.user
      && tokenObj.sub === this.authService.user.sub;
  }
}
