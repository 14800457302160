import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { AddLendingType, LendingType } from '../models';
import { ClubHubDataService } from './clubhub-data.service';

@Injectable({
  providedIn: 'root',
})
export class LendingTypeService {
  public lendingType: LendingType | undefined;
  public lendingTypes: LendingType[];

  public constructor(private clubHubDataService: ClubHubDataService) {}

  public get visibleLendingTypes() {
    return this.lendingTypes.filter(t => !t.archived);
  }

  public setLendingTypeByCode(code: string) {
    this.lendingType = this.lendingTypes.find(x => x.code.toLowerCase() === code.toLowerCase());
  }

  public setLendingTypeByName(name: string) {
    this.lendingType = this.lendingTypes.find(x => x.name.toLowerCase() === name.toLowerCase());
  }

  public getLendingTypeById(id: number) {
    return this.lendingTypes.find(l => l.id === id);
  }

  public getLendingTypeByCode(code: string) {
    return this.lendingTypes.find((l) => l.code === code);
  }

  public get currentLendingTypeCode(): string | null {
    return this.lendingType?.code ?? null;
  }

  public get currentLendingType(): string | null {
    return this.lendingType?.name ?? null;
  }

  public getLendingTypes() {
    return this.clubHubDataService.get<LendingType[]>('lendingtypes').pipe(
      tap((types) => {
        this.lendingTypes = types.filter(t => !t.archived);
      }),
    );
  }

  public addLendingType(model: AddLendingType) {
    return this.clubHubDataService.post('lendingtypes', model);
  }

  public updateLendingType(lendingType: LendingType) {
    return this.clubHubDataService.put('lendingtypes', lendingType);
  }

  public unArchiveLendingType(lendingTypeId: number) {
    return this.clubHubDataService.put(`lendingtypes/${lendingTypeId}`, lendingTypeId);
  }

  public removeLendingType(lendingTypeId: number) {
    return this.clubHubDataService.delete(`lendingtypes/${lendingTypeId}`);
  }

  public uploadLendingTypeLogo(lendingTypeId: number, logo: unknown) {
    return this.clubHubDataService.post<LendingType>(`lendingtypes/${lendingTypeId}/logo`, logo);
  }

  public removeLendingTypeLogo(lendingTypeId: number) {
    return this.clubHubDataService.delete<LendingType>(`lendingtypes/${lendingTypeId}/logo`);
  }

  public get isResLendingType(): boolean {
    const lendingTypeCode = this.lendingType?.code.replace(/ /g, '').toLowerCase();
    return lendingTypeCode === 'res';
  }

  public get isBtlLendingType(): boolean {
    const lendingTypeCode = this.lendingType?.code.replace(/ /g, '').toLowerCase();
    return lendingTypeCode === 'btl';
  }
}
