import {
  ApplicantCountry,
  BenefitIncomeType,
  ContractType,
  EmploymentStatus,
  Frequency,
  MortgageType,
  OtherIncomeType,
  ProductLength,
  ProductType,
  PropertyType,
  RepaymentMethod,
  UkLocation,
  WorkRelatedIncomeType,
} from 'apps/shared/src/models';
import { IUiAffordabilityModel } from 'apps/clubhub/src/app/ignite/models/affordability';

export const mockAffordabilityRequest = {
  propertyAndLoan: {
    propertyType: PropertyType.House,
    productTypeExtended: ProductType.Standard,
    newBuild: false,
    helpToBuy: false,
    location: UkLocation.YorkshireAndHumberside,
    mortgageType: MortgageType.Purchase,
    capitalRaising: { value: false, showCriteria: false },
    amountTransferredFromOtherLender: 0,
    propertyValue: 167000,
    loanAmount: 125000,
    firstTimeLandlord: true,
    firstTimeBuyer: true,
    rentalAmountReceivedExpected: 1250,
    repaymentMethod: {
      value: RepaymentMethod.CapitalAndInterest,
      showInterestOnlyCriteria: false,
      showInterestOnlyPartAndPartCriteria: false,
    },
    mortgageLender: {
      value: null,
    },
    repaymentVehicle: null,
    mortgageTerm: {
      years: 20,
      months: 0,
    },
    equityLoanValue: 0,
    productLength: ProductLength.TwoYears,
  },
  applicants: {
    numberOfApplicants: 1,
    numberOfIncomesNeeded: 1,
    financialDependants: false,
    dependantsAged0To5: 0,
    dependantsAged6To11: 0,
    dependantsAged12To17: 0,
    dependantsAged18AndOver: 0,
    applicantAges: [
      {
        dateOfBirth: { year: 1985, month: 5, day: 16 },
        plannedRetirementAge: 65,
        grossAnnualIncome: 50000,
        countryResident: ApplicantCountry.England,
      },
    ],
  },
  incomeAndExpenditure: {
    applicants: [
      {
        applicantNumber: 1,
        primaryIncome: {
          employmentStatus: EmploymentStatus.Employed,
          contractType: ContractType.Permanent,
          treatedAsEmployee: true,
          timeInCurrentJobMonths: { years: 5, months: 2 },
          timeRemainingOnContractMonths: null,
          grossAnnualIncome: 80000,
        },
        secondJob: false,
        additionalIncome: true,
        [WorkRelatedIncomeType.Bonus]: Frequency.Annually,
        ['1Amounts']: [1000, 1000],
        [WorkRelatedIncomeType.Commission]: Frequency.Annually,
        ['2Amounts']: [500, 500],
        [WorkRelatedIncomeType.Overtime]: Frequency.Annually,
        ['3Amounts']: [400, 400],
        workAdditionalIncome: [
          {
            option: {
              category: WorkRelatedIncomeType.CarAllowance,
              amount: 50, frequency: Frequency.Monthly,
            },
          },
        ],
        pensionIncome: {
          amount: 400,
        },
        benefitAdditionalIncome: [
          {
            option: {
              category: BenefitIncomeType.CarersAllowance,
              amount: 50, frequency: Frequency.Monthly,
            },
          },
        ],
        otherAdditionalIncome: [
          {
            option: {
              category: OtherIncomeType.RentalIncome,
              amount: 100, frequency: Frequency.Monthly,
            },
          },
        ],
        creditCardBalance: 0,
        totalMonthlyCreditPayments: 0,
        householdExpenses: 50,
        gasAndElectricity: 100,
        water: 40,
        tvAndInternet: 25,
        mobileAndLandlines: 30,
        councilTax: 135,
        buildingsAndContentsInsurance: 10,
        lifeAssuranceAndPrivateHealthPremiums: 20,
        groundRentAndServiceCharge: 0,
        travel: 50,
        childcareAndSchoolCollegeUniversityFees: 0,
        childMaintenance: 0,
        carUpkeep: 0,
        personalEssentials: 0,
        recreational: 200,
        other: 25,
      },
    ],
  },
  otherMortgages: 0,
} as unknown as IUiAffordabilityModel;

export const mockRioAffordabilityRequestForTwoApplicants = {
  propertyAndLoan: {
    propertyType: PropertyType.House,
    newBuild: false,
    helpToBuy: false,
    location: UkLocation.YorkshireAndHumberside,
    mortgageType: MortgageType.Purchase,
    capitalRaising: { value: false, showCriteria: false },
    amountTransferredFromOtherLender: 0,
    propertyValue: 167000,
    loanAmount: 125000,
    firstTimeLandlord: true,
    firstTimeBuyer: true,
    rentalAmountReceivedExpected: 1250,
    repaymentMethod: {
      value: RepaymentMethod.CapitalAndInterest,
      showInterestOnlyCriteria: false,
      showInterestOnlyPartAndPartCriteria: false,
    },
    repaymentVehicle: null,
    mortgageTerm: {
      years: 20,
      months: 0,
    },
    equityLoanValue: 0,
    productLength: ProductLength.TwoYears,
  },
  applicants: {
    numberOfApplicants: 2,
    numberOfIncomesNeeded: 1,
    financialDependants: false,
    dependantsAged0To5: 0,
    dependantsAged6To11: 0,
    dependantsAged12To17: 0,
    dependantsAged18AndOver: 0,
    applicantAges: [
      {
        dateOfBirth: { year: 1950, month: 5, day: 16 },
        plannedRetirementAge: 65,
        grossAnnualIncome: 50000,
        countryResident: ApplicantCountry.England,
      },
      {
        dateOfBirth: { year: 1960, month: 5, day: 16 },
        plannedRetirementAge: 65,
        grossAnnualIncome: 50000,
        countryResident: ApplicantCountry.England,
      },
    ],
  },
  incomeAndExpenditure: {
    applicants: [
      {
        applicantNumber: 1,
        primaryIncome: {
          employmentStatus: EmploymentStatus.Employed,
          contractType: ContractType.Permanent,
          treatedAsEmployee: true,
          timeInCurrentJobMonths: { years: 5, months: 2 },
          timeRemainingOnContractMonths: null,
          grossAnnualIncome: 80000,
        },
        secondJob: false,
        additionalIncome: true,
        [WorkRelatedIncomeType.Bonus]: Frequency.Annually,
        ['1Amounts']: [1000, 1000],
        [WorkRelatedIncomeType.Commission]: Frequency.Annually,
        ['2Amounts']: [500, 500],
        [WorkRelatedIncomeType.Overtime]: Frequency.Annually,
        ['3Amounts']: [400, 400],
        workAdditionalIncome: [
          {
            option: {
              category: WorkRelatedIncomeType.CarAllowance,
              amount: 50, frequency: Frequency.Monthly,
            },
          },
        ],
        pensionIncome: {
          amount: 400,
        },
        benefitAdditionalIncome: [
          {
            option: {
              category: BenefitIncomeType.CarersAllowance,
              amount: 50, frequency: Frequency.Monthly,
            },
          },
        ],
        otherAdditionalIncome: [
          {
            option: {
              category: OtherIncomeType.RentalIncome,
              amount: 100, frequency: Frequency.Monthly,
            },
          },
        ],
        creditCardBalance: 0,
        totalMonthlyCreditPayments: 0,
        householdExpenses: 50,
        gasAndElectricity: 100,
        water: 40,
        tvAndInternet: 25,
        mobileAndLandlines: 30,
        councilTax: 135,
        buildingsAndContentsInsurance: 10,
        lifeAssuranceAndPrivateHealthPremiums: 20,
        groundRentAndServiceCharge: 0,
        travel: 50,
        childcareAndSchoolCollegeUniversityFees: 0,
        childMaintenance: 0,
        carUpkeep: 0,
        personalEssentials: 0,
        recreational: 200,
        other: 25,
      },
      {
        applicantNumber: 2,
        primaryIncome: {
          employmentStatus: EmploymentStatus.Employed,
          contractType: ContractType.Permanent,
          treatedAsEmployee: true,
          timeInCurrentJobMonths: { years: 5, months: 2 },
          timeRemainingOnContractMonths: null,
          grossAnnualIncome: 80000,
        },
        secondJob: false,
        additionalIncome: true,
        [WorkRelatedIncomeType.Bonus]: Frequency.Annually,
        ['1Amounts']: [1000, 1000],
        [WorkRelatedIncomeType.Commission]: Frequency.Annually,
        ['2Amounts']: [500, 500],
        [WorkRelatedIncomeType.Overtime]: Frequency.Annually,
        ['3Amounts']: [400, 400],
        workAdditionalIncome: [
          {
            option: {
              category: WorkRelatedIncomeType.CarAllowance,
              amount: 50, frequency: Frequency.Monthly,
            },
          },
        ],
        pensionIncome: {
          amount: 400,
        },
        benefitAdditionalIncome: [
          {
            option: {
              category: BenefitIncomeType.CarersAllowance,
              amount: 50, frequency: Frequency.Monthly,
            },
          },
        ],
        otherAdditionalIncome: [
          {
            option: {
              category: OtherIncomeType.RentalIncome,
              amount: 100, frequency: Frequency.Monthly,
            },
          },
        ],
        creditCardBalance: 0,
        totalMonthlyCreditPayments: 0,
        householdExpenses: 50,
        gasAndElectricity: 100,
        water: 40,
        tvAndInternet: 25,
        mobileAndLandlines: 30,
        councilTax: 135,
        buildingsAndContentsInsurance: 10,
        lifeAssuranceAndPrivateHealthPremiums: 20,
        groundRentAndServiceCharge: 0,
        travel: 50,
        childcareAndSchoolCollegeUniversityFees: 0,
        childMaintenance: 0,
        carUpkeep: 0,
        personalEssentials: 0,
        recreational: 200,
        other: 25,
      },
    ],
  },
  otherMortgages: 0,
} as unknown as IUiAffordabilityModel;
