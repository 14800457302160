<div *ngIf="isClubHub || isIgnite" class="container clubhub-home mt-3">
  <section class="row" *ngIf="isClubHub">
    <div class="header-image px-0">
      <img src="assets/images/criteria/home-page/1130318_ClubHub_Banner.jpg" alt="" />
      <div class="header-title">
        <h1>ClubHub</h1>
      </div>
    </div>
  </section>

  <section class="row" *ngIf="isClubHub">
    <div class="promo-bar col">
      <h2>
        Welcome to ClubHub. Here you will find all the information you need to make the most of your partnership with us. You will have
        access to member only content and exclusives, giving you a better view of what is available to you as a Mortgage Club partner.
      </h2>
    </div>
  </section>

  <section class="row pt-3">
    <div class="col px-0">
      <app-clubhub-home-section
        title="Legal & General Ignite"
        content="Fuel right-first time results with our seamless, intuitive sourcing platform that works the way you do."
        contentSecondParagraph="Legal & General Ignite, provides free access to all the research and sourcing tools you need, to find your client the most appropriate mortgage."
        contentThirdParagraph="Access Criteria, Affordability, Product Sourcing and Property Check tools from one easy to use platform without the need for licences or subscriptions."
        contentFourthParagraph="Perform whole-of-market comparisons, and provide your clients with reliable, real-time results from our broad base of lenders and products, whatever their circumstances."
        igniteBulletPoints="true"
        button="Legal & General Ignite"
        buttonLink="/ignite"
      >
      </app-clubhub-home-section>
    </div>
  </section>

  <ng-container *ngIf="isClubHub">
    <section class="row mt-3">
      <a
        [href]="imageLink"
        target="_blank"
        rel="noopener noreferrer"
        class="row mx-0 px-0"
      >
        <img [src]="imageUrl" alt="" class="img-fluid px-0"/>
      </a>
    </section>

    <section class="row pt-3">
      <div class="col ps-0">
        <app-clubhub-home-section
          image="criteria/home-page/1130318_ClubHub_Banner_Proc-Fee.jpg"
          title="Claim your proc fees"
          content="We are the only Mortgage Club that pays your procuration fee on exchange, allowing you to have greater control over your cash flow. All you need to do is notify us of your cases at exchange stage via our digital claim form here in ClubHub, and we will arrange payment on your next available commission statement."
          button="Claim here"
          buttonLink="/procfee/newclaim"
          button2="Claims guide (PDF)"
          button2Link="assets/documents/Mortgage ClubHub - Proc Fee Guide.pdf"
        >
        </app-clubhub-home-section>
      </div>
      <div class="col">
        <app-clubhub-home-section
          image="criteria/home-page/1130318_ClubHub_Banner_Events.jpg"
          title="Events"
          content="Our popular UK-wide events programme is designed to help you grow your business by keeping you updated on the latest market & industry information. Register today to secure your place."
          button="Events"
          buttonLink="https://www.legalandgeneral.com/adviser/mortgage-club/events/?cid=PartnerSite18342"
        >
        </app-clubhub-home-section>
      </div>
      <div class="col pe-0">
        <app-clubhub-home-section
          image="criteria/home-page/1130318_ClubHub_Banner_Resources.jpg"
          title="Useful resources"
          content="Visit our Adviser Academy where you can find a number of educational webinars and videos, on a variety of topics in the mortgage market. You can also access a suite of Marketing Toolkits which include templates, images and guides to help promote your services to your customers."
          button="Adviser academy"
          buttonLink="https://www.legalandgeneral.com/adviser/mortgage-club/Adviser-Academy/#marketing-toolkits"
        >
        </app-clubhub-home-section>
      </div>
    </section>

    <section class="row">
      <div class="col box-green">
        <div class="box-inner">
          <h2 class="text-white">Contact us</h2>
          <div class="row">
            <div class="col"></div>
            <div class="col">
              <div class="cta">
                <p>
                  Can’t find what you need? Further support is available via our Mortgage Support Services on {{ contactUsPhone.text }}*. Lines are open
                  between 9.00am - 5.30pm. Alternatively, you can chat to us via
                  <a href="https://www.legalandgeneral.com/adviser/mortgage-club" target="_blank" rel="noopener noreferrer">Live Chat</a>
                  which is available on our website.<br />
                  <br />
                  <small>*We may record and monitor calls and call charges will vary.</small>
                </p>
                <i role="presentation" class="fa fa-phone"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</div>
