import { Inject, Injectable } from '@angular/core';
import orderBy from 'lodash-es/orderBy';
import { FilterKey, IIgniteFilterOption } from 'apps/clubhub/src/app/ignite/models/affordability';
import { AnalyticsService } from '@msslib/services/analytics.service';
import { OutcomeResults } from '@msslib/models/enums/outcome-results';
import { IgniteService } from 'apps/clubhub/src/app/ignite/services';
import { ISortOption, SortOrder } from '@msslib/models/filter-sort';
import { IgniteResponse } from 'apps/clubhub/src/app/ignite/models';
import { YesNoResults } from '@msslib/models';

@Injectable({
  providedIn: 'root',
})
export class ResultsFilterService {
  public constructor(
    @Inject(IgniteService) private igniteService,
    private analyticsService: AnalyticsService,
  ) {
  }

  public filter: IIgniteFilterOption = {};
  public filterOpened: boolean;

  public alphaSortOptions: readonly ISortOption[] = [
    { label: 'A to Z', value: SortOrder.Ascending, icon: 'fas fa-sort-alpha-down' },
    { label: 'Z to A', value: SortOrder.Descending, icon: 'fas fa-sort-alpha-down-alt' },
  ];

  public numericSortOptions: readonly ISortOption[] = [
    { label: 'High to Low', value: SortOrder.Descending, icon: 'fas fa-sort-numeric-down-alt' },
    { label: 'Low to High', value: SortOrder.Ascending, icon: 'fas fa-sort-numeric-down' },
  ];

  public loanSortOptions: readonly ISortOption[] = [
    { value: OutcomeResults.Yes, label: 'Yes', icon: 'far fa-check-circle text-success', iconColour: 'text-success' },
    {
      value: OutcomeResults.Refer,
      label: 'Refer', icon: 'fas fa-exchange-alt text-warning', iconColour: 'text-warning',
    },
    { value: OutcomeResults.No, label: 'No', icon: 'far fa-times-circle text-danger', iconColour: 'text-danger' },
    { value: OutcomeResults.Unset, label: 'Restricted', icon: 'fas fa-ban text-dark', iconColour: 'text-dark' },
  ];

  private get hasFilter(): boolean {
    return !!(this.filter.lenderName ??
      this.filter.lendersOrder ??
      this.filter.outcomeResult ??
      this.filter.igniteResultsOrder ??
      this.filter.propertyResult ??
      this.filter.productsMatched ??
      this.filter.initialRateOrder ??
      this.filter.trueCostInitialPeriodOrder ??
      this.filter.monthlyPaymentOrder
    );
  }

  public get hasOrdering(): boolean {
    return !!(this.filter.lendersOrder ??
      this.filter.maxValueOrder ??
      this.filter.igniteResultsOrder ??
      this.filter.outcomeResult ??
      this.filter.propertyResult ??
      this.filter.productsMatched ??
      this.filter.initialRateOrder ??
      this.filter.trueCostInitialPeriodOrder ??
      this.filter.monthlyPaymentOrder
    );
  }

  public resetFilter(): void {
    this.filter = {};
  }

  public onFilterChange(key: FilterKey , value: unknown): void {
    if (
      key === FilterKey.InitialRateOrder
      || key === FilterKey.TrueCostInitialPeriodOrder
      || key === FilterKey.MonthlyPaymentOrder
    ) {
      // Reset product fields order before set new one
      this.filter.initialRateOrder = undefined;
      this.filter.trueCostInitialPeriodOrder = undefined;
      this.filter.monthlyPaymentOrder = undefined;
    }

    this.filter = { ...this.filter, [key]: value };
    let filterType: string | undefined = undefined;
    let details: string | undefined = undefined;

    if (key === FilterKey.ProductMatched) {
      filterType = 'Products';
      if (value === YesNoResults.Yes) {
        details = 'Yes';
      } else if (value === YesNoResults.No) {
        details = 'No';
      } else {
        details = 'Unset';
      }

      return;
    }

    if (key === FilterKey.OutcomeResult || key === FilterKey.PropertyResult) {
      switch (key) {
        case FilterKey.OutcomeResult:
          filterType = 'Criteria';
          break;
        case FilterKey.PropertyResult:
          filterType = 'Property';
          break;
      }

      if (value === OutcomeResults.Yes) {
        details = 'Yes';
      } else if (value === OutcomeResults.Refer) {
        details = 'Refer';
      } else if (value === OutcomeResults.No) {
        details = 'No';
      } else if (value === OutcomeResults.Unset) {
        details = 'Restricted';
      } else {
        details = 'Unset';
      }
    }

    if (details !== undefined) {
      this.analyticsService.trackDropdown(`Select ${filterType} Result Type: ${details}`, details);
    }
  }

  public filterResults(results: IgniteResponse[] | undefined): IgniteResponse[] | undefined {
    if (!this.hasFilter || !results?.length) {
      return results;
    }

    const {
      lenderName,
      lendersOrder,
      igniteResultsOrder,
      outcomeResult,
      maxValueOrder,
      propertyResult,
      productsMatched,
      initialRateOrder,
      trueCostInitialPeriodOrder,
      monthlyPaymentOrder,
    } = this.filter;

    if (lenderName) {
      results = lenderName.length ? results.filter((o: IgniteResponse) => {
        return lenderName.some(x => x.toUpperCase() === o.lenderName.toUpperCase());
      }) : results;
    }

    if (outcomeResult) {
      results = results.filter(
        (result: IgniteResponse) =>
          this.igniteService.getCriteriaFilterResult(result) ===
          (outcomeResult === OutcomeResults.Unset ? undefined : outcomeResult),
      );
    }

    if (propertyResult) {
      results = results.filter(
        (result: IgniteResponse) =>
          this.igniteService.getPropertyFilterResult(result) ===
          (propertyResult === OutcomeResults.Unset ? undefined : propertyResult),
      );
    }

    if (productsMatched) {
      let resultsWithLenderProducts = results.map(result => {
        const lenderProducts = this.igniteService.getLenderMatchedProducts(result);
        return {
          lenderProducts: lenderProducts,
          ...result,
        };
      }).filter(result => {
        return productsMatched === YesNoResults.Yes
          ? result.lenderProducts?.products?.length
          : (!result.lenderProducts?.products?.length);
      });

      if (productsMatched === YesNoResults.No) {
        resultsWithLenderProducts = resultsWithLenderProducts.sort(r => !r.lenderProducts ? 1 : -1);
      }

      results = resultsWithLenderProducts;
    }

    if (lendersOrder) {
      results = orderBy(results, (o: IgniteResponse) =>
        o.lenderName, lendersOrder === SortOrder.Ascending ? 'asc' : 'desc');
    }

    if (igniteResultsOrder) {
      results = orderBy(
        results,
        // The fallback value for max value depends on the sort order, this way results with no data always appear last
        (o: IgniteResponse) => o.maximumAffordableLoanAmount
          ?? (igniteResultsOrder === SortOrder.Ascending ? Number.MAX_VALUE : -1),
        igniteResultsOrder === SortOrder.Ascending ? 'asc' : 'desc',
      );
    }

    if (maxValueOrder) {
      results = orderBy(
        results,
        (o: IgniteResponse) => o.maximumValue ?? '',
        maxValueOrder === SortOrder.Ascending ? 'asc' : 'desc',
      );
    }

    if (initialRateOrder) {
      results = this.sortProductsParameters(results, 'initialRateFrom', initialRateOrder);
    }

    if (trueCostInitialPeriodOrder) {
      results = this.sortProductsParameters(results, 'trueCostInitialPeriodFrom', trueCostInitialPeriodOrder);
    }

    if (monthlyPaymentOrder) {
      results = this.sortProductsParameters(results, 'monthlyPaymentFrom', monthlyPaymentOrder);
    }

    return results;
  }

  private sortProductsParameters(results: IgniteResponse[], productFieldName: string, sortOrder: SortOrder) {
    let resultsWithLenderProducts = results.map(result => {
      const lenderProducts = this.igniteService.getLenderMatchedProducts(result);
      return {
        lenderProducts: lenderProducts,
        ...result,
      };
    });

    resultsWithLenderProducts = orderBy(
      resultsWithLenderProducts,
      (r: any) => {
        let noLenderProductsSortRate = Number.MAX_SAFE_INTEGER;
        let noProductsSortRate = Number.MAX_SAFE_INTEGER - 1;
        if (sortOrder === SortOrder.Descending) {
          noLenderProductsSortRate = Number.MIN_SAFE_INTEGER;
          noProductsSortRate = Number.MIN_SAFE_INTEGER + 1;
        }

        return !r.lenderProducts ?
          noLenderProductsSortRate
          : (!r.lenderProducts.products?.length
              || (!r.lenderProducts[productFieldName] && r.lenderProducts[productFieldName] !== 0))
            ? noProductsSortRate
            : r.lenderProducts[productFieldName];
      },
      sortOrder === SortOrder.Ascending ? 'asc' : 'desc',
    );

    return resultsWithLenderProducts;
  }

  public toggleFilter(lenderInput: HTMLElement) {
    this.filterOpened = !this.filterOpened;
    this.filter = { ...this.filter, lenderName: null };
    if (this.filterOpened) {
      lenderInput.focus();
    }
  }
}
