<div
  class="input-group"
  [class.is-invalid]="invalid"
  [formlyAttributes]="field"
  #wrapperDiv
>
  <div class="date-wrapper form-control">
    <input
      type="number"
      class="form-control text-center"
      placeholder="DD"
      min="1"
      max="31"
      step="1"
      maxlength="2"
      (keyup)="onKeyup($event, monthControl)"
      (keydown)="onKeydown($event, 'day')"
      (blur)="onBlur('day')"
      [ngModel]="dateModel.day"
      (ngModelChange)="updateModel($event, 'day')"
      #dayControl
      [attr.data-testid]="props.testId  + '_day'"
    />
    <span class="input-group-text">/</span>
    <input
      type="number"
      class="form-control text-center"
      placeholder="MM"
      min="1"
      max="12"
      step="1"
      maxlength="2"
      (keyup)="onKeyup($event, yearControl)"
      (keydown)="onKeydown($event, 'month')"
      (blur)="onBlur('month')"
      [ngModel]="dateModel.month"
      (ngModelChange)="updateModel($event, 'month')"
      #monthControl
      [attr.data-testid]="props.testId + '_month'"
    />
    <span class="input-group-text">/</span>
    <input
      type="number"
      class="form-control text-center"
      placeholder="YYYY"
      min="1900"
      max="2100"
      step="1"
      maxlength="4"
      (blur)="onBlur('year')"
      [ngModel]="dateModel.year"
      (ngModelChange)="updateModel($event, 'year')"
      #yearControl
      [attr.data-testid]="props.testId + '_year'"
    />
  </div>
</div>
