<div class="filter dropdown" [class.wider]="wider" [class.large-height]="largeHeight">
  <div
    [class.disabled]="disabled"
    aria-expanded="false"
    class="filter-wrapper dropdown-toggle"
    [attr.data-testid]="'multiselect_dropdown_' + itemsLabel"
    [id]="dropdownId"
    [attr.data-bs-toggle]="disabled ? '' : 'dropdown'"
    (hide.bs.dropdown)="onDropdownHide()"
    (show.bs.dropdown)="onDropdownShow()"
    #dropdown
  >
    <div #inputWrapper class="filter-wrapper-input" tabindex="0">
      <div [class.d-none]="!itemsHidden" class="filter-selected-items">
        <div  class="filter-selected-count">
          {{selectedValues.length}} {{itemsLabel}} selected
        </div>
      </div>
    </div>
    <div [class.disabled]="disabled" class="filter-wrapper-button">
      <i *ngIf="!isShown" class="fas fa-chevron-down"></i>
      <i *ngIf="isShown" class="fas fa-chevron-up"></i>
    </div>
  </div>

  <div class="filter-input" [class.invisible]="itemsHidden">
    <div #selectedItems>
      <div class="filter-selected-items">
        <div *ngFor="let item of selectedValues"class="filter-selected-item">
          <div class="filter-selected-item-name">
            {{item}}
          </div>
          <div
            *ngIf="!disabled"
            (click)="removeItem(item)"
            class="filter-selected-item-remove"
            [attr.data-testid]="'multiselect_dropdown_remove_selected_' + item"
          >
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div #popper></div>

  <div class="dropdown-menu" [attr.aria-labelledby]="dropdownId">
    <div class="filter-search p-1">
      <input
        type="text"
        class="w-100"
        [attr.data-testid]="'multiselect_dropdown_search_input_' + itemsLabel"
        [(ngModel)]="searchTerm"/>
    </div>
    <div class="filter-options">
      <div
        class="filter-option p-1"
        *ngFor="let item of filteredOptions; index as index; trackBy: trackByFn;"
        (click)="$event.stopPropagation()"
      >
        <input
          class="form-check-input"
          type="checkbox"
          [id]="dropdownId + '-multiselect-filter-' + item"
          [attr.data-testid]="'multiselect_dropdown_option_' + itemsLabel + '_' + index"
          [value]="item"
          [checked]="isChecked(item)"
          (change)="changeValue(item, $event.target.checked)"
        />
        <label
          [for]="dropdownId + '-multiselect-filter-' + item"
          class="filter-option-label form-check-label ps-1"
        >
          {{ item }}
        </label>
      </div>
    </div>
  </div>
</div>
