import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'slugify'})
export class SlugifyPipe implements PipeTransform {
  public transform(input: string, separator: string): string {
    return input.toString().toLowerCase()
      .replace(/\s+/g, separator)
      .replace(/[^\w-]+/g, '')
      .replace(`/^${separator}+/`, '')
      .replace(`/${separator}+$/`, '');
  }
}
