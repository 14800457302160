import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ProductsFormFieldsBtlService } from './products-form-fields-btl.service';
import { ProductsFormFieldsResiService } from './products-form-fields-resi.service';
import { ProductFormFieldsBridgingService } from './products-form-fields-bridging.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsFormFieldsService {
  public constructor(
    private resiFields: ProductsFormFieldsResiService,
    private btlFields: ProductsFormFieldsBtlService,
    private bdgFields: ProductFormFieldsBridgingService,
  ) {}

  public getFields(lendingType: string): FormlyFieldConfig[] {
    switch (lendingType) {
      case 'btl':
        return this.btlFields.fields;
      case 'res':
        return this.resiFields.fields;
      case 'bdg':
        return this.bdgFields.fields;
      default:
        throw new Error(`No products question set configured for lending type '${lendingType}'`);
    }
  }
}
