<div class="modal-header {{ options.headerClass }}" *ngIf="!options.hideHeader">
  <h3 class="modal-title w-100 {{titleAlignment}}">
    {{ options.title }}
  </h3>
  <div class="modal-floating-title-content" *ngIf="options.titleFloatingContent">
    <div *ngIf="titleFloatingContentIsHtmlString; else floatingTplRef" [innerHTML]="options.titleFloatingContent"></div>
    <ng-template #floatingTplRef>
      <ng-component *ngTemplateOutlet="options.titleFloatingContent"></ng-component>
    </ng-template>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    *ngIf="!options.hideTopClose"
    (click)="cross()"
    data-testid="modal_close_btn"
  >
  </button>
</div>

<div class="modal-body" (scroll)="onScroll($event)">
  <div *ngIf="options.message" [innerHTML]="options.message"></div>

  <div class="container-fluid" *ngIf="options.template">
    <ng-container *ngTemplateOutlet="options.template; context: options.template.data"></ng-container>
  </div>
</div>

<div class="modal-footer" *ngIf="options.showButtons">
  <button data-testid="modal_cancel_btn" type="button" class="btn btn-2022--grey" (click)="no()" *ngIf="!options.hideCancel">
    {{ options.cancelLabel || 'Cancel' }}
  </button>
  <button data-testid="modal_submit_btn" type="button" class="btn btn-2022--secondary" (click)="yes()" *ngIf="!options.hideSubmit" [disabled]="isDisabled">
    {{ options.okLabel || 'Save' }}
  </button>
</div>
