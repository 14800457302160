<div class="esis-form-container mx-auto" [formGroup]="form">
  <ng-container formGroupName="details">
    <ng-container formGroupName="clientDetails">
      <div class="form-section">
        <div *ngFor="let clientForm of clients.controls; let idx = index;">
          <div class="group-label">{{clientDetailsTitle(idx)}}</div>
          <div [formGroup]="clientForm">
            <div class="mb-4 row align-items-center">
              <label class="input-label field-required" for="name">First Name</label>
              <div>
                <input
                  formControlName="forename"
                  type="text"
                  class="form-control"
                  [ngClass]="validityClasses('details.clientDetails.clients.' + idx + '.forename')"
                  id="forename"
                  placeholder="First Name"
                  [pattern]="namePattern"
                  required
                />
                <lib-input-error-description
                  [errorMessage]="'First Name is required.'"
                  [visible]="invalid('details.clientDetails.clients.' + idx + '.forename') &&
                   errors('details.clientDetails.clients.' + idx + '.forename')?.required"
                ></lib-input-error-description>
                <lib-input-error-description
                  [errorMessage]="'First Name contains invalid character/s.'"
                  [visible]="invalid('details.clientDetails.clients.' + idx + '.forename') &&
                   errors('details.clientDetails.clients.' + idx + '.forename')?.pattern"
                ></lib-input-error-description>
              </div>
            </div>
            <div class="mb-2 row align-items-center">
              <label class="input-label field-required" for="surname">Last Name</label>
              <div>
                <input
                  formControlName="surname"
                  type="text"
                  class="form-control"
                  [ngClass]="validityClasses('details.clientDetails.clients.' + idx + '.surname')"
                  id="surname"
                  placeholder="Last Name"
                  [pattern]="namePattern"
                  required
                />
                <lib-input-error-description
                  [errorMessage]="'Last Name is required.'"
                  [visible]="invalid('details.clientDetails.clients.' + idx + '.surname') &&
                  errors('details.clientDetails.clients.' + idx + '.surname')?.required"
                ></lib-input-error-description>
                <lib-input-error-description
                  [errorMessage]="'Last Name contains invalid character/s.'"
                  [visible]="invalid('details.clientDetails.clients.' + idx + '.surname') &&
                  errors('details.clientDetails.clients.' + idx + '.surname')?.pattern"
                ></lib-input-error-description>
              </div>
            </div>
          </div>
          <div class="mb-4 text-end">
            <div *ngIf="showAddClient(idx)" class="btn btn-2022--secondary" (click)="addClient()">Add Client<i class="fas fa-plus ms-2"></i></div>
            <div *ngIf="showDeleteClient(idx)" class="btn btn-2022--secondary ms-2" (click)="deleteClient(idx)">Remove<i class="fas fa-trash ms-2"></i></div>
          </div>
        </div>
        <div class="mb-4 row align-items-center">
          <label class="input-label field-required" for="clientPostcode">Postcode</label>
          <div>
            <input
              formControlName="postcode"
              type="text"
              class="form-control"
              [ngClass]="validityClasses('details.clientDetails.postcode')"
              id="clientPostcode"
              placeholder="Postcode"
              required
            />
            <div *ngIf="invalid('details.clientDetails.postcode')">
              <lib-input-error-description
                [errorMessage]="'Postcode is required.'"
                [visible]="errors('details.clientDetails.postcode').required"
              ></lib-input-error-description>
              <lib-input-error-description
                [errorMessage]="'Postcode must be no longer than 10 characters.'"
                [visible]="errors('details.clientDetails.postcode').maxLength"
              ></lib-input-error-description>
            </div>
          </div>
        </div>
        <div class="mb-4 row align-items-center">
          <label class="input-label" for="referenceNumber">Your Reference Number</label>
          <div>
            <input
              formControlName="referenceNumber"
              type="text"
              class="form-control"
              [ngClass]="validityClasses('details.clientDetails.referenceNumber')"
              id="referenceNumber"
              placeholder="Reference number"
            />
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="form-section">
    <div class="group-label">Procuration Fees</div>
    <ng-container formGroupName="fees">
      <ng-container formGroupName="procuration">
        <div class="mb-4 row align-items-center">
          <label class="input-label field-required" for="lenderIdx">Lender</label>
          <div>
            <select
              id="lenderIdx"
              class="form-select"
              formControlName="lenderIdx"
              [ngClass]="validityClasses('fees.procuration.lenderIdx')"
              (change)="onSelectedLenderChange()"
              required
            >
              <option [value]="undefined" hidden>Please select...</option>
              <!-- If there is a good lender match based on the chosen product, show that first -->
              <ng-container *ngIf="procFeeLenderGoodMatch as goodMatch">
                <option [value]="procFeeLenderGoodMatchIndex">{{ goodMatch.lenderName }}</option>
                <optgroup label="⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯"></optgroup>
              </ng-container>
              <!-- Show all lenders after a good match -->
              <option *ngFor="let lender of productProcFeeLenders; let idx = index; trackBy: trackById" [value]="idx">
                {{ lender.lenderName }}
              </option>
            </select>
            <lib-input-error-description
              [errorMessage]="'Lender is required.'"
              [visible]="invalid('fees.procuration.lenderIdx')"
            ></lib-input-error-description>
          </div>
        </div>

        <div class="mb-4 row align-items-center">
          <label class="input-label field-required">Select Mortgage Club and Product</label>
          <div class="club-list" *ngIf="hasSelectedLender; else noLenderSelected">
            <!-- For each club, show a select box with that club's products -->
            <div *ngFor="let club of clubsForSelectedLender; let clubIndex = index; trackBy: trackByIndex">
              <ng-container *ngIf="club.rates.length">
                <label class="input-label" [for]="'club-rate-select-' + clubIndex">{{ club.clubName }}</label>
                <select #rateSelect
                  [id]="'club-rate-select-' + clubIndex"
                  class="d-block form-select"
                  formControlName="rateId"
                  [ngClass]="rateIdProcFeePaymentAmountValidityClasses"
                  (change)="onSelectedRateChange($event)"
                  size="4"
                >
                  <option *ngFor="let rate of club.rates; trackBy: trackByRateId" [ngValue]="rate.rateId">
                    {{ rate.productName }} ({{ calculatePayment(rate) | currency:'GBP':'symbol':'1.0-0' }})
                  </option>
                </select>
              </ng-container>
            </div>

            <!-- After the clubs, show the 'Other' input box -->
            <div>
              <label class="input-label">Other...</label>
              <label class="input-label d-block mt-2" for="procFeePaymentAmount">Fee Amount</label>
              <div>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text h-100">£</span>
                  </div>
                  <input
                    id="procFeePaymentAmount"
                    class="form-control"
                    type="text"
                    formControlName="procFeePaymentAmount"
                    [ngClass]="rateIdProcFeePaymentAmountValidityClasses"
                    (input)="onOtherProcFeeAmountChange()"
                    placeholder="0.00"
                    mask="separator.2"
                    [allowNegativeNumbers]="false"
                  />
                </div>
                <lib-input-error-description
                  [errorMessage]="'Fee amount is required and must be a positive number.'"
                  [visible]="invalid('fees.procuration.procFeePaymentAmount')"
                ></lib-input-error-description>
              </div>
            </div>
          </div>

          <ng-template #noLenderSelected>
            Please select a lender first.
          </ng-template>

          <lib-input-error-description
              [errorMessage]="'Procuration fee amount is required.'"
              [visible]="invalid('fees.procuration') && errors('fees.procuration')?.required"
            ></lib-input-error-description>
        </div>

        <div class="mb-4 row align-items-center">
          <label class="input-label">Payable To</label>
          <div>
            <input type="text" class="form-control" [value]="formDetails.brokerFirmName" disabled />
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <hr />

  <div class="group-label">Loan Details</div>
  <div class="mb-4 align-items-center">
    <label class="input-label">Product Description</label>
    <div>
      <input type="text" class="form-control" [value]="productDescription" disabled />
    </div>
  </div>
  <div class="mb-4 row align-items-center">
    <label class="input-label">Product Code</label>
    <div>
      <input type="text" class="form-control" [value]="productCode" disabled />
    </div>
  </div>
  <div class="mb-4 row align-items-center">
    <label class="input-label">Property Value</label>
    <div>
      <div class="input-group">
        <span class="input-group-text">£</span>
        <input type="text" class="form-control" [value]="searchDetails.propertyValue | number : '1.0-0'" disabled />
      </div>
    </div>
  </div>
  <div class="mb-4 row align-items-center">
    <label class="input-label">Loan Amount</label>
    <div>
      <div class="input-group">
        <span class="input-group-text">£</span>
        <input type="text" class="form-control" [value]="searchDetails.loanAmount | number : '1.0-0'" disabled />
      </div>
    </div>
  </div>
  <div class="mb-4 row align-items-center" *ngIf="searchDetails.outstandingBalanceCurrentMortgage">
    <label class="input-label">Outstanding Balance on the Current Mortgage</label>
    <div>
      <div class="input-group">
        <span class="input-group-text">£</span>
        <input type="text" class="form-control" [value]="searchDetails.outstandingBalanceCurrentMortgage | number : '1.0-0'" disabled />
      </div>
    </div>
  </div>
  <div class="mb-4 row align-items-center">
    <label class="input-label">Loan to Value</label>
    <div>
      <div class="input-group">
        <input type="text" class="form-control" [value]="ltv" disabled />
        <span class="input-group-text">%</span>
      </div>
    </div>
  </div>
  <div class="mb-4 row align-items-center">
    <label class="input-label">Mortgage Term</label>

    <div *ngIf="isBridging; else resAndBtlMortgageTermTemplate" class="col-3 pe-2">
      <div class="input-group">
        <input type="text" class="form-control" [value]="searchDetails.mortgageTerm.months || 0" disabled />
        <span class="input-group-text">Months</span>
      </div>
    </div>

    <ng-template #resAndBtlMortgageTermTemplate>
      <div class="col-3 pe-2">
        <div class="input-group">
          <input type="text" class="form-control" [value]="searchDetails.mortgageTerm.years || 0" disabled />
          <span class="input-group-text">Years</span>
        </div>
      </div>
      <div class="col-3 ps-2">
        <div class="input-group">
          <input type="text" class="form-control" [value]="searchDetails.mortgageTerm.months || 0" disabled />
          <span class="input-group-text">Months</span>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="mb-4 row align-items-center">
    <label class="input-label">Payment Method</label>
    <div>
      <input type="text" class="form-control" [value]="repaymentMethod" disabled />
    </div>
  </div>

  <hr />

  <div class="form-section">
    <div class="group-label">Broker Details</div>
    <div class="mb-4 row align-items-center">
      <label class="input-label">Broker Name</label>
      <div>
        <input type="text" class="form-control" [value]="broker?.brokerName" disabled />
      </div>
    </div>
    <div class="mb-4 row align-items-center">
      <label class="input-label">Address</label>
      <div>
        <textarea class="form-control resize-none" [rows]="addressLinesBroker.length" disabled>{{
          addressLinesBroker.join('\n')
        }}</textarea>
      </div>
    </div>
    <div class="mb-4 row align-items-center">
      <label class="input-label">Telephone Number</label>
      <div>
        <input type="text" class="form-control" [value]="broker?.telephone" disabled />
      </div>
    </div>
    <div class="mb-4 row align-items-center">
      <label class="input-label">Email Address</label>
      <div>
        <input type="text" class="form-control" [value]="broker?.emailAddress" disabled />
      </div>
    </div>
  </div>
  <hr />

  <div class="form-section">
    <div class="group-label">Complaints Details</div>
    <div class="mb-4 row align-items-center">
      <label class="input-label">Contact Person / Department Name</label>
      <div>
        <input type="text" class="form-control" [value]="complaints?.contactPerson" disabled />
      </div>
    </div>
    <div class="mb-4 row align-items-center">
      <label class="input-label">Address</label>
      <div>
        <textarea class="form-control resize-none" [rows]="addressLines.length" disabled>{{ addressLines.join('\n') }}</textarea>
      </div>
    </div>
    <div class="mb-4 row align-items-center">
      <label class="input-label">Telephone Number</label>
      <div>
        <input type="text" class="form-control" [value]="complaints?.telephone" disabled />
      </div>
    </div>
    <div class="mb-4 row align-items-center">
      <label class="input-label">Email Address</label>
      <div>
        <input type="text" class="form-control" [value]="complaints?.emailAddress" disabled />
      </div>
    </div>
    <div class="mb-4 row align-items-center">
      <label class="input-label">Max time for handling complaints</label>
      <div>
        <div class="input-group">
          <input type="text" class="form-control" [value]="complaints?.maxHandlingTime" disabled />
          <span class="input-group-text">Weeks</span>
        </div>
      </div>
    </div>
  </div>
</div>
