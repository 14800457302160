<div *ngIf="banner && visible" class="banner d-print-none" [ngClass]="['justify-content-' + content]">
  <div
    class="banner-container"
    [style.max-width]="maxWidth"
  >
    <div *ngIf="withCloseButton" class="banner-close-button" (click)="close()">
      <i class="far fa-window-close"></i>
    </div>
    <a *ngIf="banner.link" href="{{ banner.link }}" target="_blank" rel="noopener noreferrer">
      <img
        class="img-fluid"
        [style.max-height]="maxHeight"
        [src]="banner.imageUrl"
        [alt]="banner.altText"/>
    </a>
    <img *ngIf="!banner.link"
      class="img-fluid"
      [style.max-height]="maxHeight"
      [src]="banner.imageUrl"
      [alt]="banner.altText"/>
  </div>
</div>
