export enum InaccuracyReportCategory {
  ScreenShotIncorrect = 1,
  LoanAmountGivenDoesNotSeemRight = 2,
  CriteriaIsIncorrect = 3,
  Other = 4,
  ProductsAreIncorrect = 5,
}

export enum InaccuracyReportSubCategory {
  ScreenshotFiguresNotWhatWasEntered = 1,
  ScreenshotFigureEnteredIntoDifferentField = 2,
  ScreenshotOther = 3,
  LoanAmountHigherThanExpected = 4,
  LoanAmountLowerThanExpected = 5,
  LoanAmountOther = 6,
  CriteriaIntuitiveIncorrect = 7,
  CriteriaAffordabilityCriteriaIncorrect = 8,
  CriteriaOther = 9,
  CriteriaOutcomeNotesIncorrect = 10,
}
