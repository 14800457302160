<ul class="navbar-nav" *ngIf="isAuthenticated$ | async; else notAuthenticated">
  <li class="nav-item">
    <div ngbDropdown display="dynamic" placement="bottom-right" class="float_right">
      <a class="nav-link text-dark" title="Manage" id="my-account" ngbDropdownToggle>My Account</a>
      <div ngbDropdownMenu aria-labelledby="myAccountDropdown">
        <button ngbDropdownItem *ngFor="let item of (accountMenu$ | async)" [routerLink]="item.routerLink"
                [attr.data-testid]="item.label + 'Btn'">
          {{ item.label }}
        </button>
      </div>
    </div>
  </li>
  <li class="nav-item">
    <a class="nav-link text-dark" [routerLink]="['/authentication/logout']" [state]="{ local: true }" title="Logout">Logout</a>
  </li>
</ul>

<ng-template #notAuthenticated>
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLink]="['/authentication/register']">Register</a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLink]="['/authentication/login']">Login</a>
    </li>
  </ul>
</ng-template>
