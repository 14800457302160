import { Inject, Injectable } from '@angular/core';
import { LendingTypeService } from '@msslib/services/lending-type.service';
import {
  BridgingProduct, BridgingPurchaserType, LendingTypeCode, Product, RepaymentMethod,
} from 'apps/shared/src/models';
import { BridgingProductsHelper } from 'apps/shared/src/helpers/bridging-products.helper';
import uniqBy from 'lodash-es/uniqBy';
import {
  SourcingProductsFilterService,
} from 'apps/clubhub/src/app/ignite/services/products-filter/sourcing-products-filter.service';
import * as ProductFiltersConstants from 'apps/clubhub/src/app/ignite/constants/product-filters';
import { ProductFeeType } from '@msslib/constants';

export interface FilterOptionsContext<T> {
  products: T[] | null;
  initialPeriod?: number;
}

export interface ProductsFilterContext {
  repaymentMethod?: RepaymentMethod;
}

export interface ProductFilterDefinition<T> {
  title: string;
  isMatchFunc?: (product: T) => boolean;
  isUnknownFunc?: (product: T) => boolean;
  isExcludeFunc?: (product:T ) => boolean;
  visible?: (ctx: FilterOptionsContext<T>) => boolean;
  /** Override the visible status of the checkbox. Does not affect the generated filter function, only the UI. */
  overrideChecked?: (ctx: FilterOptionsContext<T>) => boolean | null;
  isCriteriaFilter?: boolean;
  hasSubFilters?: boolean;
  itemFactory?: (ctx: FilterOptionsContext<T>) => ProductFilterDefinition<T>[];
  isMatchFuncFactory?: (key: string) => (product: T) => boolean;
}

export type ProductFilterGroupDefinition<T> = {
  title: string;
  requestObjKey: string;
  isIncludeExcludeFilter?: boolean;
  itemOperator: 'and' | 'or';
  groupInfoText?: string;
  hasSubFilters?: boolean;
  disabled?: (ctx: FilterOptionsContext<T>) => boolean;
} & ({
  items: ProductFilterDefinition<T>[];
} | {
  itemFactory: (ctx: FilterOptionsContext<T>) => ProductFilterDefinition<T>[];
  isMatchFuncFactory: (key: string) => (product: T) => boolean;
  isUnknownFuncFactory?: (key: string) => (product: T) => boolean;
});


@Injectable({
  providedIn: 'root',
})
export class ProductsFilterHelper {
  public constructor(
    @Inject(LendingTypeService) private lendingTypeService,
    private bridgingProductsHelper: BridgingProductsHelper,
    private sourcingProductsFilterService: SourcingProductsFilterService,
  ) { }

  // A list of all options for the initial period range.
  private readonly initialPeriodRangeOptions = [1, 2, 3, 4, 5, 6, 7, 10, 15];

  private initialPeriodFilter(period: number | null): (p: Product) => boolean {
    // A 'null' period indicates a for-term product
    if (period === null) {
      return p => p.initialPeriod === null;
    }

    // If a non-null period is given:
    // Find the current period in years with the upper and lower bounds of 6 months.
    // Return a filter function that finds the products within the upper and lower bounds.
    // E.G. for a 10 year filter 10 * 12 = 120 months making the lower 114 and upper 126 month bounds.
    // meaning we return products which are more or equal to 126 and less or equal to 114 months.

    const periodInMonths = period * 12;
    const periodUpperLimit = periodInMonths + 9;
    const periodLowerLimit = periodInMonths - 2;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return p => p.initialPeriod! >= periodLowerLimit &&
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    p.initialPeriod! <= periodUpperLimit;
  }

  private get productTypeCode(): LendingTypeCode {
    return (this.lendingTypeService as LendingTypeService).lendingType?.code as LendingTypeCode ?? LendingTypeCode.Res;
  }

  public resetSourcingFilters(products?: Product[] | null) {
    this.sourcingProductsFilterService.reset(products);
  }

  public clearSourcingFilters() {
    this.sourcingProductsFilterService.clear();
  }

  private titleCase(text: string):string {
    return text
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  public resAndBtlProductFilterDefinitions(): ProductFilterGroupDefinition<Product>[] {
    return [
      {
        requestObjKey: 'rateType',
        title: 'Rate Type',
        itemOperator: 'or',
        itemFactory: ({ products }) => uniqBy((products ?? []).map(x => x.initialRateType), t => t?.toLowerCase())
          .filter(type => type !== null && type !== undefined && type !== '')
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .map(type => type !== 'Tracker (SONIA)' ? this.titleCase(type!) : type)
          .sort()
          .map(type => ({ key: type, title: type } as ProductFilterDefinition<Product>)),
        isMatchFuncFactory: key => product => {
          const normalizedKey = key.toLowerCase();
          const normalizedProductType = (product.initialRateType ?? '').toLowerCase();

          return normalizedKey === 'tracker' ?
          // eslint-disable-next-line spellcheck/spell-checker
            normalizedProductType === 'tracker' || normalizedProductType === 'tracker (sonia)'
            : normalizedProductType === normalizedKey;
        },
      },
      {
        requestObjKey: 'initialPeriod',
        title: 'Initial Product Period',
        itemOperator: 'or',
        groupInfoText: '<span>If select 2 years - show products with an initial period between 22 to 33 months</span>',
        disabled: ({ initialPeriod }) => initialPeriod !== undefined && initialPeriod !== 0,
        items: [
          // Note that products with a month count not divisible by 12 should match for either of their nearest years.
          // For example, an 18 month period should show up for 1 year products (12 months) OR
          // 2 year products (24 months)
          ...this.initialPeriodRangeOptions.map(length => ({
            title: `${length} year${length === 1 ? '' : 's'}`,
            isMatchFunc: this.initialPeriodFilter(length),
            overrideChecked: ({ initialPeriod }) => initialPeriod === undefined ? null : initialPeriod === length,
          } as ProductFilterDefinition<Product>)),
          {
            title: 'For Term',
            isMatchFunc: this.initialPeriodFilter(0),
            overrideChecked: ({ initialPeriod }) => initialPeriod === undefined ? null : initialPeriod === 0,
          },
        ],
      },
      // If either product features or tailored products are changed, ensure that the filter logic on the backend
      // is also changed in MSS.SmartrCriteria/Extensions/ProductFilterExtensions.cs
      {
        requestObjKey: 'productPreferences',
        title: 'Product Features',
        itemOperator: 'and',
        items: [
          {
            title: 'No ERC\'s applied',
            isMatchFunc: p => !p.earlyRepaymentChargesErcApplied,
          },
          {
            title: 'ERC\'s applied',
            isMatchFunc: p => p.earlyRepaymentChargesErcApplied === true,
          },
          {
            title: 'Cashback',
            isMatchFunc: p => !!p.cashback && p.cashback > 0,
            isUnknownFunc: p => p.cashback === null,
          },
          {
            title: 'Free legal',
            isMatchFunc: p => p.freeLegal === true || p.freeLegal === null,
          },
          {
            title: 'Free val',
            isMatchFunc: p => p.freeVal === true || p.freeVal === null || (p.fees[ProductFeeType.Valuation] ?? 0) === 0,
          },
          {
            title: 'No Arrangement fee',
            isMatchFunc: p => !(ProductFeeType.Arrangement in p.fees),
          },
          {
            title: 'No Booking fee',
            isMatchFunc: p => !(ProductFeeType.Booking in p.fees),
          },
          {
            title: 'Higher lending fee',
            isMatchFunc: p => ProductFeeType.HigherLending in p.fees &&
            (p.fees[ProductFeeType.HigherLending] ?? 0) > 0,
          },
          {
            title: 'Valuation fee',
            isMatchFunc: p => ProductFeeType.Valuation in p.fees &&
              (p.fees[ProductFeeType.Valuation] ?? 0) > 0,
          },
        ],
      },
      {
        requestObjKey: 'productPreferences',
        title: 'Tailored Products',
        groupInfoText: `
          <span>
            Tick = Only show these products in the search results. <br/>
            Cross = Remove these products from search results. <br/>
            Blank (neither tick nor cross selected) = Products will be shown alongside
            other products in the search results
          </span>`,
        isIncludeExcludeFilter: true,
        hasSubFilters: true,
        itemOperator: 'and',
        items: [
          {
            title: ProductFiltersConstants.greenEcoFilterTitle,
            ...this.sourcingProductsFilterService
              .sourcingCriteriaFilterFor(ProductFiltersConstants.greenEcoFilterValue),
          },
          {
            title: ProductFiltersConstants.offsetFilterTitle,
            ...this.sourcingProductsFilterService
              .sourcingCriteriaFilterFor(ProductFiltersConstants.offsetFilterValue),
            visible: () => this.productTypeCode === LendingTypeCode.Res,
          },
          {
            title: ProductFiltersConstants.expatNotInUkFilterTitle,
            ...this.sourcingProductsFilterService
              .sourcingCriteriaFilterFor(ProductFiltersConstants.expatNotInUkFilterValue),
            isCriteriaFilter: true,
          },
          {
            title: ProductFiltersConstants.secondResidentialFilterTitle,
            ...this.sourcingProductsFilterService
              .sourcingCriteriaFilterFor(ProductFiltersConstants.secondResidentialFilterValue),
            isCriteriaFilter: true,
            visible: () => this.productTypeCode === LendingTypeCode.Res,
          },
          {
            title: ProductFiltersConstants.regulatedBTLFilterTitle,
            ...this.sourcingProductsFilterService
              .sourcingCriteriaFilterFor(ProductFiltersConstants.regulatedBTLFilterValue),
            visible: () => this.productTypeCode === LendingTypeCode.Btl,
            isCriteriaFilter: true,
          },
          {
            title: 'Direct Only',
            isMatchFunc: p => p.direct === true,
            isUnknownFunc: () => false,
            isExcludeFunc: p => p.direct !== true,
            isCriteriaFilter: true,
          },
          {
            title: 'Packagers',
            hasSubFilters: true,
            itemFactory: ({ products }) => uniqBy((products ?? []).map(x => x.packager), t => t?.toLowerCase())
              .filter(type => type !== null && type !== undefined && type !== '')
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              .sort()
              .map(type => ({ key: type, title: type } as ProductFilterDefinition<Product>)),
            isMatchFuncFactory: key => product => product.packager?.toLowerCase() === key.toLowerCase(),
          },
        ],
      },
    ];
  }

  public readonly bridgingProductFilterDefinitions: ProductFilterGroupDefinition<BridgingProduct>[] = [
    {
      // Bridging! Rename?
      requestObjKey: 'productType',
      title: 'Product Type',
      itemOperator: 'or',
      itemFactory: ({ products }) => uniqBy((products ?? [])
        .map(x => this.bridgingProductsHelper.productTypeToString(x.productType)), t => t)
        .filter(type => type !== null && type !== undefined)
        .sort()
        .map(type => ({ key: type, title: type } as ProductFilterDefinition<BridgingProduct>)),
      isMatchFuncFactory: key =>
        product => this.bridgingProductsHelper.productTypeToString(product.productType) === key,
    },
    {
      requestObjKey: 'productPeriod',
      title: 'Product Period',
      itemOperator: 'or',
      itemFactory: ({ products }) => uniqBy((products ?? [])
        .map(x => this.bridgingProductsHelper.maxTermToString(x.maxTerm)), t => t)
        .filter(type => type !== null && type !== undefined)
        .sort()
        .map(type => ({ key: type, title: type } as ProductFilterDefinition<BridgingProduct>)),
      isMatchFuncFactory: key =>
        product => this.bridgingProductsHelper.maxTermToString(product.maxTerm) === key,
    },
    {
      requestObjKey: 'productPreferences',
      title: 'Product Features',
      itemOperator: 'and',
      items: [
        {
          title: 'Exit fee charges applied',
          isMatchFunc: p => p.exitFee,
          isCriteriaFilter: true,
        },
        {
          title: 'Dual legal representation',
          isMatchFunc: p => p.dualLegalRepresentation === true,
          isUnknownFunc: p => p.dualLegalRepresentation === null,
          isCriteriaFilter: true,
        },
        {
          title: 'Free legal',
          isMatchFunc: p => p.freeLegal === true,
          isUnknownFunc: p => p.freeLegal === null,
          isCriteriaFilter: true,
        },
        {
          title: 'Avm accepted',
          isMatchFunc: p => p.avmAccepted === true,
          isUnknownFunc: p => p.avmAccepted === null,
          isCriteriaFilter: true,
        },
      ],
    },
    {
      requestObjKey: 'productPreferences',
      title: 'Tailored Products',
      isIncludeExcludeFilter: true,
      hasSubFilters: true,
      itemOperator: 'and',
      items: [
        {
          title: 'Expat not in UK',
          isMatchFunc: p => p.expatNotInUk === true,
          isExcludeFunc: p => p.expatNotInUk === false,
          isUnknownFunc: p => p.expatNotInUk === null,
          isCriteriaFilter: true,
        },
        {
          title: 'Limited Company',
          isMatchFunc: p => p.purchaserTypes?.includes(BridgingPurchaserType.LimitedCompany),
          isUnknownFunc: p => p.purchaserTypes === null,
          isCriteriaFilter: true,
        },
        {
          title: 'Bridge to Let',
          isMatchFunc: p => p.bridgeToLet === true,
          isExcludeFunc: p => p.bridgeToLet === false,
          isUnknownFunc: p => p.bridgeToLet === null,
          isCriteriaFilter: true,
        },
        {
          title: 'Portfolio Landlord',
          isMatchFunc: p => p.portfolioLandlord === true,
          isExcludeFunc: p => p.portfolioLandlord === false,
          isUnknownFunc: p => p.portfolioLandlord === null,
          isCriteriaFilter: true,
        },
      ],
    },
  ];
}
