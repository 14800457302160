import { GridStateModel } from '../../../models';
import { SimpleGridComponent } from '../simple-grid.component';
import { Datasource } from './datasource';

export class PromiseDatasource<T = unknown> implements Datasource<T> {

  public allItems: T[];

  public constructor(private promise: Promise<T[]>) {
    promise.then(items => this.allItems = items);
  }

  public async getItems(_state: GridStateModel, grid: SimpleGridComponent) {
    const items = await this.promise;
    const filteredItems = grid.clientSideFilterSort(items);
    return { items: grid.clientSidePaginate(filteredItems), totalCount: filteredItems.length };
  }
}
