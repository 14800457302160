import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PropertyMetadata } from '@msslib/models/rules-engine';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { OperatorFunction, Subject, map, merge } from 'rxjs';

@Component({
  selector: 'lib-rules-engine-property-selector',
  templateUrl: 'property-selector.component.html',
  styleUrls: ['property-selector.component.scss'],
})
export class RulesEnginePropertySelectorComponent {
  @ViewChild('typeahead', { static: true }) public typeahead: NgbTypeahead;

  @Input() public properties: PropertyMetadata[];

  @Input() public propertyName: string;
  @Input() public index:number;
  @Output() public propertyNameChange = new EventEmitter<string>();

  // https://ng-bootstrap.github.io/#/components/typeahead/examples#focus
  public focus$ = new Subject<string>();

  public get selectedProperty() {
    return this.properties.find(p => p.name === this.propertyName);
  }

  public typeaheadOperator: OperatorFunction<string, readonly PropertyMetadata[]> = text$ => {
    return merge(text$, this.focus$).pipe(
      map(term => term.toLowerCase()),
      map(term => this.properties
        .filter(p => term === '' || p.displayName.toLowerCase().includes(term))),
    );
  };

  public formatter = (result: PropertyMetadata) => result.displayName;

  // When the input is focused, clear the input so that it behaves more like a <select>.
  public openTypeahead(event: FocusEvent) {
    if (!this.typeahead.isPopupOpen()) {
      (event.currentTarget as HTMLInputElement).value = '';
      this.focus$.next('');
    }
  }

  // When the dropdown closes, if there is a property selected, re-select it so that it behaves more like a <select>.
  public closeTypeahead(event: Event) {
    (event.currentTarget as HTMLInputElement).value = this.selectedProperty?.displayName ?? '';
  }
}
