export enum PropertyLenderAudit {
  ErrorReported = 0,
  Approved = 1,
  NotCompleted = 2,
  NotApplicable = 3,
}

export enum PropertyAuditResultsStatus {
  NotSubmitted = 1,
  Approved = 2,
  ErrorReported = 3,
}

