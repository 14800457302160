import { Injectable } from '@angular/core';
import { UserGuideDetails } from 'apps/shared/src/models';
import { downloadBlob } from '@msslib/helpers/url-helpers';
import { Observable } from 'rxjs';
import { ClubHubDataService } from './clubhub-data.service';

@Injectable({
  providedIn: 'root',
})
export class UserGuideService {
  public constructor(private dataService: ClubHubDataService) {}

  public listGuideDetails(): Observable<UserGuideDetails[]> {
    return this.dataService.get<UserGuideDetails[]>('UserGuide', { loading: false });
  }

  public getGuideUrl(guideName: string): string {
    return `${this.dataService.baseUrl}/UserGuide/${guideName}`;
  }

  public uploadGuideRevision(guideName: string, file: File | undefined): Observable<void> | undefined {
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('guideFile', file);
    return this.dataService.put(`UserGuide/${guideName}`, formData);
  }

  public downloadGuide(guideName: string) {
    this.dataService.getFile(`UserGuide/${guideName}`, 'application/pdf')
      .subscribe(blob => downloadBlob(blob, 'Ignite_UserGuide.pdf'));
  }
}
