import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LenderHubDataService } from '@msslib/services/lenderhub-data.service';
import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { ClientApps } from '@msslib/models';

export interface DisclaimerResponse {
  disclaimerRead: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DisclaimerService {
  public constructor(
    private lenderHubDataService: LenderHubDataService,
    private clubHubDataService: ClubHubDataService,
  ) {}

  public setDisclaimerAsRead(client: ClientApps): Observable<void> {
    switch (client) {
      case ClientApps.ClubHub:
        return this.clubHubDataService.post('Broker/UserReadLatestDisclaimer', null);
      case ClientApps.LenderHub:
        return this.lenderHubDataService.post('LenderDisclaimer/UserReadLatestDisclaimer', null);
      default:
        throw new Error('Unrecognised client error');
    }
  }

  public hasUserDisclaimerBeenRead(client: ClientApps): Observable<DisclaimerResponse> {
    switch (client) {
      case ClientApps.ClubHub:
        return this.clubHubDataService.get<DisclaimerResponse>('Broker/UserReadLatestDisclaimer');
      case ClientApps.LenderHub:
        return this.lenderHubDataService.get<DisclaimerResponse>('LenderDisclaimer/UserReadLatestDisclaimer');
      default:
        throw new Error('Unrecognised client error');
    }
  }
}
