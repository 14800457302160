export function scrollIntoView(selector: string, scope?: HTMLElement): void;
export function scrollIntoView(target: HTMLElement): void;
export function scrollIntoView(target: string | Element | null, scope: HTMLElement | null = null): void {
  setTimeout(() => {
    if (typeof target === 'string') {
      const selector = target;
      target = (scope ?? document.body).querySelector(selector);

      // if target invisible
      if (target && !target.clientWidth) {
        const allTargets = (scope ?? document.body).querySelectorAll(selector) as NodeListOf<HTMLElement>;
        const visibleTargets = Array.from(allTargets).filter(e => e.clientWidth);
        target = visibleTargets[0];
      }
    }
    if (target !== null && target !== undefined) {
      (target as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, 1);
}
