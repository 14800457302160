import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

type FormControlPath = Parameters<UntypedFormGroup['get']>[0];

@Component({
  selector: 'lib-esis-form-base',
  template: '',
})
export class EsisFormBaseComponent {
  @Input() public form: UntypedFormGroup;

  public value(path: FormControlPath) {
    return this.form.get(path)?.value;
  }

  public touched(path: FormControlPath) {
    const ctrl = this.form.get(path);
    return ctrl?.touched;
  }

  public valid(path: FormControlPath) {
    const ctrl = this.form.get(path);
    return ctrl?.valid && ctrl.touched;
  }

  public errors(path: FormControlPath) {
    return this.form.get(path)?.errors;
  }

  public invalid(path: FormControlPath) {
    const ctrl = this.form.get(path);

    // If the given path is a group, then use special handling. A group is considered 'touched' if ANY of its controls
    // have been touched. If the user edits one field in a group with multiple controls, the group would be considered
    // touched and invalid even though the user has not yet touched the other fields.
    if (!ctrl || !('controls' in ctrl)) {
      return ctrl?.invalid && ctrl.touched;
    }

    const pathArray = Array.isArray(path) ? path : path.split('.');
    if (Array.isArray(ctrl.controls)) {
      if (ctrl.invalid) {
        return true;
      }
      (ctrl as UntypedFormArray).controls.some((_, index) => this.invalid([...pathArray, index]));
    } else {
      return Object.keys((ctrl as UntypedFormGroup).controls)
        .some(controlName => this.invalid([...pathArray, controlName]));
    }
  }

  public validityClasses(...paths: FormControlPath[]) {
    return {
      'is-valid': paths.every(path => this.valid(path)),
      'is-invalid': paths.some(path => this.invalid(path)),
    };
  }

  public trackById<T extends { id: number } = any>(_index: number, item: T) {
    return item.id;
  }

  public trackByKey<T extends { key: number } = any>(_index: number, item: T) {
    return item.key;
  }

  public trackByIndex(index: number) {
    return index;
  }
}
