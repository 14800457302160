import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface VerificationFlowResult {
  shouldSave: boolean;
}

export interface SendVerificationEmailResponse {
  requestAgainAt: Date;
  expiresAt: Date;
}

export type VerifyCodeResponse = {
  success: false;
  hasMoreAttempts: boolean;
} | {
  success: true;
  hasMoreAttempts: false;
  verificationToken: string;
};

export interface IEmailVerificationService {
  /** Gets the email verification token that can be attached to requests to prove verification to the server. */
  get verificationToken(): string | null;

  /** Whether or not the verification code has expired. */
  get hasVerificationCodeExpired(): boolean;

  /** Whether the user can request a verification email. */
  get canRequestVerificationEmail(): boolean;

  /** Number of seconds before a user can request another verification email. */
  get canRequestVerificationEmailIn(): number;

  /** If the user is not currently verified, starts a verification flow as per `beginEmailVerificationFlow`. */
  assertEmailVerified(): Promise<void>;

  /** Starts an email verification flow (showing modal asking user to verify their email address).
   * If the verification was successful, the returned promise resolves. If the verification failed or was cancel, it
   * instead rejects. */
  beginEmailVerificationFlow(): Promise<void>;

  sendVerificationEmail(): Observable<SendVerificationEmailResponse>;
  submitVerificationCode(code: string): Observable<VerifyCodeResponse>;

  /** Saves the currently stored verification token for future sessions to use. */
  persistVerificationToken(): void;

  /** Removes a persisted verification token, requiring the user to re-verify their email address. */
  unsetVerificationToken(): void;
}

export const EMAIL_VERIFICATION_SERVICE = new InjectionToken<IEmailVerificationService>('emailVerificationService');
