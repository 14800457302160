import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { map } from 'rxjs';
import { AuthorizeService, IUser } from '../authorize.service';
import { ModalService } from '../modal.service';
import { AgencyNumberModalComponent } from '@msslib/components';
import { clientIds } from '@msslib/constants';

export const agencyCodeGuard: CanActivateFn =
  (
    _next: ActivatedRouteSnapshot,
  ) => {
    const authorizeService = inject(AuthorizeService);
    const modalService = inject(ModalService);

    return authorizeService.getUser().pipe(
      map((user: IUser) => {
        if (user?.clientId === clientIds.simplyBiz) {
          return false;
        }

        if (user?.agencyNumber) {
          return true;
        }

        modalService
          .open({
            title: 'Please enter your Legal and General Agency Number',
            component: AgencyNumberModalComponent,
            size: 'md',
            sticky: true,
            client: _next.data.client,
          }).then(() => null, () => null);
        return false;
      }));
  };
