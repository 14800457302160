import { Inject, Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormatService } from '@msslib/services/format.service';
import { FormsValidators } from '@msslib/components/forms/validators/forms.validators';
import { IInputOption } from '@msslib/components/formly/models/input';
import { mapDateToUtc } from '@msslib/helpers/date-helpers';
import { KeyCodes } from '@msslib/constants/key-codes';
import {
  IPropertyBtl,
  IUiMainIncome,
  IUiOtherMortgage,
} from 'apps/clubhub/src/app/ignite/models/affordability';
import {
  ApplicantCountry,
  BtlType,
  EmploymentStatus,
  LenderName,
  LendingTypeCode,
  MortgageLenders,
  MortgageType,
  ProductLength,
  RepaymentMethod,
} from 'apps/shared/src/models';
import { FormFieldType, WrapperType } from '@msslib/components/formly/formly.config';
import { IDateInput } from '@msslib/components/formly/models/date-input';
import { LendingType } from '@msslib/models/lending-type';

interface LtvInputs {
  mortgageType?: MortgageType;
  loanAmount?: number | null;
  propertyValue?: number | null;
  outstandingBalanceCurrentMortgage?: number | null;
}

@Injectable({
  providedIn: 'root',
})
export class IgniteHelperService {
  public constructor(@Inject(FormatService) private formatService) {}

  public get yesNoOptions() {
    return [
      { value: true, icon: 'check', label: 'Yes', srOnly: true },
      { value: false, icon: 'times', label: 'No', srOnly: true },
    ];
  }

  public get yearMonthValidator() {
    return {
      lengthOfTime: {
        expression: (control: UntypedFormControl) => {
          const { years, months } = control.value;
          return years > 0 || months > 0;
        },
        message: (_error: unknown, field: FormlyFieldConfig) =>
          `${field.props?.label} must be greater than 0`,
      },
      monthsValidation: {
        expression: (control: UntypedFormControl) => {
          const { months } = control.value;
          return months < 12;
        },
        message: (_error: unknown, field: FormlyFieldConfig) =>
          `${field.props?.label} should be a maximum of 11`,
      },
      max40YearsValidationMessage: {
        expression: (control: UntypedFormControl) => {
          const { years, months } = control.value;
          return this.isMortgageTermValid(years, months);
        },
        message: (_error: unknown, field: FormlyFieldConfig) =>
          `${field.props?.label} has a maximum of 40 years`,
      },
    };
  }

  public get yearMonthValidatorOnTouched() {
    return {
      lengthOfTime: {
        expression: (control: UntypedFormControl) => {
          if (control.untouched || control.value.years === '' || control.value.years === null) {
            return true;
          }

          const { years, months } = control.value;
          return years > 0 || months > 0;
        },
        message: (error: unknown, field: { parent: { props: { text: string } } }) =>
          `${field.parent.props.text} must be greater than 0 or undefined`,
        errorPath: 'years',
      },
    };
  }

  public yearsAndMonthsFields(): FormlyFieldConfig[] {
    return [
      {
        key: 'years',
        type: FormFieldType.Number,
        wrappers: [WrapperType.NoWrappers],
        className: 'col',
        defaultValue: 0,
        props: {
          addonEnd: 'years',
          addonEndClass: 'fw-light',
          min: 0,
          max: 40,
          required: false,
          groupedField: true,
          collapseMargin: true,
        },
      },
      {
        key: 'months',
        type: FormFieldType.Number,
        wrappers: [WrapperType.NoWrappers],
        className: 'col',
        defaultValue: 0,
        props: {
          addonEnd: 'months',
          addonEndClass: 'fw-light',
          min: 0,
          max: 11,
          required: false,
          groupedField: true,
          collapseMargin: true,
        },
      },
    ];
  }

  public get defaultSelectOption(): IInputOption {
    return { value: null, label: 'Please select...', disabled: true };
  }

  public get defaultSelectOptionNotDisabled(): IInputOption {
    return { value: null, label: 'Please select...' };
  }

  public get productLengthOptions(): IInputOption[] {
    return [
      {
        value: ProductLength.OneYear,
        label: '1 Year',
      },
      {
        value: ProductLength.TwoYears,
        label: '2 Years',
      },
      {
        value: ProductLength.ThreeYears,
        label: '3 Years',
      },
      {
        value: ProductLength.FourYears,
        label: '4 Years',
      },
      {
        value: ProductLength.FiveYears,
        label: '5 Years',
      },
    ];
  }

  public get productLengthBtlOptions(): IInputOption[] {
    return [
      {
        value: ProductLength.TwoYears,
        label: '2 Years',
      },
      {
        value: ProductLength.ThreeYears,
        label: '3 Years',
      },
      {
        value: ProductLength.FiveYears,
        label: '5 Years',
      },
    ];
  }

  public get productBtlTypeOptions(): IInputOption[] {
    return [
      {
        value: BtlType.Standard,
        label: 'Standard',
      },
      {
        value: BtlType.HMO,
        label: 'HMO',
      },
      {
        value: BtlType.HolidayLet,
        label: 'Holiday let',
      },
      {
        value: BtlType.MUFB,
        label: 'MUFB',
      },
    ];
  }

  public get productBtlTypeOptionsWithDefaultSelect(): IInputOption[] {
    const defaultBtlTypeOption: IInputOption[] = [this.defaultSelectOption];
    return defaultBtlTypeOption.concat(this.productBtlTypeOptions);
  }

  public get countryResidentTypeOptions(): IInputOption[] {
    return [
      this.defaultSelectOption,
      {
        value: ApplicantCountry.England,
        label: 'England',
      },
      {
        value: ApplicantCountry.NorthernIreland,
        label: 'Northern Ireland',
      },
      {
        value: ApplicantCountry.Scotland,
        label: 'Scotland',
      },
      {
        value: ApplicantCountry.Wales,
        label: 'Wales',
      },
      {
        value: ApplicantCountry.OutsideUk,
        label: 'Country outside of the UK',
      },
    ];
  }

  public isEmployed(employmentStatus: EmploymentStatus | null): boolean {
    return (
      employmentStatus === EmploymentStatus.Employed ||
      employmentStatus === EmploymentStatus.SelfEmployed ||
      employmentStatus === EmploymentStatus.DirectorOrShareholder
    );
  }

  public isUnemployed(employmentStatus: EmploymentStatus | null): boolean {
    return (
      employmentStatus === EmploymentStatus.Homemaker ||
      employmentStatus === EmploymentStatus.Retired ||
      employmentStatus === EmploymentStatus.Student ||
      employmentStatus === EmploymentStatus.Unemployed
    );
  }

  public isHomemakerStudentOrUnemployed(employmentStatus: EmploymentStatus | null): boolean {
    return (
      employmentStatus === EmploymentStatus.Homemaker ||
      employmentStatus === EmploymentStatus.Student ||
      employmentStatus === EmploymentStatus.Unemployed
    );
  }

  public buildSelectOptionsFromEnum(
    enumeration: Record<string, unknown>,
    convertToLowerCase = true,
    disableDefault = true,
  ): IInputOption[] {
    const propertyNames = this.formatService.getEnumPropertyNames(enumeration);
    return [
      disableDefault ? this.defaultSelectOption : this.defaultSelectOptionNotDisabled,
      ...propertyNames.map((prop) => {
        let optionText = this.formatService.toSentenceCase(prop, convertToLowerCase);
        if (prop === 'RentalIncome') {
          optionText = 'Rental income (Mortgage Free)';
        }
        return {
          value: enumeration[prop],
          label: optionText,
        };
      }),
    ];
  }

  public buildOptionsFromEnum(enumeration: any, convertToLowerCase = true): any[] {
    const propertyNames = this.formatService.getEnumPropertyNames(enumeration);
    return propertyNames.map((prop) => {
      let optionText = this.formatService.toSentenceCase(prop, convertToLowerCase);
      if (prop === 'RentalIncome') {
        optionText = 'Rental income (Mortgage Free)';
      }

      return {
        value: enumeration[prop],
        label: optionText,
      };
    });
  }

  public getEnumLabel(value: number, enumeration: unknown, convertToLowerCase = true) {
    const types = this.buildOptionsFromEnum(enumeration, convertToLowerCase);
    const enumValue = types.find((s) => s.value === value);
    return enumValue?.label;
  }

  public hiddenInput(
    key: string,
    hideExpression: ((model: unknown, field: FormlyFieldConfig) => boolean ) | null = null,
  ) {
    return {
      key,
      type: 'input',
      props: {
        type: 'hidden',
      },
      expressions: {
        hide: (field) => hideExpression?.(field.model, field),
      },
    };
  }

  public currencyInput(
    key: string,
    text: string,
    tooltip: string | null = null,
    hideExpression: ((model: unknown, field: FormlyFieldConfig) => boolean) | null = null,
    validators: unknown[] = [],
    expressions: Record<string, string | ((field: FormlyFieldConfig) => unknown)> | null = null,
    extraValidators: FormlyFieldConfig['validators'] | null = null,
    className: string | null = null,
    props: Record<string, unknown> | null = null,
    defaultValue: number | null = null,
    displayZeroIfDefaultValueIsNull = true,
  ) {
    return this.numberInput(
      key,
      text,
      tooltip,
      hideExpression,
      validators,
      expressions,
      extraValidators,
      className,
      null,
      null,
      props,
      'currency',
      defaultValue,
      displayZeroIfDefaultValueIsNull,
    );
  }

  public numberInput(
    key: string,
    text: string,
    tooltip: string | null = null,
    hideExpression: ((model: unknown, field: FormlyFieldConfig) => boolean) | null = null,
    validators: unknown[] | null = null,
    expressions: Record<string, string | ((model: unknown, field?: FormlyFieldConfig) => unknown)> | null = null,
    extraValidators: FormlyFieldConfig['validators'] | null = {},
    className: string | null = null,
    addonLeft: unknown = null,
    addonRight: unknown = null,
    props: Record<string, unknown> | null = {},
    type: string | null = null,
    defaultValue: number | null = null,
    displayZeroIfDefaultValueIsNull = true,
  ) {
    const field = {
      key,
      type: type ?? 'input',
      defaultValue: displayZeroIfDefaultValueIsNull ? defaultValue ?? '0' : defaultValue,
      className,
      props: {
        ...{
          text,
          type: 'number',
          tooltip,
          required: true,
          min: 0,
          focus: (formlyField: FormlyFieldConfig, event) => {
            const { value } = event.target;
            const valid = FormsValidators.greaterThanZero(value);
            if (!valid) {
              event.target.value = '';
            }
            formlyField.formControl?.markAsUntouched();
          },
          blur: (formlyField: FormlyFieldConfig, event) => {
            formlyField.formControl?.markAsTouched();
            const { value } = event.target;
            if (displayZeroIfDefaultValueIsNull) {
              const valid = FormsValidators.greaterThanZero(value);
              if (!valid) {
                event.target.value = 0;
                formlyField.formControl?.setValue(0);
              }
            } else if (!displayZeroIfDefaultValueIsNull && !value) {
              formlyField.formControl?.reset();
            }
          },
          keydown: (formlyField: FormlyFieldConfig, event) => {
            const { value } = event.target;
            if (!value && !displayZeroIfDefaultValueIsNull) {
              formlyField.formControl?.reset();
            }
            // formlyField.formControl.markAsTouched();
          },
          keyup: (formlyField: FormlyFieldConfig, event) => {
            const key = event.which ?? event.keyCode ?? 0;
            if (key === KeyCodes.Tab || key === KeyCodes.Shift) {
              return;
            }
            formlyField.formControl?.markAsTouched();
          },
        },
        ...props,
      },
      validators: {
        validation: validators ?? [FormsValidators.integer],
        ...extraValidators,
      },
      expressions: {
        ...expressions,
        hide: (field) => {
          return hideExpression?.(field.model, field);
        },
      },
    } as FormlyFieldConfig;

    if (field.props && addonLeft) {
      field.props.addonLeft = {
        text: addonLeft,
      };
    }

    if (field.props && addonRight) {
      field.props.addonRight = {
        text: addonRight,
      };
    }

    return field;
  }

  public radioInput(
    key: string,
    text: string,
    tooltip: string | null = null,
    hideExpression: ((
      model: unknown,
      field: FormlyFieldConfig,
    ) => boolean) | null = null,
    options: IInputOption[] | null = null,
    expressions: FormlyFieldConfig['expressions'] | null = null,
    defaultValue: unknown | null = null,
    className: string | null = null,
    props: Record<string, unknown> = {},
  ) {
    return {
      key,
      type: 'customRadio',
      defaultValue,
      className,
      props: {
        text,
        tooltip,
        options: options ?? this.yesNoOptions,
        required: true,
        ...props,
      },
      expressions: {
        ...expressions,
        hide: (field) => hideExpression?.(field.model, field),
      },
    } as FormlyFieldConfig;
  }

  public checkboxInput(
    key: string,
    text: string,
    tooltip: string | null = null,
    hideExpression: ((model: unknown, field: FormlyFieldConfig) => boolean) | null = null,
    options: IInputOption[] | null = null,
    expressions: Record<string, string | ((field: FormlyFieldConfig) => unknown)> | null = null,
    defaultValue = '',
  ) {
    const defaultObj: Record<string, unknown> = {};
    defaultObj[defaultValue] = true;
    return {
      key,
      type: 'customCheckbox',
      props: {
        text,
        tooltip,
        options: options ?? this.yesNoOptions,
        required: true,
      },
      defaultValue: defaultObj,
      expressions: {
        ...expressions,
        hide: (field) => hideExpression?.(field.model, field),
      },
    };
  }

  public selectInput(
    key: string,
    text: string,
    tooltip: string | null = null,
    hideExpression: ((model: unknown, field: FormlyFieldConfig) => boolean) | null = null,
    options: IInputOption[] | null = null,
    expressions: Record<string, string | ((field: FormlyFieldConfig) => unknown )> | null = null,
    defaultValue: unknown | null = null,
    className: string | null = null,
    props = {},
  ) {
    return {
      key,
      type: 'select',
      className,
      defaultValue,
      props: {
        text,
        tooltip,
        options,
        required: true,
        ...props,
      },
      expressions: {
        ...expressions,
        hide: (field) => hideExpression?.(field.model, field),
      },
    } as FormlyFieldConfig;
  }

  public postcodeLookup(
    key: string,
    text: string,
    submitText: string,
    props = {},
    tooltip: string | null = null,
    hideExpression: ((model: unknown, field: FormlyFieldConfig) => boolean) | null = null,
    options: IInputOption[] | null = null,
    expressions: Record<string, string | ((field: FormlyFieldConfig) => unknown)> | null = null,
    defaultValue: unknown | null = null,
    className: string | null = null,
  ) {
    return {
      key,
      type: 'postcodeInput',
      defaultValue,
      className,
      props: {
        text,
        submitText,
        tooltip,
        options: options ?? this.yesNoOptions,
        required: true,
        ...props,
      },
      expressions: {
        ...expressions,
        hide: (field) => hideExpression?.(field.model, field),
      },
      validators: {
        validation: [FormsValidators.postcode],
      },
    } as FormlyFieldConfig;
  }

  public calculateRepaymentAmount(loanAmount: number, interestOnlyAmount: number | undefined): number {
    if (loanAmount && interestOnlyAmount) {
      const value = loanAmount - interestOnlyAmount ?? 0;
      return value > 0 ? value : 0;
    }
    return 0;
  }

  public getEnumValue(value: string): number {
    return +value.split(':')[1];
  }

  public isMortgageTermValid(years: number, months: number): boolean {
    return years + months / 12 <= 40;
  }

  public canPaymentsCoverMortgageBalance(group: UntypedFormGroup): boolean {
    const otherMortgage = group.getRawValue() as IUiOtherMortgage;
    if (+otherMortgage?.monthlyMortgagePayments > 0) {
      const term: number =
        +(otherMortgage?.remainingTermOfLoan?.years ?? 0) * 12 +
        +(otherMortgage?.remainingTermOfLoan?.months ?? 0);
      const paymentAmount: number = term * +otherMortgage.monthlyMortgagePayments;
      return otherMortgage.repaymentMethod !== RepaymentMethod.CapitalAndInterest ||
        paymentAmount >= +otherMortgage.totalMortgageBalance;
    }
    return true;
  }

  public canPaymentsCoverMortgageOutstanding(group: UntypedFormGroup): boolean {
    const otherMortgageBtl = group.getRawValue() as IPropertyBtl;
    if (+otherMortgageBtl.monthlyBTLMortgageRepayment > 0) {
      const term: number = +otherMortgageBtl.mortgageTerm.years * 12 + +otherMortgageBtl.mortgageTerm.months;
      const paymentAmount: number = term * +otherMortgageBtl.monthlyBTLMortgageRepayment;
      return (
        otherMortgageBtl.repaymentMethod !== RepaymentMethod.CapitalAndInterest ||
        paymentAmount >= +otherMortgageBtl.totalBTLMortgageBalanceOutstanding
      );
    }
    return true;
  }

  public salaryAndDividendsGreaterThanZero() {
    return {
      expression: (control: UntypedFormControl) => {
        const {
          grossSalaryForLatestPeriod,
          grossDividendsForLatestPeriod,
          netProfitBeforeDividendsForLatestPeriod,
          grossAnnualIncome,
          employmentStatus,
        } = control.value as IUiMainIncome;
        return (
          employmentStatus !== EmploymentStatus.DirectorOrShareholder ||
          (
            +(grossSalaryForLatestPeriod ?? 0) +
            +(grossDividendsForLatestPeriod ?? 0) +
            +(netProfitBeforeDividendsForLatestPeriod ?? 0) +
            +(grossAnnualIncome ?? 0)
          ) > 0
        );
      },
      message:
        'Either gross annual income, director\'s remuneration for latest period, gross dividends for latest period ' +
        'or net profit for latest period should be greater than 0',
    };
  }

  public getMortgageLendersList(lenders: LenderName[]) {
    const lendersViewModels = lenders.map(lender => ({ value: lender.lenderName, label: lender.lenderName }));

    lendersViewModels.push({
      label: 'Other (please specify)',
      value: MortgageLenders.Other,
    });

    return lendersViewModels;
  }

  public getCurrentMortgageLender(mortgageLender: string | undefined, selectedLender: { value: string } | undefined)
    : string | null {
    if (!!mortgageLender) {
      return mortgageLender;
    }

    if (!selectedLender || selectedLender.value as MortgageLenders === MortgageLenders.Other) {
      return null;
    }

    return selectedLender.value;
  }

  public getDateOrNull(dateInput: IDateInput | undefined): Date | null {
    if (!dateInput) {
      return null;
    }

    if (!dateInput.day || !dateInput.month || !dateInput.year) {
      return null;
    }

    return mapDateToUtc(dateInput);
  }

  public getProductTransferInitialPeriodValidation() {
    const monthsNumber = 6;
    const sixMonthsInDays = 183;
    return  {
      expression: FormsValidators.expression(FormsValidators.productTransferDateInAdvance(sixMonthsInDays)),
      message: `Product Transfer products cannot be applied for more than ${monthsNumber} months in advance`,
      errorPath: 'productTransferInitialDate',
    };
  }

  public getBtlLendingType(): LendingType {
    return {
      code: LendingTypeCode.Btl,
    } as LendingType;
  }

  public getResLendingType(): LendingType {
    return {
      code: LendingTypeCode.Res,
    } as LendingType;
  }

  public getProductTransferInitialDate(date: string | Date): IDateInput | null {
    const dateInput = new Date(date);

    if (dateInput.getFullYear() === 1) {
      return null;
    }

    return {
      day: dateInput.getDate(),
      month: dateInput.getMonth() + 1,
      year: dateInput.getFullYear(),
    } as IDateInput;
  }

  public calculateLtv({ mortgageType, loanAmount, propertyValue, outstandingBalanceCurrentMortgage }: LtvInputs) {
    if (mortgageType === MortgageType.SecondCharge) {
      return loanAmount && propertyValue && outstandingBalanceCurrentMortgage
        ? Math.ceil(100 * (loanAmount + outstandingBalanceCurrentMortgage) / propertyValue)
        : null;
    }

    return loanAmount && propertyValue
      ? Math.ceil(100 * loanAmount / propertyValue)
      : null;
  }

  public calculateLtvString(inputs: LtvInputs, addPercentSymbol = true): string {
    const ltv = this.calculateLtv(inputs);
    return typeof ltv === 'number' && ltv > 0
      ? `${ltv}${addPercentSymbol ? '%' : ''}`
      : '';
  }

  public isLtvValid(inputs: LtvInputs): boolean {
    const ltv = this.calculateLtv(inputs);
    return ltv === null || ltv <= 500;
  }
}
