import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import isObjectEqual from 'lodash-es/isEqual';

/**
 * Directive which takes an object and applies each property/value pair as an attribute on the targeted element.
 */
@Directive({
  selector: '[appAttrFromObj]',
})
export class AttributesFromObjectDirective implements OnChanges {
  @Input('appAttrFromObj') public attributes: Record<string, any>;

  public constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (!changes.attributes) {
      return;
    }

    const { previousValue: previous, currentValue: current } = changes.attributes;

    // Check to see whether previous and current values have the same values, as Angular may see they are different
    // references and so treat them as such, but they may have the same values and we don't want to manipulate the
    // element in that case.
    if (isObjectEqual(previous, current)) {
      return;
    }

    // Remove previous attributes
    if (previous) {
      for (const attr of Object.keys(previous as this['attributes'])) {
        this.renderer.removeAttribute(this.el.nativeElement, attr);
      }
    }

    // Add new attributes
    if (current) {
      for (const [attr, value] of Object.entries(current as this['attributes'])) {
        this.renderer.setAttribute(this.el.nativeElement, attr, value);
      }
    }
  }
}
