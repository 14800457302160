import { isInvalid } from '@msslib/components/rules-engine/utils';

/** Unique propertyName used to identify the 'Always' condition type. */
export const alwaysConditionPropertyName = '$$Always';

export enum RulesEngineDataType {
  String = 1,
  Integer = 2,
  Decimal = 3,
  Boolean = 4,
  Enum = 5,
}

export interface TypeMetadata {
  name: string;
  dataType: RulesEngineDataType;
  choices: {
    value: any;
    displayName: string;
  }[] | null;
}

export interface PropertyMetadata {
  name: string;
  displayName: string;
  isNullable: boolean;
  isReadable: boolean;
  isWriteable: boolean;
  typeName: string;
}

export interface OperandMetadata {
  displayName: string;
  isNullable: boolean;
  typeName: string;
}

export interface OperatorMetadata {
  name: string;
  leftOperandMetadata: OperandMetadata;
  rightOperandMetadata: OperandMetadata[];
}

export interface RulesEngineSchemaResponseModel {
  properties: Omit<PropertyMetadata, 'type'>[];
  types: TypeMetadata[];
  operators: OperatorMetadata[];
}

export class RulesEngineSchema {

  public readonly types: Map<string, TypeMetadata>;
  public readonly properties: PropertyMetadata[];
  public readonly propertiesMap: Map<string, PropertyMetadata>;
  private readonly operatorsMap = new Map<string, Map<string, OperatorMetadata>>();

  public constructor(rawModel: RulesEngineSchemaResponseModel) {
    this.types = new Map(rawModel.types
      .map(t => [t.name, t]));

    this.properties = rawModel.properties.sort((a, b) => a.displayName.localeCompare(b.displayName));

    this.propertiesMap = new Map(rawModel.properties
      .map(p => [p.name, p]));

    for (const op of rawModel.operators) {
      let operatorByTypeMap = this.operatorsMap.get(op.leftOperandMetadata.typeName);
      if (!operatorByTypeMap) {
        operatorByTypeMap = new Map<string, OperatorMetadata>();
        this.operatorsMap.set(op.leftOperandMetadata.typeName, operatorByTypeMap);
      }
      operatorByTypeMap.set(op.name, op);
    }
  }

  /** Equivalent to doing `types.get` but does not allow for `undefined` return values. */
  public getType(typeName: string): TypeMetadata {
    const t = this.types.get(typeName);
    if (!t) {
      throw new Error(`Could not find type '${typeName}'`);
    }
    return t;
  }

  public getOperator(typeName: string, operatorName: string): OperatorMetadata | undefined {
    return this.operatorsMap.get(typeName)?.get(operatorName);
  }

  public getOperatorsForType(typeName: string): OperatorMetadata[] {
    const operators = this.operatorsMap.get(typeName);
    if (!operators) {
      return [];
    }

    return [...operators.values()];
  }

  public hasOperatorForType(typeName: string, operatorName: string): boolean {
    return this.getOperatorsForType(typeName).some(o => o.name === operatorName);
  }
}

export enum ConditionType {
  Property = 1,
}

export interface PropertyCondition {
  type: ConditionType.Property;
  propertyName: string;
  operatorName: string;
  arguments: any[];
}

/** Validates the given PropertyCondition. Returns the error message if there is one; or undefined if valid. */
export function validatePropertyCondition(
  condition: PropertyCondition,
  schema: RulesEngineSchema,
  allowAlwaysCondition = true,
) {
  if (!condition.propertyName?.length) {
    return ['No property selected'];
  }

  if (allowAlwaysCondition && condition.propertyName === alwaysConditionPropertyName) {
    return undefined;
  }

  const propertyMetadata = schema.propertiesMap.get(condition.propertyName);
  if (!propertyMetadata) {
    return  [`Invalid property '${condition.propertyName}'`];
  }

  if (!condition.operatorName?.length) {
    return ['No operator selected'];
  }

  const operatorMetadata = schema.getOperator(propertyMetadata.typeName, condition.operatorName);
  if (!operatorMetadata) {
    return [`Invalid operator '${condition.operatorName}' for type '${propertyMetadata.typeName}'`];
  }

  const args = condition.arguments ?? [];
  if (args.length !== operatorMetadata.rightOperandMetadata.length) {
    return [
      `Mismatched number of arguments. Expected ${operatorMetadata.rightOperandMetadata.length}, found ${args.length}`,
    ];
  }

  const argErrors = args.map((arg, idx) => {
    const { typeName, isNullable } = operatorMetadata.rightOperandMetadata[idx];
    const typeMetadata = schema.getType(typeName);
    return isInvalid(arg, typeMetadata, isNullable);
  }).filter(Boolean) as string[];
  if (argErrors.length) {
    return argErrors;
  }

  return undefined;
}

export enum ActionType {
  SetValue = 1,
}

export interface SetValueAction {
  type: ActionType.SetValue;
  propertyName: string;
  value: any;
}

/** Validates the given SetValueAction. Returns the error message if there is one; or undefined if valid. */
export function validateSetValueAction(action: SetValueAction, schema: RulesEngineSchema) {
  if (!action.propertyName?.length) {
    return 'No property selected';
  }

  const propertyMetadata = schema.propertiesMap.get(action.propertyName);
  if (!propertyMetadata) {
    return  `Invalid property '${action.propertyName}'`;
  }

  const typeMetadata = schema.getType(propertyMetadata.typeName);
  return isInvalid(action.value, typeMetadata, propertyMetadata.isNullable);
}
