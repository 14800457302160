import { ConfigOption, FormlyFieldConfig } from '@ngx-formly/core';
import { ButtonsFieldTypeComponent } from './fields/buttons/buttons.component';
import { CurrencyFieldTypeComponent } from './fields/currency/currency.component';
import { NumberFieldTypeComponent } from './fields/number/number.component';
import { PostcodeInputComponent } from './fields/postcode-input/postcode-input.component';
import { RadioButtonFieldTypeComponent } from './fields/radio-button/radio-button.component';
import { TextAreaFieldTypeComponent } from './fields/textarea/textarea.component';
import { SelectFieldTypeComponent } from './fields/select/select.component';
import { InvalidPassthroughWrapperComponent }
  from './wrappers/invalid-passthrough/invalid-passthrough-wrapper.component';
import { FormCardWrapperComponent } from './wrappers/form-card-wrapper/form-card-wrapper.component';
import { HorizontalFieldWrapperComponent }
  from './wrappers/horizontal-field-wrapper/horizontal-field-wrapper.component';
import { DisabledFieldTypeComponent } from './fields/disabled/disabled.component';
import { TableRowWrapperComponent } from './wrappers/table-row/table-row-wrapper.component';
import {
  AdditionalIncomeHeaderFieldTypeComponent,
  CurrencyMultiAddFieldTypeComponent,
  DateFieldTypeComponent,
  InputFieldTypeComponent,
  RepeatingFieldTypeComponent,
  SeparatorComponent,
  TextComponent,
} from './fields';
import { TemplateComponent } from './fields/template/template.component';
import { RadioListComponent } from './fields/radio-list/radio-list.component';
import { EmptyWrapperComponent } from './wrappers';



function fieldName(field: FormlyFieldConfig): string {
  if (field.props && !!field.props.label?.length) {
    return field.props.label;
  }
  return field.key?.toString() ?? 'Field';
}

// This extension is added globally to the configuration to create a "testId" which we add to the "props" object
// for further use (ex. [attr.data-testid]="props.testId"). The creation of "testId" is based on a hierarchy of fields.
function addTestIdExtension(field: FormlyFieldConfig) {
  field.props = field.props || {};

  if (field.props.testId) {
    return;
  }

  let testId = '';

  if (field.parent) {
    let target: FormlyFieldConfig | undefined = field.parent;

    // If there is a parent, we form "testId" from the chain of parents
    while (target) {
      if (target.key) {
        testId = `${target.key}_${testId}`;
      }
      target = target.parent;
    }
  }

  if (field.key) {
    testId += field.key;
  }

  field.props.testId = testId;
}

export const formlyComponents = [
  // Fields
  ButtonsFieldTypeComponent,
  CurrencyFieldTypeComponent,
  DateFieldTypeComponent,
  DisabledFieldTypeComponent,
  InputFieldTypeComponent,
  NumberFieldTypeComponent,
  PostcodeInputComponent,
  RadioButtonFieldTypeComponent,
  RadioListComponent,
  RepeatingFieldTypeComponent,
  SelectFieldTypeComponent,
  TemplateComponent,
  TextAreaFieldTypeComponent,
  AdditionalIncomeHeaderFieldTypeComponent,
  CurrencyMultiAddFieldTypeComponent,
  TextComponent,
  SeparatorComponent,

  // Wrappers
  FormCardWrapperComponent,
  HorizontalFieldWrapperComponent,
  TableRowWrapperComponent,
];

export enum FormFieldType {
  Buttons = 'buttons',
  Currency = 'currency',
  Date = 'date',
  Disabled = 'disabled',
  Input = 'input',
  Number = 'number',
  PostCode = 'postcodeInput',
  RadioButtons = 'radio-button',
  RadioList = 'radio-list',
  Repeating = 'repeating',
  Select = 'select',
  AdditionalIncomeHeader = 'additional-income-header',
  Template = 'template',
  CurrencyAddMulti = 'currency-add-multi',
  Text = 'text',
  Separator = 'separator',
}

export enum WrapperType {
  FormCard = 'form-card',
  HorizontalField = 'horizontal-field',
  InvalidPassthrough = 'invalid-passthrough',
  TableRow = 'table-row',
  EmptyWrapper = 'empty-wrapper',
  NoWrappers = 'no-wrappers',
}

export const formlyModuleConfig: ConfigOption = {
  types: [
    { name: FormFieldType.Buttons, component: ButtonsFieldTypeComponent, wrappers: [] },
    { name: FormFieldType.Currency, component: CurrencyFieldTypeComponent, wrappers: [],
      defaultOptions: CurrencyFieldTypeComponent.defaultOptions },
    { name: FormFieldType.CurrencyAddMulti, component: CurrencyMultiAddFieldTypeComponent, wrappers: [],
      defaultOptions: CurrencyFieldTypeComponent.defaultOptions },
    { name: FormFieldType.Date, component: DateFieldTypeComponent, wrappers: [] },
    { name: FormFieldType.Disabled, component: DisabledFieldTypeComponent, wrappers: [] },
    { name: FormFieldType.Input, component: InputFieldTypeComponent, wrappers: [] },
    { name: FormFieldType.Number, component: NumberFieldTypeComponent, wrappers: [],
      defaultOptions: NumberFieldTypeComponent.defaultOptions },
    { name: FormFieldType.PostCode, component: PostcodeInputComponent, wrappers: [] },
    { name: FormFieldType.RadioButtons, component: RadioButtonFieldTypeComponent, wrappers: [] },
    { name: FormFieldType.RadioList, component: RadioListComponent, wrappers: [] },
    { name: FormFieldType.Repeating, component: RepeatingFieldTypeComponent, wrappers: [WrapperType.NoWrappers] },
    { name: FormFieldType.Select, component: SelectFieldTypeComponent, wrappers: [],
      defaultOptions: SelectFieldTypeComponent.defaultOptions },
    { name: 'textarea', component: TextAreaFieldTypeComponent, wrappers: [] },
    { name: FormFieldType.Template, component: TemplateComponent, wrappers: [WrapperType.NoWrappers] },
    { name: FormFieldType.AdditionalIncomeHeader, component: AdditionalIncomeHeaderFieldTypeComponent, wrappers: [] },
    { name: FormFieldType.Text, component: TextComponent, wrappers: [] },
    { name: FormFieldType.Separator, component: SeparatorComponent, wrappers: [] },

  ],
  wrappers: [
    { name: WrapperType.FormCard, component: FormCardWrapperComponent },
    { name: WrapperType.HorizontalField, component: HorizontalFieldWrapperComponent },
    { name: WrapperType.InvalidPassthrough, component: InvalidPassthroughWrapperComponent },
    { name: WrapperType.TableRow, component: TableRowWrapperComponent },
    { name: WrapperType.EmptyWrapper, component: EmptyWrapperComponent },
  ],
  validationMessages: [
    {
      name: 'required',
      message: (_, field) => `${fieldName(field)} is required`,
    },
    {
      name: 'minLength',
      message: ({ requiredLength }, field) => `${fieldName(field)} should have at least ${requiredLength} characters`,
    },
    {
      name: 'maxLength',
      message: ({ maxLength }, field) => `${fieldName(field)} should be less than ${maxLength} characters`,
    },
    {
      name: 'min',
      message: ({ min }, field) => `${fieldName(field)} should be a minimum of ${min}`,
    },
    {
      name: 'max',
      message: ({ max }, field) => `${fieldName(field)} should be a maximum of ${max}`,
    },
    {
      name: 'greaterThan',
      message: (greaterThan, field) => `${fieldName(field)} should be greater than ${greaterThan}`,
    },
    {
      name: 'lessThan',
      message: (lessThan, field) => `${fieldName(field)} should be less than ${lessThan}`,
    },
    {
      name: 'email',
      message: (_, field) => `${fieldName(field)} is not a valid email address`,
    },
    {
      name: 'postcode',
      message: (_, field) => `${fieldName(field)} is not a valid UK postcode`,
    },
    {
      name: 'integer',
      message: (_, field) => `${fieldName(field)} must be a whole number`,
    },
    {
      name: 'date',
      message: (_, field) => `${fieldName(field)} is not a valid date`,
    },
    {
      name: 'atLeast18Years',
      message: (_, field) => `${fieldName(field)} must be at least 18 years old`,
    },
    {
      name: 'valueNotInEnum',
      message: (_, field) => `${fieldName(field)} is not valid`,
    },
  ],
  extensions: [
    {
      // Adds the default 'horizontal-field' wrapper to any wrapper-less form fields
      name: 'defaultWrapper',
      priority: 1,
      extension: {
        onPopulate(field) {
          if ('parent' in field && (!field.wrappers || field.wrappers.length === 0)) {
            field.wrappers = ['horizontal-field'];
          }
        },
      },
    },
    {
      name: 'removeFalseyWrappers',
      priority: 1000,
      extension: {
        onPopulate(field) {
          field.wrappers = field.wrappers?.filter(x => x !== WrapperType.NoWrappers);
        },
      },
    },
    { name: 'addTestId',  extension: { prePopulate: addTestIdExtension } },
  ],
  extras: {
    resetFieldOnHide: false,
    renderFormlyFieldElement: true,
    lazyRender: false,
    checkExpressionOn: 'changeDetectionCheck',
  },
};

export const yesNoOptions = [
  { label: 'No', value: false },
  { label: 'Yes', value: true },
];
