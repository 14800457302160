<div [formGroup]="formGroup" [class]="config.cssClass ? 'switch mb-4 ' + config.cssClass: 'form-group switch'">
  <input
    type="checkbox"
    class="form-check-input"
    [checked]="formGroup.value[config.name]"
    [id]="config.name"
    [formControlName]="config.name"
    (click)="changeValue(config.name); config.clickHandler ? config.clickHandler($event) : null"
    (change)="onChange($event)"
    [attr.data-testid]="config.name"
  />
  <label class="form-check-label" [for]="config.name">
    <div class="switch-slider me-2"></div>
    <span class="my-auto">{{ config.label }}</span>
    <span *ngIf="config.toggleWarningExpression && config.toggleWarningExpression(this, formModel)"
      class="my-auto ms-2 switch-warning"
    >
      <i class="fas fa-exclamation-triangle"></i>
      {{config.warningText}}
    </span>
  </label>
  <div libFormFieldError class="invalid-feedback" [fieldConfig]="config"></div>
</div>
