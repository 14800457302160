import { AffordabilityPollResponse } from '../../models/affordability';
import { IgniteResponse } from 'apps/clubhub/src/app/ignite/models/ignite-response';

export const mockAffordabilityResponse = {
  pollJobID: 34,
  // eslint-disable-next-line spellcheck/spell-checker
  affordabilitySearchId: 'd047d7d5-2f08-4e74-b4af-2d4c67a164e8',
  lenderCount: 25,
  quotes: [
    {
      // eslint-disable-next-line spellcheck/spell-checker
      'affordabilitySearchId': '00dd3cfd-9922-4a1f-910f-6eafcc6239d9',
      'source': 'Asanto',
      'lenderName': 'Hinckley & Rugby',
      'lenderLogoUrl':
        'https://mcsmartrcriteriauat.blob.core.windows.net/lender-logos/2ab16c6a-5a3b-4ee0-8c5b-cd06cb8aa505.jpeg',
      'maximumLoanAmount': 0.00,
      'error': {
        'statusCode': null,
        'message': null,
      },
      'affordabilityResult': null,
      'maximumAffordableLoanAmount': 0.00,
      'quoteNumber': null,
      'httpError': null,
      'httpErrorMessage': null,
      'payrate': null,
      'stressRate': null,
      'maximumValue': 0.00,
      'tooManyApplicants': false,
    },
    {
      // eslint-disable-next-line spellcheck/spell-checker
      'affordabilitySearchId': '00dd3cfd-9922-4a1f-910f-6eafcc6239d9',
      'source': 'ScreenScrape',
      'lenderName': 'Leeds',
      'lenderLogoUrl':
        'https://mcsmartrcriteriauat.blob.core.windows.net/lender-logos/40f049af-b224-4b09-a06a-fb2b21f9c61a.png',
      'maximumLoanAmount': null,
      'error': {
        'statusCode': null,
        'message': null,
      },
      'affordabilityResult': null,
      'maximumAffordableLoanAmount': null,
      'quoteNumber': null,
      'httpError': 'Ignite Error',
      'httpErrorMessage': 'Connection refused',
      'payrate': null,
      'stressRate': null,
      'maximumValue': null,
      'tooManyApplicants': false,
    },
    {
      // eslint-disable-next-line spellcheck/spell-checker
      'affordabilitySearchId': '00dd3cfd-9922-4a1f-910f-6eafcc6239d9',
      'source': 'ScreenScrape',
      'lenderName': 'Barclays',
      'lenderLogoUrl':
        'https://mcsmartrcriteriauat.blob.core.windows.net/lender-logos/f6672567-35db-4b66-abd7-a31a6086342a.png',
      'maximumLoanAmount': null,
      'error': {
        'statusCode': null,
        'message': null,
      },
      'affordabilityResult': null,
      'maximumAffordableLoanAmount': null,
      'quoteNumber': null,
      'httpError': 'Ignite Error',
      'httpErrorMessage': 'Connection refused',
      'payrate': null,
      'stressRate': null,
      'maximumValue': null,
      'tooManyApplicants': false,
    },
    {
      // eslint-disable-next-line spellcheck/spell-checker
      'affordabilitySearchId': '00dd3cfd-9922-4a1f-910f-6eafcc6239d9',
      'source': 'Asanto',
      'lenderName': 'Melton',
      'lenderLogoUrl': null,
      'maximumLoanAmount': 0.00,
      'error': {
        'statusCode': null,
        'message': null,
      },
      'affordabilityResult': null,
      'maximumAffordableLoanAmount': 0.00,
      'quoteNumber': null,
      'httpError': null,
      'httpErrorMessage': null,
      'payrate': null,
      'stressRate': null,
      'maximumValue': 0.00,
      'tooManyApplicants': false,
    },
    {
      // eslint-disable-next-line spellcheck/spell-checker
      'affordabilitySearchId': '00dd3cfd-9922-4a1f-910f-6eafcc6239d9',
      'source': 'Asanto',
      'lenderName': 'Legal & General Home Finance',
      'lenderLogoUrl':
        'https://mcsmartrcriteriauat.blob.core.windows.net/lender-logos/f6a9d7e7-b1da-4d97-8de5-367953ce9b3a.svg',
      'maximumLoanAmount': 0.00,
      'error': {
        'statusCode': null,
        'message': null,
      },
      'affordabilityResult': null,
      'maximumAffordableLoanAmount': 0.00,
      'quoteNumber': null,
      'httpError': null,
      'httpErrorMessage': null,
      'payrate': null,
      'stressRate': null,
      'maximumValue': 0.00,
      'tooManyApplicants': false,
    },
    {
      // eslint-disable-next-line spellcheck/spell-checker
      'affordabilitySearchId': '00dd3cfd-9922-4a1f-910f-6eafcc6239d9',
      'source': 'Asanto',
      'lenderName': 'Progressive',
      'lenderLogoUrl':
        'https://mcsmartrcriteriauat.blob.core.windows.net/lender-logos/1b218135-2b03-46aa-88b4-2fd82cfd558b.png',
      'maximumLoanAmount': 0.00,
      'error': {
        'statusCode': null,
        'message': null,
      },
      'affordabilityResult': null,
      'maximumAffordableLoanAmount': 0.00,
      'quoteNumber': null,
      'httpError': null,
      'httpErrorMessage': null,
      'payrate': null,
      'stressRate': null,
      'maximumValue': 0.00,
      'tooManyApplicants': false,
    },
    {
      // eslint-disable-next-line spellcheck/spell-checker
      'affordabilitySearchId': '00dd3cfd-9922-4a1f-910f-6eafcc6239d9',
      'source': 'Asanto',
      'lenderName': 'Metro',
      'lenderLogoUrl':
        'https://mcsmartrcriteriauat.blob.core.windows.net/lender-logos/4785152f-d5c5-4a96-8f15-45e75c95ae98.png',
      'maximumLoanAmount': 374196.60,
      'error': {
        'statusCode': null,
        'message': null,
      },
      'affordabilityResult': 'Pass',
      'maximumAffordableLoanAmount': 374196.60,
      // eslint-disable-next-line spellcheck/spell-checker
      'quoteNumber': 'fe48c4a5-b7d6-44eb-afd2-d251633ad0b9',
      'httpError': null,
      'httpErrorMessage': null,
      'payrate': null,
      'stressRate': null,
      'maximumValue': 374196.60,
      'tooManyApplicants': false,
    },
    {
      // eslint-disable-next-line spellcheck/spell-checker
      'affordabilitySearchId': '00dd3cfd-9922-4a1f-910f-6eafcc6239d9',
      'source': 'Asanto',
      'lenderName': 'Stafford Railway',
      'lenderLogoUrl':
        'https://mcsmartrcriteriauat.blob.core.windows.net/lender-logos/51102093-0e42-4f03-8994-9182f0f9b293.png',
      'maximumLoanAmount': 376875.00,
      'error': {
        'statusCode': null,
        'message': null,
      },
      'affordabilityResult': 'Pass',
      'maximumAffordableLoanAmount': 376875.00,
      // eslint-disable-next-line spellcheck/spell-checker
      'quoteNumber': '6318725e-fccb-4218-a6b9-c347b7c7c5e2',
      'httpError': null,
      'httpErrorMessage': null,
      'payrate': null,
      'stressRate': null,
      'maximumValue': 376875.00,
      'tooManyApplicants': false,
    },
    {
      // eslint-disable-next-line spellcheck/spell-checker
      'affordabilitySearchId': '00dd3cfd-9922-4a1f-910f-6eafcc6239d9',
      'source': 'Asanto',
      'lenderName': 'Saffron',
      'lenderLogoUrl':
        'https://mcsmartrcriteriauat.blob.core.windows.net/lender-logos/cf7f92e2-ab22-4ccc-aef4-ff4da0f1692b.svg',
      'maximumLoanAmount': 373344.00,
      'error': {
        'statusCode': null,
        'message': null,
      },
      'affordabilityResult': 'Pass',
      'maximumAffordableLoanAmount': 373344.00,
      // eslint-disable-next-line spellcheck/spell-checker
      'quoteNumber': 'fd9ef9ef-4bf0-4284-9ad6-10ff8b00ba4b',
      'httpError': null,
      'httpErrorMessage': null,
      'payrate': null,
      'stressRate': null,
      'maximumValue': 373344.00,
      'tooManyApplicants': false,
    },
    {
      // eslint-disable-next-line spellcheck/spell-checker
      'affordabilitySearchId': '00dd3cfd-9922-4a1f-910f-6eafcc6239d9',
      'source': 'Asanto',
      'lenderName': 'Swansea BS',
      'lenderLogoUrl':
        'https://mcsmartrcriteriauat.blob.core.windows.net/lender-logos/0f4d4d70-2a52-4a49-a92f-8151f91cc026.png',
      'maximumLoanAmount': 378900.00,
      'error': {
        'statusCode': null,
        'message': null,
      },
      'affordabilityResult': 'Pass',
      'maximumAffordableLoanAmount': 378900.00,
      // eslint-disable-next-line spellcheck/spell-checker
      'quoteNumber': '14f39f75-14bc-4391-b762-c0bb490908ec',
      'httpError': null,
      'httpErrorMessage': null,
      'payrate': null,
      'stressRate': null,
      'maximumValue': 378900.00,
      'tooManyApplicants': false,
    },
    {
      // eslint-disable-next-line spellcheck/spell-checker
      'affordabilitySearchId': '00dd3cfd-9922-4a1f-910f-6eafcc6239d9',
      'source': 'Asanto',
      'lenderName': 'Nottingham',
      'lenderLogoUrl':
        'https://mcsmartrcriteriauat.blob.core.windows.net/lender-logos/d07641c8-6b80-4bee-8236-af8aebf83745.svg',
      'maximumLoanAmount': 369900.00,
      'error': {
        'statusCode': null,
        'message': null,
      },
      'affordabilityResult': 'Pass',
      'maximumAffordableLoanAmount': 369900.00,
      // eslint-disable-next-line spellcheck/spell-checker
      'quoteNumber': '4d6328af-1cdf-4e68-ba8d-bf0e88a3a65b',
      'httpError': null,
      'httpErrorMessage': null,
      'payrate': null,
      'stressRate': null,
      'maximumValue': 369900.00,
      'tooManyApplicants': false,
    },
    {
      // eslint-disable-next-line spellcheck/spell-checker
      'affordabilitySearchId': '00dd3cfd-9922-4a1f-910f-6eafcc6239d9',
      'source': 'Asanto',
      'lenderName': 'Suffolk',
      'lenderLogoUrl':
        'https://mcsmartrcriteriauat.blob.core.windows.net/lender-logos/a145bc62-efd4-4f48-a7f7-da24df784a5d.svg',
      'maximumLoanAmount': 367200.00,
      'error': {
        'statusCode': null,
        'message': null,
      },
      'affordabilityResult': 'Pass',
      'maximumAffordableLoanAmount': 367200.00,
      // eslint-disable-next-line spellcheck/spell-checker
      'quoteNumber': '59660e2e-45b1-4b68-850a-becadc3c6672',
      'httpError': null,
      'httpErrorMessage': null,
      'payrate': null,
      'stressRate': null,
      'maximumValue': 367200.00,
      'tooManyApplicants': false,
    },
  ] as IgniteResponse[],
  errorMessage: null,
  completed: true,

} as AffordabilityPollResponse;
