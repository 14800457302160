import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ModalStateService } from '../../services';
import { ModalOptions } from '../../models';

/**
 * The component displayed in the confirmation modal opened by the ModalService.
 */
@Component({
  selector: 'lib-modal-component',
  styleUrls: ['modal.component.scss'],
  templateUrl: 'modal.component.html',
})
export class ModalComponent implements AfterViewInit {
  public options: ModalOptions;
  public isDisabled = false;

  public constructor(private state: ModalStateService, private readonly changeDetectorRef: ChangeDetectorRef) {
    this.options = state.options;
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      if (this.options.disabledTillScroll) {
        this.isDisabled = true;
      }
    }, 200);
  }

  public onScroll(event: any) {
    const distanceFromBottom = 120;
    if (
      this.options.disabledTillScroll &&
      this.isDisabled &&
      event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - distanceFromBottom
    ) {
      this.isDisabled = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  public yes() {
    this.state.topmostModal?.close('confirmed');
    if (this.state.modalStack?.length > 0) {
      this.state.modal = this.state.topmostModal;
    }
  }

  public no() {
    this.state.topmostModal?.dismiss('not confirmed');
    if (this.state.modalStack?.length > 0) {
      this.state.modal = this.state.topmostModal;
    }
  }

  public cross() {
    this.state.topmostModal?.dismiss('Cross click');
    if (this.state.modalStack?.length > 0) {
      this.state.modal = this.state.topmostModal;
    }
  }

  public get titleAlignment() {
    switch (this.options.titleAlign) {
      case 'left':
        return 'text-left';
      case 'center':
        return 'text-center ml-4';
      case 'right':
        return 'text-right';
      default:
        return 'text-left';
    }
  }

  public get titleFloatingContentIsHtmlString(): boolean {
    return typeof this.options.titleFloatingContent === 'string';
  }
}
