export const errorMessages = {
  required: 'This field is required',
  minlength: 'This field should be at least 3 characters',
  maxlength: 'This field should be max 3 characters',
  requiredCheckboxGroup: 'Please select at least one checkbox',
  greaterThan: 'This value should be greater than 0',
  ltv500Percent: 'Loan amount should not exceed 500% of property value',
  interestOnlyLessThanLoanAmount: 'Amount on interest only must be less than loan amount',
  creditCardBalanceToRepayLessThanTotalOutstandingCreditCardBalance:
    'Credit card balance to be repaid must be less than or equal to total outstanding credit card balance',
  paymentsNotCoveringMortgageBalance: `The current monthly mortgage payment over the mortgage term
    is not enough to pay off the current mortgage balance.
    Please check that the monthly mortgage payment and remaining term have been entered correctly.`,
  ageOlderThanRetirementAge: 'Planned retirement age must be older than your current age.',
};
