import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ModalStateService } from '@msslib/services';
import { DisclaimerService } from '@msslib/services/disclaimer.service';
import { keys } from 'apps/clubhub/src/app/constants';
import { Router } from '@angular/router';
import { ClientApps } from '@msslib/models';


@Component({
  selector: 'lib-disclaimer-modal',
  templateUrl: 'disclaimer-modal.component.html',
  styleUrls: ['disclaimer-modal.component.scss'],
})
export class DisclaimerModalComponent {
  public form = new UntypedFormGroup({});
  public fields: FormlyFieldConfig[];

  public formOptions: FormlyFormOptions = {};
  public isDisabled = true;

  public constructor(
    @Inject(ModalStateService) private state,
    @Inject(ChangeDetectorRef) private changeDetectorRef,
    @Inject(DisclaimerService) private disclaimerService,
    @Inject(Router) private router,
  ) {}

  public yes() {
    this.disclaimerService.setDisclaimerAsRead(this.options.client).subscribe(() => {
      sessionStorage.setItem(keys.termsDisclaimer, 'true');
      this.state.topmostModal?.close('confirmed');
      if (this.state.modalStack?.length > 0) {
        this.state.modal = this.state.topmostModal;
      }
    });
  }

  public cancel() {
    this.state.topmostModal?.dismiss('not confirmed');
    if (this.state.modalStack?.length > 0) {
      this.state.modal = this.state.topmostModal;
    }
    this.router.navigate(['/']);
  }

  public get options() {
    return this.state.options;
  }

  public get isClubHub(): boolean {
    return this.options.client === ClientApps.ClubHub;
  }

  public get isLenderHub(): boolean {
    return this.options.client === ClientApps.LenderHub;
  }

  public onScroll(event: any) {
    const distanceFromBottom = 120;
    if (
      this.isDisabled &&
      event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - distanceFromBottom
    ) {
      this.isDisabled = false;
      this.changeDetectorRef.detectChanges();
    }
  }
}
