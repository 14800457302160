import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';

import { ContextOption } from '@msslib/models/contexts';
import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { OptionsRange } from '../models';

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  public fields: FormlyFieldConfig[] = [];
  public options: ContextOption[];
  public questionAndAnswerModel: any = {};

  public constructor(private clubHubDataService: ClubHubDataService) {}

  public loadOptions(answerId: number | undefined): void {
    if (!answerId) {
      return;
    }
    this.getOptions(answerId).subscribe((contextOptions: ContextOption[]) => {
      this.setOptions(contextOptions);
      this.getAnswerFields(answerId);
      this.questionAndAnswerModel = {};
    });
  }

  public getAnswerFields(answerId: number | undefined): void {
    const question = this.nextQuestion(answerId);
    const answers = this.options
      .filter((x) => x.parentId === question?.id)
      .map(({ id, questionText }) => ({
        value: id,
        label: questionText,
      }));

    this.fields = [
      {
        key: question?.id.toString(),
        type: 'radioList',
        props: {
          fullWidth: true,
          label: question?.questionText,
          options: answers,
          formCheck: 'custom',
        },
      },
    ];
  }

  public setOptions(options: ContextOption[]): void {
    this.options = options;
  }

  public getOption(optionId: number): ContextOption | undefined {
    return this.options.find((x) => x.id === optionId);
  }

  public nextQuestion(answerId: number | undefined): ContextOption | undefined {
    return this.options.find((x) => x.parentId === answerId);
  }

  public getOptions(contextId: number): Observable<ContextOption[]> {
    return this.clubHubDataService.get<ContextOption[]>(`Answer/GetOptions?contextId=${contextId}`);
  }

  public getRange(questionId: number) {
    return this.clubHubDataService.get(`Question/GetRange/${questionId}`);
  }

  public updateRange(range: OptionsRange) {
    return this.clubHubDataService.put(`Question/UpdateRange/${range.questionId}`, range);
  }
}
