import { EventEmitter, Injectable } from '@angular/core';
import { ProductsSearchService } from './products-search.service';
import { ProductsFilterService } from './products-filter.service';
import { Availability, BtlType, FeeScaleConditionType, MortgageType, Product } from 'apps/shared/src/models';
import { IgniteService } from './ignite.service';
import { AffordabilityRequest, BdgAffordabilityRequest, BtlAffordabilityRequest } from '../models/affordability';
import { ProductFeeType } from '@msslib/constants';

@Injectable({ providedIn: 'root' })
export class ProductsFeeScaleService {

  public feesChanged = new EventEmitter<void>();

  public constructor(
    private readonly filterService: ProductsFilterService,
    private readonly searchService: ProductsSearchService,
    private readonly igniteService: IgniteService,
  ) {
    // Whenever a search is completed, perform the scaled fee logic on the initial results.
    this.searchService.productsSearchCompleted.subscribe(() => this.updateConditionalValuationFeeScales());
  }

  /** Updates the values of scaled fees in the `ProductsSearchService.matchedProducts` based on the current filters. */
  public updateConditionalValuationFeeScales() {
    // Note: This only applies to valuation fees for now

    let anyChangesMade = false;

    for (const lenderProducts of this.searchService.matchedProducts ?? []) {
      // Skip lenders that don't have scaled fees or products
      if (!lenderProducts.lenderFeeScales?.length || !lenderProducts.products?.length) {
        continue;
      }

      for (const product of lenderProducts.products.filter(p => p.isValuationFeeScaled)) {
        const applicableConditions = this.getApplicableConditions(product);

        // Figure out which scaled fee is the correct one to use.
        // The applicableConditions is a list of the current conditions based on the search and applied filters.
        // If, out of all the lender's scales, they have exactly one that is relevant given the current conditions,
        // apply that one.
        // If the lender has no scales that apply in the current conditions, use the default 'None' condition scale.
        // If lender has multiple scales that apply in the current conditions, and they are not all the same value, use
        // -1 to indicate it should be referred.
        const candidateScales = lenderProducts.lenderFeeScales
          .filter(scale => applicableConditions.includes(scale.condition));

        let newValuationFee: number;
        let isReferToLender: boolean;
        switch (candidateScales.length) {
          case 0:
            const scale = lenderProducts.lenderFeeScales.find(scale => scale.condition === FeeScaleConditionType.None);
            newValuationFee = scale?.fee ?? -1;
            isReferToLender = scale?.isReferToLender ?? false;
            break;
          case 1:
            newValuationFee = candidateScales[0].fee ?? -1;
            isReferToLender = candidateScales[0]?.isReferToLender ?? false;
            break;
          default:
            if (new Set(candidateScales.map(s => s.fee)).size === 1) {
              newValuationFee = candidateScales[0].fee ?? -1;
              isReferToLender = candidateScales[0]?.isReferToLender ?? false;
            } else {
              newValuationFee = -1;
              isReferToLender = false;
            }
            break;
        }

        if (product.fees[ProductFeeType.Valuation] !== newValuationFee ||
          product.isValuationFeeRefer !== isReferToLender) {
          product.fees[ProductFeeType.Valuation] = newValuationFee;
          product.isValuationFeeRefer = isReferToLender;
          anyChangesMade = true;
        }
      }
    }

    if (anyChangesMade) {
      this.feesChanged.emit();
    }
  }

  private isProductFieldAvailable(productField: Availability | null): boolean {
    if (!productField) {
      return false;
    }

    return productField === Availability.AlsoAvailable || productField === Availability.OnlyAvailable;
  }

  /** For a given product, figures out which scale conditions may apply based on the product and the current filters. */
  private getApplicableConditions(product: Product) {
    const conditions: FeeScaleConditionType[] = [];

    //
    // !! THESE CONDITIONS ALSO EXIST IN THE BACKEND. BE SURE TO UPDATE THEM THERE IF THEY ARE CHANGED HERE !!
    //    See PopulateScaledValuationFees in MSS.Infrastructure/Products/Services/ResiBtlProductService.cs
    //

    // 1. Expat not in UK
    if (this.hasTailoredFilter('Expat not in UK')
      && (this.isProductFieldAvailable(product.expatNotInUk))) {
      conditions.push(FeeScaleConditionType.ExpatNotInUk);
    }

    // 2. Help to Buy
    if ((this.hasModelValue('helpToBuy', true) || this.hasTailoredFilter('Help to Buy'))
      && (this.isProductFieldAvailable(product.helpToBuy))) {
      conditions.push(FeeScaleConditionType.HelpToBuy);
    }

    // 3. HMO
    if ((this.hasModelValue('btlType', BtlType.HMO) || this.hasTailoredFilter('HMO'))
      && (this.isProductFieldAvailable(product.hmo))) {
      conditions.push(FeeScaleConditionType.Hmo);
    }

    // 4. Purchase
    if (this.hasModelValue('mortgageType', MortgageType.Purchase)
      && product.mortgageType && product.mortgageType.includes(MortgageType.Purchase)) {
      conditions.push(FeeScaleConditionType.Purchase);
    }

    // 5. Remortgage
    if (this.hasModelValue('mortgageType', MortgageType.Remortgage)
      && product.mortgageType && product.mortgageType.includes(MortgageType.Remortgage)) {
      conditions.push(FeeScaleConditionType.Remortgage);
    }

    // 6. Self Build
    if (this.hasTailoredFilter('Self build')
      && (this.isProductFieldAvailable(product.selfBuild))) {
      conditions.push(FeeScaleConditionType.SelfBuild);
    }

    return conditions;
  }

  /** Checks both the affordability and products search models for a property with the given value. */
  private hasModelValue(
    propertyName: keyof AffordabilityRequest | keyof BtlAffordabilityRequest | keyof BdgAffordabilityRequest,
    value: any,
  ) {
    const productsSearchModel = this.searchService.productsModelRequest as any | undefined;
    if (!!productsSearchModel && propertyName in productsSearchModel && productsSearchModel[propertyName] === value) {
      return true;
    }

    const affSearchModel = this.igniteService.model
      && this.igniteService.mappedModel(this.igniteService.model, null, true) as any | undefined;
    if (!!affSearchModel && propertyName in affSearchModel && affSearchModel[propertyName] === value) {
      return false;
    }

    return false;
  }

  private hasTailoredFilter(name: string): boolean {
    return this.filterService.hasActiveIncludeFilter('Tailored Products', name);
  }
}
