<div [class]="marginLeft ? 'mx-5' : ''">
  <ng-template #contextSearchTemplate let-r="result" let-t="term">
    <ngb-highlight [result]="(r.sourceQuestionText ? r.sourceQuestionText + ' -- ' : '') + r.questionText" [term]="t"></ngb-highlight>
  </ng-template>

  <lib-search-input-typeahead
    id="contextsearch"
    key="questionText"
    label="Search Contexts"
    [model]="searchTerm"
    [showClearButton]="true"
    [dynamicAction]="filterItems"
    (selectItem)="selectContext($event)"
    [resultTemplate]="contextSearchTemplate"
    (clearSearch)="clear()"
  >
  </lib-search-input-typeahead>

  <div class="loading-spinner" [style.opacity]="itemsLoading ? 1 : 0"></div>
</div>