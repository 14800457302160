import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ILoadingOptions } from '../models';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private totalRequests = 0;
  private spinnerDelay = 500;
  public loading$ = new Subject<ILoadingOptions>();

  public startLoading(loadingOptions: ILoadingOptions) {
    this.totalRequests++;
    setTimeout(() => {
      if (this.totalRequests > 0) {
        this.loading$.next(loadingOptions);
      }
    }, this.spinnerDelay);
  }

  public stopLoading(loadingOptions: ILoadingOptions) {
    this.totalRequests--;
    if (this.totalRequests === 0) {
      this.loading$.next(loadingOptions);
    }
  }
}
