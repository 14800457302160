import { Injectable } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { AbstractControl, NgForm, UntypedFormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';

import { FieldOption, IFieldConfig } from '../../../models';
import { FormsValidators } from '../validators';
import { FormatService } from '../../../services';

@Injectable()
export class FormsService {
  public constructor(private formatService: FormatService) {}

  public valid(form: UntypedFormGroup, fieldConfig: IFieldConfig, formRef: NgForm) {
    if (form.controls[fieldConfig.name]) {
      return form.controls[fieldConfig.name].valid && (form.controls[fieldConfig.name].touched || formRef.submitted);
    }
  }

  public invalid(form: UntypedFormGroup, fieldConfig: IFieldConfig, formRef: NgForm) {
    return !form.controls[fieldConfig.name].valid && (form.controls[fieldConfig.name].touched || formRef.submitted);
  }

  public stringToIsoUtcString(dateString: string): string {
    if (dateString) {
      return new Date(dateString).toISOString();
    }
    return '';
  }

  public requiredFileType(type: string) {
    return function(control: AbstractControl) {
      const file = control.value;
      if (file) {
        const extension = file.name.split('.')[1].toLowerCase();
        if (type.toLowerCase() !== extension.toLowerCase()) {
          return {
            requiredFileType: true,
          };
        }
        return null;
      }
      return null;
    };
  }

  public showIndicator(config: { validation?: { length: number; some: (arg0: (x: any) => boolean) => any}}) {
    return (
      config.validation &&
      config.validation.length > 0 &&
      config.validation.some((x) => {
        return FormsValidators.validationExist(x);
      })
    );
  }

  public toFormData<T extends Record<string, unknown>>(formValue: T) {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      formData.append(key, value as string | Blob);
    }
    return formData;
  }

  public uploadProgress<T>(cb: (progress: number) => void) {
    return tap((event: HttpEvent<T>) => {
      if (event.type === HttpEventType.UploadProgress) {
        return cb(Math.round((100 * event.loaded) / (event.total ?? 1)));
      }
    });
  }

  public buildOptionsFromEnum(enumeration: any, convertToLowerCase = true): FieldOption[] {
    const propertyNames = this.formatService.getEnumPropertyNames(enumeration);
    return propertyNames.map(prop => {
      return {
        value: enumeration[prop],
        text: this.formatService.toSentenceCase(prop, convertToLowerCase),
      } as FieldOption;
    });
  }
}
