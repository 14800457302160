import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { LendingTypeService } from '@msslib/services';

const criteriaV2Path = 'criteriaV2';
const criteriaPath = 'criteria';
@Injectable({
  providedIn: 'root',
})
export class CriteriaNavigateService {
  public criteriaV2Enabled = true;

  public constructor(
    private router: Router,
    private lendingTypeService: LendingTypeService,
  ) { }

  public get criteriaVersionText() {
    return this.criteriaV2Enabled ? criteriaV2Path : criteriaPath;
  }

  public navigateHome() {
    this.router.navigateByUrl(`/ignite/${this.criteriaVersionText}/landing`);
  }

  public navigateRootPage(page: string): void {
    this.router.navigateByUrl(`/ignite/${this.criteriaVersionText}/landing/${page}`);
  }

  public navigate(
    url: string,
    navigationOptions: NavigationExtras | null = null,
    isCriteriaV2: boolean | null = true,
  ) {
    let defaultNavigationExtras: NavigationExtras = {};

    if (navigationOptions) {
      defaultNavigationExtras = {
        ...defaultNavigationExtras,
        ...navigationOptions,
      };

      if (navigationOptions.queryParams && Object.values(navigationOptions.queryParams).filter((v) => v).length === 0) {
        defaultNavigationExtras.queryParams = {};
      }
    }

    const criteriaUrlPath = isCriteriaV2 === null
      ? this.criteriaVersionText
      : isCriteriaV2 ? criteriaV2Path : criteriaPath;

    const urlTree = this.router.createUrlTree(
      [this.lendingTypeService.lendingType
        ? `/ignite/${criteriaUrlPath}/landing/${this.lendingTypeService.lendingType.id}/${url}`
        : `/ignite/${criteriaUrlPath}/landing/${url}`,
      ],
      defaultNavigationExtras,
    );
    this.router.navigateByUrl(urlTree, defaultNavigationExtras);
  }
}
