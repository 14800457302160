<p>Are you sure you want to reschedule?<ng-container *ngIf="willRescheduleVariableRateSchedule"> This will also reschedule the current variable rate schedule for {{ startDateFormatted }}.</ng-container></p>

<div class="d-flex align-items-center">
  <div class="input-group me-2">
    <input
      class="form-control"
      placeholder="DD/MM/YYYY"
      name="dp"
      [(ngModel)]="startDate"
      [minDate]="minDate"
      ngbDatepicker
      #d="ngbDatepicker"
      container="body"
    />
    <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
      <i class="fa fa-calendar"></i>
    </button>
  </div>
  <div class="input-group me-2">
    <ngb-timepicker [(ngModel)]="time"></ngb-timepicker>
  </div>
</div>

<div class="row preview-buttons">
  <div class="col">
    <button class="preview-cancel btn btn-2022--secondary" (click)="this.cancel()">No, go back</button>
  </div>
  <div class="col">
    <button id="continue-but" class="preview-submit btn btn-2022--secondary" (click)="this.submit()">Yes, reschedule</button>
  </div>
</div>
