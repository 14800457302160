import { ActivatedRoute, ActivatedRouteSnapshot, Params } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public constructor(@Inject(DOCUMENT) private document: Document) {}
  public imageToFormData(image: any, name: string, isSvg = false, fileType = 'image/jpeg') {
    let base64ImageContent: string;
    if (isSvg) {
      base64ImageContent = image.changingThisBreaksApplicationSecurity.replace(/^data:image\/(svg\+xml);base64,/, '');
    } else {
      base64ImageContent = image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
    }
    const blob = this.base64ToBlob(base64ImageContent, fileType);
    const formData = new FormData();
    formData.append(name, blob);

    return formData;
  }

  public base64ToBlob(base64: string, mime: string) {
    mime = mime || '';
    const sliceSize = 1024;
    const byteChars = window.atob(base64);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0, length = byteChars.length; offset < length; offset += sliceSize) {
      const slice = byteChars.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mime });
  }

  public routeParams(activatedRoute: ActivatedRouteSnapshot | ActivatedRoute): Params {
    let route = activatedRoute.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route.params;
  }

  public arrayToPairwise(arr: any[]): unknown[] {
    const objs = arr.reduce((result, value, index, array) => {
      if (index % 2 === 0) {
        result.push(array.slice(index, index + 2));
      }
      return result;
    }, []);

    return objs;
  }

  public parseQuery(queryString: string): Record<string, string> {
    const query: Record<string, string> = {};
    const pairs = (queryString.startsWith('?') ? queryString.substring(1) : queryString).split('&');
    for (const pair of pairs) {
      const splitPair = pair.split('=');
      query[decodeURIComponent(splitPair[0])] = decodeURIComponent(splitPair[1] || '');
    }
    return query;
  }

  public isEmptyObject(obj: Record<string, unknown>): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  public scriptExists(src: string) {
    const scripts = this.document.getElementsByTagName('script');
    for (let i = scripts.length; i--; ) {
      if (scripts[i].src?.includes(src)) {
        return true;
      }
    }
    return false;
  }
}
