import { Inject, Injectable } from '@angular/core';

import { BaseConfig, MSSLIB_CONFIG, MssLibConfig } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public model: BaseConfig;

  public constructor(@Inject(MSSLIB_CONFIG) private mssLibConfig: MssLibConfig) {}

  public get config(): BaseConfig {
    return this.model;
  }

  public get isLocal() {
    return window.location.hostname.includes('localhost');
  }

  public get applicationName(): string {
    if (this.model?.clientDomains) {
      const clientDomain = this.model.clientDomains.find((x) => x.domain === window.location.host);
      if (clientDomain) {
        return clientDomain.client;
      }
    }

    return this.mssLibConfig.applicationName;
  }

  public async load(): Promise<unknown> {
    const configResponse = await fetch('assets/config.json');
    this.model = await configResponse.json();
    return this.model;
  }
}
