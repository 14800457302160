import { ArrayDatasource } from './array.datasource';
import { FunctionDatasource } from './function.datasource';
import { type Observable, isObservable } from 'rxjs';
import { ObservableDatasource } from './observable.datasource';
import type { Datasource, DatasourceResult } from './datasource';
import type { GridStateModel } from '../../../models';
import type { SimpleGridComponent } from '../simple-grid.component';
import { PromiseDatasource } from './promise.datasource';

export * from './datasource';

export type RawDatasource<T = unknown> = T[]
  | Promise<T[]>
  | Observable<T>
  | ((state: GridStateModel, grid: SimpleGridComponent) => Promise<DatasourceResult<T>>);

export function isDatasource(item: any): item is Datasource {
  return item !== null && item !== undefined && (
    item instanceof ArrayDatasource
    || item instanceof FunctionDatasource
    || item instanceof ObservableDatasource
    || item instanceof PromiseDatasource
    || ('getItems' in item && typeof item.getItems === 'function')
  );
}

export function toDatasource(source: RawDatasource): Datasource | null {
  if (source === null || source === undefined) {
    return null;
  } else if (isDatasource(source)) {
    return source;
  } else if (isObservable(source)) {
    return new ObservableDatasource(source as Observable<unknown[]>);
  } else if (Array.isArray(source)) {
    return new ArrayDatasource(source);
  } else if (typeof source === 'function') {
    return new FunctionDatasource(source);
  } else if ('then' in source && 'catch' in source && 'finally' in source) {
    return new PromiseDatasource(source as Promise<unknown[]>);
  }
  throw new Error('Unknown datasource type');
}
