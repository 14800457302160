import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AffordabilityFormFieldsResiService } from './affordability-form-fields-resi.service';
import { AffordabilityFormFieldsBtlService } from './affordability-form-fields-btl.service';
import { LendingTypeService } from '@msslib/services/lending-type.service';

@Injectable({
  providedIn: 'root',
})
export class AffordabilityFormFieldsService {
  public constructor(
    private resiFields: AffordabilityFormFieldsResiService,
    private btlFields: AffordabilityFormFieldsBtlService,
    public lendingTypeService: LendingTypeService,
  ) {}

  public get fields(): FormlyFieldConfig[] {
    if (!this.lendingTypeService.currentLendingTypeCode) {
      return [];
    }
    switch (this.lendingTypeService.currentLendingTypeCode) {
      case 'BTL':
        return this.btlFields.fields;
      case 'RES':
        return this.resiFields.fields;
      default:
        throw new Error(
          `No products question set configured for lending type '${this.lendingTypeService.currentLendingTypeCode}'`,
        );
    }
  }

  public getStepFields(): FormlyFieldConfig[] {
    return this.fields ?? [];
  }
}
