import { Component, Input } from '@angular/core';
import { ModalService } from '@msslib/services/modal.service';
import { LendingTypeCode } from 'apps/shared/src/models';
import { LendingTypeService } from '../../services';

@Component({
  selector: 'lib-product-results-modal',
  templateUrl: 'product-results-modal.component.html',
})
export class ProductResultsModalComponent {
  @Input() public isMatchedProductsView = false;
  public readonly featuresColumnCount = 3;
  public readonly productFeatures: Record<Exclude<LendingTypeCode, LendingTypeCode._Unset>, string[]> = {
    [LendingTypeCode.Res]: [
      'Lending Type:<br/>Residential or BTL',
      'Mortgage Type:<br/>Purchase or Remortgage',
      'LTV',
      'First Time Buyer / Home Mover',
      'Help to Buy',
      'Method of Repayment',
      'Initial Product Period',
      'Expat not in UK',
      'Second Residential',
      'Retirement Interest Only',
      'Joint Borrower - Sole Proprietor',
    ],
    [LendingTypeCode.Btl]: [
      'Lending Type:<br/>Residential or BTL',
      'Mortgage Type:<br/>Purchase or Remortgage',
      'LTV',
      'First Time Buyer / Home Mover',
      'Help to Buy',
      'Method of Repayment',
      'Initial Product Period',
      'Expat not in UK',
      'Second Residential',
      'Holiday Let / Air B&amp;B',
      'Regulated BTL',
      'HMO',
      'Let to Buy',
      'Portfolio Landlord',
      'Limited Company Buy to Let',
    ],
    [LendingTypeCode.Bdg]:[],
  };

  public constructor(
    private modalService: ModalService,
    private lendingTypeService: LendingTypeService,
  ) { }

  public get features(): string[] {
    return this.productFeatures[this.lendingTypeService.lendingType?.code as LendingTypeCode.Res | LendingTypeCode.Btl];
  }

  public close() {
    this.modalService.dismiss();
  }
}
