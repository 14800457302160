<div class="row justify-content-end">
  <label class="col fw-bold d-block my-auto">{{props.headerLabel}}</label>
  <div class="col d-flex justify-content-end">
    <button
      [disabled]="props.disabled"
      class="btn"
      [class]="props.button.className ?? ''"
      [class.disabled]="formControl.disabled"
      [type]="props.button.type ?? 'button'"
      (click)="!props.button.onClick || props.button.onClick(field)"
    >
      <i *ngIf="props.button.iconClass" [ngClass]="props.button.iconClass"></i>
      {{ props.button.label }}
    </button>
  </div>
</div>
