import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'lib-ff-ltv-field',
  styleUrls: ['ltv-field.component.scss'],
  templateUrl: 'ltv-field.component.html',
})
export class FormlyLtvFieldComponent extends FieldType {
  public constructor() {
    super();
  }
}
