import { Component, Input } from '@angular/core';
import { HeadingSize } from '../../models';

@Component({
  selector: 'lib-page-heading',
  styleUrls: ['page-heading.component.scss'],
  templateUrl: 'page-heading.component.html',
})
export class PageHeadingComponent {
  @Input() public text: string;
  @Input() public size: HeadingSize = HeadingSize.H1;

  public headingSize = HeadingSize;
}
