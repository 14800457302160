<div class="form-group">
  <label for="{{ id || 'searchinput' }}">{{ label || 'Search' }}</label>
  <input
    id="{{ id || 'searchinput' }}"
    [placeholder]="placeholder || ''"
    type="text"
    class="form-control"
    [(ngModel)]="model"
    [ngbTypeahead]="search"
  />
</div>
