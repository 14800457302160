function createAndClickAnchor(href: string, download: string) {
  const link = document.createElement('a');
  link.href = href;
  link.download = download;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function downloadBlob(blob: Blob, name: string) {
  const url = URL.createObjectURL(blob);
  createAndClickAnchor(url, name);
}

export function downloadTextFile(contents: string, name: string) {
  const url = `data:text/plain;charset=utf-8,${encodeURIComponent(contents)}`;
  createAndClickAnchor(url, name);
}
