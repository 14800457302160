<ng-template #defaultCellTemplate let-row="row" let-column="column">
  {{ renderCell(row, column.name, 'cell') }}
</ng-template>

<div class="position-relative simple-grid">
  <div
    #tableContainer
    class="simple-grid-container"
    [class.table-responsive]="responsive"
    [style.min-height]="minGridHeight"
  >
    <table
      class="mb-2 simple-grid-table"
      [ngClass]="classList"
      [class.fixed-layout]="fixedLayout"
      [class.table-hover]="tableHover"
      [class.text-small]="textSmall"
    >
      <thead [class.sticky-header]="sticky" [style.top]="stickyTop" class="border-bottom border-2022--black">
        <!-- Column heading labels -->
        <tr>
          <th *ngIf="showSelectionCheckboxes">
            <input type="checkbox"
              class="m-2"
              [checked]="isPageRowsSelected"
              [indeterminate]="isPageRowsIndeterminate"
              (change)="togglePageRowsSelected($event.target.checked)"
            />
          </th>
          <th *ngIf="showSelectionRadios">&nbsp;</th>
          <th *ngFor="let column of columnData; trackBy: trackByIndex" [ngClass]="combineClassList(headerCellClassList, column.headerClassList)"
            [ngbTooltip]="column.headerTooltip" [tooltipClass]="column.headerTooltipClass" container="body"
            [style]="column.headerStyle" [attr.rowspan]="shouldMergeHeaderRows(column) ? '2' : ''"
            [attr.valign]="shouldMergeHeaderRows(column) ? 'bottom' : ''">
            <div [class.break-headings]="wordBreakHeadings"
              [class.can-filter-sort]="column.discreteFilter || column.sortable"
              (click)="(column.userFilter === true || column.discreteFilter || column.sortable) && openFilter(column, $event.currentTarget)"
              [attr.data-testid]="'simple_grid_header_filter_' + column.name"
            >
              <span class="text-center px-1">{{ column.label !== undefined ? column.label : column.name }}
                <i class="{{ column.icon }}"></i>
              </span>
              <span *ngIf="filters.has(column.name) || isSortActive(column.name)">
                <i class="fas fa-filter" [hidden]="!filters.has(column.name)"></i>
                <i [class]="'fas ' + getSortIcon(column, sortDir)"
                  [hidden]="filters.has(column.name) || !isSortActive(column.name)"></i>
              </span>
              <span
                *ngIf="!(filters.has(column.name) || isSortActive(column.name) || (column.userFilter !== true && !column.discreteFilter && !column.sortable))">
                <i class="fas fa-caret-down"
                  [hidden]="filters.has(column.name) || isSortActive(column.name) || (column.userFilter !== true && !column.discreteFilter && !column.sortable)"></i>
              </span>
            </div>
          </th>
        </tr>

        <!-- Header filters -->
        <tr *ngIf="hasHeaderFilters">
          <th *ngIf="showSelectionCheckboxes || showSelectionRadios">
            &nbsp;
          </th>
          <th *ngFor="let column of columnData; trackBy: trackByIndex" class="border-bottom border-2022--black"
            class="p-1" [ngClass]="column.headerClassList" [style]="column.headerStyle"
            [style.display]="shouldMergeHeaderRows(column) ? 'none' : ''">
            <ng-container *ngIf="column.headerFilter === 'input'">
              <input
                [appAttrFromObj]="getInputAttributesFor(column.type)"
                [attr.data-testid]="'simple_grid_header_input_' + column.name"
                class="form-control form-control-sm"
                [class.border-primary]="isFilterActive(column.name)"
                placeholder="Search..."
                [value]="isFilterActive(column.name) ? filters.get(column.name).value : ''"
                (input)="updateQuickFilter(column.name, $event.target.value, 'input')"
                (blur)="updateQuickFilter(column.name, $event.target.value, 'blur')"
                (keydown)="updateQuickFilter(column.name, $event.target.value, 'keydown', $event.keyCode)">
            </ng-container>

            <div *ngIf="column.headerFilter === 'select'" class="multiselect-filter">
              <lib-multiselect-filter class="w-100" [itemsLabel]="column.pluralLabel" [data]="column.availableOptions"
                [checkedValues]="filters.get(column.name)?.value"
                (valuesSelected)="updateSelectFilter(column.name, $event)">
              </lib-multiselect-filter>
            </div>
          </th>
        </tr>
      </thead>

      <tbody (mousemove)="showTooltip && adjustTooltipPosition($event, tooltipContainer)">
        <!-- Tooltip -->
        <div class="tooltip-container" #tooltipContainer>
          <span *ngIf="showTooltip && tooltipIsString" class="textual-tooltip">{{ tooltip }}</span>
          <ng-container *ngIf="showTooltip && !tooltipIsString" [ngTemplateOutlet]="tooltip"></ng-container>
        </div>

        <!-- Status information rows -->
        <tr [hidden]="!hasFilters" class="table-warning">
          <td class="p-1 text-center" [attr.colspan]="columns.length">
            <em>
              Some records may be hidden by one or more filters you have selected.
              <a href="javascript:void(0)" (click)="unsetAllFilters()">Click to clear all filters</a>
            </em>
          </td>
        </tr>

        <!-- Extra information banner -->
        <tr [hidden]="extraInfoHidden" class="table-extra-info">
          <td class="p-1 text-center" [attr.colspan]="columns.length">
            <span [innerHTML]="extraInfoContent"></span>
            <span (click)="closeExtraInfoBanner()" class="close"><i class="fa fa-times" ></i></span>
          </td>
        </tr>

        <tr [hidden]="totalItemCount > 0" class="table-danger">
          <td class="p-1 text-center" [attr.colspan]="columns.length">
            <em>{{ noRecordsMessage || 'There are no records to display.' }}</em>
          </td>
        </tr>

        <!-- Actual data content -->
        <tr
          *ngFor="let item of pageData; trackBy: trackByIndex; index as i"
          [attr.data-testid]="'simple_grid_row_' + i"
          [ngClass]="rowClassList"
          [class.row-selected]="isRowSelected(item)"
          [ngStyle]="getExtraRowStyles(item)"
        >
          <td *ngIf="showSelectionCheckboxes" [ngStyle]="itemCellStyles">
            <input
              type="checkbox"
              class="m-2"
              [attr.data-testid]="'simple_grid_selection_checkbox_' + i"
              [checked]="isRowSelected(item)"
              (change)="toggleRowSelected(item)"
            />
          </td>
          <td *ngIf="showSelectionRadios" [ngStyle]="itemCellStyles">
            <input
              type="radio"
              class="m-2"
              [attr.data-testid]="'simple_grid_selection_radio_' + i"
              [checked]="isRowSelected(item)"
              (change)="toggleRowSelected(item)"
            />
          </td>
          <td *ngFor="let column of columnData; index as ci; trackBy: trackByIndex"
            (click)="onCellClicked(item, i, column, ci)"
            class="px-1" [ngClass]="combineClassList(rowCellClassList, executeMaybeCellAware(column.classList, item, column))"
            [attr.data-testid]="'simple_grid_' + i + '_col_' + column.name"
            [ngStyle]="getExtraCellStyles(item)"
            [style]="column.style"
            [attr.data-cname]="column.name">
            <ng-template [ngTemplateOutlet]="column.template || defaultCellTemplate" [ngTemplateOutletContext]="{
                $implicit: item[column.name],
                data: item[column.name],
                row: item,
                rowIndex: i,
                columnIndex: ci,
                fullRowIndex: i + pageSize * (page - 1),
                column: column
              }"></ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="mt-2 d-flex justify-content-between mx-3" *ngIf="totalItemCount > pageSize && paginate">
    <div class="col my-auto" *ngIf="canUserSetPageSize">
      <span>
        Showing {{(page * pageSize) - (pageSize - 1)}}-{{page * pageSize < totalItemCount ?  page * pageSize : totalItemCount}} of {{totalItemCount}} products
      </span>
    </div>
    <div class="col d-flex justify-content-end">
      <ng-container *ngIf="canUserSetPageSize">
        <label class="me-2 my-auto">Results per page</label>
        <div class="select-pagesize">
          <select class="select-pagesize-input" [(ngModel)]="pageSize" (ngModelChange)="alterPageSize($event)">
            <option *ngFor="let sizeOfPage of pageSizeChoices; trackBy: trackByIndex" [ngValue]="sizeOfPage">{{sizeOfPage}}</option>
          </select>
        </div>
      </ng-container>
      <ngb-pagination [collectionSize]="totalItemCount" [page]="page" (pageChange)="setPage($event)" [maxSize]="5"
        [pageSize]="pageSize" [rotate]="true" [ellipses]="true" class="ms-4">
        <ng-template ngbPaginationPrevious></ng-template>
        <ng-template ngbPaginationNext class="btn btn-signpost"></ng-template>
        <ng-template ngbPaginationNumber let-p>{{ p }}</ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
      </ngb-pagination>
    </div>
  </div>

  <div *ngIf="showSpinner" class="loading-overlay d-flex align-items-center justify-content-center"
    [class.show]="isLoading">
    <div class="spinner-border text-2022--primary" style="width: 3rem; height: 3rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<ng-template #filterDropdown let-column>
  <div [class]="'dropdown-menu show ' + (column.userFilter === true ? 'has-user-filter' : '')" style="top:0;left:0">

    <h6 class="dropdown-header" *ngIf="hasDropdownFilters(column) || column.discreteFilter || column.filterPresets">Filter</h6>

    <!-- Dropdown filter -->
    <div *ngIf="hasDropdownFilters(column)" class="px-3">
      <select
        class="form-select form-select-sm"
        [ngModel]="filters.get(column.name)?.op ?? ''"
        (ngModelChange)="setDropdownFilterMode(column.name, $event)"
      >
        <option value="">No filter</option>
        <option *ngFor="let item of listDropdownFilters(column); trackBy: trackByValue" [value]="item.value">{{ item.label }}</option>
      </select>

      <ng-container [ngSwitch]="filters.get(column.name)?.op ?? ''">
        <ng-container *ngSwitchCase="''"></ng-container> <!-- No filter selected, show no controls -->

        <ng-container *ngSwitchCase="filterOperator.Between"> <!-- For 'Between' show a from and a to control -->
          <input type="text"
            class="form-control form-control-sm mt-2"
            [appAttrFromObj]="getInputAttributesFor(column.type)"
            [value]="toInputValue(column.name, filters.get(column.name).value[0])"
            (blur)="updateDropdownFilterValue(column.name, $event.target.value, 0)">
          <p class="my-0 text-center">and</p>
          <input type="text"
            class="form-control form-control-sm"
            [appAttrFromObj]="getInputAttributesFor(column.type)"
            [value]="toInputValue(column.name, filters.get(column.name).value[1])"
            (blur)="updateDropdownFilterValue(column.name, $event.target.value, 1)">
        </ng-container>

        <ng-container *ngSwitch="filterOperator.In">Not Supported Yet</ng-container>

        <ng-container *ngSwitchDefault> <!-- Everything else, just show 1 control -->
          <input type="text"
            class="form-control form-control-sm mt-2"
            [appAttrFromObj]="getInputAttributesFor(column.type)"
            [value]="toInputValue(column.name, filters.get(column.name).value)"
            (blur)="updateDropdownFilterValue(column.name, $event.target.value)">
        </ng-container>
      </ng-container>
    </div>

    <!-- Discrete filter -->
    <ng-container *ngIf="column.discreteFilter">
      <button
        *ngFor="let option of (column.discreteFilter === true ? getDiscreteValues(column.name) : column.discreteFilter); trackBy: trackByValue"
        (click)="selectDiscreteFilterOption(column.name, option.value)"
        [attr.data-testid]="'simple_grid_header_discrete_filter_btn_' + column.name + '_' + option.label"
        [class]="'dropdown-item' + (isFilterActive(column.name, option.value) ? ' active' : '')" role="option">
        <i [class]="getFilterIcon(option.icon)"></i>&nbsp;
        {{ option.label }}
      </button>
    </ng-container>

    <div *ngIf="column.filterPresets" class="mt-3">
      <button
        *ngFor="let option of column.filterPresets; trackBy: trackByValue"
        [attr.data-testid]="'simple_grid_header_filterpresert_btn_' + column.name + '_' + option.label"
        (click)="selectFilterPresetOption(column.name, option.value)"
        [class]="'dropdown-item' + (option?.isActive?.(filters.get(column.name)) ? ' active' : '')" role="option">
        <i [class]="getFilterIcon(option.icon)"></i>&nbsp;
        {{ option.label }}
      </button>
    </div>

    <!-- Clear filter -->
    <ng-container *ngIf="filters.has(column.name)">
      <div class="dropdown-divider"></div>
      <button (click)="unsetFilter(column.name)" class="dropdown-item text-danger" [attr.data-testid]="'simple_grid_header_clear_filter_btn_' + column.name">
        <i class="far fa-times-circle"></i>&nbsp;
        Clear filter
      </button>
    </ng-container>

    <!-- Sorting -->
    <ng-container *ngIf="column.sortable">
      <div class="dropdown-divider" *ngIf="column.discreteFilter || column.userFilter === true"></div>
      <h6 class="dropdown-header">Sort</h6>
      <button *ngIf="column.sortDirRestrict !== 'desc'" (click)="setSort(column.name, 'asc')"
        [class]="'dropdown-item' + (isSortActive(column.name, 'asc') ? ' active' : '')"
        [attr.data-testid]="'simple_grid_header_sort_asc_btn_' + column.name"
      >
        <i [class]="'fas ' + getSortIcon(column, 'asc')"></i>&nbsp;
        {{ getSortText(column, 'asc') }}
      </button>
      <button *ngIf="column.sortDirRestrict !== 'asc'" (click)="setSort(column.name, 'desc')"
        [class]="'dropdown-item' + (isSortActive(column.name, 'desc') ? ' active' : '')"
        [attr.data-testid]="'simple_grid_header_sort_desc_btn_' + column.name"
      >
        <i [class]="'fas ' + getSortIcon(column, 'desc')"></i>&nbsp;
        {{ getSortText(column, 'desc') }}
      </button>
    </ng-container>
    <ng-container *ngIf="hasClearSortButton(column.name)">
      <div class="dropdown-divider"></div>
      <button (click)="clearSort()" class="dropdown-item text-danger">
        <i class="far fa-times-circle"></i>&nbsp;
        Clear sort
      </button>
    </ng-container>
  </div>
</ng-template>
