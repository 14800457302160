import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { clientIds } from '@msslib/constants';
import { Address } from '@msslib/models/address';
import { FirmDetails } from '@msslib/models/sso-user-firm-details';
import { AgencyNumberService, AuthorizeService } from '@msslib/services';
import { PostcodeService } from '@msslib/services/postcode.service';
import { UserDetailsService } from '@msslib/services/user-details.service';
import { FormFieldType } from '@msslib/components/formly/formly.config';
import { FormsValidators } from '@msslib/components/forms/validators';
import { ToastService } from '@msslib/services/toast.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { firstValueFrom, tap } from 'rxjs';

@Component({
  selector: 'lib-firm-details',
  templateUrl: 'firm-details.component.html',
})
export class FirmDetailsComponent implements OnInit {
  @Input() public showSave = true;
  public hasInitialised = false;
  public model: FirmDetails & { fcaNumber?: unknown };
  public form = new UntypedFormGroup({});
  public fields: FormlyFieldConfig[];
  @Output() public requestForbidden = new EventEmitter<void>();
  @Output() public save = new EventEmitter<FirmDetails>();

  private isManualAddress: boolean;
  private addressSearchSuccessful: boolean;
  public addresses: Address[];
  private agencyNumberFocused: boolean;

  public constructor(
    private authService: AuthorizeService,
    private userDetailsService: UserDetailsService,
    private postcodeService: PostcodeService,
    private agencyNumberService: AgencyNumberService,
    private toastService: ToastService,
  ) {}

  public ngOnInit(): void {
    this.fetchFirmDetails();
  }

  public get isValid(): boolean {
    return this.form.valid;
  }

  private fetchFirmDetails(): void {
    this.userDetailsService.getFirmDetails().subscribe({
      next: (details) => this.initializeForm(details),
      error: (error: HttpErrorResponse) => {
        if (error.status as HttpStatusCode === HttpStatusCode.Forbidden) {
          this.requestForbidden.emit();
        } else {
          this.initializeForm();
        }
      },
    });
  }

  private initializeForm(model?: FirmDetails): void {
    const defaultProps = {
    };

    this.model = model ?? {} as FirmDetails;
    // use session storage agency number if no firm details have been returned
    if (!model && this.authService.agencyNumber) {
      this.model.agencyNumber = this.authService.agencyNumber;
    }
    this.model.fcaNumber = this.authService.user?.frnNumber;
    this.form.patchValue(this.model);

    this.isManualAddress = !!model;

    this.fields = [
      ...(this.hideAgencyNumberField
        ? []
        : [{
          key: 'agencyNumber',
          type: FormFieldType.Number,
          props: {
            label: 'L&G Agency Number (Optional)',
            text: 'L&G Agency Number (Optional)',
            ...defaultProps,
            maxLength: 7,
            focus: () => this.agencyNumberFocused = true,
            blur: () => this.agencyNumberFocused = false,
          },
          validators: {
            validation: [
              FormsValidators.integer,
            ],
          },
          asyncValidators: [
            {
              expression: this.lookupFirmName.bind(this),
              message: 'Enter valid L&G Agency number',
            },
          ],
        }]
      ),
      {
        key: 'firmName',
        type: FormFieldType.Input,
        props: {
          label: 'Firm Name',
          text: 'Firm Name',
          ...defaultProps,
          required: true,
        },
      },
      {
        key: 'postcode',
        type: FormFieldType.PostCode,
        props: {
          label: 'Postcode',
          text: 'Postcode',
          submitText: 'Find Address',
          linkText: 'Enter address manually',
          ...defaultProps,
          required: true,
          setAddressType: this.toggleAddressSearchType.bind(this),
          clickHandler: this.searchAddress.bind(this),
        },
        expressions: {
          hide: () => this.isManualAddress,
        },
        validators: {
          validation: [
            FormsValidators.postcode,
          ],
        },
      },
      {
        key: 'addressLineLookUp',
        type: FormFieldType.Select,
        props: {
          label: 'Address',
          text: 'Address',
          placeholder: 'Please Select...',
          options: [],
          ...defaultProps,
          required: true,
          change: () => this.populateAddressFields(),
        },
        expressions: {
          hide: () => !this.addressSearchSuccessful || this.isManualAddress,
        },
      },
      {
        key: 'addressLine1',
        type: FormFieldType.Input,
        props: {
          label: 'Address Line 1',
          text: 'Address Line 1',
          ...defaultProps,
          required: true,
        },
        expressions: {
          hide: () => !this.isManualAddress,
        },
      },
      {
        key: 'addressLine2',
        type: FormFieldType.Input,
        props: {
          label: 'Address Line 2',
          text: 'Address Line 2',
          ...defaultProps,
        },
        expressions: {
          hide: () => !this.isManualAddress,
        },
      },
      {
        key: 'town',
        type: FormFieldType.Input,
        props: {
          label: 'Town',
          text: 'Town',
          ...defaultProps,
          required: true,
        },
        expressions: {
          hide: () => !this.isManualAddress,
        },
      },
      {
        key: 'county',
        type: FormFieldType.Input,
        props: {
          label: 'County',
          text: 'County',
          ...defaultProps,
          required: false,
        },
        expressions: {
          hide: () => !this.isManualAddress,
        },
      },
      {
        key: 'postcode',
        type: FormFieldType.Input,
        props: {
          label: 'Postcode',
          text: 'Postcode',
          linkText: 'Search for address',
          setAddressType: this.toggleAddressSearchType.bind(this),
          ...defaultProps,
          required: true,
        },
        expressions: {
          hide: () => !this.isManualAddress,
        },
      },
      {
        key: 'contact',
        type: FormFieldType.Input,
        props: {
          label: 'Contact (Broker Name)',
          text: 'Contact (Broker Name)',
          ...defaultProps,
          required: true,
        },
      },
      {
        key: 'telephone',
        type: FormFieldType.Input,
        props: {
          label: 'Telephone Number',
          text: 'Telephone Number',
          inputType: 'tel',
          maxLength: 15,
          ...defaultProps,
          required: true,
        },
        validators: {
          telephone: {
            expression: (fc: UntypedFormControl) => !FormsValidators.telephone(fc),
            message: 'Enter valid telephone number',
          },
        },
      },
      {
        key: 'fcaNumber',
        type: FormFieldType.Number,
        props: {
          label: 'FCA/FRN Number',
          text: 'FCA/FRN Number',
          ...defaultProps,
          disabled: true,
        },
      },
    ];

    this.hasInitialised = true;
  }

  private get hideAgencyNumberField(): boolean {
    // Hide Agency Number field from SimplyBiz SSO users
    return this.authService.isSsoUser && this.authService.clientId === clientIds.simplyBiz;
  }

  private toggleAddressSearchType(): void {
    this.isManualAddress = !this.isManualAddress;
  }

  private populateAddressFields() : void {
    const fullAddress = this.form.value.addressLineLookUp;
    const newAddress = this.addresses.find(address => address.fullAddress === fullAddress);
    const [firstLine, ...secondLine] = newAddress?.addressLine1.split(',') ?? [];

    this.model.addressLine1 = firstLine.trim();
    this.model.addressLine2 = secondLine.join(',').trim();
    this.model.town = newAddress?.town ?? '';

    this.form.patchValue(this.model);
  }

  public searchAddress(): void {
    if (!FormsValidators.postcodeRegex.test(this.form.value.postcode)) {
      return;
    }
    this.postcodeService.findAddress(this.form.value.postcode).subscribe(
      (addresses: Address[]) => {

        this.addressSearchSuccessful = addresses.length > 0;
        const addressFieldConfig: FormlyFieldConfig | undefined = this.fields.find(
          x => x.key === 'addressLineLookUp' && x.type === FormFieldType.Select,
        );

        if (addressFieldConfig?.props && this.addressSearchSuccessful) {
          addressFieldConfig.props.options = addresses.map(a => (
            { label: a.fullAddress, value: a.fullAddress }
          ));
          this.addresses = addresses;
        }
        this.form.get('postcode')?.updateValueAndValidity();
      },
    );
  }

  private async lookupFirmName(control: AbstractControl): Promise<boolean> {
    if (control.value === null || control.value === undefined) {
      return true;
    }

    const { valid, result: firmName } = await firstValueFrom(
      this.agencyNumberService.lookupAgencyNumber(control.value),
    );
    // Since validator runs whenever the value changes (including when the model is set), we only want it to update the
    // firm name field when the user actually triggered the change.
    if (valid && this.agencyNumberFocused) {
      this.form.patchValue({ firmName });
    }
    return valid;
  }

  public markAllAsTouched() {
    this.form.markAllAsTouched();
  }

  public saveFirmDetails(showToast = true): Promise<void> {
    return this.form.valid
      ? firstValueFrom(this.userDetailsService.saveFirmDetails(this.model)
        .pipe(tap(() => {
          this.authService.setAgencyNumber(this.model.agencyNumber?.toString());
          if (showToast) {
            this.toastService.success('Successfully saved firm details');
          }
          this.save.emit(this.form.value);
        })))
      : Promise.reject();
  }
}
