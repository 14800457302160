export const keys = {
  token: 'token',
  isAdmin: 'isAdmin',
  flowType: 'flowType',
  termsDisclaimer: 'termsDisclaimer',
  marketingPreferencesSaved: 'marketingPreferencesSaved',
  email: 'email',
  crmSystemInformationSaved: 'crmSystemInformationSaved',
  agencyNumber: 'agencyNumber',
  lenderNameId: 'lenderNameId',
};
