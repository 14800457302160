import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  templateUrl: 'select.component.html',
})
export class SelectFieldTypeComponent extends FieldType {
  public static defaultOptions: FormlyFieldConfig = {
    props: {
      placeholder: 'Please select...',
    },
  };
}
