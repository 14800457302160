import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IRio, IUiAffordabilityModel } from 'apps/clubhub/src/app/ignite/models/affordability';

@Injectable({
  providedIn: 'root',
})
export class RioService {
  public rioForm = new UntypedFormGroup({});

  public constructor(
    private fb: UntypedFormBuilder,
  ) { }

  public get isEmptyForm(): boolean {
    const formValue = this.rioForm?.value;
    if (!formValue) {
      return true;
    }

    const formFields = Object.keys(formValue);
    return formFields.length === 0;
  }

  /**
   * Clears the rio form, and adds the given number of applicant control groups, optionally populating the applicants
   * with data from the given model.
   */
  public reset(numberOfApplicants: number | undefined, model: IUiAffordabilityModel | null = null): void {
    this.rioForm = this.fb.group({
      applicants: this.fb.array([]),
    });
    if (numberOfApplicants) {
      for (let i = 0; i < numberOfApplicants; i++) {
        this.applicants.push(this.rioApplicantForm(model?.rioApplicants?.[i]));
      }
    }
  }

  /**
   * Adds or removes applicants to ensure that the given number of applicants are present on the form.
   * Does not clear existing values on existing applicants if present.
   */
  public ensureApplicantCount(numberOfApplicants: number) {
    if (!this.applicants) {
      this.rioForm = this.fb.group({
        applicants: this.fb.array([]),
      });
    }
    while (this.applicants.length < numberOfApplicants) {
      this.applicants.push(this.rioApplicantForm());
    }
    while (this.applicants.length > numberOfApplicants) {
      this.applicants.removeAt(this.applicants.length - 1, { emitEvent: false });
    }
  }

  public initializeRioForm(): void {
    this.rioForm = new UntypedFormGroup({});
  }

  public get applicants(): UntypedFormArray {
    return this.rioForm.get('applicants') as UntypedFormArray;
  }

  private rioApplicantForm(rioApplicant: IRio | null = null): UntypedFormGroup {
    const rioApplicantFormGroup = this.fb.group({
      currentPensionIncome: this.fb.group({
        annualNonEscalatingAnnuity: this.fb.array([]),
        annualEscalatingAnnuity: this.fb.array([]),
        drawdownPot: this.fb.array([]),
        definedBenefitFinalSalaryPension: this.fb.array([]),
        annualStatePension: new UntypedFormControl(''),
        warWidowsPension: new UntypedFormControl(''),
        warDisablementPension: new UntypedFormControl(''),
      }),
      investmentIncome: this.fb.group({
        guaranteedInvestmentIncome: new UntypedFormControl(''),
        nonGuaranteedInvestmentIncome: new UntypedFormControl(''),
      }),
      existingLoans: this.fb.group({
        monthlySecuredLoans: new UntypedFormControl(''),
        monthlyUnsecuredLoans: new UntypedFormControl(''),
      }),
      futurePensionIncome: this.fb.group({
        forecastDefinedBenefitFinalSalaryPension: this.fb.array([]),
        definedContribution: this.fb.array([]),
        personalPension: this.fb.array([]),
        expectedAnnualStatePension: new UntypedFormControl(''),
      }),
    });

    if (rioApplicant?.currentPensionIncome?.annualNonEscalatingAnnuity) {
      rioApplicant?.currentPensionIncome?.annualNonEscalatingAnnuity.forEach(x => {
        ((rioApplicantFormGroup.controls.currentPensionIncome as UntypedFormGroup)
          .controls.annualNonEscalatingAnnuity as UntypedFormArray)
          .push(this.fb.group({ value: new UntypedFormControl(x.value),
            percentage: new UntypedFormControl(x.percentage) }));
      });
    } else {
      ((rioApplicantFormGroup.controls.currentPensionIncome as UntypedFormGroup)
        .controls.annualNonEscalatingAnnuity as UntypedFormArray)
        .push(this.fb.group({ value: new UntypedFormControl(''),
          percentage: new UntypedFormControl('') }));
    }

    if (rioApplicant?.currentPensionIncome?.annualEscalatingAnnuity) {
      rioApplicant?.currentPensionIncome?.annualEscalatingAnnuity.forEach(x => {
        ((rioApplicantFormGroup.controls.currentPensionIncome as UntypedFormGroup)
          .controls.annualEscalatingAnnuity as UntypedFormArray)
          .push(this.fb.group({ value: new UntypedFormControl(x.value),
            percentage: new UntypedFormControl(x.percentage) }));
      });
    } else {
      ((rioApplicantFormGroup.controls.currentPensionIncome as UntypedFormGroup)
        .controls.annualEscalatingAnnuity as UntypedFormArray)
        .push(this.fb.group({ value: new UntypedFormControl(''),
          percentage: new UntypedFormControl('') }));
    }

    if (rioApplicant?.currentPensionIncome?.drawdownPot) {
      rioApplicant?.currentPensionIncome?.drawdownPot.forEach(x => {
        ((rioApplicantFormGroup.controls.currentPensionIncome as UntypedFormGroup)
          .controls.drawdownPot as UntypedFormArray)
          .push(this.fb.group({ value: new UntypedFormControl(x.value),
            percentage: new UntypedFormControl(x.percentage) }));
      });
    } else {
      ((rioApplicantFormGroup.controls.currentPensionIncome as UntypedFormGroup)
        .controls.drawdownPot as UntypedFormArray)
        .push(this.fb.group({ value: new UntypedFormControl(''),
          percentage: new UntypedFormControl('') }));
    }

    if (rioApplicant?.currentPensionIncome?.definedBenefitFinalSalaryPension) {
      rioApplicant?.currentPensionIncome?.definedBenefitFinalSalaryPension.forEach(x => {
        ((rioApplicantFormGroup.controls.currentPensionIncome as UntypedFormGroup)
          .controls.definedBenefitFinalSalaryPension as UntypedFormArray)
          .push(this.fb.group({ value: new UntypedFormControl(x.value),
            percentage: new UntypedFormControl(x.percentage) }));
      });
    } else {
      ((rioApplicantFormGroup.controls.currentPensionIncome as UntypedFormGroup)
        .controls.definedBenefitFinalSalaryPension as UntypedFormArray)
        .push(this.fb.group({ value: new UntypedFormControl(''),
          percentage: new UntypedFormControl('') }));
    }

    if (rioApplicant?.currentPensionIncome?.annualStatePension) {
      ((rioApplicantFormGroup.controls.currentPensionIncome as UntypedFormGroup)
        .controls.annualStatePension as UntypedFormControl) =
          new UntypedFormControl(rioApplicant.currentPensionIncome.annualStatePension);
    }

    if (rioApplicant?.currentPensionIncome?.warWidowsPension) {
      ((rioApplicantFormGroup.controls.currentPensionIncome as UntypedFormGroup)
        .controls.warWidowsPension as UntypedFormControl) =
          new UntypedFormControl(rioApplicant.currentPensionIncome.warWidowsPension);
    }

    if (rioApplicant?.currentPensionIncome?.warDisablementPension) {
      ((rioApplicantFormGroup.controls.currentPensionIncome as UntypedFormGroup)
        .controls.warDisablementPension as UntypedFormControl) =
          new UntypedFormControl(rioApplicant.currentPensionIncome.warDisablementPension);
    }

    if (rioApplicant?.investmentIncome?.guaranteedInvestmentIncome) {
      ((rioApplicantFormGroup.controls.investmentIncome as UntypedFormGroup)
        .controls.guaranteedInvestmentIncome as UntypedFormControl) =
          new UntypedFormControl(rioApplicant.investmentIncome.guaranteedInvestmentIncome);
    }

    if (rioApplicant?.investmentIncome?.nonGuaranteedInvestmentIncome) {
      ((rioApplicantFormGroup.controls.currentPensionIncome as UntypedFormGroup)
        .controls.nonGuaranteedInvestmentIncome as UntypedFormControl) =
          new UntypedFormControl(rioApplicant.investmentIncome.nonGuaranteedInvestmentIncome);
    }

    if (rioApplicant?.existingLoans?.monthlySecuredLoans) {
      ((rioApplicantFormGroup.controls.existingLoans as UntypedFormGroup)
        .controls.monthlySecuredLoans as UntypedFormControl) =
          new UntypedFormControl(rioApplicant.existingLoans.monthlySecuredLoans);
    }

    if (rioApplicant?.existingLoans?.monthlyUnsecuredLoans) {
      ((rioApplicantFormGroup.controls.existingLoans as UntypedFormGroup)
        .controls.monthlyUnsecuredLoans as UntypedFormControl) =
          new UntypedFormControl(rioApplicant.existingLoans.monthlyUnsecuredLoans);
    }

    if (rioApplicant?.futurePensionIncome?.forecastDefinedBenefitFinalSalaryPension) {
      rioApplicant?.futurePensionIncome?.forecastDefinedBenefitFinalSalaryPension.forEach(x => {
        ((rioApplicantFormGroup.controls.futurePensionIncome as UntypedFormGroup)
          .controls.forecastDefinedBenefitFinalSalaryPension as UntypedFormArray)
          .push(this.fb.group({ value: new UntypedFormControl(x.value),
            percentage: new UntypedFormControl(x.percentage) }));
      });
    } else {
      ((rioApplicantFormGroup.controls.futurePensionIncome as UntypedFormGroup)
        .controls.forecastDefinedBenefitFinalSalaryPension as UntypedFormArray)
        .push(this.fb.group({ value: new UntypedFormControl(''),
          percentage: new UntypedFormControl('') }));
    }

    if (rioApplicant?.futurePensionIncome?.definedContribution) {
      rioApplicant?.futurePensionIncome?.definedContribution.forEach(x => {
        ((rioApplicantFormGroup.controls.futurePensionIncome as UntypedFormGroup)
          .controls.definedContribution as UntypedFormArray)
          .push(this.fb.group({ value: new UntypedFormControl(x.value),
            percentage: new UntypedFormControl(x.percentage) }));
      });
    } else {
      ((rioApplicantFormGroup.controls.futurePensionIncome as UntypedFormGroup)
        .controls.definedContribution as UntypedFormArray)
        .push(this.fb.group({ value: new UntypedFormControl(''),
          percentage: new UntypedFormControl('') }));
    }

    if (rioApplicant?.futurePensionIncome?.personalPension) {
      rioApplicant?.futurePensionIncome?.personalPension.forEach(x => {
        ((rioApplicantFormGroup.controls.futurePensionIncome as UntypedFormGroup)
          .controls.personalPension as UntypedFormArray)
          .push(this.fb.group({ value: new UntypedFormControl(x.value),
            percentage: new UntypedFormControl(x.percentage) }));
      });
    } else {
      ((rioApplicantFormGroup.controls.futurePensionIncome as UntypedFormGroup)
        .controls.personalPension as UntypedFormArray)
        .push(this.fb.group({ value: new UntypedFormControl(''),
          percentage: new UntypedFormControl('') }));
    }

    if (rioApplicant?.futurePensionIncome?.expectedAnnualStatePension) {
      ((rioApplicantFormGroup.controls.futurePensionIncome as UntypedFormGroup)
        .controls.expectedAnnualStatePension as UntypedFormControl) =
          new UntypedFormControl(rioApplicant.futurePensionIncome.expectedAnnualStatePension);
    }

    return rioApplicantFormGroup;
  }
}
