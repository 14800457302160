import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { NavItem, NavPosition, PageNav } from '../../models/nav';
import { LendingTypeService, PageNavMessageService } from '../../services';

@Component({
  selector: 'lib-page-nav',
  styleUrls: ['page-nav.component.scss'],
  templateUrl: 'page-nav.component.html',
})
export class PageNavComponent {
  @Input() public title: string;
  @Input() public items: PageNav;
  @Input() public showTitle = true;
  @Input() public showLendingType = true;
  @Input() public lenderName?: string;
  @Input() public lendingTypeName: string;
  @Input() public lenderLogoUrl: string;

  public constructor(
    public lendingTypeService: LendingTypeService,
    private router: Router,
    private pageNavMessageService: PageNavMessageService,
  ) {}

  public get lendingType() {
    return this.lendingTypeName || this.lendingTypeService.lendingType?.name;
  }

  public trackByFn(index: number) {
    return index;
  }

  public getActiveClass(item: NavItem) {
    return item.link
    && this.router.url.substring(this.router.url.lastIndexOf(`/${item.link}`)) === `/${item.link}`
      ? 'active'
      : '';
  }

  public get leftNav() {
    return [...(this.items?.leftNav ?? []), ...this.pageNavMessageService.navMessagesByPosition(NavPosition.Left)];
  }

  public get rightNav() {
    return [...(this.items?.rightNav ?? []), ...this.pageNavMessageService.navMessagesByPosition(NavPosition.Right)];
  }
}
