import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { FormsValidators } from '@msslib/components';
import { NgbCalendar, NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import isBefore from 'date-fns/isBefore';
import { v4 as newGuid } from 'uuid';

export type ScheduleFormType = FormGroup<{
  isScheduled: FormControl<boolean>;
  date: FormControl<Date | null>;
  time: FormControl<NgbTimeStruct | null>;
}>;

@Component({
  selector: 'lib-event-schedule',
  templateUrl: 'event-schedule.component.html',
})
export class EventScheduleComponent {
  @Input() public showSetLiveNowOption = true;
  @Input() public alignItems = 'center';
  @Input() public scheduleForm: ScheduleFormType;

  public uniqueId = newGuid();

  public scheduleMinDate: NgbDateStruct;

  public constructor(ngbCalendar: NgbCalendar) {
    this.scheduleMinDate = ngbCalendar.getToday();
  }

  public get showDateTimeFields(): boolean {
    return !this.showSetLiveNowOption || !!this.scheduleForm.value.isScheduled;
  }

  public isValid(controlName: string): boolean {
    const { touched, valid } = this.scheduleForm.controls[controlName];
    return touched && valid && !this.scheduleForm.errors?.timeTooEarly;
  }

  public isInvalid(controlName: string): boolean {
    const { touched, invalid } = this.scheduleForm.controls[controlName];
    return (touched && invalid) || !!this.scheduleForm.errors?.timeTooEarly;
  }

  public controlErrors(controlName: string): ValidationErrors | null {
    return this.scheduleForm.controls[controlName].errors;
  }

  public static buildScheduleForm(): ScheduleFormType {
    const formBuilder = new FormBuilder();

    const requiredWhenScheduledValidator = FormsValidators.conditional(
      ctrl => (ctrl.parent as ScheduleFormType)?.controls.isScheduled.value,
      Validators.required,
    );

    // Validator to check that the scheduled date and time is after the current date/time
    const isLaterTodayValidator = FormsValidators.conditional(
      (group: ScheduleFormType) => group.controls.isScheduled.value,
      (group: ScheduleFormType) => {
        const selectedDateTime = EventScheduleComponent.getScheduleFormDate(group);
        if (!selectedDateTime) {
          return null;
        }
        const now = new Date();
        return isBefore(selectedDateTime, now) ? { timeTooEarly: true } : null;
      },
    );

    const form = formBuilder.group({
      isScheduled: formBuilder.nonNullable.control<boolean>(false),
      date: formBuilder.control<Date | null>(null, requiredWhenScheduledValidator),
      time: formBuilder.control<NgbTimeStruct | null>(null, requiredWhenScheduledValidator),
    }, {
      validators: [isLaterTodayValidator],
    });
    form.controls.isScheduled.valueChanges.subscribe(() => {
      form.controls.date.updateValueAndValidity();
      form.controls.time.updateValueAndValidity();
    });
    return form;
  }

  public static getScheduleFormDate(form: ScheduleFormType): Date | null {
    const { isScheduled, date, time } = form.value;
    return isScheduled && date && time
      ? new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.hour, time.minute)
      : null;
  }

  public static resetScheduleForm(form: ScheduleFormType, date: Date | undefined = undefined): void {
    form.reset({
      isScheduled: !!date,
      date: date ? new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        12, // Give it a non-zero hour, else it sometimes shows previous day (I think due to UTC/British Summer Time)
      ) : null,
      time: date ? {
        hour: date.getHours(),
        minute: date.getMinutes(),
      } as NgbTimeStruct : null,
    });
    form.markAsUntouched({ onlySelf: false });
    form.updateValueAndValidity({ onlySelf: false });
  }
}
