<div class="switch">
  <input
    id="switch-{{id}}"
    class="form-check-input"
    type="checkbox"
    [disabled]="disabled"
    [checked]="checked"
    (change)="onChange($event)"
  >
  <label class="form-check-label" for="switch-{{id}}">
    <div class="switch-slider"></div>
    <ng-content></ng-content>
  </label>
</div>

<ng-template #confirmSwitchRef>
  <lib-confirm-switch-modal
    (confirmSwitch)="confirmChange($event)"
  >
  </lib-confirm-switch-modal>
</ng-template>