import { Injectable } from '@angular/core';
import { ClubHubDataService } from './clubhub-data.service';
import { FloorRateViewModel } from '@msslib/models/floor-rate';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FloorRatesService {
  public constructor(
    private clubHubDataService: ClubHubDataService,
  ) {}

  public getFloorRatesByLenderId(lenderId: number): Observable<FloorRateViewModel> {
    return this.clubHubDataService.get(`FloorRates/lender/${lenderId}`);
  }

  public getFloorRatesByLenderNameId(lenderNameId: number): Observable<FloorRateViewModel[]> {
    return this.clubHubDataService.get(`FloorRates/lendername/${lenderNameId}`);
  }

  public updateFloorRates(floorRatesList: FloorRateViewModel[]): Observable<void> {
    return this.clubHubDataService.post('FloorRates', floorRatesList);
  }
}
