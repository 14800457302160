import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ModalService } from '@msslib/services/modal.service';
import { v4 } from 'uuid';

@Component({
  selector: 'lib-switch',
  styleUrls: ['switch.component.scss'],
  templateUrl: 'switch.component.html',
})
export class SwitchComponent implements OnInit {
  @ViewChild('confirmSwitchRef', { static: true }) public confirmSwitchRef: TemplateRef<unknown>;
  public id: string;

  @Input() public checked: boolean;
  @Input() public showWarning = true;
  @Input() public disabled = false;
  @Output() public checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public constructor(
    private modalService: ModalService,
  ) { }

  public ngOnInit(): void {
    this.id = v4();
  }

  public onChange(e: any) {
    if (e.target.checked) {
      this.checked = e.target.checked;
      this.checkedChange.emit(this.checked);
    } else if (this.showWarning) {
      e.target.checked = true;
      this.modalService.open({
        title: 'Do you want to switch products off',
        template: this.confirmSwitchRef,
      }).then(() => null, () => null);
    } else {
      this.checked = e.target.checked;
      this.checkedChange.emit(this.checked);
    }
  }

  public confirmChange(confirmed: boolean) {
    if (confirmed) {
      this.checked = false;
      this.checkedChange.emit(this.checked);
    }
  }
}
