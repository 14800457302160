<div [class.mt-4]="!props.collapseMargin">
  <div class="row">
    <label class="col fw-bold d-block">{{ props.label }}</label>
    <i *ngIf="props.tooltip" class="col-1 far fa-question-circle my-auto" (click)="openTooltip()"></i>
  </div>
  <div class="mt-1">
    <ng-container #fieldComponent></ng-container>
  </div>

  <div
    *ngIf="showError && !props.suppressErrors && showGroupField"
    class="d-flex gap-3 align-items-center mt-2 alert alert-danger is-invalid"
  >
    <i class="fas fa-times-circle"></i>
    <span>
      <formly-validation-message [field]="field"></formly-validation-message>
    </span>
  </div>
</div>
