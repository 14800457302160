import { BtlType, MortgageType, ProductType, PurchaserType, RepaymentMethod } from 'apps/shared/src/models';
import { IUiProductsSearchModel } from '../../models/products';

export const mockProductSearchRequest: IUiProductsSearchModel = {
  mortgageRequirements: {
    mortgageType: MortgageType.Purchase,
    productTypeExtended: ProductType.Standard,
    remortgageType: undefined,
    purchaserType: PurchaserType.FirstTimeBuyer,
    propertyValue: 100000,
    loanAmount: 50000,
    helpToBuy: false,
    mortgageLender: {
      value: 'LenderX',
      label: 'LenderX',
    },
    productTransferInitialDate: {
      day: 11,
      month: 12,
      year: 2022,
    },
    repaymentMethod: {
      value: RepaymentMethod.CapitalAndInterest,
      showInterestOnlyCriteria: false,
      showInterestOnlyPartAndPartCriteria: false,
    },
    mortgageTerm: {
      years: 20,
      months: 0,
    },
    btlType: BtlType.Standard,
    limitedCompanyPurchase: false,
    portfolioLandlord: false,
  },
  productsSearchId: '',
  bridging: {
    regulated: true,
  },
};
