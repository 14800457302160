import { Component } from '@angular/core';

@Component({
  selector: 'app-user-custom-product-settings-page',
  templateUrl: './user-custom-product-settings-page.component.html',
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class UserCustomProductSettingsPageComponent {

}
