<nav class="navbar navbar-expand-lg">
  <div>
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" routerLink="/home">
          <i class="fas fa-arrow-left"></i>
          Back
        </a>
      </li>
      <li *ngIf="hasBrokerMi" class="nav-item">
        <a class="nav-link active" routerLink="/mi" routerLinkActive="tab-active">
          Broker Mi
        </a>
      </li>
      <li *ngIf="hasBrokerIntegrationMi" class="nav-item">
        <a class="nav-link active" routerLink="/mi/integration" routerLinkActive="tab-active">
          Broker Integration Mi
        </a>
      </li>
      <li *ngIf="hasBrokerNewKeyPartnerMI" class="nav-item">
        <a class="nav-link active" routerLink="/mi/key-partner" routerLinkActive="tab-active">
          Key Partner MI
        </a>
      </li>
      <li *ngIf="hasBrokerIgniteMI" class="nav-item">
        <a class="nav-link active" routerLink="/mi/broker-ignite" routerLinkActive="tab-active">
          Legal & General Ignite MI
        </a>
      </li>
      <li *ngIf="hasExclusiveMarketSummaryMI" class="nav-item">
        <a class="nav-link active" routerLink="/mi/executive-market-summary" routerLinkActive="tab-active">
          Executive Market Summary
        </a>
      </li>
    </ul>
  </div>
</nav>
