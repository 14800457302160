<form *ngIf="hasInitialised" [formGroup]="form" (ngSubmit)="saveFirmDetails()">
  <div class="container">
    <div class="row">
      <div class="col">
        <formly-form
          [model]="model"
          [form]="form"
          [fields]="fields"
        ></formly-form>
      </div>
    </div>
    <div class="row" *ngIf="showSave">
      <div class="col-md-5 col-md-offset-0 pt-3">
        <div class="d-grid">
          <button type="submit" class="btn btn-2022--secondary">Save Firm Details</button>
        </div>
      </div>
    </div>
  </div>
</form>