export enum KeyCodes {
  Tab = 9,
  Enter = 13,
  Shift = 16,
  Space = 32,
  LeftArrow = 37,
  UpArrow = 38,
  RightArrow = 39,
  DownArrow = 40,
  Insert = 45,
  Delete = 46,
  Zero = 48,
  Nine = 57,
  ZeroNumpad = 96,
  NineNumpad = 105,
  PeriodNumpad = 108,
  Period = 190,
}

export enum KeyCodeNames {
  Tab = 'Tab',
  Shift = 'Shift',
  Insert = 'Insert',
  Space = 'Space',
  Delete = 'Delete',
  Digit9 = 'Digit9',
  Numpad9 = 'Numpad9',
  Digit0 = 'Digit0',
  Numpad0 = 'Numpad0',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Period = 'Period',
  NumpadPeriod = 'NumpadPeriod',
  Enter = 'Enter',
  Escape = 'Escape',
}
