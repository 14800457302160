import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthorizeService } from '../authorize.service';
import { tap } from 'rxjs/operators';
import { applicationPaths, queryParameterNames } from '../../constants';

export const authorizeGuard: CanActivateFn =
  (
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) => {
    const authorizeService = inject(AuthorizeService);
    const router = inject(Router);

    return authorizeService.isAuthenticated()
      .pipe(tap(isAuthenticated => {
        if (!isAuthenticated) {
          router.navigate(applicationPaths.loginPathComponents, {
            queryParams: {
              [queryParameterNames.returnUrl]: state.url,
            },
          });
        }
      }));
  };
