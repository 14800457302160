import { IgniteResponse } from 'apps/clubhub/src/app/ignite/models/ignite-response';

export interface AffordabilityLenders {
  lenderName: string;
  lenderLogoUrl: string;
}
export class AffordabilityPollResponse {
  public pollJobID: number | null;
  public affordabilitySearchId: string | null;
  public quotes: IgniteResponse[];
  public errorMessage: string | null;
  public completed: boolean;

  /** Will only have a value on the initial request to the server, not subsequent poll requests. */
  public lenderCount: number;
  /** Will only have a value on the initial request to the server, not subsequent poll requests. */
  public lenders?: AffordabilityLenders[];
  public static response(lender: AffordabilityLenders) {
    return {
      affordabilitySearchId: null,
      source: null,
      lenderName: lender.lenderName,
      lenderLogoUrl: lender.lenderLogoUrl,
      maximumLoanAmount: null,
      maximumLoanAmounts: null,
      minimumRentalNeededAmounts: null,
      maximumAffordableLoanAmount: null,
      maximumValue: null,
      affordabilityResult: null,
      quoteNumber: null,
      error: {
        statusCode: '102',
        message: 'Not Completed',
      },
      httpError: null,
      httpErrorMessage: null,
      tooManyApplicants: null,
      limitToTwoIncomes: null,
      payrate: null,
      stressRate: null,
      expanded: false,
    };
  }
}
