<strong
  class="fees"
  [ngbTooltip]="feesTooltip"
  placement="left"
  tooltipClass="custom-tooltip fees-tooltip"
>
  {{ total | currency: 'GBP':'symbol':'1.2-2' }}{{isRefer ? '*' : ''}}
</strong>

<ng-template #feesTooltip>
  <ng-container *ngFor="let fee of product.fees | keyvalue">
    <div class="row">
      <div class="col-8 text-start">{{getFeeDisplayName(fee.key)}}{{product.isValuationFeeRefer && fee.key === '0' ? '*' : ''}}:</div>
      <div class="col-4 text-start">
        <ng-container *ngIf="fee.value === -1 && fee.key === '0'; else showFee">
          Refer to Lender
        </ng-container>
        <ng-template #showFee>
          {{ fee.value | currency: 'GBP':'symbol':'1.2-2' }}
          <span *ngIf="(product.isValuationFeeRefer || fee.value === -1) && fee.key === '0'">
            - Refer to Lender
          </span>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <div class="row" *ngIf="assumedLegalFee">
    <div class="col-8 text-start">Assumed Legal Fee:</div>
    <div class="col-4 text-start">{{ assumedLegalFee | currency: 'GBP':'symbol':'1.2-2' }}</div>
  </div>
  <ng-container *ngFor="let customFee of customFees">
    <div class="row">
      <div class="col-8 text-start">{{ customFee.feeName }}:</div>
      <div class="col-4 text-start">{{ getCustomFeeAmount(customFee) | currency: 'GBP':'symbol':'1.2-2' }}</div>
    </div>
  </ng-container>
  <div class="row">
    <div class="col-12">
      <hr />
    </div>
  </div>
  <div class="row">
    <div class="col-8 text-start"><strong>Total Fees:</strong></div>
    <div class="col-4 text-start">
      <strong>{{ total | currency: 'GBP':'symbol':'1.2-2' }}</strong>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <hr />
    </div>
  </div>
</ng-template>
