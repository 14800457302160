import {Injectable } from '@angular/core';

import { LenderNameApplicationToOfferDefinition, LenderNameCreditType } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ServiceLevelUpdatesService {
  public get serviceLabels() {
    return {
      canAcceptApplicantOnline: 'Accept application online',
      canAcceptOnlineDipAip: 'Accept online DIP/AIP',
      canUploadDocumentsOntoWebsite: 'Upload documents onto website/portal',
      canAcceptNonAdvisedReferrals: 'Accept Non-advised referrals',
      hasLiveChat: 'Live chat',
      hasManualUnderwriter: 'Manual underwriter',
      creditType: 'Credit score or credit search',
      applicationToOfferDefinition: 'Application to offer definition',
      applicationToOffer: 'Application to offer',
      initialCaseAssessment: 'Initial case assessment',
    };
  }

  public get creditScoreLabels() {
    return {
      [LenderNameCreditType.CreditScore]: 'Credit score',
      [LenderNameCreditType.CreditSearch]: 'Credit search',
    };
  }

  public get applicationToOfferDefinitionLabels() {
    return {
      [LenderNameApplicationToOfferDefinition.DayOfApplicationReceiptToDayOfferIssued]:
        'Day of application receipt to day offer issued',
      [LenderNameApplicationToOfferDefinition.DayAllRequiredDocumentationIsProvidedToDayOfferIssued]:
        'Day all required documentation is provided to day offer issued',
      [LenderNameApplicationToOfferDefinition.CaseDependent]:
        'Case dependent',
      [LenderNameApplicationToOfferDefinition.SeeLenderWebsite]:
        'See lender website',
    };
  }
}
