import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IDateInput } from '../../models';
import { MssFieldBaseFieldTypeComponent } from '../mss-field-base.component';

@Component({
  templateUrl: 'date.component.html',
  styleUrls: ['date.component.scss'],
})
export class DateFieldTypeComponent extends MssFieldBaseFieldTypeComponent implements OnInit {
  @ViewChild('wrapperDiv', { static: true }) public wrapperDiv: ElementRef<HTMLElement>;
  public dateModel: IDateInput = {} as IDateInput;

  public ngOnInit(): void {
    this.initialiseModel();
    this.form.valueChanges.subscribe(() => {
      this.initialiseModel();
    });
  }

  public get invalid(): boolean {
    return this.formControl.touched && !this.formControl.valid;
  }

  public updateModel(value: number, key: keyof IDateInput): void {
    this.dateModel[key] = value;
    this.patchValue();
  }

  public onBlur(fieldId: string): void {
    if (fieldId === 'year') {
      this.formControl.markAsTouched();
      this.formControl.markAsDirty();
      this.patchValue();
    } else {
      this.formControl.markAsUntouched();
      this.formControl.markAsPristine();
    }
  }

  public onKeyup(event: KeyboardEvent, nextField: HTMLInputElement): void {
    const { key, target, shiftKey } = event;
    const { value, maxLength } = target as HTMLInputElement;

    if ((nextField && key === 'Tab' && shiftKey) || key === 'Shift') {
      return;
    }

    if (nextField && value.length >= maxLength) {
      nextField.focus();
    }
    if (!nextField && value.length >= maxLength) {
      this.formControl.markAsTouched();
      this.formControl.markAsDirty();
    }
  }

  public onKeydown(event: KeyboardEvent, currentFieldId: keyof IDateInput): void {
    const { key, target, shiftKey } = event;
    const { value, maxLength } = target as HTMLInputElement;

    if (key === 'Tab' && !shiftKey && value.toString().length > 0) {
      this.dateModel[currentFieldId] = value.padStart(maxLength, '0') as unknown as number;
    }
  }

  public patchValue(): void {
    this.formControl.setValue(this.dateModel);
    this.form.patchValue([{ [this.field.key as string]: { ...this.dateModel } }]);
  }

  private initialiseModel(): void {
    if (this.model) {
      this.dateModel = this.model[this.field.key as string] ?? {};
    }
  }
}
