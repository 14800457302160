import { CustomFeeDisplayModel } from '@msslib/models/custom-product-calculations';
import { BridgingProductFeeTypeModal } from 'apps/shared/src/models';

export enum ProductFeeType {
  Valuation = 0,
  Booking = 1,
  Broker = 2,
  Arrangement = 3,
  Chaps = 4,
  Conveyancing = 6,
  DeedsRelease = 7,
  Disbursement = 8,
  Processing = 9,
  MortgageExit = 11,
  HigherLending = 13,
  RedemptionAdministration = 14,
  Packager = 15,
  Custom = 16,
  FurtherAdvance = 17,
}

export interface FeeTypesObj {
  name: string;
  key: ProductFeeType;
}

export const feesDisplayNames = {
  [ProductFeeType.Arrangement]: 'Arrangement fee',
  [ProductFeeType.Booking]: 'Booking fee',
  [ProductFeeType.Broker]: 'Broker fee',
  [ProductFeeType.Chaps]: 'Chaps fee',
  [ProductFeeType.Conveyancing]: 'Conveyancing fee',
  [ProductFeeType.DeedsRelease]: 'Deeds release fee',
  [ProductFeeType.Disbursement]: 'Disbursement fee',
  [ProductFeeType.HigherLending]: 'Higher lending fee',
  [ProductFeeType.MortgageExit]: 'Mortgage exit fee',
  [ProductFeeType.Packager]: 'Packager fee',
  [ProductFeeType.Valuation]: 'Valuation fee',
  [ProductFeeType.Processing]: 'Processing fee',
  [ProductFeeType.RedemptionAdministration]: 'Redemption administration fee',
  [ProductFeeType.FurtherAdvance]: 'Further Advance Fee',
};

export const fesTableHeaders = {
  [ProductFeeType.Arrangement]: 'Arrangement fee',
  [ProductFeeType.Booking]: 'Booking fee',
  [ProductFeeType.Broker]: 'Broker fee',
  [ProductFeeType.Chaps]: 'Chaps fee',
  [ProductFeeType.Conveyancing]: 'Conveyancing fee',
  [ProductFeeType.DeedsRelease]: 'Deeds release fee',
  [ProductFeeType.Disbursement]: 'Disbursement fee',
  [ProductFeeType.HigherLending]: 'Higher lending fee',
  [ProductFeeType.MortgageExit]: 'Mortgage exit fee',
  [ProductFeeType.Packager]: 'Packager fee',
  [ProductFeeType.Valuation]: 'Valuation fee',
  [ProductFeeType.Processing]: 'Processing fee',
  [ProductFeeType.RedemptionAdministration]: 'Redemption administration fee',
  'earlyRepaymentChargesErcApplied': 'ERC Applies',
  'freeLegal': 'Legal Fees Free',
  'valuationFeeConditions': 'Valuation Fee Conditions',
};

const bridgingFeesDisplayNames = {
  [BridgingProductFeeTypeModal.Admin]: 'Administration fee',
  [BridgingProductFeeTypeModal.Arrangement]: 'Arrangement fee',
  [BridgingProductFeeTypeModal.Avm]: 'AVM fee',
  [BridgingProductFeeTypeModal.Chaps]: 'Chaps fee',
  [BridgingProductFeeTypeModal.Legal]: 'Legal fee',
  [BridgingProductFeeTypeModal.Valuation]: 'Valuation fee',
};

export const feeDisplayModel:CustomFeeDisplayModel[] = [
  { type: ProductFeeType.Arrangement, customName:'', displayName: 'Arrangement fee', isCustom: false },
  { type: ProductFeeType.Booking, customName:'', displayName: 'Booking fee', isCustom: false },
  { type: ProductFeeType.Broker, customName:'', displayName: 'Broker fee', isCustom: false },
  { type: ProductFeeType.Chaps, customName:'', displayName: 'Chaps fee', isCustom: false },
  { type: ProductFeeType.Conveyancing, customName:'', displayName: 'Conveyancing fee', isCustom: false },
  { type: ProductFeeType.DeedsRelease, customName:'', displayName: 'Deeds release fee', isCustom: false },
  { type: ProductFeeType.Disbursement, customName:'', displayName: 'Disbursement fee', isCustom: false },
  { type: ProductFeeType.HigherLending, customName:'', displayName: 'Higher lending fee', isCustom: false },
  { type: ProductFeeType.MortgageExit, customName:'', displayName: 'Mortgage exit fee', isCustom: false },
  { type: ProductFeeType.Packager, customName:'', displayName: 'Packager fee', isCustom: false },
  { type: ProductFeeType.Processing, customName:'', displayName: 'Processing fee', isCustom: false },
  { type: ProductFeeType.FurtherAdvance, customName:'', displayName: 'Further Advance fee', isCustom: false },
  { type: ProductFeeType.RedemptionAdministration, customName:'',
    displayName: 'Redemption administration fee', isCustom: false },
  { type: ProductFeeType.Valuation, customName:'', displayName: 'Valuation fee', isCustom: false },
  { type: ProductFeeType.Custom, customName:'', displayName: 'Custom fee', isCustom: true },
];

export function getFormattedFeeTypes(skipEntry?: ProductFeeType[]): FeeTypesObj[] {
  return feeDisplayModel.filter(fee =>!skipEntry?.includes(fee.type)).map(fee => {
    return {
      name: fee.displayName,
      key: fee.type,
    } as FeeTypesObj;
  });
}

export function getFeeDisplayNameNumeric(fee: ProductFeeType): string {
  return feesDisplayNames[fee] ?? '';
}

export function getBridgingFeeDisplayName(fee: string): string {
  return bridgingFeesDisplayNames[fee] ?? fee;
}

export enum FeeCalculationScenario {
  Exclude = 0,
  AddToLoan = 1,
  OneOffCost = 2,
}

export const customSettingsExcludedFees = [ProductFeeType.Broker, ProductFeeType.Custom];
