<div class="modal-header">
  <h3 class="modal-title">
    {{ options.title }}
  </h3>
  <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="cancel()">
  </button>
</div>

<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-body">
    <formly-form [fields]="fields" [options]="formOptions" [form]="form"></formly-form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
      Cancel
    </button>
    &nbsp;
    <button type="submit" class="btn btn-2022--secondary">
      Add
    </button>
  </div>
</form>
