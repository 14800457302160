import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ModalService } from '../modal.service';
import { DisclaimerModalComponent } from '@msslib/components';
import { DisclaimerResponse, DisclaimerService } from '../disclaimer.service';
import { ClientApps } from '@msslib/models';
import { AuthorizeService } from '../authorize.service';

export const disclaimerGuard: CanActivateFn =
  async (
    _next: ActivatedRouteSnapshot,
  ) => {
    const modalService = inject(ModalService);
    const disclaimerService = inject(DisclaimerService);
    const authService = inject(AuthorizeService);

    const isHistoric = _next.queryParams.historicResult ||
      _next.queryParams.key;

    if (isHistoric) {
      return true;
    }

    let hasReadDisclaimer:DisclaimerResponse = {disclaimerRead: true};
    if (authService.isLoggedIn) {
      hasReadDisclaimer =
        await firstValueFrom(disclaimerService.hasUserDisclaimerBeenRead(ClientApps[_next.data.client]));
    }

    if (hasReadDisclaimer.disclaimerRead) {
      return true;
    }

    return modalService
      .open({
        title: 'Disclaimer',
        component: DisclaimerModalComponent,
        size: 'xl',
        sticky: true,
        client: _next.data.client,
      }).then(() => {
        return true;
      }, () => {
        return false;
      });
  };
