export const roles = {
  // ClubHub
  betaFeaturesClubHub: 'BetaFeaturesClubHub',
  broker: 'Broker',
  brokerIntegrationMi: 'BrokerIntegrationMi',
  brokerMi: 'BrokerMi',
  brokerNewKeyPartnerMI: 'BrokerNewKeyPartnerMI',
  claims: 'Claims',
  claimsAdmin: 'ClaimsAdmin',
  clubHub: 'ClubHub',
  procFeeHistory: 'ProcFeeHistory',
  procFeePreview: 'ProcFeePreview',
  ignite: 'Ignite',
  igniteTesting: 'IgniteTesting',
  staff: 'Staff',
  clubHubGenerateEsisResidential: 'ClubHubGenerateEsisResidential',
  clubHubGenerateEsisBuyToLet: 'ClubHubGenerateEsisBuyToLet',
  brokerIgniteMI: 'BrokerMI',
  executiveMarketSummaryMI: 'ExecutiveMarketSummaryMI',
  brokerPaidMI: 'BrokerPaidMI',
  viewConsumerDuty: 'ViewConsumerDuty',
  bridgingClubHub: 'BridgingClubHub',
  ssoTesting: 'SsoTesting',

  // LenderHub
  packager: 'Packager',
  affordabilityManager: 'AffordabilityManager',
  betaFeaturesLenderHub: 'BetaFeaturesLenderHub',
  lender: 'Lender',
  lenderCriteria: 'LenderCriteria',
  lenderEsisConfiguration: 'LenderEsisConfiguration',
  product: 'Product',
  igniteAudit: 'IgniteAudit',
  igniteBuyToLetTesting: 'IgniteBuyToLetTesting',
  productsAudit: 'ProductsAudit',
  consumerDuty: 'ConsumerDuty',
  bridgingLenderHub: 'BridgingLenderHub',

  lenderPaidMi: 'LenderPaidMi',
  lenderPaidMiRoles: {
    affordabilityMi: 'AffordabilityMi',
    affordabilityMiR12: 'AffordabilityMiR12',
    bdmMiPack: 'BdmMiPack',
    completionsMi: 'CompletionsMi',
    consumerDutyMi: 'ConsumerDutyMi',
    criteriaMi: 'CriteriaMi',
    criteriaMiR12: 'CriteriaMiR12',
    executiveMarketSummary: 'ExecutiveMarketSummary',
    hostedMi: 'HostedMi',
    lGSSLenderSpecificMI: 'LGSSLenderSpecificMI',
    lGSSNewBuildMI: 'LGSSNewBuildMI',
    paidCompletionsMi: 'PaidCompletionsMi',
    productMi: 'ProductMi',
    productMiR12: 'ProductMiR12',
    propertyMi: 'PropertyMi',
    lenderIgniteActivity: 'LenderIgniteActivity',
  },

  // Admin Dashboard
  adminDashboard: 'AdminDashboard',
  adminDashboardUserManagement: 'AdminDashboardUserManagement',
  analytics: 'Analytics',
  clubHubUserManagement: 'ClubHubUserManagement',
  clubHubUserManagementLimited: 'ClubHubUserManagementLimited',
  externalClientManagement: 'ExternalClientManagement',
  flowManagement: 'FlowManagement',
  criteriaManagement: 'CriteriaManagement',
  lenderHubUserManagement: 'LenderHubUserManagement',
  lenderManagement: 'LenderManagement',
  lendingTypeManagement: 'LendingTypeManagement',
  panelManagement: 'PanelManagement',
  screenscrapeTesting: 'ScreenscrapeTesting',
  siteManagement: 'SiteManagement',
  uiTesting: 'UiTesting',
  formManagement: 'FormManagement',
};
