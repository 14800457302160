import { Component } from '@angular/core';

@Component({
  selector: 'lib-disclaimer-content-lenderhub',
  templateUrl: 'disclaimer-content-lenderhub.component.html',
  styleUrls: ['disclaimer-content-lenderhub.component.scss'],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class DisclaimerContentLenderHubComponent {

}
