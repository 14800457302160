import { GridStateModel } from '../../../models';
import { SimpleGridComponent } from '../simple-grid.component';
import { Datasource } from './datasource';

export class ArrayDatasource<T = unknown> implements Datasource<T> {
  public constructor(public allItems: T[]) {}

  public getItems(_state: GridStateModel, grid: SimpleGridComponent) {
    const filteredItems = grid.clientSideFilterSort(this.allItems);
    return Promise.resolve({ items: grid.clientSidePaginate(filteredItems), totalCount: filteredItems.length });
  }
}
