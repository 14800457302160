import { Directive, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field, IFieldConfig } from '@msslib/models';
import { AppFormComponent } from '../form';
import { FormsService } from '../../services';

@Directive()
@Injectable()
export abstract class FieldBaseComponent implements Field {

  public config: IFieldConfig;

  public constructor(public fc: AppFormComponent, public formService: FormsService) {}

  public get formGroup(): UntypedFormGroup {
    return this.fc.form;
  }

  public get formModel(): unknown {
    return this.fc.model;
  }

  public showAsterisk(config: IFieldConfig): boolean {
    return this.formService.showIndicator(config);
  }

  public get valid(): boolean {
    return this.fc.controls[this.config.name].valid &&
      (this.fc.controls[this.config.name].touched || this.fc.submitted);
  }

  public get invalid(): boolean {
    return this.fc.controls[this.config.name].invalid &&
      (this.fc.controls[this.config.name].touched || this.fc.submitted);
  }
}
