import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  templateUrl: 'postcode-input.component.html',
})
export class PostcodeInputComponent extends FieldType {
  public test() {
    this.props.submit;
  }
}
