import { AfterViewInit, Component, OnInit } from '@angular/core';

import { AnalyticsService } from '@msslib/services/analytics.service';
import { IEmbedConfig, IMiReport } from '../mi-generic.model';
import { MiGenericService } from '../mi-generic.service';

@Component({
  selector: 'app-mi-key-partner',
  styleUrls: [],
  templateUrl: 'mi-key-partner.component.html',
})
export class MiKeyPartnerComponent implements OnInit, AfterViewInit {
  public miReports: IMiReport[];
  public embedConfig: IEmbedConfig;

  public constructor(
    private analyticsService: AnalyticsService,
    private miGenericService: MiGenericService,
  ) { }

  public ngOnInit() {
    this.miGenericService.getReports('GetKeyPartnerReports').subscribe(reports => {
      this.miReports = reports;
      if (reports.length && reports[0].id) {
        this.reportChange(reports[0].id);
      }
    });
  }

  public ngAfterViewInit() {
    this.analyticsService.log('Broker MI');
  }

  public reportChange(reportId: string) {
    this.miGenericService.getReportConfiguration(reportId, 'GetKeyPartnerConfigurationReports')
      .subscribe(config => {
        this.embedConfig = config;
      });
  }
}
