import { Injectable } from '@angular/core';
import padEnd from 'lodash-es/padEnd';

/** Flag for specifying how the fractional part of the currency should be handled. */
export enum FractionalMode {
  /** Keeps up to 2 digits of the fractional part if present in incoming string/value. */
  Keep,
  /** Removes any fractional part, forcing the currency to a whole value. */
  Remove,
  /** Ensures that the fractional part is present and is exactly 2 digits long. */
  Enforce,
}

@Injectable({
  providedIn: 'root',
})
export class FormatService {
  public toCamelCase(text: string): string {
    return text.charAt(0).toLowerCase() + text.substring(1);
  }

  public toPascalCase(text: string): string {
    return text.charAt(0).toUpperCase() + text.substring(1);
  }

  public toSentenceCase(text: string, convertToLowerCase = true): string {
    let result = text !== 'HomeMover' ? text.replace(/([A-Z])/g, ' $1').trim() : 'Homemover';
    if (convertToLowerCase) {
      result = result.toLowerCase();
    }
    return result.charAt(0).toUpperCase() + result.substring(1);
  }

  public getEnumPropertyNames(enumeration: any): string[] {
    return Object.keys(enumeration).filter((key) => !isNaN(Number(enumeration[key])));
  }

  public formatCurrency(value: string | number, fractionalMode: FractionalMode = FractionalMode.Keep): string | null {
    if (!value) {
      return null;
    }

    let [whole, fractional] = value.toString().split('.');

    whole = whole
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      .replace(/^0{2,}/, '0')
      .replace(/^0([1-9])/, '$1');
    fractional = fractional?.slice(0, 2);

    switch (fractionalMode) {
      case FractionalMode.Keep: return value.toString().includes('.') ? `${whole}.${fractional}` : whole;
      case FractionalMode.Remove: return whole;
      case FractionalMode.Enforce: return `${whole}.${padEnd(fractional, 2, '0')}`;
    }
  }
}
