<h3 id="banner" class="p-2 text-center">Fair Value</h3>
<div class="text-left justify-content-center d-flex p-3 m-3">
  <ngb-accordion style="width: 50%;" #acc="ngbAccordion" [closeOthers]="true" [(activeIds)]="activeAccordions">        <!-- Loop through table titles -->
    <ng-container *ngFor="let lenderName of lenderNames">
      <ngb-panel [id]="lenderName" title={{lenderName}} class="p-3 m-3">
        <!-- Main accordion content -->
        <ng-template ngbPanelContent>
          <table class="table align-middle">
            <thead>
              <tr>
                <th>
                  Description
                </th>
                <th class="text-end">
                  Last Updated
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let document of getDocumentsByLenderName(lenderName)">
                  <tr>
                    <td>
                      <a class="link" (click)="download(document.fileName, document.lenderNameId)">{{document.description}}</a>
                    </td>
                    <td class="text-end">
                      {{ formatDate(document.uploadTime) }}
                    </td>
                  </tr>
              </ng-container>
              <tr class="text-end">
                <td></td>
                <td>
                  <button class="btn btn-2022--secondary"
                          data-testid="downloadAllBtn"
                          (click)="downloadAll(lenderName)">
                    Download All
                    <i class="fas fa-download ms-2"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-template>
      </ngb-panel>
    </ng-container>
  </ngb-accordion>
</div>
