import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

import { IInputList } from '../../models';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'lib-radio-list',
  styleUrls: ['./radio-list.component.scss'],
  templateUrl: './radio-list.component.html',
})
export class RadioListComponent implements OnInit {
  @Input() public list: IInputList[];
  @Input() public value: unknown;
  @Input() public name = 'radio-list';
  @Output() public valueChange = new EventEmitter();
  public radioListForm: UntypedFormGroup;

  public constructor(private fb: UntypedFormBuilder) {}

  public ngOnInit(): void {
    this.radioListForm = this.fb.group({
      radioInput: this.value,
    });
  }

  public trackByFn(index: number) {
    return index;
  }

  public onChange(key: unknown) {
    this.value = key;
    this.valueChange.next(key);
  }

  public isTemplate(item: string | TemplateRef<unknown>) {
    return item instanceof TemplateRef;
  }
}
