import { Injectable } from '@angular/core';
import { NavItem, NavPosition, PageNavMessage } from '@msslib/models';

@Injectable({
  providedIn: 'root',
})
export class PageNavMessageService {
  public messages: PageNavMessage[] = [];

  public pushToMessages(message: PageNavMessage) {
    this.messages.push(message);
  }

  public removeFromMessages(key: string) {
    this.messages = this.messages.filter(message => message.key !== key);
  }

  public navMessagesByPosition(position: NavPosition) : NavItem[] {
    return this.messages.filter(message => message.position === position)
      .map(message => message.navItem);
  }
}
