<div [formGroup]="formGroup" [class]="config.cssClass ? 'mb-4 ' + config.cssClass: 'form-group'">
  <label for="{{ config.name }}">
    {{ config.label }}

    <ng-container *ngIf="showAsterisk(config)">
      *
    </ng-container>
  </label>

  <div class="input-group">
    <input
      type="text"
      [attr.placeholder]="config.placeholder"
      [formControlName]="config.name"
      class="form-control"
      [ngClass]="{ 'is-valid': valid, 'is-invalid': invalid }"
      [id]="config.name"
      [attr.data-testid]="config.name"
      [attr.aria-describedby]="config.name + 'err'"

    />
    <button class="btn btn-outline-secondary" type="button" (click)="config?.inputGroupAddOnHandler()"
            [attr.data-testid]="config.inputGroupAddOnLabel + 'Btn'">
      {{ config.inputGroupAddOnLabel }}
    </button>
  </div>

  <div libFormFieldError class="invalid-feedback" [fieldConfig]="config"></div>
</div>
