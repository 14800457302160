import { Component } from '@angular/core';
import { ModalService } from '@msslib/services/modal.service';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  templateUrl: 'horizontal-field-wrapper.component.html',
})
export class HorizontalFieldWrapperComponent extends FieldWrapper {
  public constructor(private modalService: ModalService) {
    super();
  }

  public openTooltip() {
    this.modalService
      .open({
        title: this.props.label !== undefined ? this.props.label : this.props.addText,
        message: this.props.tooltip,
      }).then(() => null, () => null);
  }

  public close() {
    this.modalService.close();
  }

  public get showGroupField() {
    if (!this.props.groupedField) {
      return true;
    }
    if (this.field.fieldGroup) {
      return !this.field.fieldGroup.some(field => field.formControl?.errors);
    }
  }
}
