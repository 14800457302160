<div class="modal-body">
  <div *ngIf="s365Data" class="case-info p-3 text-center">
    <h4>{{modalHeader}}</h4>
    <div>{{modalText}}</div>
  </div>

  <div class="d-flex">
    <div class="col-6 d-flex flex-column btn-col p-3">
      <button type="button" (click)="linkCase()" class="btn btn-outline-2022--secondary w-100 h-100">
        <i class="fa-2x fa-check fas"></i>
      </button>
    </div>
    <div class="col-6 d-flex flex-column btn-col p-3">
      <button type="button" (click)="unlinkCase()" class="btn btn-outline-2022--secondary w-100 h-100">
        <i class="fa-2x fa-times fas"></i>
      </button>
    </div>
  </div>
</div>
