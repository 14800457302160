import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AuthorizeService, IgniteHelperService } from '@msslib/services';
import {
  EsisEorGenFormDetails,
  EsisEorGenerationRequest,
  EsisSearchDetailsModel,
  MethodOfRepayment,
  ProcFeeLenderViewModel,
  ProcFeeRateViewModel,
  Product,
  RepaymentMethod,
} from 'apps/shared/src/models';
import clamp from 'lodash-es/clamp';
import { EsisFormBaseComponent } from '../esis-form-base.component';
import { ProductsUtils } from 'apps/shared/src/utils/products.utils';
import { clientIds } from '@msslib/constants';

const mortgageClubOtherRateId = -1;

@Component({
  selector: 'lib-esis-product-summary',
  templateUrl: 'esis-product-summary.component.html',
  styleUrls: ['../esis-tab-shared-style.scss', './esis-product-summary.component.scss'],
})
export class EsisProductSummaryComponent extends EsisFormBaseComponent implements OnInit {
  @ViewChildren('rateSelect') public rateSelects: QueryList<ElementRef<HTMLSelectElement>>;
  @Input() public product: Product;
  @Input() public isBridging: boolean;
  @Input() public searchDetails: EsisSearchDetailsModel;
  @Input() public formDetails: EsisEorGenFormDetails;
  public mortgageClubOtherRateId = mortgageClubOtherRateId;
  // eslint-disable-next-line quotes
  public namePattern = "^[a-zA-Z ,.'-]*$"; // have to use double quotes here as you are not allowed to escape the '

  public constructor(
    private authService: AuthorizeService,
    private productsUtils: ProductsUtils,
    private fb: UntypedFormBuilder,
    private helperService: IgniteHelperService,
  ) {
    super();
  }

  public ngOnInit(): void {
    const { lenderIdx } = (this.form.getRawValue() as EsisEorGenerationRequest).fees.procuration;
    // only pre-selects lender when opening modal for the first time, it will not patch form if you click amend
    if (this.procFeeLenderGoodMatchIndex && !lenderIdx) {
      this.form.patchValue({
        fees: { procuration: { lenderIdx: this.procFeeLenderGoodMatchIndex } },
      });
      this.onSelectedLenderChange();
    }
  }

  public get ltv(): number | null {
    return this.helperService.calculateLtv(this.searchDetails);
  }

  public get repaymentMethod(): string | undefined {
    if (this.isBridging) {
      if (this.searchDetails.methodOfRepayment?.value) {
        return {
          [MethodOfRepayment.Serviced]: 'Serviced',
          [MethodOfRepayment.Retained]: 'Retained',
          [MethodOfRepayment.RolledUp]: 'Rolled Up',
        }[this.searchDetails.methodOfRepayment.value];
      }
    }

    if (this.searchDetails.repaymentMethod.value) {
      return {
        [RepaymentMethod.CapitalAndInterest]: 'Capital & Interest',
        [RepaymentMethod.InterestOnly]: 'Interest Only',
        [RepaymentMethod.InterestOnlyPartAndPart]: 'Interest Only Part & Part',
      }[this.searchDetails.repaymentMethod.value];
    }
  }

  public get addressLines(): string[] {
    if (this.formDetails.userEsisInformation.complaints) {
      const { addressLine1, addressLine2, town, county, postcode } = this.formDetails.userEsisInformation.complaints;
      return [addressLine1, addressLine2, town, county, postcode].filter(Boolean);
    }

    return [];
  }

  public get addressLinesBroker(): string[] {
    if (this.formDetails.userEsisInformation.broker) {
      const { addressLine1, addressLine2, town, county, postcode } = this.formDetails.userEsisInformation.broker;
      return [addressLine1, addressLine2, town, county, postcode].filter(Boolean);
    }

    return [];
  }

  public get complaints() {
    return this.formDetails.userEsisInformation.complaints;
  }

  public get broker() {
    return this.formDetails.userEsisInformation.broker;
  }

  public get productDescription(): string {
    return this.productsUtils.getProductDescription(this.product);
  }

  public get productCode(): string {
    return this.productsUtils.getProductCode(this.product);
  }

  //#region Proc fee
  public get productProcFeeLenders(): ProcFeeLenderViewModel[] {
    return this.formDetails.procFeePaymentRates.sort((a, b) => a.lenderName.localeCompare(b.lenderName));
  }

  // If there is a good match for a proc fee lender based on the chosen product's lender, return it
  public get procFeeLenderGoodMatchIndex(): number {
    return this.productProcFeeLenders
      ?.findIndex(x => x.lenderName.startsWith(this.product.lender) || this.product.lender.startsWith(x.lenderName));
  }

  public get procFeeLenderGoodMatch(): ProcFeeLenderViewModel | undefined {
    const idx = this.procFeeLenderGoodMatchIndex;
    return idx >= 0 ? this.productProcFeeLenders[this.procFeeLenderGoodMatchIndex] : undefined;
  }

  public get hasSelectedLender() {
    const { lenderIdx } = (this.form.getRawValue() as EsisEorGenerationRequest).fees.procuration;
    return lenderIdx !== undefined && lenderIdx !== null;
  }

  public get clubsForSelectedLender() {
    const { lenderIdx } = (this.form.getRawValue() as EsisEorGenerationRequest).fees.procuration;
    return lenderIdx !== null && lenderIdx !== undefined
      ? this.productProcFeeLenders[+lenderIdx].clubRates
      : [];
  }

  public get hasOtherProcFeeClub(): boolean {
    // Hide the "via. Other" club option for SimplyBiz SSO users if product doesn't have packager
    return this.authService.clientId !== clientIds.simplyBiz
      || this.isPackagerProduct;
  }

  public get isPackagerProduct(): boolean {
    return !!this.product.packager;
  }

  public onSelectedLenderChange() {
    // When the lender is selected, clear the selected rate
    this.clearField('fees.procuration.rateId');
  }

  public onSelectedRateChange(evt: Event) {
    // When the user selects a rate, ensure we clear out any custom value they entered
    this.clearField('fees.procuration.procFeePaymentAmount');

    // When there are multiple clubs, we need to manually clear the selection from other <select> elements
    this.rateSelects
      .map(ref => ref.nativeElement)
      .filter(select => select !== evt.currentTarget)
      .forEach(select => select.selectedIndex = -1);
  }

  public onOtherProcFeeAmountChange() {
    // When the user types in a custom amount, deselect any rate they may have previously had selected
    this.clearField('fees.procuration.rateId');
  }

  private clearField(path: string) {
    const ctrl = this.form.get(path) as UntypedFormControl;
    ctrl.setValue(undefined);
    ctrl.updateValueAndValidity();
  }

  public calculatePayment(rate: ProcFeeRateViewModel): number {
    const { loanAmount } = (this.form.getRawValue() as EsisEorGenerationRequest).details.loanDetails;
    return clamp(
      (rate.grossPaymentPercent * loanAmount) + rate.grossPaymentAdditional,
      rate.grossPaymentMin ?? 0,
      rate.grossPaymentMax ?? Number.MAX_VALUE);
  }

  public trackByRateId({ rateId }: ProcFeeRateViewModel) {
    return rateId;
  }

  public get clients() {
    return this.form.get('details.clientDetails.clients') as UntypedFormArray;
  }

  public addClient() {
    const clientForm = this.fb.group({
      forename: new UntypedFormControl(''),
      surname: new UntypedFormControl(''),
    });
    this.clients.push(clientForm);
  }

  public deleteClient(clientIndex: number) {
    this.clients.removeAt(clientIndex);
  }

  public showAddClient(clientIndex: number) {
    return this.clients.length - 1 === clientIndex && this.clients.length < 4;
  }

  public showDeleteClient(clientIndex: number) {
    return this.clients.length - 1 === clientIndex && this.clients.length > 1;
  }

  public clientDetailsTitle(clientIndex: number) {
    return `Client${ clientIndex === 0 ? ' ' : ` ${clientIndex + 1} `}Details`;
  }

  public get rateIdProcFeePaymentAmountValidityClasses() {
    return {
      'is-valid': this.valid('fees.procuration.rateId') && this.valid('fees.procuration.procFeePaymentAmount'),

      // Invalid if either sub-field is invalid, OR either subfield is touched and the procuration group is invalid.
      // Do not ONLY check if the procuration group is invalid, because it will be treated as touched when the user
      // changes the lender, before they've got to the point of entering a rate/amount, so don't want to show the error
      // too early. Also use !valid on the procuration group as invalid has custom logic that seems to break it.
      'is-invalid':
        this.invalid('fees.procuration.rateId') ||
        this.invalid('fees.procuration.procFeePaymentAmount') ||
        (
          (this.touched('fees.procuration.rateId') || this.invalid('fees.procuration.procFeePaymentAmount')) &&
          !this.valid('fees.procuration')
        ),
    };
  }
  //#endregion
}
