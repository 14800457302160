<span
  placement="left"
  class="initial-rate"
  [class.is-multiple]="isMultipleRates"
  [ngbTooltip]="productRatesTooltip"
  tooltipClass="custom-tooltip fees-tooltip"
>
  {{ initialRateValue }}%
</span>

<ng-template #productRatesTooltip>

  <div *ngFor="let viewModel of ratesViewModels; index as index; trackBy: trackByFn">
    <div class="product-rate">
      <div><strong>{{viewModel.title}}</strong></div>
      <div>{{viewModel.rateValue}} {{viewModel.rateType}}</div>
      <div>{{viewModel.initialPeriod}}</div>
      <div *ngIf="viewModel.until">{{viewModel.until}}</div>
    </div>
  </div>

</ng-template>
