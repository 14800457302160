import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, UntypedFormGroup } from '@angular/forms';
import { IInputOption } from '@msslib/components/formly/models';
import { AddOptionModalComponent, FormsValidators } from '@msslib/components';
import { FormFieldType, WrapperType, yesNoOptions } from '@msslib/components/formly/formly.config';
import { AuthorizeService, FormStateService, FormatService, IgniteHelperService, ModalService } from '@msslib/services';
import { FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';
import {
  IApplicants,
  IPropertyAndLoan,
  IUiAffordabilityModel,
  IUiApplicant,
  IUiBaseIncome,
  IUiBenefitIncomeOption,
  IUiMainIncome,
  IUiOtherIncomeOption,
  IUiWorkRelatedIncomeOption,
  ResultsView,
  UiBaseIncomeEnumType,
  errorMessages,
  initialProductLengthTooltip,
  tooltipMessages,
} from 'apps/clubhub/src/app/ignite/models/affordability';
import {
  BenefitIncomeType,
  ContractType,
  EmploymentStatus,
  Frequency,
  MortgageLenders,
  MortgageType,
  OtherIncomeType,
  ProductLength,
  ProductType,
  PropertyType,
  PropertyUse,
  PurchaserType,
  RemortgageReason,
  RepaymentMethod,
  RepaymentVehicle,
  UkLocation,
  WorkRelatedIncomeType,
} from 'apps/shared/src/models';
import { IgniteService } from 'apps/clubhub/src/app/ignite/services/ignite.service';
import { LenderService } from 'apps/shared/src/services/lender.service';
import { map } from 'rxjs';
import { getAge, mapDate } from '@msslib/helpers';

const defaultWorkRelatedIncomeTypes = [
  WorkRelatedIncomeType.Bonus,
  WorkRelatedIncomeType.Commission,
  WorkRelatedIncomeType.Overtime,
];

@Injectable({
  providedIn: 'root',
})
export class AffordabilityFormFieldsResiService {

  public constructor(
    private authService: AuthorizeService,
    private igniteService: IgniteService,
    private helperService: IgniteHelperService,
    private modalService: ModalService,
    private formStateService: FormStateService,
    private formatService: FormatService,
    private lenderService: LenderService,
  ) { }

  public get model(): IUiAffordabilityModel | undefined {
    return this.igniteService.model;
  }

  public fields: FormlyFieldConfig[] = [
    // Step 1
    {
      key: 'propertyAndLoan',
      wrappers: [WrapperType.FormCard],
      props: {
        title: 'Property & Loan',
        summary: `<p>
          <i class="fas fa-info-circle"></i>
          All fields within this section are mandatory
        </p>`,
      },
      validators: {
        max500PercentageLoanValue: {
          expression: (_control: AbstractControl, field: FormlyFieldConfig) => {
            return this.helperService.isLtvValid(field.form?.getRawValue().propertyAndLoan as IPropertyAndLoan);
          },
          message: errorMessages.ltv500Percent,
          errorPath: 'loanAmount',
        },
        lessThanDeposit: {
          expression: (control: AbstractControl, field: FormlyFieldConfig) => {
            const { loanAmount, propertyValue } = field.form?.getRawValue().propertyAndLoan as IPropertyAndLoan;
            const equityLoanValue = control.value.equityLoanValue;
            if (!equityLoanValue) {
              return true;
            }

            return equityLoanValue < propertyValue - loanAmount;
          },
          message: 'Equity loan value must be less than deposit',
          errorPath: 'equityLoanValue',
        },
        interestOnlyAmount: {
          expression: (control: AbstractControl, field: FormlyFieldConfig) => {
            const { loanAmount, interestOnlyAmount } = field.form?.getRawValue().propertyAndLoan as IPropertyAndLoan;
            if (!interestOnlyAmount) {
              return true;
            }

            return +interestOnlyAmount < +loanAmount;
          },
          message: errorMessages.interestOnlyLessThanLoanAmount,
          errorPath: 'interestOnlyAmount',
        },
        productTransferInitialDate: this.helperService.getProductTransferInitialPeriodValidation(),
      },
      fieldGroup: [
        {
          key: 'location',
          type: FormFieldType.Select,
          props: {
            label: 'Property location',
            change: (field: FormlyFieldConfig) => {
              const { location } = field.model;
              if (location === UkLocation.NorthernIreland) {
                field.form?.get('helpToBuy')?.setValue(false);
                this.igniteService.updateModel(this.model);
              }
              if (location !== UkLocation.Wales && field.model?.mortgageType === MortgageType.Purchase) {
                field.form?.get('helpToBuy')?.setValue(null);
                if (this.model) {
                  this.model.propertyAndLoan.helpToBuy = undefined;
                }
                this.igniteService.updateModel(this.model);
              }
            },
            required: true,
            options: [
              this.helperService.defaultSelectOption,
              { value: UkLocation.EastMidlands, label: 'East Midlands' },
              { value: UkLocation.EastOfEngland, label: 'East of England' },
              { value: UkLocation.GreaterLondon, label: 'Greater London' },
              { value: UkLocation.NorthEast, label: 'North East' },
              { value: UkLocation.NorthWest, label: 'North West' },
              { value: UkLocation.SouthEast, label: 'South East' },
              { value: UkLocation.SouthWest, label: 'South West' },
              { value: UkLocation.WestMidlands, label: 'West Midlands' },
              { value: UkLocation.YorkshireAndHumberside, label: 'Yorkshire & Humberside' },
              { value: UkLocation.NorthernIreland, label: 'Northern Ireland' },
              { value: UkLocation.Scotland, label: 'Scotland' },
              { value: UkLocation.Wales, label: 'Wales' },
            ],
          },
        },
        {
          key: 'productTypeExtended',
          type: 'select',
          props: {
            label: 'Product Type',
            options: [
              { label: 'Standard', value: ProductType.Standard },
              { label: 'Retirement Interest Only (RIO)', value: ProductType.RetirementInterestOnly },
            ],
            change: (field: FormlyFieldConfig, event) => {
              const isRio = this.helperService
                .getEnumValue(event.target.value) as ProductType === ProductType.RetirementInterestOnly;
              if (isRio) {
                (field.parent?.formControl as UntypedFormGroup).controls.repaymentMethod
                  .setValue({ value: RepaymentMethod.InterestOnly });
                (field.parent?.formControl as UntypedFormGroup).controls.helpToBuy
                  ?.setValue(false);
              }
            },
          },
        },
        {
          key: 'propertyType',
          type: FormFieldType.RadioButtons,
          defaultValue: PropertyType.House,
          props: {
            label: 'Property type',
            options: [
              { label: 'House', value: PropertyType.House },
              { label: 'Flat', value: PropertyType.Flat },
            ],
            required: true,
          },
        },
        {
          key: 'newBuild',
          type: FormFieldType.RadioButtons,
          props: {
            label: 'New build',
            options: yesNoOptions,
            required: true,
          },
        },
        {
          key: 'propertyValue',
          type: FormFieldType.Currency,
          props: {
            label: 'Property value',
            required: true,
            change: (field: FormlyFieldConfig, event) => {
              const value = +event.target.value.replace(',', '');
              this.fields
                .find(f => f.key === 'propertyAndLoan')?.fieldGroup
                ?.find(f => f.key === 'equityValue')?.formControl
                ?.setValue(value - field.parent?.model.loanAmount);
            },
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
          expressions: {
            'props.readonly': () => this.igniteService.hasPresetPropertyValue,
          },
        },
        {
          key: 'loanAmount',
          type: FormFieldType.Currency,
          props: {
            label: 'Loan amount',
            required: true,
            change: (field: FormlyFieldConfig, event) => {
              const value = +event.target.value.replace(',', '');
              this.fields
                .find(f => f.key === 'propertyAndLoan')?.fieldGroup
                ?.find(f => f.key === 'equityValue')?.formControl
                ?.setValue(field.parent?.model.propertyValue - value);
            },
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
          expressions: {
            'props.readonly': () => this.igniteService.hasPresetPropertyValue,
          },
        },
        {
          key: 'ltv',
          type: FormFieldType.Disabled,
          props: {
            label: 'LTV',
            addonEnd: '%',
          },
          expressions: {
            'props.value': (field) => {
              const { propertyValue, loanAmount } = field.model as IPropertyAndLoan;
              return this.helperService.calculateLtv({ propertyValue, loanAmount }) ?? 0;
            },
          },
        },
        {
          key: 'mortgageType',
          type: FormFieldType.RadioButtons,
          defaultValue: MortgageType.Purchase,
          props: {
            label: 'Mortgage type',
            options: [
              { label: 'Purchase', value: MortgageType.Purchase },
              { label: 'Remortgage', value: MortgageType.Remortgage },
            ],
            required: true,
            change: () => {
              const mortgageType = this.model?.propertyAndLoan?.mortgageType;
              if (mortgageType === MortgageType.Purchase) {
                if (this.model) {
                  this.model.propertyAndLoan.equityLoanValue = null;
                  this.model.propertyAndLoan.helpToBuy = this.model.propertyAndLoan.location === UkLocation.Wales
                    ? false
                    : undefined;
                }
                this.igniteService.updateModel(this.model);
              }
            },
          },
        },
        ...this.capitalRaisingFields(),
        ...this.mortgageLenderFields(),
        {
          key: 'amountTransferredFromOtherLender',
          type: FormFieldType.Currency,
          props: {
            label: 'Current mortgage balance outstanding',
            tooltip: 'What is the outstanding mortgage balance from the previous lender?',
            required: true,
          },
          expressions: {
            hide: field => field.model?.mortgageType !== MortgageType.Remortgage
              || !field.model?.capitalRaising?.value,
          },
        },
        {
          key: 'purchaserType',
          type: FormFieldType.RadioButtons,
          defaultValue: PurchaserType.FirstTimeBuyer,
          props: {
            label: 'Purchaser type',
            options: this.helperService.buildOptionsFromEnum(PurchaserType),
            required: true,
          },
          expressions: {
            hide: field => field.model?.mortgageType !== MortgageType.Purchase,
          },
        },
        {
          key: 'helpToBuy',
          type: FormFieldType.RadioButtons,
          resetOnHide: true,
          defaultValue: false,
          props: {
            label: 'Help to buy',
            tooltip: 'Only select if your customer is an existing Help to Buy scheme user',
            options: yesNoOptions,
            required: true,
            change: (field: FormlyFieldConfig) =>
              field.form?.get('repaymentMethod')?.patchValue({
                value: field.formControl?.value ? RepaymentMethod.CapitalAndInterest : null,
              }),
          },
          expressions: {
            hide: this.isHelpToBuyHidden,
            'props.disabled': (field) => (field.model as IPropertyAndLoan).location === UkLocation.NorthernIreland ||
              (field.model as IPropertyAndLoan).productTypeExtended === ProductType.RetirementInterestOnly,
          },
        },
        {
          key: 'equityLoanStartDate',
          type: FormFieldType.Date,
          resetOnHide: true,
          props:{
            label: 'Equity Loan Start Date',
            required: true,
          },
          validators: {
            validation: [FormsValidators.date],
          },
          expressions: {
            hide: field => !this.isHelpToBuySelected(field),
          },
        },
        {
          key: 'equityLoanValue',
          type: FormFieldType.Currency,
          resetOnHide: true,
          props: {
            label: 'Equity loan value',
            tooltip: 'Enter the amount of the equity loan from the government scheme. ' +
              'DO NOT include any of the customer\'s own deposit.',
            required: true,
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
          expressions: {
            hide: field => !this.isHelpToBuySelected(field),
          },
        },
        {
          key: 'remortgageReason',
          type: FormFieldType.Select,
          defaultValue: null,
          resetOnHide: true,
          props: {
            label: 'Reason for remortgage',
            tooltip: 'What is the intention for the Help to Buy Equity loan Remortgage?',
            options: [
              {
                value: RemortgageReason.PurchaseAdditionalShares,
                label: 'Purchase additional shares',
              },
              {
                value: RemortgageReason.PoundForPound,
                label: '£ for £ remortgage',
              },
              {
                value: RemortgageReason.Other,
                label: 'Other',
              },
            ],
            required: true,
          },
          expressions: {
            hide: (field) => !(field.model as IPropertyAndLoan).helpToBuy,
            'props.disabled': () => false,
          },
        },
        {
          key: 'repaymentMethod',
          wrappers: [WrapperType.NoWrappers],
          props: {
            suppressErrors: true,
          },
          fieldGroup: [
            {
              key: 'value',
              type: FormFieldType.Select,
              props: {
                label: 'Method of repayment',
                options: this.helperService.buildSelectOptionsFromEnum(RepaymentMethod),
                required: true,
              },
              expressions: {
                'props.readonly': field => this.isHelpToBuySelected(field.parent?.parent) ||
                  (field.parent?.parent?.formControl as UntypedFormGroup).controls?.productTypeExtended
                    ?.value === ProductType.RetirementInterestOnly,
              },
            },
          ],
        },
        {
          key: 'repaymentVehicle',
          type: FormFieldType.Select,
          defaultValue: null,
          props: {
            label: 'Repayment vehicle',
            tooltip: 'How will the interest only element be repaid at the end of the term?',
            options: [
              this.helperService.defaultSelectOption,
              {
                value: RepaymentVehicle.SaleOfProperty,
                label: 'Sale of property',
              },
              {
                value: RepaymentVehicle.SaleOfSecondProperty,
                label: 'Sale of second property',
              },
              {
                value: RepaymentVehicle.EndowmentsInvestmentsPension,
                label: 'Endowments / Investments / Pension',
              },
              {
                value: RepaymentVehicle.SavingsISA,
                label: 'Savings / ISA\'s',
              },
            ],
            required: true,
            change: (field: FormlyFieldConfig) => {
              (field.parent?.formControl as UntypedFormGroup).controls.equityValue?.setValue(
                field.parent?.model?.propertyValue - field.parent?.model?.loanAmount,
              );
            },
          },
          expressions: {
            'props.disabled': () => false,
            hide: (field) =>
              (field.model as IPropertyAndLoan).repaymentMethod.value !== RepaymentMethod.InterestOnly &&
              (field.model as IPropertyAndLoan).repaymentMethod.value !== RepaymentMethod.InterestOnlyPartAndPart,
          },
        },
        {
          key: 'equityValue',
          type: FormFieldType.Currency,
          props: {
            label: 'How much equity is to remain in the property?',
            required: true,
          },
          expressions: {
            hide: field => field.model?.repaymentVehicle !== RepaymentVehicle.SaleOfProperty
              || field.model?.repaymentMethod.value === RepaymentMethod.CapitalAndInterest,
          },
          validators: {
            validation: [FormsValidators.integer, FormsValidators.greaterThan(0)],
          },
        },
        {
          key: 'interestOnlyAmount',
          type: FormFieldType.Currency,
          props: {
            label: 'Amount on interest only',
            required: true,
          },
          expressions: {
            hide: field => field.model?.repaymentMethod.value !== RepaymentMethod.InterestOnlyPartAndPart,
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
        },
        {
          type: FormFieldType.Disabled,
          props: {
            label: 'Repayment amount',
            addonStart: '£',
            testId: 'repaymentAmount',
          },
          expressions: {
            'props.value': (field) => {
              const { loanAmount, interestOnlyAmount } = field.model as IPropertyAndLoan;
              const repaymentAmount = this.helperService.calculateRepaymentAmount(loanAmount, interestOnlyAmount);
              return repaymentAmount ? `${this.formatService.formatCurrency(repaymentAmount)}` : 0;
            },
            hide: (field) =>
              (field.model as IPropertyAndLoan).repaymentMethod.value !== RepaymentMethod.InterestOnlyPartAndPart,
          },
        },
        {
          key: 'mortgageTerm',
          props: {
            label: 'Mortgage term',
            required: true,
            groupedField: true,
          },
          validators: {
            ...this.helperService.yearMonthValidator,
          },
          fieldGroupClassName: 'row',
          fieldGroup: this.helperService.yearsAndMonthsFields(),
        },
        {
          key: 'productLength',
          type: FormFieldType.Select,
          defaultValue: ProductLength.TwoYears,
          props: {
            label: 'Initial product period',
            tooltip: initialProductLengthTooltip,
            options: this.helperService.productLengthOptions,
            required: true,
          },
        },
      ],
    },
    // Step 2
    {
      key: 'applicants',
      wrappers: [WrapperType.FormCard],
      props: {
        title: 'Applicants',
        id: 'applicantsAgesSection',
        summary: `<p>
          <i class="fas fa-info-circle"></i>
          All fields within this section are mandatory
        </p>`,
      },
      validators: {
        totalDependants: {
          expression: (control: AbstractControl) => {
            const {
              financialDependants,
              dependantsAged0To5,
              dependantsAged6To11,
              dependantsAged12To17,
              dependantsAged18AndOver,
            } = control.value as IApplicants;
            if (!financialDependants) {
              return true;
            }
            const total = +dependantsAged0To5 +
              +dependantsAged6To11 +
              +dependantsAged12To17 +
              +dependantsAged18AndOver;
            return total > 0 && total <= 20;
          },
          message: 'Total dependants must be between 1 and 20',
          errorPath: 'dependantsAged18AndOver',
        },
      },
      fieldGroup: [
        {
          key: 'numberOfApplicants',
          type: FormFieldType.RadioButtons,
          defaultValue: 1,
          props: {
            label: 'Number of applicants',
            options: [
              { value: 1, label: '1' },
              { value: 2, label: '2' },
              { value: 3, label: '3' },
              { value: 4, label: '4' },
            ],
            required: true,
            change: (field: FormlyFieldConfig) => {
              const numberOfApplicants = +field.formControl?.value;
              (field.parent?.formControl as UntypedFormGroup)
                .controls.numberOfIncomesNeeded?.setValue(numberOfApplicants);
              if (field.parent) {
                field.parent.model.numberOfIncomesNeeded = numberOfApplicants;
              }
              this.onNumberOfApplicantsChange(numberOfApplicants, numberOfApplicants);
              const numberOfIncomesNeededProps = field.parent?.fieldGroup?.find(
                (f) => f.key === 'numberOfIncomesNeeded',
              )?.props;
              if (numberOfIncomesNeededProps) {
                numberOfIncomesNeededProps.options = this.getNumberOfIncomesNeededOptions(numberOfApplicants);
              }

              field.form?.reset(field.form.value);
            },
          },
        },
        {
          key: 'numberOfIncomesNeeded',
          type: FormFieldType.Select,
          props: {
            label: 'How many incomes are needed for affordability?',
            tooltip: tooltipMessages.numberOfIncomesNeeded,
            required: true,
            change: (field: FormlyFieldConfig) => {
              const numberOfApplicants = field.model?.numberOfApplicants;
              const numberOfIncomesNeeded = field.formControl?.value;
              this.onNumberOfApplicantsChange(numberOfApplicants, numberOfIncomesNeeded);
            },
          },
          expressions: {
            'props.options': (field: FormlyFieldConfig) => {
              return this.getNumberOfIncomesNeededOptions((field.model as IApplicants).numberOfApplicants);
            },
            hide: (field) => {
              const { numberOfApplicants } = field.model as IApplicants;
              return numberOfApplicants !== 3 && numberOfApplicants !== 4;
            },
          },
        },
        {
          key: 'financialDependants',
          type: FormFieldType.RadioButtons,
          props: {
            label: 'Any financial dependants',
            options: yesNoOptions,
            required: true,
          },
          expressions: {
            hide: field => field.model?.applicantNumber > 1,
          },
        },
        {
          key: 'dependantsAged0To5',
          type: FormFieldType.Number,
          defaultValue: 0,
          props: {
            label: 'Dependants aged 0-5',
            min: 0,
            max: 20,
            required: true,
          },
          expressions: {
            hide: field => !field.model?.financialDependants,
          },
        },
        {
          key: 'dependantsAged6To11',
          type: FormFieldType.Number,
          defaultValue: 0,
          props: {
            label: 'Dependants aged 6-11',
            min: 0,
            max: 20,
            required: true,
          },
          expressions: {
            hide: field => !field.model?.financialDependants,
          },
        },
        {
          key: 'dependantsAged12To17',
          type: FormFieldType.Number,
          defaultValue: 0,
          props: {
            label: 'Dependants aged 12-17',
            min: 0,
            max: 20,
            required: true,
          },
          expressions: {
            hide: field => !field.model?.financialDependants,
          },
        },
        {
          key: 'dependantsAged18AndOver',
          type: FormFieldType.Number,
          defaultValue: 0,
          props: {
            label: 'Dependants aged 18 and over',
            min: 0,
            max: 20,
            required: true,
          },
          expressions: {
            hide: field => !field.model?.financialDependants,
          },
        },
        {
          key: 'applicantAges',
          type: FormFieldType.Repeating,
          props: {
            suppressErrors: true,
            id: 'applicantAges',
          },
          fieldArray: {
            wrappers: [WrapperType.NoWrappers],
            props: {
              suppressErrors: true,
            },
            validators:{
              ageYoungerThanRetirementAge: {
                expression: (control: AbstractControl) => {
                  const { plannedRetirementAge, dateOfBirth }  = control.value;
                  if (plannedRetirementAge && dateOfBirth) {
                    const dob = mapDate(dateOfBirth);
                    if (dob) {
                      return getAge(dob) <= plannedRetirementAge;
                    }
                  }
                },
                message:errorMessages.ageOlderThanRetirementAge,
                errorPath: 'plannedRetirementAge',
              },
            },
            fieldGroup: [
              {
                expressions: {
                  template: field => {
                    const summary = field.model.applicantNumber === 1
                      ? `<p class="mb-3">
                        Enter the applicants in the order of how you want their income to be considered
                      </p>`
                      : '';
                    return `
                      <hr class="my-0" />
                      <h2 class="fw-light mt-4 mb-3">Applicant ${field.model.applicantNumber}</h2>
                      ${summary}
                    `;
                  },
                },
              },
              {
                key: 'dateOfBirth',
                type: FormFieldType.Date,
                props: {
                  label: 'Date of birth',
                  required: true,
                },
                validators: {
                  validation: [FormsValidators.date, FormsValidators.atLeast18Years],
                },
              },
              {
                key: 'plannedRetirementAge',
                type: FormFieldType.Number,
                defaultValue: 0,
                props: {
                  label: 'Planned retirement age',
                  required: true,
                  min: 50,
                  max: 85,
                },
              },
            ],
          },
        },
      ],
    },
    // Step 3
    {
      key: 'incomeAndExpenditure',
      wrappers: [WrapperType.FormCard],
      props: {
        title: 'Income & Expenditure',
        suppressErrors: true,
        summary: `<p>
          <i class="fas fa-info-circle"></i>
          Please note - Entering the correct expenditure at this point will allow us to give you a more accurate
          affordability amount! (Some lenders apply default expenditure values but some take the expenditure values
          entered below.)
        </p>`,
      },
      fieldGroup: [
        {
          key: 'applicants',
          type: FormFieldType.Repeating,
          props: {
            suppressErrors: true,
            id: 'applicantsIncome',
          },
          fieldArray: {
            wrappers: [WrapperType.NoWrappers],
            props: {
              suppressErrors: true,
            },
            expressions: {
              hide: (field) => {
                const { applicantNumber } = field.model as IUiApplicant;
                const { numberOfIncomesNeeded } = field.parent?.parent?.parent?.model?.applicants;
                return applicantNumber > numberOfIncomesNeeded;
              },
            },
            validators: {
              atLeastOneAdditionalIncome: {
                expression: (_control: AbstractControl, field: FormlyFieldConfig) => {
                  const {
                    applicantNumber,
                    primaryIncome,
                    workAdditionalIncome,
                    pensionIncome,
                    benefitAdditionalIncome,
                    otherAdditionalIncome,
                  } = field.model as IUiApplicant;
                  const { employmentStatus } = primaryIncome;
                  if (applicantNumber !== 1 || !this.helperService.isHomemakerStudentOrUnemployed(employmentStatus)) {
                    return true;
                  }
                  const totalIncome = [
                    ...(workAdditionalIncome ?? []),
                    ...(benefitAdditionalIncome ?? []),
                    ...(otherAdditionalIncome ?? []),
                  ]
                    .map((o) => o.option.amount)
                    .reduce((a, b) => +(a ?? 0) + +b, pensionIncome.amount);

                  return totalIncome && +totalIncome > 0;
                },
                message:
                  'Total income must be greater than zero. Please add at least 1 income from the options below.',
                errorPath: 'primaryIncome.employmentStatus',
              },
              retiredRequiresPensionIncome: {
                expression: (_control: AbstractControl, field: FormlyFieldConfig) => {
                  const { primaryIncome, pensionIncome } = field.model as IUiApplicant;
                  const { employmentStatus } = primaryIncome;
                  return employmentStatus !== EmploymentStatus.Retired || +(pensionIncome?.amount ?? 0) > 0;
                },
                message: 'Pension income must be greater than 0',
                errorPath: 'pensionIncome.amount',
              },
              creditCardBalanceToRepayLessThanTotalOutstandingCreditCardBalance: {
                expression: (_control: AbstractControl, field: FormlyFieldConfig) => {
                  const { creditCardBalance, creditCardBalanceToRepay } = field.model as IUiApplicant;
                  if (creditCardBalance && +creditCardBalance === 0) {
                    return true;
                  }
                  return +(creditCardBalanceToRepay ?? 0) <= +(creditCardBalance ?? 0);
                },
                message: errorMessages.creditCardBalanceToRepayLessThanTotalOutstandingCreditCardBalance,
                errorPath: 'creditCardBalanceToRepay',
              },
            },
            fieldGroup: [
              {
                expressions: {
                  template: field => `
                    <hr class="my-0" />
                    <h2 class="fw-light mt-4 mb-3">Applicant ${field.model.applicantNumber}</h2>
                  `,
                },
              },
              this.primaryIncomeFields('primaryIncome', true),
              {
                key: 'additionalIncome',
                type: FormFieldType.RadioButtons,
                props: {
                  label: 'Additional income',
                  tooltip: 'Any income that has not been included above',
                  options: yesNoOptions,
                  required: true,
                  change: (field: FormlyFieldConfig) => {
                    this.setAllAdditionalIncome(
                      field.model?.primaryIncome.employmentStatus,
                      field.formControl?.value,
                      field.parent?.model as IUiApplicant,
                    );
                  },
                },
                expressions: {
                  'props.disabled': (field) => {
                    const { employmentStatus } = (field.model as IUiApplicant).primaryIncome;
                    return this.helperService.isUnemployed(employmentStatus);
                  },
                },
              },
              ...this.getAdditionalIncomeFields<IUiWorkRelatedIncomeOption>(
                'Work related additional income',
                'Add other work related income',
                'workAdditionalIncome',
                WorkRelatedIncomeType,
                defaultWorkRelatedIncomeTypes,
                'Other earned income received that is not included in the main income above',
                null,
                true,
                'Please input all that apply',
              ),
              {
                key: 'secondJob',
                type: FormFieldType.RadioButtons,
                defaultValue: false,
                props: {
                  label: 'Does the applicant have a 2nd Job?',
                  tooltip: 'Another earned income other than the applicant\'s main income',
                  options: yesNoOptions,
                  required: true,
                },
                expressions: {
                  hide: (field) => {
                    const { primaryIncome, additionalIncome } = field.model as IUiApplicant;
                    return !this.helperService.isEmployed(primaryIncome.employmentStatus) || !additionalIncome;
                  },
                },
              },
              this.primaryIncomeFields('secondaryIncome', false),
              {
                type: FormFieldType.Template,
                template: `
                  <hr class="mt-4" />
                  <h2 class="fw-light mt-4 mb-3">Pension income (annual)</h2>
                `,
                expressions: {
                  hide: (field: FormlyFieldConfig) =>
                    !(field.model as IUiApplicant).additionalIncome,
                },
              },
              {
                key: 'pensionIncome',
                wrappers: [WrapperType.NoWrappers],
                expressions: {
                  hide: (field) =>
                    !field.parent?.model?.additionalIncome,
                },
                fieldGroup: [
                  {
                    key: 'amount',
                    type: FormFieldType.Currency,
                    props: {
                      label: 'Pension',
                    },
                  },
                ],
              },
              ...this.getAdditionalIncomeFields<IUiBenefitIncomeOption>(
                'Benefit related additional income',
                'Add other benefit related income',
                'benefitAdditionalIncome',
                BenefitIncomeType,
                [],
                'Benefit income being received by the Government',
                this.benefitIncomeCustomOptions,
              ),
              ...this.getAdditionalIncomeFields<IUiOtherIncomeOption>(
                'Other additional income',
                'Add other additional income',
                'otherAdditionalIncome',
                OtherIncomeType,
                [],
                null,
                null,
              ),
              {
                template: `
                  <hr class="my-0" />
                  <h2 class="fw-light mt-4 mb-3">Expenditure (monthly)</h2>
                `,
              },
              {
                key: 'creditCardBalance',
                type: FormFieldType.Currency,
                props: {
                  label: 'Total outstanding credit card balance',
                  tooltip: 'Combined total of all outstanding credit cards',
                  required: true,
                },
              },
              {
                key: 'willRepayCreditCard',
                defaultValue: false,
                type: FormFieldType.RadioButtons,
                props: {
                  label: 'Is any of the balance to be repaid before completion?',
                  options: yesNoOptions,
                  required: true,
                },
                expressions: {
                  hide: field => field.model?.creditCardBalance.toString() === '0',
                },
              },
              {
                key: 'creditCardBalanceToRepay',
                type: FormFieldType.Currency,
                props: {
                  label: 'What amount is to be repaid?',
                  required: true,
                },
                expressions: {
                  hide: field =>
                    (field.model?.creditCardBalance ?? 0).toString() === '0' || !field.model?.willRepayCreditCard,
                },
                validators: {
                  validation: [FormsValidators.greaterThan(0)],
                },
              },
              {
                key: 'totalMonthlyCreditPayments',
                type: FormFieldType.Currency,
                props: {
                  label: 'Total monthly payments for all other credit commitments',
                  tooltip: 'Combined total of all outstanding credit commitments EXCLUDING credit cards',
                  required: true,
                },
              },
              {
                key: 'studentLoanContributions',
                type: FormFieldType.Currency,
                props: {
                  label: 'Monthly student loan contributions',
                  required: true,
                },
                expressions: {
                  hide: field => !this.helperService.isEmployed(field.model?.primaryIncome.employmentStatus),
                },
              },
              {
                key: 'pensionContributions',
                type: FormFieldType.Currency,
                props: {
                  label: 'Monthly pension contributions',
                  required: true,
                },
                expressions: {
                  hide: field => !this.helperService.isEmployed(field.model?.primaryIncome.employmentStatus),
                },
              },
              // Other
              {
                template: `
                  <hr class="my-0" />
                  <h2 class="fw-light mt-4 mb-3">Other expenditure (monthly)</h2>
                `,
              },
              {
                key: 'lifeAssuranceAndPrivateHealthPremiums',
                type: FormFieldType.Currency,
                defaultValue: 0,
                props: {
                  label: 'Life assurance/Private health premiums',
                  required: true,
                },
              },
              {
                key: 'travel',
                type: FormFieldType.Currency,
                defaultValue: 0,
                props: {
                  label: 'Travel',
                  tooltip: 'To work, to school, fuel, other transport',
                  required: true,
                },
              },
              {
                key: 'childcareAndSchoolCollegeUniversityFees',
                type: FormFieldType.Currency,
                defaultValue: 0,
                props: {
                  label: 'Child care/School/College/University fees',
                  required: true,
                },
              },
              {
                key: 'childMaintenance',
                type: FormFieldType.Currency,
                defaultValue: 0,
                props: {
                  label: 'Child Maintenance',
                  required: true,
                },
              },
              {
                key: 'carUpkeep',
                type: FormFieldType.Currency,
                defaultValue: 0,
                props: {
                  label: 'Car upkeep',
                  tooltip: 'Tax, MOT, Service, Insurance',
                  required: true,
                },
              },
              {
                key: 'personalEssentials',
                type: FormFieldType.Currency,
                defaultValue: 0,
                props: {
                  label: 'Personal essentials',
                  tooltip: 'Prescriptions, Health Care, Contact Lenses, Medical expenses',
                  required: true,
                },
              },
              {
                key: 'recreational',
                type: FormFieldType.Currency,
                defaultValue: 0,
                props: {
                  label: 'Recreational',
                  tooltip: 'Clothing, Toiletries, Restaurants, Entertainment, Holidays',
                  required: true,
                },
              },
              {
                key: 'other',
                type: FormFieldType.Currency,
                defaultValue: 0,
                props: {
                  label: 'Other',
                  tooltip: 'Any other expenses your applicant may have',
                  required: true,
                },
              },
              {
                wrappers: [WrapperType.NoWrappers],
                props: {
                  suppressErrors: true,
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) =>
                    (field.model as IUiApplicant).applicantNumber > 1 &&
                    (field.parent?.parent?.model as IUiApplicant[])[0].householdExpenditureSameForAllApplicants,
                },
                fieldGroup: [
                  {
                    template: `
                      <hr class="my-0" />
                      <h2 class="fw-light mt-4 mb-3">Household expenditure (monthly)</h2>
                    `,
                  },
                  {
                    key: 'householdExpenses',
                    type: FormFieldType.Currency,
                    defaultValue: 0,
                    props: {
                      label: 'Household expenses',
                      tooltip: 'Food, Drink, Alcohol, Tobacco, Household goods',
                      required: true,
                    },
                  },
                  {
                    key: 'gasAndElectricity',
                    type: FormFieldType.Currency,
                    defaultValue: 0,
                    props: {
                      label: 'Gas and Electricity',
                      required: true,
                    },
                  },
                  {
                    key: 'water',
                    type: FormFieldType.Currency,
                    defaultValue: 0,
                    props: {
                      label: 'Water',
                      required: true,
                    },
                  },
                  {
                    key: 'tvAndInternet',
                    type: FormFieldType.Currency,
                    defaultValue: 0,
                    props: {
                      label: 'TV and Internet',
                      required: true,
                    },
                  },
                  {
                    key: 'mobileAndLandlines',
                    type: FormFieldType.Currency,
                    defaultValue: 0,
                    props: {
                      label: 'Mobile and Landlines',
                      required: true,
                    },
                  },
                  {
                    key: 'councilTax',
                    type: FormFieldType.Currency,
                    defaultValue: 0,
                    props: {
                      label: 'Council Tax',
                      required: true,
                    },
                  },
                  {
                    key: 'buildingsAndContentsInsurance',
                    type: FormFieldType.Currency,
                    defaultValue: 0,
                    props: {
                      label: 'Buildings and Contents insurance',
                      required: true,
                    },
                  },
                  {
                    key: 'groundRentAndServiceCharge',
                    type: FormFieldType.Currency,
                    defaultValue: 0,
                    props: {
                      label: 'Ground rent/Service charge',
                      required: true,
                    },
                  },
                ],
              },
              {
                key: 'householdExpenditureSameForAllApplicants',
                type: FormFieldType.RadioButtons,
                defaultValue: true,
                props: {
                  label: 'Are these household expenses for all applicants in the application? (these will not ' +
                    'duplicate across applicants on selection of Yes)',
                  options: yesNoOptions,
                },
                expressions: {
                  hide: field =>
                    field.parent?.parent?.parent?.parent?.model?.applicants.numberOfApplicants === 1 ||
                    field.model?.applicantNumber > 1,
                },
              },
            ],
          },
        },
      ],
    },
    // Other mortgages
    {
      wrappers: [WrapperType.FormCard],
      props: {
        title: 'Other property commitments (all applicants)',
      },
      fieldGroup: [
        {
          key: 'otherMortgages',
          type: FormFieldType.Select,
          defaultValue: 0,
          props: {
            label: 'How many other mortgaged properties?',
            options: [...Array(11)].map((_, i) => ({ value: i, label: i.toString() } as IInputOption)),
            required: true,
            change: (field: FormlyFieldConfig) => {
              if (this.model) {

                if (this.model.mortgages === undefined) {
                  this.model.mortgages = [];
                }

                this.model.mortgages.length = +field.formControl?.value;
              }
              this.igniteService.updateModel(this.model);
            },
          },
        },
        {
          key: 'mortgages',
          type: FormFieldType.Repeating,
          props: {
            id: 'otherMortgage',
          },
          fieldArray: {
            wrappers: [WrapperType.NoWrappers],
            props: {
              label: 'Other mortgage',
            },
            validators: {
              mortgageBalance: {
                expression: (group: UntypedFormGroup) => this.helperService.canPaymentsCoverMortgageBalance(group),
                message: errorMessages.paymentsNotCoveringMortgageBalance,
                errorPath: 'monthlyMortgagePayments',
              },
            },
            fieldGroup: [
              {
                expressions: {
                  /* eslint-disable @typescript-eslint/dot-notation */
                  template: field => `
                    <hr class="my-0" />
                    <h2 class="font-roboto fw-light mt-4 mb-3">Other mortgage ${+field.parent?.['index'] + 1}</h2>
                  `,
                  /* eslint-enable @typescript-eslint/dot-notation */
                },
              },
              {
                key: 'propertyUse',
                type: FormFieldType.Select,
                defaultValue: null,
                props: {
                  label: 'Property use',
                  options: [
                    { value: PropertyUse.Rental, label: 'Let out/To be let out' },
                    { value: PropertyUse.OwnUse, label: 'Own use/For dependant' },
                  ],
                  required: true,
                },
              },
              {
                key: 'propertyValue',
                type: FormFieldType.Currency,
                props: {
                  label: 'Property value',
                  required: true,
                },
              },
              {
                key: 'totalMortgageBalance',
                type: FormFieldType.Currency,
                props: {
                  label: 'Total mortgage balance',
                  required: true,
                },
                validators: {
                  validation: [FormsValidators.greaterThan(0)],
                },
              },
              {
                key: 'repaymentMethod',
                type: FormFieldType.Select,
                defaultValue: RepaymentMethod.CapitalAndInterest,
                props: {
                  label: 'Method of repayment',
                  options: this.helperService.buildSelectOptionsFromEnum(RepaymentMethod),
                  required: true,
                },
              },
              {
                key: 'repaymentBalance',
                type: FormFieldType.Currency,
                defaultValue: 0,
                props: {
                  label: 'Repayment balance',
                },
                expressions: {
                  hide: field => field.model?.repaymentMethod !== RepaymentMethod.InterestOnlyPartAndPart,
                },
              },
              {
                key: 'interestOnlyBalance',
                type: FormFieldType.Currency,
                props: {
                  label: 'Interest only balance',
                },
                expressions: {
                  hide: field => field.model?.repaymentMethod !== RepaymentMethod.InterestOnlyPartAndPart,
                },
              },
              {
                key: 'remainingTermOfLoan',
                props: {
                  label: 'Remaining term of the loan',
                  required: true,
                },
                validators: {
                  ...this.helperService.yearMonthValidator,
                },
                fieldGroupClassName: 'row',
                fieldGroup: this.helperService.yearsAndMonthsFields(),
              },
              {
                key: 'monthlyMortgagePayments',
                type: FormFieldType.Currency,
                props: {
                  label: 'Monthly mortgage payments',
                  required: true,
                },
              },
              {
                key: 'monthlyUtilities',
                type: FormFieldType.Currency,
                props: {
                  label: 'Monthly utilities',
                  required: true,
                },
                expressions: {
                  hide: field => field.model?.propertyUse !== PropertyUse.OwnUse,
                },
              },
              {
                key: 'monthlyCouncilTax',
                type: FormFieldType.Currency,
                props: {
                  label: 'Monthly council tax',
                  required: true,
                },
                expressions: {
                  hide: field => field.model?.propertyUse !== PropertyUse.OwnUse,
                },
              },
              {
                key: 'interestRate',
                type: FormFieldType.Number,
                defaultValue: 0,
                validators: {
                  validation: [FormsValidators.greaterThan(0), FormsValidators.percentage],
                },
                props: {
                  label: 'Interest rate',
                  min: 0,
                  max: 100,
                  step: 0.01,
                  addonEnd: '%',
                  allowDecimal: true,
                },
              },
              {
                key: 'tenancyInPlace',
                type: FormFieldType.RadioButtons,
                defaultValue: true,
                props: {
                  label: 'Is there a tenancy in place',
                  options: yesNoOptions,
                  required: true,
                },
                expressions: {
                  hide: field => field.model?.propertyUse !== PropertyUse.Rental,
                },
              },
              {
                key: 'grossMonthlyRental',
                type: FormFieldType.Currency,
                props: {
                  label: 'Gross monthly rental',
                  required: true,
                },
                expressions: {
                  hide: field => field.model?.propertyUse !== PropertyUse.Rental,
                },
              },
            ],
          },
        },

        {
          key: 'proceedBtn',
          type: 'buttons',
          props: {
            buttons: [
              { label: 'Proceed', type: 'submit', className: 'btn-2022--secondary' },
            ],
          },
          expressions: {
            // Hide button user clicks on email link from affordability
            hide: ()=> this.authService.historyView && this.igniteService.resultsView === ResultsView.Input,
          },
        },
      ],
    },
  ];

  public get benefitIncomeCustomOptions(): IInputOption[] {
    return [
      this.helperService.defaultSelectOption,
      { value: BenefitIncomeType.ChildBenefit, label: 'Child benefit' },
      { value: BenefitIncomeType.ChildTaxCredits, label: 'Child tax credits' },
      { value: BenefitIncomeType.WorkingTaxCredits, label: 'Working tax credits' },
      { value: BenefitIncomeType.CarersAllowance, label: 'Carers allowance' },
      { value: BenefitIncomeType.DlaOrPip, label: 'DLA or PIP' },
      { value: BenefitIncomeType.UniversalCredit, label: 'Universal credit' },
    ];
  }

  private mortgageLenderFields(): FormlyFieldConfig[] {
    const mortgageLenderHidden = (field) => {
      return field?.model?.mortgageType !== MortgageType.Remortgage
        && field?.model?.mortgageType !== MortgageType.Purchase;
    };

    const initialPeriodHidden = (field) => field?.model?.mortgageType !== MortgageType.Remortgage;

    return [
      {
        key: 'mortgageLender',
        wrappers: [WrapperType.NoWrappers],
        props: {
          suppressErrors: true,
        },
        fieldGroup: [
          {
            key: 'value',
            type: FormFieldType.Select,
            props: {
              label: 'Who is the current mortgage Lender?',
              allowNull: true,
            },
            expressions: {
              hide: field => mortgageLenderHidden(field.parent?.parent),
              'props.options': this.lenderService.getUnarchivedLendersNames(this.helperService.getResLendingType())
                .pipe(map(lenderNames => this.helperService.getMortgageLendersList(lenderNames))),
            },
          },
        ],
      },
      {
        key: 'currentMortgageLender',
        type: FormFieldType.Input,
        expressions: {
          hide: field => {
            if (mortgageLenderHidden(field)) {
              return true;
            }

            const mortgageLenderValue = field.model?.mortgageLender?.value;
            return !mortgageLenderValue || mortgageLenderValue !== MortgageLenders.Other;
          },
        },
      },
      {
        key: 'productTransferInitialDate',
        type: FormFieldType.Date,
        props: {
          label: 'What is the end date for the Initial period?',
        },
        validators: {
          validation: [FormsValidators.dateOrEmpty],
        },
        expressions: {
          hide: field => {
            if (initialPeriodHidden(field)) {
              return true;
            }

            return field.model?.mortgageLender?.value === MortgageLenders.Other;
          },
        },
      },
    ];
  }

  public capitalRaisingFields(): FormlyFieldConfig[] {
    return [
      {
        key: 'capitalRaising',
        wrappers: [WrapperType.NoWrappers],
        expressions: {
          hide: (field: FormlyFieldConfig) =>
            field.parent?.model?.mortgageType !== MortgageType.Remortgage,
        },
        fieldGroup: [
          {
            key: 'value',
            type: FormFieldType.RadioButtons,
            props: {
              label: 'Capital raising',
              tooltip: 'Are there any additional funds being taken as part of the Remortgage?',
              options: yesNoOptions,
              required: true,
            },
          },
        ],
      },
    ];
  }

  private onNumberOfApplicantsChange(numberOfApplicants: number, numberOfIncomesNeeded: number) {
    if (!this.model) {
      this.igniteService.updateModel();
    }
    if (this.model && (this.model?.applicants.applicantAges?.length ?? 0) > numberOfApplicants) {
      this.model.applicants.applicantAges = this.model.applicants.applicantAges?.slice(0, numberOfApplicants);
      ((this.igniteService.form.controls.applicants as FormGroup).controls.applicantAges as FormArray).controls =
        ((this.igniteService.form.controls.applicants as FormGroup)
          .controls.applicantAges as FormArray).controls.slice(0, numberOfApplicants);
    }
    if (
      this.model?.incomeAndExpenditure.applicants &&
      this.model.incomeAndExpenditure.applicants.length > numberOfIncomesNeeded
    ) {
      this.model.incomeAndExpenditure.applicants =
        this.model.incomeAndExpenditure.applicants?.slice(0, numberOfIncomesNeeded);
    }

    if (
      this.model?.applicants.applicantAges &&
      this.model.incomeAndExpenditure.applicants
    ) {
      while (this.model.applicants.applicantAges.length < numberOfApplicants) {
        this.igniteService.addApplicant();
      }
      while (this.model.incomeAndExpenditure.applicants.length < numberOfIncomesNeeded) {
        this.igniteService.addApplicantIncome();
      }
    }

    this.igniteService.updateModel();
  }

  private hideNetProfitFields(model: IUiMainIncome) {
    if (!model) {
      return true;
    }
    const { employmentStatus, contractType, treatedAsEmployee } = model;
    return (
      employmentStatus !== EmploymentStatus.SelfEmployed &&
      (employmentStatus !== EmploymentStatus.Employed ||
        treatedAsEmployee !== false ||
        (contractType !== ContractType.SubContractorFtc && contractType !== ContractType.SubContractorOpen))
    );
  }

  private hideSalaryDividendsAndNetProfitFields(model: IUiMainIncome) {
    if (!model) {
      return true;
    }
    const { employmentStatus } = model;

    return employmentStatus !== EmploymentStatus.DirectorOrShareholder;
  }

  private optionAddHandler(model, field, title, optionLabel, options) {
    this.modalService
      .open({
        title,
        component: AddOptionModalComponent,
        data: {
          model,
          field,
          optionLabel,
          options,
        },
      })
      .then(
        () => {
          this.igniteService.updateModel();
        },
        () => null,
      );
  }

  private removeOptionItem(field: FormlyFieldConfig) {
    const parentModel = field.parent?.parent?.parent?.model;
    this.removeCategory(field.model?.category, parentModel);
    this.igniteService.updateModel();
  }

  private removeCategory(category, parentModel) {
    const categories = parentModel.map((c) => c.option.category);
    const index = categories.indexOf(category);
    if (index > -1) {
      parentModel.splice(index, 1);
    }
  }

  private setAllAdditionalIncome(employmentStatus: EmploymentStatus, additionalIncome: boolean, model: IUiApplicant) {
    if (this.helperService.isEmployed(employmentStatus) && additionalIncome) {
      defaultWorkRelatedIncomeTypes
        .slice()
        .reverse();
    } else {
      model.workAdditionalIncome = model?.workAdditionalIncome?.filter(
        (x) => !defaultWorkRelatedIncomeTypes.includes(x.option.category),
      );
    }

    this.igniteService.updateModel();
  }

  private getAdditionalIncomeFields<ModelType extends IUiBaseIncome>(
    title: string,
    addText: string,
    key: string,
    enumValue: any,
    defaultOptions: UiBaseIncomeEnumType[] | null = null,
    tooltip: string | null = null,
    customOptions: IInputOption[] | null = null,
    isWorkRelatedIncome = false,
    headerTooltip: string | null = null,
  ): FormlyFieldConfig[] {
    defaultOptions = defaultOptions ?? [];
    const fields: FormlyFieldConfig[] = [
      {
        type: FormFieldType.Template,
        wrappers: [WrapperType.NoWrappers],
        props: {
          tooltip: headerTooltip,
          addText: title,
        },
        expressions: {
          hide: (field: FormlyFieldConfig) => !field.parent?.model?.additionalIncome,
          'props.template': () => `
            <hr class="mt-4" />
            <h2 class="fw-light mt-4">${title}</h2>
          `,
        },
      },
    ];

    if (isWorkRelatedIncome) {
      const bonusCommissionOvertimeFields:
        FormlyFieldConfig<FormlyFieldProps & Record<string, unknown>>[] = [];
      defaultWorkRelatedIncomeTypes.forEach((category: WorkRelatedIncomeType) => {
        const amountsFieldKey = `${category}Amounts`;
        const categoryField: FormlyFieldConfig = {
          key: category,
          defaultValue: null,
          type: FormFieldType.Select,
          expressions: {
            hide: (field: FormlyFieldConfig) => !field.parent?.model?.additionalIncome,
          },
          props: {
            label: this.helperService.getEnumLabel(category, WorkRelatedIncomeType),
            options: this.helperService.buildSelectOptionsFromEnum(Frequency),
            change: (field: FormlyFieldConfig, event) => {
              const frequency: Frequency = this.helperService.getEnumValue(event.target.value);

              const fieldLength = this.workIncomeMainFieldLabel[frequency].length;

              if (!field.model[amountsFieldKey]) {
                field.model[amountsFieldKey] = [];
              }
              field.model[amountsFieldKey].length = fieldLength;
              this.igniteService.updateModel(this.model);
            },
          },
        };

        const categoryAmountFields: FormlyFieldConfig = {
          key: amountsFieldKey,
          type: FormFieldType.Repeating,
          expressions: {
            hide: (field) => !field.parent?.model?.additionalIncome,
          },
          fieldArray: {
            key: 'amount',
            type: FormFieldType.Currency,
            props: {
              required: true,
            },
            expressions: {
              'props.label': (field: FormlyFieldConfig) => {
                return this.workIncomeMainFieldLabel[field.parent?.parent?.model[category]][field.key];
              },
            },
          },
        };

        bonusCommissionOvertimeFields.push(categoryField);
        bonusCommissionOvertimeFields.push(categoryAmountFields);
      });

      fields.push(...bonusCommissionOvertimeFields);
    }

    fields.push(
      ...[
        {
          key,
          type: FormFieldType.Repeating,
          expressions: {
            hide: (field: FormlyFieldConfig) =>
              !field.parent?.model?.additionalIncome,
          },
          props: { tooltip },
          fieldArray: {
            wrappers: [WrapperType.NoWrappers],
            fieldGroup: this.additionalIncomeFields(true, enumValue, customOptions),
          },
        } as FormlyFieldConfig,
        {
          key: key + 'Btn',
          type: FormFieldType.Buttons,
          props: {
            numColumns: 1,
            tooltip: tooltip,
            addText,
            buttons: [
              {
                label: addText,
                className: 'btn-2022--secondary',
                onClick: (field: FormlyFieldConfig) => {
                  if (!field.model[key]) {
                    field.model[key] = [];
                  }
                  this.optionAddHandler(
                    field.model[key],
                    field,
                    addText,
                    'Income type',
                    (
                      this.getWorkRelatedOptions(
                        isWorkRelatedIncome, field.parent?.model.primaryIncome?.employmentStatus)
                      ?? customOptions
                      ?? this.helperService.buildSelectOptionsFromEnum(enumValue)
                    )
                      .filter((option: IInputOption) => !defaultOptions?.includes(option.value))
                      .filter((option: IInputOption) => field.model[key]
                        ? field.model[key]?.every((m: { option: ModelType }) => m.option.category !== option.value)
                        : true),
                  );
                },
              },
            ],
          },
          expressions: {
            hide: (field: FormlyFieldConfig) =>
              !field.parent?.model?.additionalIncome,
          },
        } as FormlyFieldConfig,
      ],
    );

    return fields;
  }

  public additionalIncomeFields<ModelType extends IUiBaseIncome>(
    showAllFields: boolean,
    enumValue: unknown,
    customOptions: IInputOption[] | null = null,
  ): FormlyFieldConfig[] {
    return [
      {
        key: 'option',
        fieldGroupClassName: 'row align-items-center minus-margin',
        fieldGroup: [
          {
            type: FormFieldType.AdditionalIncomeHeader,
            props: {
              button: {
                className: 'link',
                iconClass: 'fas fa-trash-alt text-danger',
                onClick: this.removeOptionItem.bind(this),
              },
            },
            expressions: {
              hide: () => !showAllFields,
              'props.headerLabel': (field) =>
                this.getAdditionalIncomeLabel(customOptions, field.model as ModelType, enumValue),
            },
          },
          {
            key: 'category',
            type: FormFieldType.Input,
            className: 'd-none',
            props: {
              inputType: 'hidden',
            },
            hide: !showAllFields,
          },
          {
            key: 'amount',
            type: FormFieldType.Currency,
            className: 'col-md-6 mb-3',
            props: {
              required: true,
              label: 'Amount',
            },
            hide: !showAllFields,
          },
          {
            key: 'frequency',
            className: 'col-md-6 mb-3',
            type: FormFieldType.Text,
            props: {
              label: 'Payment frequency',
            },
            expressions: {
              hide: () => !showAllFields,
              'props.disabled': () => {
                return !showAllFields;
              },
              'props.value': (field) =>
                this.helperService.getEnumLabel(field.model.frequency, Frequency),
            },
          },
          {
            key: 'frequency',
            type: FormFieldType.Input,
            className: 'd-none',
            props: {
              inputType: 'hidden',
            },
            expressions: {
              hide: () => !showAllFields,
            },
          },
        ],
      },
    ];
  }

  private getAdditionalIncomeLabel(customOptions: IInputOption[] | null, model: IUiBaseIncome, enumValue: unknown) {
    return customOptions
      ? customOptions.find((x) => x.value === model?.category)?.label
      : this.helperService.getEnumLabel(model?.category, enumValue);
  }

  private getWorkRelatedOptions(
    isWorkRelatedIncome: boolean, employmentStatus: EmploymentStatus,
  ) {
    if (!isWorkRelatedIncome) {
      return null;
    }

    switch (employmentStatus) {
      case EmploymentStatus.SelfEmployed:
      case EmploymentStatus.DirectorOrShareholder:
      case EmploymentStatus.Homemaker:
      case EmploymentStatus.Retired:
      case EmploymentStatus.Unemployed:
        return [
          this.helperService.defaultSelectOption,
          {
            value: WorkRelatedIncomeType.FosterIncome,
            label: 'Foster income',
          },
        ];
      case EmploymentStatus.Student:
        return [
          this.helperService.defaultSelectOption,
          { value: WorkRelatedIncomeType.Bursary, label: 'Bursary' },
          { value: WorkRelatedIncomeType.Stipend, label: 'Stipend' },
        ];
    }
  }

  private primaryIncomeFields(key: string, isPrimaryIncome: boolean): FormlyFieldConfig {
    const employmentStatusOptions = [
      this.helperService.defaultSelectOption,
      { value: EmploymentStatus.Employed, label: 'Employed' },
      { value: EmploymentStatus.SelfEmployed, label: 'Self employed (sole trader/partner)' },
      { value: EmploymentStatus.DirectorOrShareholder, label: 'Director/Shareholder' },
    ];

    if (isPrimaryIncome) {
      employmentStatusOptions.push(
        { value: EmploymentStatus.Homemaker, label: 'Homemaker' },
        { value: EmploymentStatus.Retired, label: 'Retired' },
        { value: EmploymentStatus.Student, label: 'Student' },
        { value: EmploymentStatus.Unemployed, label: 'Unemployed' },
      );
    }

    const incomeSectionTestId = isPrimaryIncome ? 'primaryIncomeSection' : 'secondaryIncomeSection';

    return {
      key,
      wrappers: [WrapperType.EmptyWrapper],
      props: {
        fullWidth: true,
        suppressErrors: true,
        testId: incomeSectionTestId,
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => {
          return !isPrimaryIncome && !field.form?.getRawValue().secondJob;
        },
      },
      validators: {
        grossSalaryForLatestPeriod: {
          ...this.helperService.salaryAndDividendsGreaterThanZero(),
          errorPath: 'grossSalaryForLatestPeriod',
        },
        grossDividendsForLatestPeriod: {
          ...this.helperService.salaryAndDividendsGreaterThanZero(),
          errorPath: 'grossDividendsForLatestPeriod',
        },
        netProfitBeforeDividendsForLatestPeriod: {
          ...this.helperService.salaryAndDividendsGreaterThanZero(),
          errorPath: 'netProfitBeforeDividendsForLatestPeriod',
        },
        greaterThanZeroShareholder: {
          ...this.helperService.salaryAndDividendsGreaterThanZero(),
          errorPath: 'grossAnnualIncome',
        },
      },
      fieldGroup: [
        {
          key: 'employmentStatus',
          type: FormFieldType.Select,
          defaultValue: null,
          props: {
            label: 'Employment status',
            options: employmentStatusOptions,
            change: (field: FormlyFieldConfig, event) => {
              const employmentStatus = this.helperService.getEnumValue(event.target.value) as EmploymentStatus;
              if (employmentStatus === EmploymentStatus.Employed) {
                (field.parent?.formControl as UntypedFormGroup).controls.contractType?.setValue(ContractType.Permanent);
              }

              if (isPrimaryIncome) {
                (field.form?.parent as UntypedFormGroup).patchValue({
                  additionalIncome: this.helperService.isUnemployed(employmentStatus),
                });

                this.setAllAdditionalIncome(
                  employmentStatus,
                  field.parent?.model?.additionalIncome,
                  field.parent?.parent?.model as IUiApplicant,
                );
              }
            },
            required: true,
          },
        },
        {
          key: 'shareholding',
          type: FormFieldType.Number,
          props: {
            label: '% Shareholding',
            min: 0,
            max: 100,
            addonEnd: '%',
          },
          expressions: {
            hide: field => field.model?.employmentStatus !== EmploymentStatus.DirectorOrShareholder,
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
        },
        {
          key: 'contractType',
          type: FormFieldType.Select,
          defaultValue: ContractType.Permanent,
          props: {
            label: 'Contract type',
            options: [
              this.helperService.defaultSelectOption,
              { value: ContractType.FixedTerm, label: 'Fixed term' },
              { value: ContractType.Permanent, label: 'Permanent' },
              { value: ContractType.SubContractorFtc, label: 'Sub-contractor (FTC)' },
              { value: ContractType.SubContractorOpen, label: 'Sub-contractor (open)' },
              { value: ContractType.Temporary, label: 'Temporary' },
            ],
            required: true,
          },
          expressions: {
            hide: (field) => (field.model as IUiMainIncome)?.employmentStatus !== EmploymentStatus.Employed,
          },
        },
        {
          key: 'treatedAsEmployee',
          type: FormFieldType.RadioButtons,
          props: {
            label: 'Treated as an employee for tax purposes',
            tooltip: 'Is the applicant paid via PAYE?',
            options: yesNoOptions,
          },
          expressions: {
            hide: field =>
              field.model?.employmentStatus !== EmploymentStatus.Employed ||
              (
                field.model?.contractType !== ContractType.SubContractorFtc &&
                field.model?.contractType !== ContractType.SubContractorOpen
              ),
          },
        },
        {
          key: 'timeInCurrentJobMonths',
          props: {
            required: true,
          },
          validators: {
            ...this.helperService.yearMonthValidator,
          },
          fieldGroupClassName: 'row',
          fieldGroup: this.helperService.yearsAndMonthsFields(),
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              if (!field.parent?.model) {
                return;
              }
              const { employmentStatus } = field.parent?.model;
              return !this.helperService.isEmployed(employmentStatus);
            },
            'props.label': (field: FormlyFieldConfig) => {
              if (field.parent?.model) {
                if (!field.parent.model) {
                  return;
                }
                const { employmentStatus, contractType } = field.parent.model;
                switch (employmentStatus) {
                  case EmploymentStatus.Employed:
                    switch (contractType) {
                      case ContractType.Permanent:
                      case ContractType.FixedTerm:
                      case ContractType.SubContractorOpen:
                        return 'Length of time in current job';
                      case ContractType.SubContractorFtc:
                        return 'Length of time as a contractor';
                      case ContractType.Temporary:
                        return 'Length of time in regular employment';
                    }
                    break;
                  case EmploymentStatus.DirectorOrShareholder:
                  case EmploymentStatus.SelfEmployed:
                    return 'Length of time in business';
                }
              }

              return 'Length of time in current job';
            },
          },
        },
        {
          key: 'timeRemainingOnContractMonths',
          props: {
            label: 'Length of time remaining on contract',
            required: true,
          },
          validators: {
            ...this.helperService.yearMonthValidator,
          },
          fieldGroupClassName: 'row',
          fieldGroup: this.helperService.yearsAndMonthsFields(),
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              if (!field.parent?.model) {
                return;
              }
              const { employmentStatus, contractType } = field.parent.model;
              return (
                employmentStatus !== EmploymentStatus.Employed ||
                (contractType !== ContractType.FixedTerm && contractType !== ContractType.SubContractorFtc)
              );
            },
          },
        },
        {
          template:
            '<h2 class="fw-light">Please complete all income that is applicable to your applicant</h2>',
          expressions: {
            hide: field => field.model?.employmentStatus !== EmploymentStatus.DirectorOrShareholder,
          },
        },
        {
          key: 'grossAnnualIncome',
          type: FormFieldType.Currency,
          props: {
            label: 'Gross annual income',
            tooltip: 'Gross income before any tax deductions if classed as an employee',
          },
          expressions: {
            hide: field => {
              if (!field.parent?.model) {
                return;
              }
              const { employmentStatus, contractType, treatedAsEmployee } = field.parent.model;
              if (employmentStatus === EmploymentStatus.SelfEmployed ||
                employmentStatus === EmploymentStatus.Homemaker ||
                employmentStatus === EmploymentStatus.Retired ||
                employmentStatus === EmploymentStatus.Student ||
                employmentStatus === EmploymentStatus.Unemployed) {
                return true;
              }
              return (
                employmentStatus !== EmploymentStatus.DirectorOrShareholder &&
                (
                  employmentStatus !== EmploymentStatus.Employed ||
                  (
                    contractType !== ContractType.Permanent &&
                    contractType !== ContractType.FixedTerm &&
                    contractType !== ContractType.Temporary
                  )
                ) &&
                (
                  employmentStatus !== EmploymentStatus.Employed ||
                  treatedAsEmployee === false ||
                  (
                    contractType !== ContractType.SubContractorFtc &&
                    contractType !== ContractType.SubContractorOpen
                  )
                )
              );
            },
            'props.disabled': (field) => {
              const model = (field.model as IUiMainIncome);
              if (model) {
                return (model?.employmentStatus === EmploymentStatus.DirectorOrShareholder &&
                  (
                    ![0, NaN].includes(+(model?.grossSalaryForLatestPeriod ?? 0)) ||
                    ![0, NaN].includes(+(model?.grossSalaryForPreviousPeriod ?? 0)) ||
                    ![0, NaN].includes(+(model?.grossSalaryForThirdPeriod ?? 0)) ||
                    ![0, NaN].includes(+(model?.netProfitBeforeDividendsForLatestPeriod ?? 0)) ||
                    ![0, NaN].includes(+(model?.netProfitBeforeDividendsForPreviousPeriod ?? 0)) ||
                    ![0, NaN].includes(+(model?.netProfitBeforeDividendsForThirdPeriod ?? 0))
                  ));
              }
            },
          },
          validators: {
            greaterThanZeroNotShareholder: {
              expression: (_control: AbstractControl, field: FormlyFieldConfig) => {
                const grossAnnualIncome = _control.value;
                const {
                  employmentStatus,
                } = field.form?.getRawValue() as IUiMainIncome;
                return grossAnnualIncome > 0 || employmentStatus === EmploymentStatus.DirectorOrShareholder;
              },
              message: 'Gross Annual Income should be greater than 0',
            },
          },
        },
        {
          key: 'netProfitForLatestPeriod',
          type: FormFieldType.Currency,
          props: {
            label: 'Net profit for latest period',
            tooltip: 'Figure after tax deductions for the latest accounts',
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
          expressions: {
            hide: field => this.hideNetProfitFields(field.model),
          },
        },
        {
          key: 'netProfitForPreviousPeriod',
          type: FormFieldType.Currency,
          props: {
            label: 'Net profit period 2',
            tooltip: 'Figure after tax deductions for the penultimate year\'s accounts',
            required: true,
          },
          expressions: {
            hide: field => this.hideNetProfitFields(field.model),
          },
        },
        {
          key: 'netProfitForThirdPeriod',
          type: FormFieldType.Currency,
          props: {
            label: 'Net profit period 3',
            tooltip: 'Figure after tax deductions for the 3rd last year\'s accounts',
            required: true,
          },
          expressions: {
            hide: field => this.hideNetProfitFields(field.model),
          },
        },
        {
          props: {
            label: 'Director salary for latest period',
            suppressErrors: true,
          },
          key: 'grossSalaryForLatestPeriod',
          type: FormFieldType.CurrencyAddMulti,
          wrappers: [WrapperType.NoWrappers],
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model),
            'props.button': () =>
              ({
                label: `${this.formStateService.hideGrossSalaryPreviousFields ? 'Add' : 'Hide'} previous years`,
                className: 'btn-2022--secondary',
                onClick: () => this.formStateService.toggleExpandedField('hideGrossSalaryPreviousFields'),
              }),
            'props.disabled': (field) => {
              if (field.model) {
                return (field.model as IUiMainIncome).employmentStatus === EmploymentStatus.DirectorOrShareholder &&
                  ![0, NaN].includes(+(field.model as IUiMainIncome).grossAnnualIncome);
              }
            },
          },
        },
        {
          key: 'grossSalaryForPreviousPeriod',
          type: FormFieldType.Currency,
          props: {
            label: 'Director salary period 2',
          },
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model)
              || this.formStateService.hideGrossSalaryPreviousFields
              || ![0, NaN].includes(+field.model?.grossAnnualIncome),
          },
        },
        {
          key: 'grossSalaryForThirdPeriod',
          type: FormFieldType.Currency,
          props: {
            label: 'Director salary period 3',
          },
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model)
              || this.formStateService.hideGrossSalaryPreviousFields
              || ![0, NaN].includes(+field.model?.grossAnnualIncome),
          },
        },
        {
          props: {
            label: 'Gross dividends for latest period',
            suppressErrors: true,
          },
          key: 'grossDividendsForLatestPeriod',
          type: FormFieldType.CurrencyAddMulti,
          wrappers: [WrapperType.NoWrappers],
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model),
            'props.button': () =>
              ({
                label: `${this.formStateService.hideGrossDividendsPreviousFields ? 'Add' : 'Hide'} previous years`,
                className: 'btn-2022--secondary',
                onClick: () => this.formStateService.toggleExpandedField('hideGrossDividendsPreviousFields'),
              }),
          },
        },
        {
          key: 'grossDividendsForPreviousPeriod',
          type: FormFieldType.Currency,
          props: {
            label: 'Gross dividends period 2',
            required: true,
          },
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model) ||
              this.formStateService.hideGrossDividendsPreviousFields,
          },
        },
        {
          key: 'grossDividendsForThirdPeriod',
          type: FormFieldType.Currency,
          props: {
            label: 'Gross dividends period 3',
            required: true,
          },
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model) ||
              this.formStateService.hideGrossDividendsPreviousFields,
          },
        },
        {
          props: {
            label: 'Net profit before dividends for latest period',
            suppressErrors: true,
          },
          key: 'netProfitBeforeDividendsForLatestPeriod',
          type: FormFieldType.CurrencyAddMulti,
          wrappers: [WrapperType.NoWrappers],
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model),
            'props.button': () =>
              ({
                label: `${this.formStateService.hideNetProfitBeforeDividendsPreviousFields ? 'Add' : 'Hide'}
                    previous years`,
                className: 'btn-2022--secondary',
                onClick: () => {
                  this.formStateService.toggleExpandedField('hideNetProfitBeforeDividendsPreviousFields');
                },
              }),
            'props.disabled': (field) => {
              if (field.model) {
                return (field.model as IUiMainIncome).employmentStatus === EmploymentStatus.DirectorOrShareholder &&
                  ![0, NaN].includes(+(field.model as IUiMainIncome).grossAnnualIncome);
              }
            },
          },
        },
        {
          key: 'netProfitBeforeDividendsForPreviousPeriod',
          type: FormFieldType.Currency,
          props: {
            label: 'Net profit before dividends period 2',
            required: true,
          },
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model) ||
              this.formStateService.hideNetProfitBeforeDividendsPreviousFields,
          },
        },
        {
          key: 'netProfitBeforeDividendsForThirdPeriod',
          type: FormFieldType.Currency,
          props: {
            label: 'Net profit before dividends period 3',
            required: true,
          },
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model) ||
              this.formStateService.hideNetProfitBeforeDividendsPreviousFields,
          },
        },
      ],
    };
  }

  private getNumberOfIncomesNeededOptions(numberOfApplicants: number) {
    return [
      this.helperService.defaultSelectOption,
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      ...(numberOfApplicants === 4 ? [{ value: 4, label: '4' }] : []),
    ] as IInputOption[];
  }

  private get workIncomeMainFieldLabel() {
    return {
      [Frequency.Annually]: ['Latest year amount', 'Year 2 amount'],
      [Frequency.HalfYearly]: ['Latest period amount', 'Period 2 amount'],
      [Frequency.Quarterly]: ['Latest quarter amount', 'Quarter 2 amount', 'Quarter 3 amount', 'Quarter 4 amount'],
      [Frequency.Monthly]: ['Latest month amount', 'Month 2 amount', 'Month 3 amount'],
      [Frequency.FourWeekly]: ['Latest period amount', 'Period 2 amount', 'Period 3 amount'],
      [Frequency.Fortnightly]: [
        'Latest fortnight amount',
        'Fortnight 2 amount',
        'Fortnight 3 amount',
        'Fortnight 4 amount',
        'Fortnight 5 amount',
        'Fortnight 6 amount',
      ],
      [Frequency.Weekly]: [
        'Latest week amount',
        'Week 2 amount',
        'Week 3 amount',
        'Week 4 amount',
        'Week 5 amount',
        'Week 6 amount',
        'Week 7 amount',
        'Week 8 amount',
        'Week 9 amount',
        'Week 10 amount',
        'Week 11 amount',
        'Week 12 amount',
      ],
    };
  }

  private isHelpToBuyHidden(field: FormlyFieldConfig): boolean {
    const mortgageType = field.model?.mortgageType;
    const location = field.model?.location;
    return mortgageType !== MortgageType.Remortgage
      && (mortgageType !== MortgageType.Purchase || location !==  UkLocation.Wales);
  }

  private isHelpToBuySelected(field: FormlyFieldConfig | undefined) {
    if (!field) {
      return false;
    }

    const helpToBuy = !!field.model?.helpToBuy;
    return !this.isHelpToBuyHidden(field) && helpToBuy;
  }
}
