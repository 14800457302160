import { Component, Input } from '@angular/core';
import { Availability, Product } from 'apps/shared/src/models';
import { ProductsSearchService } from 'apps/clubhub/src/app/ignite/services';

@Component({
  selector: 'lib-type-product-column',
  templateUrl: 'type-product-column.component.html',
  styleUrls: ['type-product-column.component.scss'],
})
export class TypeProductColumnComponent {
  @Input() public product: Product;
  @Input() public useProductTransferAvailability: boolean;

  public constructor(private productsSearchService: ProductsSearchService) { }

  public get isProductTransfer() {
    if (!this.productsSearchService.hasProductTransfer) {
      return false;
    }

    if (this.useProductTransferAvailability) {
      return this.product.productTransfer === Availability.AlsoAvailable
        || this.product.productTransfer === Availability.OnlyAvailable;
    }

    return this.product.productTransferEligible;
  }
}
