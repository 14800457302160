import { EventEmitter, Inject, Injectable } from '@angular/core';
import { LendingTypeService } from '@msslib/services/lending-type.service';
import {
  BridgingProduct,
  LendingTypeCode,
  MatchedLenderProducts,
  Product,
} from 'apps/shared/src/models';
import {
  FilterOptionsContext,
  ProductFilterDefinition,
  ProductFilterGroupDefinition,
  ProductsFilterContext,
  ProductsFilterHelper,
} from './products-filter/products-filter.helper';

export interface ProductFilter {
  title: string;
  checked: boolean;
  included: boolean;
  excluded: boolean;
  isCriteriaFilter: boolean;
  subFilters?: {
    items: ProductFilterDefinition<Product | BridgingProduct>[];
    checkedItems: Set<string>;
  };
  hasSubFilters?: boolean;
}

export type ProductFilterGroup = Readonly<{
  title: string;
  items: readonly ProductFilter[];
  disabled: boolean;
  isIncludeExcludeFilter?: boolean;
  groupInfoText?: string;
}>;

@Injectable({
  providedIn: 'root',
})
export class ProductsFilterService {

  public filterChanged = new EventEmitter<boolean>();
  private checkedFilters: Map<string, Set<string>>;
  private includeExcludeGroupsFilters: Map<string, any> = new Map<string, unknown>;
  private availableFiltersCache = new Map<string, boolean | undefined>();
  private isPristine = true;

  private productFilterDefinitions: ProductFilterGroupDefinition<Product | BridgingProduct>[];
  private isBridgingType: boolean;
  public productsFilterContext: ProductsFilterContext | undefined;

  public constructor(
    @Inject(LendingTypeService) private lendingTypeService,
    private productsFilterHelper: ProductsFilterHelper,
  ) {
    this.setProductFilterDefinitions();
  }

  public setProductFilterDefinitions(): void {
    const lendingTypeCode = this.lendingTypeService.lendingType?.code?.replace(/ /g, '').toLowerCase();
    this.isBridgingType = lendingTypeCode === LendingTypeCode.Bdg.toLowerCase();

    if (this.isBridgingType) {
      this.productFilterDefinitions = this.productsFilterHelper.bridgingProductFilterDefinitions;
    } else {
      this.productFilterDefinitions =
        this.productsFilterHelper.resAndBtlProductFilterDefinitions();
    }

    this.checkedFilters = new Map(this.productFilterDefinitions.map(g => [g.title, new Set()]));
  }

  private getIncludeExcludeFilterContexts() {
    if (this.includeExcludeGroupsFilters?.size > 0) {
      const filtersContexts = [...this.includeExcludeGroupsFilters.keys()]
        .map(key => {
          const group = this.productFilterDefinitions.find(i => i.title === key);
          const includeExcludeGroupFilters = this.includeExcludeGroupsFilters.get(key);
          return {
            group,
            includedItems: [...includeExcludeGroupFilters.includedFilterDefs.keys()],
            excludedItems: [...includeExcludeGroupFilters.excludedFilterDefs.keys()],
          };
        });
      return filtersContexts;
    }

    return [];
  }

  /** Gets all 'active' filters, including those that have been overwritten to be active */
  private getActiveFilters(ctx: FilterOptionsContext<Product | BridgingProduct>, includeOverridden: boolean) {
    return this.productFilterDefinitions
      .map(group => ({
        group,
        items: 'items' in group
          ? group.items
            .filter(i =>
              (includeOverridden ? i.overrideChecked?.(ctx) : null)
              ?? this.checkedFilters.get(group.title)?.has(i.title))
            .map(i => i.title)
          : this.checkedFilters.get(group.title)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ? [...this.checkedFilters.get(group.title)!]
            : [],
      }));
  }

  public hasActiveIncludeFilter(group: string, name: string): boolean {
    return this.includeExcludeGroupsFilters.get(group)?.includedFilterDefs?.has(name);
  }

  /** Gets an object for the backend to replicate the selected filters. */
  public getSelectedFilterNames(context: FilterOptionsContext<Product | BridgingProduct>): Record<string, string[]> {
    context ??= {} as any;
    const obj = {};
    this.getActiveFilters(context, true).forEach(({ group, items }) => {
      obj[group.requestObjKey] = [...(obj[group.requestObjKey] ?? []), ...items];
    });

    const filterOptions = this.getFilterOptions(context);
    const includeExcludeGroupsFilter = this.getIncludeExcludeFilterContexts();
    includeExcludeGroupsFilter.forEach(({group, includedItems, excludedItems}) => {
      if (!group) {
        return;
      }

      const includedFieldName = `${group.requestObjKey}Included`;
      const excludedFieldName = `${group.requestObjKey}Excluded`;

      const groupItems = filterOptions.find(g => g.title === group.title)?.items ?? [];
      const getIncludedExcludedFilters = (items) => {
        return items.map(itemTitle => {
          const filterWithSubFilters = groupItems.find(i => i.title === itemTitle);
          if (!filterWithSubFilters) {
            return null;
          }

          if (filterWithSubFilters.hasSubFilters) {
            return {
              title: itemTitle,
              hasSubFilters: true,
              checkedSubItems: [...(filterWithSubFilters.subFilters?.checkedItems ?? [])],
            };
          }

          return {
            title: itemTitle,
            hasSubFilters: false,
          };
        }).filter(f => !!f);
      };

      const includedFilters = getIncludedExcludedFilters(includedItems);
      const excludedFilters = getIncludedExcludedFilters(excludedItems);

      obj[includedFieldName] = [...(obj[includedFieldName] ?? []), ...includedFilters];
      obj[excludedFieldName] = [...(obj[excludedFieldName] ?? []), ...excludedFilters];
    });

    return obj;
  }

  public getFilterOptions(ctx: FilterOptionsContext<Product | BridgingProduct>): ProductFilterGroup[] {
    ctx ??= {} as any;
    return this.productFilterDefinitions.map(group => {
      const isIncludeExcludeFilter = group.isIncludeExcludeFilter;
      const groupFilters = isIncludeExcludeFilter
        ? this.includeExcludeGroupsFilters.get(group.title)
        : null;

      return {
        title: group.title,
        isIncludeExcludeFilter: isIncludeExcludeFilter,
        hasSubFilters: group.hasSubFilters,
        groupInfoText: group.groupInfoText,
        items: ('itemFactory' in group ? group.itemFactory(ctx) : group.items)
          .filter(item =>
            (item.visible?.(ctx) ?? true)
            && this.isFilterAvailable(group, item, ctx),
          )
          .map(filter => {
            const includedFilterDef = groupFilters?.includedFilterDefs?.get(filter.title);
            const excludedFilterDef = groupFilters?.excludedFilterDefs?.get(filter.title);

            const result = {
              title: filter.title,
              checked: filter.overrideChecked?.(ctx) ?? this.checkedFilters.get(group.title)?.has(filter.title),
              included: !!includedFilterDef,
              excluded: !!excludedFilterDef,
              isCriteriaFilter: filter.isCriteriaFilter ?? false,
              hasSubFilters: filter.hasSubFilters,
              subFilters: {
                items: filter.hasSubFilters && filter.itemFactory ? filter.itemFactory(ctx) : [],
                checkedItems: (includedFilterDef || excludedFilterDef)?.checkedSubFilters || new Set<string>(),
              },
            } as ProductFilter;
            return result;
          }),
        disabled: group.disabled?.(ctx) ?? false,
      } as ProductFilterGroup;
    });
  }

  public setIncludeExcludeFilter(
    groupName: string,
    filterName: string,
    includeChecked: boolean,
    excludeChecked: boolean,
    bulkUpdate = false,
  ) {
    if (!this.includeExcludeGroupsFilters.has(groupName)) {
      this.includeExcludeGroupsFilters.set(
        groupName,
        {
          includedFilterDefs: new Map<string, ProductFilterDefinition<Product | BridgingProduct>>(),
          excludedFilterDefs: new Map<string, ProductFilterDefinition<Product | BridgingProduct>>(),
        },
      );
    }

    this.isPristine = false;

    const groupFilters = this.includeExcludeGroupsFilters.get(groupName);
    if (!includeChecked && !excludeChecked) {
      if (groupFilters.includedFilterDefs.has(filterName)) {
        groupFilters.includedFilterDefs.delete(filterName);
      }

      if (groupFilters.excludedFilterDefs.has(filterName)) {
        groupFilters.excludedFilterDefs.delete(filterName);
      }

      if (!bulkUpdate) {
        this.filterChanged.emit();
      }
      return;
    }

    const groupFilterDef = this.productFilterDefinitions.find(p => p.title === groupName);
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const itemsFiltersDefs = groupFilterDef?.['items'];
    const filterDef = itemsFiltersDefs.find(i => i.title === filterName);

    if (!filterDef.isMatchFunc && !filterDef.hasSubFilters) {
      if (!bulkUpdate) {
        this.filterChanged.emit();
      }
      return;
    }

    if (includeChecked) {
      if (groupFilters.excludedFilterDefs.has(filterName)) {
        groupFilters.excludedFilterDefs.delete(filterName);
      }
      if (!groupFilters.includedFilterDefs.has(filterName)) {
        groupFilters.includedFilterDefs.set(filterName, {
          title: filterDef.title,
          isMatchFunc: filterDef.isMatchFunc,
          isUnknownFunc: filterDef.isUnknownFunc,
          hasSubFilters: filterDef.hasSubFilters,
          itemFactory: filterDef.itemFactory,
          isMatchFuncFactory: filterDef.isMatchFuncFactory,
          checkedSubFilters: new Set<string>(),
        });
      }
    } else {
      if (groupFilters.includedFilterDefs.has(filterName)) {
        groupFilters.includedFilterDefs.delete(filterName);
      }
      if (!groupFilters.excludedFilterDefs.has(filterName)) {
        groupFilters.excludedFilterDefs.set(filterName, {
          title: filterDef.title,
          isMatchFunc: filterDef.isMatchFunc,
          isUnknownFunc: filterDef.isUnknownFunc,
          isExcludeFunc: filterDef.isExcludeFunc,
          hasSubFilters: filterDef.hasSubFilters,
          itemFactory: filterDef.itemFactory,
          isMatchFuncFactory: filterDef.isMatchFuncFactory,
          checkedSubFilters: new Set<string>(),
        });
      }
    }

    if (!bulkUpdate) {
      this.filterChanged.emit();
    }
  }

  private getIncludeExcludeFilter(
    groupName: string | undefined,
    includeUnknown = false,
    matchedProducts: MatchedLenderProducts[] | null = null,
    matchedProductsFlat: Product[] | null = null,
    productsModelRequest: any,
  ): ((product: Product) => boolean) | null {
    if (!groupName) {
      return null;
    }

    if (!this.includeExcludeGroupsFilters.has(groupName)) {
      this.productsFilterHelper.resetSourcingFilters(matchedProductsFlat);
      return null;
    }

    const groupFilters = this.includeExcludeGroupsFilters.get(groupName);
    const includedFilterDefs = [...groupFilters.includedFilterDefs?.values()];
    const excludedFilterDefs = [...groupFilters.excludedFilterDefs?.values()];

    const hasIncluded = includedFilterDefs.length > 0;
    const hasExcluded = excludedFilterDefs.length > 0;

    if (!hasIncluded && !hasExcluded) {
      this.includeExcludeGroupsFilters.delete(groupName);
      this.productsFilterHelper.resetSourcingFilters(matchedProductsFlat);
      return null;
    }

    const includedFiltersNames = includedFilterDefs.map(f => f.title);

    const includeFilter = (product: Product) => {
      const includeFilterResult = includedFilterDefs.every(fd => {
        let isMatchedSubFilters = false;

        if (fd.hasSubFilters && fd.isMatchFuncFactory) {
          const checkedSubFilters = [...fd.checkedSubFilters];
          if (checkedSubFilters.length === 0) {
            isMatchedSubFilters = false;
          } else {
            const isMatch = checkedSubFilters
              .some(checkedFilterKey => fd.isMatchFuncFactory(checkedFilterKey)(product));
            isMatchedSubFilters = isMatch;
          }
        }

        const isUnknownMatched = !!fd.isUnknownFunc
          ? fd.isUnknownFunc(product, includedFiltersNames)
          : false;
        const isMatched = !!fd.isMatchFunc
          ? fd.isMatchFunc(product, matchedProducts, includedFiltersNames, productsModelRequest)
          : false;

        const result = includeUnknown
          ? isMatchedSubFilters || isMatched || isUnknownMatched
          : isMatchedSubFilters || isMatched;

        return result;
      });

      return includeFilterResult;
    };

    const excludeFilter = (product: Product) => {
      const excludeFilterResult = excludedFilterDefs.every(fd => {
        let isMatchedSubFilters = false;

        if (fd.hasSubFilters && fd.isMatchFuncFactory) {
          const checkedSubFilters = [...fd.checkedSubFilters];
          if (checkedSubFilters.length === 0) {
            isMatchedSubFilters = true;
          } else {
            const isMatch = checkedSubFilters
              .every(checkedFilterKey => !fd.isMatchFuncFactory(checkedFilterKey)(product));
            isMatchedSubFilters = isMatch;
          }
        }

        const isMatched = fd.isExcludeFunc
          ? fd.isExcludeFunc(product, includedFiltersNames)
          : !!fd.isMatchFunc
            ? !fd.isMatchFunc(product, matchedProducts, includedFiltersNames, productsModelRequest)
            : false;

        const isUnknownMatched = !!fd.isUnknownFunc
          ? fd.isUnknownFunc(product, includedFiltersNames)
          : false;
        const result = includeUnknown
          ? isMatchedSubFilters || isMatched || isUnknownMatched
          : isMatchedSubFilters || isMatched;

        return result;
      });

      return excludeFilterResult;
    };

    const groupFilter = (product: Product) => {
      if (hasIncluded && hasExcluded) {
        return includeFilter(product) && excludeFilter(product);
      }
      if (hasIncluded) {
        return includeFilter(product);
      }
      return excludeFilter(product);
    };

    return groupFilter;
  }

  public toggleAllSubFilters(groupKey: string, filter: ProductFilter, selectAll: boolean, bulkUpdate = false) {
    if (!this.includeExcludeGroupsFilters.has(groupKey)) {
      return;
    }

    this.isPristine = false;
    const groupFilter = this.includeExcludeGroupsFilters.get(groupKey);
    const filterDefs = [...groupFilter.includedFilterDefs?.values(), ...groupFilter.excludedFilterDefs?.values()];
    const filterDef = filterDefs.find(x => x.title === filter.title);
    if (!filterDef) {
      return;
    }

    filterDef.checkedSubFilters.clear();
    if (selectAll) {
      filter.subFilters?.items.forEach(i => filterDef.checkedSubFilters.add(i.title));
    }

    if (!bulkUpdate) {
      this.filterChanged.emit();
    }
  }

  public setSubFilter(groupKey: string, filterKey: string, subFilterKey: string, checked: boolean) {
    if (!this.includeExcludeGroupsFilters.has(groupKey)) {
      return;
    }

    this.isPristine = false;
    const groupFilter = this.includeExcludeGroupsFilters.get(groupKey);
    const filterDefs = [...groupFilter.includedFilterDefs?.values(), ...groupFilter.excludedFilterDefs?.values()];
    const filterDef = filterDefs.find(x => x.title === filterKey);
    if (!filterDef) {
      return;
    }

    if (checked) {
      filterDef.checkedSubFilters.add(subFilterKey);
    } else {
      filterDef.checkedSubFilters.delete(subFilterKey);
    }

    this.filterChanged.emit();
  }

  public mapCriteriaToFilters(criteriaName: string | undefined): string | null {
    switch (criteriaName) {
      case 'Expat not in UK':
        return 'Expat not in UK';
      case 'Help to Buy':
        return 'Help to Buy';
      case '2nd Residential':
        return 'Second Residential';
      case 'Limited Company Buy to Let':
        return 'Limited Company Buy to Let';
      case 'Let to Buy':
        return 'Let to Buy';
      case 'Portfolio Landlord':
        return 'Portfolio Landlord';
      case 'Regulated Buy to Let':
        return 'Regulated BTL';
      default:
        return null;
    }
  }

  public setFilter(groupKey: string, filterKey: string, checked: boolean) {
    if (checked && !this.checkedFilters.get(groupKey)?.has(filterKey)) {
      this.checkedFilters.get(groupKey)?.add(filterKey);
      this.filterChanged.emit();
    } else if (!checked && this.checkedFilters.get(groupKey)?.has(filterKey)) {
      this.checkedFilters.get(groupKey)?.delete(filterKey);
      this.filterChanged.emit();
    }
  }

  /**
   * Creates a function based on the currently selected filters that will apply said filters to a product.
   * Will return `null` if there are no filters to be applied to the product.
   */
  public getFilterFunc(
    context: FilterOptionsContext<Product | BridgingProduct>,
    includeUnknown = true,
    matchedProducts: MatchedLenderProducts[] | null = null,
    matchedProductsFlat: Product[] | null = null,
    productsModelRequest: any,
  ): ((product: Product) => boolean) | null {
    const appliedFilters = this.getActiveFilters(context, true)
      .filter(({ items, group }) => items.length > 0 && !group.isIncludeExcludeFilter)
      .map(({ group, items }) => {
        const filterFuncs = items.map(filterLabel => {
          if ('itemFactory' in group) {
            return group.isMatchFuncFactory(filterLabel);
          }
          const filterDef = group.items.find(x => x.title === filterLabel);
          return includeUnknown && filterDef?.isUnknownFunc
            ? (p: Product) => !!filterDef.isUnknownFunc?.(p) || !!filterDef.isMatchFunc?.(p)
            : filterDef?.isMatchFunc;
        });
        return (product: Product) => filterFuncs[group.itemOperator === 'and' ? 'every' : 'some'](f => f?.(product));
      });

    this.getIncludeExcludeFilterContexts()
      .map(r => r.group)
      .forEach(g => {
        const includeExcludeFilter = this.getIncludeExcludeFilter(
          g?.title,
          includeUnknown,
          matchedProducts,
          matchedProductsFlat,
          productsModelRequest,
        );
        if (includeExcludeFilter) {
          appliedFilters.push(includeExcludeFilter);
        }
      });

    return appliedFilters.length > 0
      ? (product: Product) => appliedFilters.every(f => f(product))
      : null;
  }

  public clearFilters(unsetProductFilters?: boolean, products?: Product[] | null) {
    this.isPristine = true;
    this.availableFiltersCache.clear();
    this.setProductFilterDefinitions();

    this.includeExcludeGroupsFilters?.clear();
    this.checkedFilters.forEach(grp => grp.clear());
    this.productsFilterHelper.resetSourcingFilters(products);
    this.filterChanged.emit(unsetProductFilters);
  }

  public clearSourcingFilters() {
    this.productsFilterHelper.clearSourcingFilters();
  }

  private isFilterAvailable(
    groupDef: ProductFilterGroupDefinition<Product | BridgingProduct>,
    filterDef: ProductFilterDefinition<Product | BridgingProduct>,
    ctx: FilterOptionsContext<Product | BridgingProduct>,
  ) {
    if (!groupDef.isIncludeExcludeFilter) {
      return true;
    }

    if (!filterDef.isMatchFunc) {
      return true;
    }

    const productsCount = ctx?.products?.length;
    const filterCacheKey = `${filterDef.title}_${productsCount}`;
    if (this.availableFiltersCache.has(filterCacheKey)) {
      return this.availableFiltersCache.get(filterCacheKey);
    }

    // Display filter when context has any products related to that filter
    // (has any products match to isMatchFunc OR isExcludeFunc filters)
    const isFilterAvailable =
      !!filterDef.isUnknownFunc
        ? (ctx?.products?.some(p => !!filterDef.isMatchFunc ? filterDef.isMatchFunc(p) : true)
          || (!!filterDef.isExcludeFunc && ctx?.products?.some(filterDef.isExcludeFunc)))
          ?? ctx?.products?.some(filterDef.isUnknownFunc)
        : ctx?.products?.some(p => !!filterDef.isMatchFunc ? filterDef.isMatchFunc(p) : true)
          || (!!filterDef.isExcludeFunc && ctx?.products?.some(filterDef.isExcludeFunc));

    this.availableFiltersCache.set(filterCacheKey, isFilterAvailable);

    return isFilterAvailable;
  }

  public excludeTailoredProductsByDefault(products: Product[] | BridgingProduct[] | null | undefined) {
    if (this.isPristine && !!products?.length) {
      this.isPristine = false;

      const excludedGroupTitle = 'Tailored Products';
      const filterGroup = this.getFilterOptions({ products: products })?.find(g => g.title === excludedGroupTitle);
      const groupDef = this.productFilterDefinitions.find(g => g.title === excludedGroupTitle);
      if (groupDef) {
        const availableFilters = groupDef['items'].filter((filterDef) => {
          if (!!filterDef.visible) {
            return filterDef.visible(products) && this.isFilterAvailable(groupDef, filterDef, { products: products });
          }

          return this.isFilterAvailable(groupDef, filterDef, { products: products });
        });

        availableFilters.forEach(filterDef => {
          this.setIncludeExcludeFilter(excludedGroupTitle, filterDef.title, false, true, true);
          if (filterDef.hasSubFilters && filterGroup) {
            const filter = filterGroup?.items.find(f => f.title === filterDef.title);
            if (filter) {
              this.toggleAllSubFilters(excludedGroupTitle, filter, true, true);
            }
          }
        });
        this.filterChanged.emit();
        this.isPristine = true;
      }
    }
  }

  public get activeFilterTitles() {
    const groupFilters = [...this.includeExcludeGroupsFilters.values()];
    const includedFilterDefs = groupFilters.flatMap(gf => [...gf.includedFilterDefs?.values()]);
    return includedFilterDefs.map(f => f.title);
  }
}
