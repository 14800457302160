<div class="esis-form-container mx-auto" [formGroup]="form">
  <div class="form-section">
    <div class="group-label"><span class="field-required">Level of Advice</span>
      <button
        type="button"
        class="btn p-0 m-0 ms-2 border-0 btn-sm text-primary"
        [ngbTooltip]="tooltipMessages.levelOfAdvice"
        placement="right"
        tooltipClass="custom-tooltip esis-tooltip"
        container="body"
      >
        <i class="far fa-lg fa-question-circle pointer text-2022--dark"></i>
      </button>
    </div>
    <div class="mb-4 row align-items-center" formGroupName="details">
      <div class="d-flex">
        <div class="form-check">
          <input class="form-check-input" value ="True" type="radio" name="isAdvised" id="isAdvised" formControlName="isAdvised">
          <label class="form-check-label" for="isAdvised">
            Advised
          </label>
        </div>
        <div class="form-check ms-2">
          <input class="form-check-input" value ="False" type="radio" name="isAdvised" id="isAdvised2" formControlName="isAdvised">
          <label class="form-check-label" for="isAdvised2">
            Non-Advised
          </label>
        </div>
      </div>
    </div>
  </div>
  <hr />
</div>

<div [formGroup]="form">
  <div class="form-section w-978 mx-auto">
    <div class="group-label">Product Fees (*Please note, where fees are added to the loan they will be calculated over the full term)</div>
    <div formGroupName="fees" class="w-978">
      <table class="table table-bordered" formGroupName="fees">
        <thead>
          <tr>
            <th scope="col">Fee type</th>
            <th scope="col">Fee amount</th>
            <th scope="col">When payable?</th>
            <th scope="col">Add to loan?</th>
            <th scope="col">When refundable?</th>
            <th scope="col">Amount refundable?</th>
            <th scope="col">Payable to</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="feesGroup.controls.length; else noItems">
            <tr *ngFor="let cost of feesGroup.controls; index as i; trackBy: trackFee" [formGroupName]="i">
              <td>
                <select
                  class="form-select fee-type-select"
                  formControlName="type"
                  [ngClass]="errors(['fees', 'fees'])?.brokerFeeDuplicated ? 'is-invalid' : validityClasses(['fees', 'fees', i, 'type'])"
                  required
                  [attr.disabled]="isLenderFee(i) ? 'readonly' : null"
                  [hidden]="isCustomFeeDropdownHidden(i)"
                  (focus)="customFeeOnFocus(i)"
                  (blur)="customFeeOnBlur()"
                >
                  <option [value]="null" hidden>Please select...</option>
                  <option *ngFor="let fee of availableFeeTypes; trackBy: trackByKey" [value]="fee.key">{{ fee.name }}</option>
                </select>
                <input
                    *ngIf="isCustomFeeType(i)"
                    type="text"
                    class="form-control fee-type-select"
                    [ngClass]="errors(['fees', 'fees'])?.brokerFeeDuplicated ? 'is-invalid' : validityClasses(['fees', 'fees', i, 'customFeeName'])"
                    [required]="isCustomFeeType(i)"
                    (focus)="customFeeOnFocus(i)"
                    (blur)="customFeeOnBlur()"
                    formControlName="customFeeName">
                <div *ngIf="invalid(['fees', 'fees', i, 'type']) && errors(['fees', 'fees', i, 'type']).required" class="invalid-feedback">
                  Fee type is required.
                </div>
                <div *ngIf="invalid(['fees', 'fees', i, 'customFeeName']) && errors(['fees', 'fees', i, 'customFeeName']).required" class="invalid-feedback">
                  Fee type is required if Custom Fee is selected.
                </div>

              </td>
              <td>
                <div class="input-group amount-refundable">
                  <span class="input-group-text">£</span>
                  <input
                    type="text"
                    class="form-control w-64"
                    formControlName="amount"
                    placeholder="0.00"
                    currencyMask
                    [currencyMaskAllowFractional]="true"
                    [ngClass]="validityClasses(['fees', 'fees', i, 'amount'])"
                    required
                    [attr.disabled]="isLenderFee(i) ? 'readonly' : null">
                </div>
                <div *ngIf="invalid(['fees', 'fees', i, 'amount']) && errors(['fees', 'fees', i, 'amount']).required" class="invalid-feedback">
                  Fee amount is required.
                </div>
                <div *ngIf="invalid(['fees', 'fees', i, 'amount']) && errors(['fees', 'fees', i, 'amount']).min" class="invalid-feedback">
                  Fee amount must be at least £0.01.
                </div>
              </td>
              <td>
                <select
                class="form-select when-payble"
                formControlName="whenPayable"
                [ngClass]="validityClasses(['fees', 'fees', i, 'whenPayable'])"
                required
                >
                  <option [value]="null" hidden>Please select...</option>
                  <option *ngFor="let opt of whenPayable | enumvalues; trackBy: trackByKey"
                    [value]="opt.key">
                    {{ opt.value | startcase }}
                  </option>
                </select>
                <div *ngIf="invalid(['fees', 'fees', i, 'whenPayable'])" class="invalid-feedback">
                  When Payable is required.
                </div>
              </td>
              <td>
                <select
                class="form-select add-to-loan"
                formControlName="addToLoan"
                [ngClass]="addToLoanClasses(i)"
                required
                >
                  <option [value]="null" hidden>Please select...</option>
                  <option [value]="true">Yes</option>
                  <option [value]="false">No</option>
                </select>
                <div *ngIf="invalid(['fees', 'fees', i, 'addToLoan'])" class="invalid-feedback">
                  Add fee to loan is required.
                </div>
              </td>
              <td>
                <select
                  class="form-select when-refundable"
                  formControlName="whenRefundable"
                  [ngClass]="validityClasses(['fees', 'fees', i, 'whenRefundable'])"
                  (change)="whenRefundableChange($event.target.value, i)"
                  required
                >
                  <option [value]="null" hidden>Please select...</option>
                  <option *ngFor="let opt of whenRefundable | enumvalues; trackBy: trackByKey"
                    [value]="opt.key">
                    {{ opt.value | startcase : 'to' }}
                  </option>
                </select>
                <div *ngIf="invalid(['fees', 'fees', i, 'whenRefundable'])" class="invalid-feedback">
                  Refundable Date is required.
                </div>
              </td>
              <td>
                <div class="input-group amount-refundable">
                  <span class="input-group-text">£</span>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="amountRefundable"
                    placeholder="0.00"
                    currencyMask
                    [currencyMaskAllowFractional]="true"
                    [ngClass]="validityClasses(['fees', 'fees', i, 'amountRefundable'])"
                    [readonly]="+form.get(['fees', 'fees', i, 'whenRefundable']).value === whenRefundable.NotRefundable ? 'readonly' : null"
                  >
                </div>
                <div *ngIf="invalid(['fees', 'fees', i, 'amountRefundable']) && errors(['fees', 'fees', i, 'amountRefundable']).required" class="invalid-feedback">
                  Amount refundable is required when fee is refundable.
                </div>
                <div *ngIf="invalid(['fees', 'fees', i, 'amountRefundable']) && errors(['fees', 'fees', i, 'amountRefundable']).min" class="invalid-feedback">
                  Amount refundable must be at least £0.01.
                </div>
              </td>
              <td>
                <input
                  *ngIf="isCustomFeeType(i)"
                  [ngClass]="validityClasses(['fees', 'fees', i, 'payableTo'])"
                  [required]="isCustomFeeType(i)"
                  type="text"
                  class="form-control payable-to"
                  formControlName="payableTo"
                >
                <div *ngIf="isCustomFeeType(i) && invalid(['fees', 'fees', i, 'payableTo']) && errors(['fees', 'fees', i, 'payableTo']).maxlength" class="invalid-feedback">
                  Maximum character length should be 100
                </div>
                <div *ngIf="isCustomFeeType(i) && invalid(['fees', 'fees', i, 'payableTo']) && errors(['fees', 'fees', i, 'payableTo']).required" class="invalid-feedback">
                  Payable To is required if Custom Fee is selected.
                </div>

                <label class="payable-to" *ngIf="!isCustomFeeType(i)">
                  {{ getPayableToLabel(i) }}
                </label>
              </td>
              <td class="remove-fee-container text-center">
                <button *ngIf="removingItem !== i" type="button" class="btn btn-2022--danger" (click)="removingItem = i">Remove</button>
                <ng-container *ngIf="removingItem === i">
                  <button type="button" class="btn btn-2022--danger btn-sm" (click)="confirmDeleteFee(i)">Yes</button>
                  <button type="button" class="ms-2 btn btn-2022--warning btn-sm" (click)="removingItem = null">No</button>
                </ng-container>
              </td>
            </tr>
            <div *ngIf="invalid(['fees', 'fees']) && errors(['fees', 'fees'])?.brokerFeeDuplicated" class="text-danger">
              Maximum number of broker fees have been provided
            </div>
          </ng-container>
          <ng-template #noItems>
            <tr>
              <td colspan="100%" class="text-center">
                No fees have been added, please click the "Add new fee" button if you need to add some.
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="row mx-0 justify-content-end">
        <button type="button" class="add-fee-button btn btn-2022--secondary mb-3" (click)="addFee.emit()">
          Add new fee
        </button>
      </div>
    </div>
  </div>
</div>

<div class="esis-form-container mx-auto" [formGroup]="form">
  <ng-container formGroupName="fees">
    <div class="form-section">
      <div class="input-label">Fee Waiver Wording
        <button
          type="button"
          class="btn p-0 m-0 ms-2 border-0 btn-sm text-primary"
          [ngbTooltip]="tooltipMessages.feeWaiver"
          placement="right"
          tooltipClass="custom-tooltip esis-tooltip"
          container="body"
        >
          <i class="far fa-lg fa-question-circle pointer text-2022--dark"></i>
        </button>
      </div>
      <textarea
        class="form-control"
        [ngClass]="validityClasses('fees.feeWaiver')"
        formControlName="feeWaiver"
        rows="3"
      ></textarea>
    </div>
  </ng-container>

  <hr />

  <ng-container formGroupName="eor">
    <div class="form-section">
      <div class="group-label">Evidence of Research</div>
      <ng-container formGroupName="resultsGrid">
        <div class="mb-4 row align-items-center">
          <label class="input-label field-required" for="rowCount">Number of results to display</label>
          <div>
            <input
              formControlName="rowCount"
              type="number"
              class="form-control"
              [ngClass]="validityClasses('eor.resultsGrid.rowCount')"
              id="rowCount"
              placeholder="Results count"
              required
            >
            <div *ngIf="invalid('eor.resultsGrid.rowCount')" class="invalid-feedback">
              <lib-input-error-description
                [errorMessage]="'Number of results is required.'"
                [visible]="errors('eor.resultsGrid.rowCount').required"
              ></lib-input-error-description>
              <lib-input-error-description
                [errorMessage]="'Number of results must be 0 or greater.'"
                [visible]="errors('eor.resultsGrid.rowCount').min"
              ></lib-input-error-description>
              <lib-input-error-description
                [errorMessage]="'Number of results must be a whole number.'"
                [visible]="errors('eor.resultsGrid.rowCount').integer"
              ></lib-input-error-description>
              <span *ngIf="errors('eor.resultsGrid.rowCount').required">Number of results is required.</span>
              <span *ngIf="errors('eor.resultsGrid.rowCount').min">Number of results must be 0 or greater.</span>
              <span *ngIf="errors('eor.resultsGrid.rowCount').integer">Number of results must be a whole number.</span>
            </div>
          </div>
        </div>
        <div class="mb-4 row align-items-center">
          <label class="input-label" for="title">Results grid title</label>
          <div>
            <input
              formControlName="title"
              type="text"
              class="form-control"
              [ngClass]="validityClasses('eor.resultsGrid.title')"
              id="title"
              placeholder="Title"
            >
          </div>
        </div>
        <div class="mb-4 row align-items-center">
          <label class="input-label" for="description">Results grid description</label>
          <div>
            <input
              formControlName="description"
              type="text"
              class="form-control"
              [ngClass]="validityClasses('eor.resultsGrid.description')"
              id="description"
              placeholder="Description"
            >
          </div>
        </div>
      </ng-container>
    </div>
    <div class="form-section">
      <div class="input-label">Reason for Recommendation</div>
        <textarea
        class="form-control"
        [ngClass]="validityClasses('eor.recommendationReason')"
        formControlName="recommendationReason"
        rows="3"
      ></textarea>
    </div>

    <div class="form-section">
      <div class="input-label">Additional Information</div>
        <textarea
        class="form-control"
        [ngClass]="validityClasses('eor.additionalInformation')"
        formControlName="additionalInformation"
        rows="3"
      ></textarea>
    </div>
  </ng-container>
</div>
