<div class="d-flex justify-content-end" [formGroup]="scheduleForm" *ngIf="showSetLiveNowOption">
  <div>
    <div class="form-check">
      <input class="form-check-input" type="radio" id="scheduleModeNow-{{ uniqueId }}" formControlName="isScheduled" [value]="false">
      <label class="form-check-label" for="scheduleModeNow-{{ uniqueId }}">Set live now</label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" id="scheduleModeFuture-{{ uniqueId }}" formControlName="isScheduled" [value]="true">
      <label class="form-check-label" for="scheduleModeFuture-{{ uniqueId }}">Schedule go live date</label>
    </div>
  </div>
</div>

<div *ngIf="showDateTimeFields" class="d-flex flex-column mt-2" [ngClass]="'align-items-' + alignItems">
  <div class="d-flex align-items-center" [formGroup]="scheduleForm">
    <div class="input-group me-2 w-auto">
      <input
        class="form-control"
        placeholder="DD/MM/YYYY"
        formControlName="date"
        [minDate]="scheduleMinDate"
        [class.is-valid]="isValid('date')"
        [class.is-invalid]="isInvalid('date')"
        ngbDatepicker
        container="body"
        #scheduleDatePicker="ngbDatepicker"
      />
      <button
        type="button"
        class="btn btn-outline-secondary"
        [class.btn-outline-success]="isValid('date')"
        [class.btn-outline-danger]="isInvalid('date')"
        (click)="scheduleDatePicker.toggle()"
      >
        <i class="fa fa-calendar"></i>
      </button>
    </div>
    <div class="me-2">
      <ngb-timepicker
        formControlName="time"
        [class.is-valid]="isValid('time')"
        [class.is-invalid]="isInvalid('time')"
      ></ngb-timepicker>
    </div>
  </div>
  <div
    *ngIf="isInvalid('date') && controlErrors('date')?.required"
    class="d-block invalid-feedback w-auto"
  >
    Schedule date is required
  </div>
  <div
    *ngIf="isInvalid('time') && controlErrors('time')?.required"
    class="d-block invalid-feedback w-auto"
  >
    Schedule time is required
  </div>
  <div
    *ngIf="scheduleForm.invalid && scheduleForm.errors?.timeTooEarly"
    class="d-block invalid-feedback w-auto"
  >
    Schedule must be in the future
  </div>
</div>
