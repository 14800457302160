import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'lib-yes-no-column',
  templateUrl: 'yes-no-column.component.html',
  styleUrls: ['yes-no-column.component.scss'],
})
export class YesNoColumnComponent {
  @Input() public columnValue: boolean | null | undefined;
}
