import { Injectable } from '@angular/core';
import { ClubHubDataService } from './clubhub-data.service';
import {
  EsisEorGenFormDetails,
  EsisEorGenerationRequest,
  EsisEorGenerationResponse,
  GeneratedLendingDocumentType,
  HistoricalEsisEorDocument,
  IgnitePlusSourcingCallbackRequest,
} from 'apps/shared/src/models';
import { ProductCollectionType } from '@msslib/models';

export type EsisEorDocumentApiSuffix = 'affordability' | 'products';

@Injectable({
  providedIn: 'root',
})
export class EsisEorDocumentService {

  public constructor(
    private clubHubDataService: ClubHubDataService,
  ) {}

  /**
   * Gets additional information that is required for the ESIS/EOR form, such as the lender's complaint information.
  */
  public getFormDetails(lenderName: string, lendingTypeId: number, productCode: string) {
    return this.clubHubDataService.get<EsisEorGenFormDetails>(
      `EsisInformation/FormDetails/${lenderName}/${lendingTypeId}?productCode=${productCode}`,
    );
  }

  /**
   * Gets a list of available documents that have been generated by the user previously.
   */
  public getDocuments() {
    return this.clubHubDataService.get<HistoricalEsisEorDocument[]>('EsisEorDocument');
  }

  /**
   * @returns The generated document ID for the documents that can be used in the `downloadDocument` func.
   */
  public generateDocuments(
    esisAdditionalInformation: EsisEorGenerationRequest,
    endpointSuffix: EsisEorDocumentApiSuffix,
    productType: ProductCollectionType,
  ) {
    return this.clubHubDataService.post<EsisEorGenerationResponse>(
      `EsisEorDocument/${endpointSuffix}/${productType}`,
      esisAdditionalInformation,
    );
  }

  /**
   * Gets the blob for a document PDF of the specified type.
   */
  public downloadDocument(documentType: GeneratedLendingDocumentType, personId: string, documentId: string) {
    return this.clubHubDataService.getFile(
      `EsisEorDocument/${personId}/${documentId}/${documentType}`,
      'application/pdf',
    );
  }

  public archiveDocument(personId: string, documentId: string) {
    return this.clubHubDataService.put(
      `EsisEorDocument/${personId}/${documentId}/Archived`,
      { archive: 1 },
    );
  }

  public documentGenerationSmartr365Callback(body: IgnitePlusSourcingCallbackRequest) {
    return this.clubHubDataService.post<IgnitePlusSourcingCallbackRequest>(
      'SmartrFitPlusCallback',
      body,
    );
  }
}
