<div class="d-flex flex-column mt-2" [ngClass]="'align-items-' + alignItems">
  <div class="d-flex align-items-center" [formGroup]="dateTimeForm">
    <div class="input-group me-2 w-auto">
      <input
        class="form-control"
        placeholder="DD/MM/YYYY"
        formControlName="date"
        [class.is-valid]="isValid('date')"
        [class.is-invalid]="isInvalid('date')"
        ngbDatepicker
        container="body"
        #scheduleDatePicker="ngbDatepicker"
        data-testid="date_time_date_input"
      />
      <button
        type="button"
        class="btn btn-outline-secondary"
        [class.btn-outline-success]="isValid('date')"
        [class.btn-outline-danger]="isInvalid('date')"
        (click)="scheduleDatePicker.toggle()"
        data-testid="date_time_date_btn"
      >
        <i class="fa fa-calendar"></i>
      </button>
    </div>
    <div class="me-2">
      <ngb-timepicker
        formControlName="time"
        [class.is-valid]="isValid('time')"
        [class.is-invalid]="isInvalid('time')"
        data-testid="date_time_time_picker"
      ></ngb-timepicker>
    </div>
  </div>
  <div
    *ngIf="isInvalid('date') && controlErrors('date')?.required"
    class="d-block invalid-feedback w-auto"
  >
    Schedule date is required
  </div>
  <div
    *ngIf="isInvalid('time') && controlErrors('time')?.required"
    class="d-block invalid-feedback w-auto"
  >
    Schedule time is required
  </div>
</div>
