export enum ApplicationToOfferPeriod {
  Hours = 1,
  Days = 2,
}

export enum InitialCaseAssessmentPeriod {
  Hours = 1,
  Days = 2,
}

export interface ServiceLevelUpdate {
  canAcceptApplicantOnline: boolean;
  canAcceptNonAdvisedReferrals: boolean;
  canUploadDocumentsOntoWebsite: boolean;
  hasLiveChat: boolean;
  hasManualUnderwriter: boolean;
  creditType: string;
  applicationToOfferTime: number;
  applicationToOfferPeriod: ApplicationToOfferPeriod;
  initialCaseAssessmentTime: number;
  initialCaseAssessmentPeriod: InitialCaseAssessmentPeriod;
  logoUrl: string;
}
