import { Component } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MssFieldBaseFieldTypeComponent } from '../mss-field-base.component';

@Component({
  templateUrl: 'currency-multi-add.component.html',
  styleUrls: ['currency-multi-add.component.scss'],
})
export class CurrencyMultiAddFieldTypeComponent extends MssFieldBaseFieldTypeComponent {
  public static defaultOptions: FormlyFieldConfig = {
    defaultValue: 0,
    props: {
      addonStart: '£',
      min: 0,
      ...MssFieldBaseFieldTypeComponent.focusBlurZeroDefault,
    },
  };

  public get numColumns(): number {
    return this.props.numColumns ?? 3;
  }
}
