export enum AvailableLenderToolsCategories {
  AllTools,
  ProductAndCriteriaAndProperty,
  AffordabilityMetAndProductOrCriteriaOrProperty,
  AffordabilityAndProductOrCriteriaOrProperty,
  ProductOrCriteriaOrProperty,
  AffordabilityMet,
  PropertyOnly,
  ProductAndAffordability,
  ProductAndCriteria,
  ProductAndProperty,
  AffordabilityAndCriteriaAndProperty,
  AffordabilityAndCriteria,
  AffordabilityAndProperty,
  CriteriaAndProperty,
  AffordabilityErrorAndProductOrCriteriaOrProperty,
  AffordabilityOrCriteria,
  AffordabilityNotChecked,
  None,
}

export interface LenderTools {
    lenderName: string;
    availableToolsCategory: AvailableLenderToolsCategories;
}
