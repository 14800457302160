import { Injectable } from '@angular/core';
import {
  BridgingInterestMethod,
  BridgingProductRate,
  BridgingProductType,
} from 'apps/shared/src/models';

@Injectable({
  providedIn: 'root',
})
export class BridgingProductsHelper {
  public productTypeToString(type: number): string {
    switch (type) {
      case BridgingProductType.SteppedFixed:
        return 'Stepped Fixed';
      default:
        return BridgingProductType[type];
    }
  }

  public maxTermToString(maxTerm: number): string {
    return maxTerm === 1 ? '1 month' : `${maxTerm} months`;
  }

  public interestMethodsToString(interestMethods: number[]): string {
    return interestMethods.map(i => BridgingInterestMethod[i]).join(', ');
  }

  public exitFeeToString(exitFee: boolean): string {
    return exitFee ? 'Yes' : 'No';
  }

  public monthlyRateToString(rates: BridgingProductRate[]) {
    if (!rates || rates.length < 1) {
      return '';
    }

    return `${rates[0].rate}%`;
  }

  public productTypesDiscreteFilter() {
    return Object.keys(BridgingProductType)
      .filter(k => !isNaN(Number(k)))
      .map(type => {
        return {
          label: BridgingProductType[type],
          value: type,
          icon: 'fa-file-contract',
        };
      });
  }
}
