import { Injectable } from '@angular/core';
import { AgencyNumberLookupResponse } from '@msslib/models/agency-number';
import { Observable, of, shareReplay } from 'rxjs';
import { ClubHubDataService } from './clubhub-data.service';

@Injectable({
  providedIn: 'root',
})
export class AgencyNumberService {

  private lookupCache = new Map<number, Observable<AgencyNumberLookupResponse>>();

  public constructor(
    private dataService: ClubHubDataService,
  ) {}

  public lookupAgencyNumber(agencyNumber: number, loading = false)
    : Observable<AgencyNumberLookupResponse> {
    if (agencyNumber === null || agencyNumber < 0) {
      return of({ valid: false, result: 'Invalid agency number', isMasterAgency: false });
    }
    if (!this.lookupCache.has(+agencyNumber)) {
      this.lookupCache.set(+agencyNumber, this.dataService
        .get<AgencyNumberLookupResponse>(`Agency/Lookup?agencyNumber=${agencyNumber}`, { loading })
        .pipe(shareReplay(1)));
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.lookupCache.get(+agencyNumber)!;
  }
}
