import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ToastService } from '@msslib/services/toast.service';
import { TariffOfCharges } from 'apps/usermanager/src/app/models/tariff-of-charges';
import { TariffOfChargesService } from 'apps/usermanager/src/app/services/tariff-of-charges.service';
import { Subject, takeUntil } from 'rxjs';

type FormControlPath = Parameters<UntypedFormGroup['get']>[0];

@Component({
  selector: 'lib-lender-tariff-of-charges',
  templateUrl: './lender-tariff-of-charges.component.html',
  styleUrls: ['./lender-tariff-of-charges.component.css'],
})

export class LenderTariffOfChargesComponent implements OnInit, OnDestroy {
  @Input({ required: true }) public lenderNameId: number;
  @Input() public disabled = false;
  public form: FormGroup;
  public feeList: {name: string; description: string}[] = [
    {name: 'Funds Transfer Fee (CHAPS Fee)',
      description: 'Electronically transferring the mortgage funds to you or your solicitor.'},
    {name: 'Mortgage Exit Fee', description: `You may have to pay this if:
    <ul>
    <li>Your mortgage term comes to an end</li>
    <li>You transfer the loan to another lender: or</li>
    <li>Transfer borrowing from one property to another.</li>
    </ul>
    This is payable either at the end of the mortgage term, or before the end of your mortgage term
    if you transfer the loan to another lender or another property (known as 'redemption')
    You may be charged a separate fee by your solicitor or licensed or qualified conveyancer
    for this work relating to redemption of the mortgage and discharge of the security`},
    {name: 'Further Advance Fee', description: 'This is charged when borrowing additional funds on your mortgage'},
    {name: 'Processing Fee',
      description: `Assessing and processing your application (even if your application
      is unsuccessful or you withdraw it)`},
    {name: 'Conveyancing / Legal Fee',
      description: `You will normally instruct a solicitor/licensed conveyancer to act on your behalf
    in connection with your home purchase transaction. You may be required to pay
    their legal fees and costs as part of their work on your behalf.
    These fees/costs are normally charged by the solicitor/licensed conveyancer,
    directly to you unless we tell you that we will contribute to the legal costs as part of your product deal.`},
    {name: 'Deeds Release Fee',
      description: `This is is paid to your existing lender so they can forward on the property's
      title deeds to your solicitor.`},
    {name: 'Disbursement Fee',
      description: `These cover various fees and charges necessary to complete the conveyancing process
      for a property purchase or sale`},
    {name: 'Higher Lending Fee',
      description: `If your mortgage has a high loan-to-value (LTV) ratio (typically 75% LTV or higher),
      you might incur an HLC. The lender purchases insurance against the risk of default.`},

  ];

  private ngUnsubscribe = new Subject<void>();

  @Output() public unsavedChangesChange = new EventEmitter<boolean>();

  public constructor(
    private formBuilder: FormBuilder,
    private tariffOfChargesService: TariffOfChargesService,
    private toastService: ToastService) { }

  public ngOnInit(): void {
    this.createFeeForm(null);
    this.tariffOfChargesService.get(this.lenderNameId).subscribe((res: TariffOfCharges)=> this.createFeeForm(res));
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get feeArray() {
    return this.form.controls['fees'] as FormArray;
  }

  private createFeeForm(model: TariffOfCharges | null) {
    this.form = this.formBuilder.group({fees: this.formBuilder.array([])});
    this.feeList.forEach(fee =>{
      const existingFee = model?.fees?.find(f =>f.name === fee.name);
      const feeGroup = this.formBuilder.group({
        name: (fee.name),
        description: (fee.description),
        amount: [existingFee?.amount ?? 0, Validators.max(99999.99)],
        feeConfiguration: this.formBuilder.group({
          purchase: existingFee?.feeConfiguration.purchase ?? false,
          remortgage: existingFee?.feeConfiguration.remortgage ?? false,
          productTransfer: existingFee?.feeConfiguration.productTransfer ?? false,
          furtherAdvance: existingFee?.feeConfiguration.furtherAdvance ?? false,
          existingCustomer: existingFee?.feeConfiguration.existingCustomer ?? false,
          secondCharge: existingFee?.feeConfiguration.secondCharge ?? false,
        }),
      },
      { validators: this.checkFeeConfiguration.bind(this) });

      this.feeArray.push(feeGroup);
    });

    if (this.disabled) {
      this.form.disable({ onlySelf: false });
    }

    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.unsavedChangesChange.emit(true));
  }

  // Custom validator to ensure at least one option is selected in fee configuration
  private checkFeeConfiguration(group: FormGroup): ValidationErrors | null {
    const amount = group?.controls['amount'].value;
    if (!+amount) {
      return null;
    }

    const feeConfigGroup = group.controls['feeConfiguration'] as FormGroup;
    const purchase = feeConfigGroup.controls['purchase'].value;
    const remortgage = feeConfigGroup.controls['remortgage'].value;
    const productTransfer = feeConfigGroup.controls['productTransfer'].value;
    const furtherAdvance = feeConfigGroup.controls['furtherAdvance'].value;
    const existingCustomer = feeConfigGroup.controls['existingCustomer'].value;
    const secondCharge = feeConfigGroup.controls['secondCharge'].value;
    return purchase || remortgage || productTransfer || furtherAdvance || existingCustomer || secondCharge
      ? null : { invalidFeeConfiguration: true };
  }

  public onSubmit() {
    if (this.disabled) {
      return;
    }
    if (this.form.valid) {
      this.tariffOfChargesService.save(this.lenderNameId, this.form.value)
        .subscribe(()=> {
          this.toastService.success('Tariff successfully updated');
          this.unsavedChangesChange.emit(false);
        });
    } else {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
    }
  }

  public invalid(path: FormControlPath) {
    const ctrl = this.form.get(path);
    return ctrl?.invalid && ctrl.touched;
  }

  public errors(path: FormControlPath) {
    return this.form.get(path)?.errors;
  }
}
