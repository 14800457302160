<div class="d-flex mt-4">
  <label class="col fw-bold d-block">{{ props.label }}</label>
  <i *ngIf="props.tooltip" class="col-1 far fa-question-circle my-auto" (click)="openTooltip()"></i>
</div>

<div class="d-flex mt-1">
  <div class="col-7">
    <div class="input-group" [ngClass]="props.inputClass" [class.is-invalid]="showError"
      [class.disabled]="formControl.disabled">
      <span *ngIf="props.addonStart" class="input-group-text" [ngClass]="props.addonStartClass">
        {{ props.addonStart }}
      </span>

      <input type="text" class="form-control" [formControl]="formControl" [placeholder]="props.placeholder"
        [formlyAttributes]="field" maxlength="11" mask="separator.0" thousandSeparator="," [attr.data-testid]="props.testId"/>

      <span *ngIf="props.addonEnd" class="input-group-text" [ngClass]="props.addonEndClass">
        {{ props.addonEnd }}
      </span>
    </div>
  </div>
  <div class="col-5 d-flex justify-content-end">
    <button [disabled]="props.disabled" class="btn" [class]="props.button.className ?? ''"
      [class.disabled]="formControl.disabled" [type]="props.button.type ?? 'button'"
      (click)="!props.button.onClick || props.button.onClick(field)" [attr.data-testid]="props.testId + '_btn'">
      <i *ngIf="props.button.iconClass" [ngClass]="props.button.iconClass"></i>
      {{ props.button.label }}
    </button>
  </div>
</div>

<div *ngIf="showError" class="d-flex gap-3 align-items-center mt-2 alert alert-danger ">
  <i class="fas fa-times-circle"></i>
  <span>
    <formly-validation-message [field]="field"></formly-validation-message>
  </span>
</div>
