import {Component, HostBinding, TemplateRef} from '@angular/core';
import { ToastService } from '../../services';

@Component({
  selector: 'lib-toast',
  styleUrls: ['toast.component.scss'],
  templateUrl: 'toast.component.html',
})
export class ToastComponent {
  public constructor(public toastService: ToastService) {}

  @HostBinding('class.ngb-toasts') public ngbToasts = true;

  public isTemplate(toast: Record<string, unknown>) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
