<div ngbDropdown #dropdownFilter="ngbDropdown">
  <button
    class="btn {{ textClass || 'text-white' }} fw-bold"
    [ngClass]="{ 'image-button': image }"
    [id]="name"
    ngbDropdownAnchor
    (click)="dropdownFilter.toggle();"
    [attr.aria-label]="ariaText ? (dropdownFilter.isOpen() ? 'Close ' : 'Open ') + ariaText : null"
  >
    <img *ngIf="image" [src]="image.src" class="img-fluid me-2" [style.max-width.px]="image.maxWidth" />
    {{ label }}
    <i class="{{ active.icon }} {{ active?.iconColour }}" *ngIf="active"></i>
  </button>
  <div ngbDropdownMenu [attr.aria-labelledby]="name" role="listbox" class="p-3" id="audit-dropdown">
    <div class="input-group">
      <input
        class="form-control"
        ngbDatepicker
        #datePicker="ngbDatepicker"
        [(ngModel)]="date"
        (ngModelChange)="select(date); dropdownFilter.close()"
      />
      <span (click)="datePicker.toggle()" class="input-group-text bg-2022--primary">
        <i class="far fa-calendar-alt text-white"></i>
      </span>
    </div>
    <button *ngIf="date" class="btn btn-danger mt-2 w-100" (click)="clearDate(); dropdownFilter.close()">
      <i class="filter-icon fas fa-times"></i>
      Clear
    </button>
  </div>
</div>
