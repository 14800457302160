<div [formGroup]="formGroup" [class]="config.cssClass ? 'mb-4 ' + config.cssClass: 'form-group'">
  <label for="{{ config.name }}">
    {{ config.label }}
    <ng-container *ngIf="showAsterisk(config)">
      *
    </ng-container>
    <ng-template #tooltip>
      <div [innerHtml]="config.tooltip"></div>
    </ng-template>
    <ng-container *ngIf="config.tooltip">
      <i class="fas fa-info-circle" [ngbTooltip]="tooltip"></i>
    </ng-container>
  </label>
  <select
    [formControlName]="config.name"
    [id]="config.name"
    class="form-select"
    [ngClass]="{ 'is-valid': valid, 'is-invalid': invalid }"
    (change)="onChange($event)"
    [attr.aria-describedby]="config.name + 'err'"
    [attr.data-testid]="config.name"
  >
    <option [value]="undefined" *ngIf="config.hasPlaceholder !== false" disabled>{{ config.placeholder || 'Please select...' }}</option>
    <option [value]="option.value" *ngFor="let option of config.options" [attr.disabled]="option.disabled ? '' : null" [attr.hidden]="option.hidden ? '' : null"
            [attr.data-testid]="'option_' + option.value">
      {{ option.text }}
    </option>
  </select>

  <div libFormFieldError class="invalid-feedback" [fieldConfig]="config"></div>
</div>
