import { EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, distinctUntilChanged, filter } from 'rxjs';
import { GridStateModel } from '../../../models';
import { SimpleGridComponent } from '../simple-grid.component';
import { Datasource, DatasourceResult } from './datasource';

export class ObservableDatasource<T = unknown> implements Datasource<T> {

  private items = new BehaviorSubject<T[] | null>(null);
  private mainSub: Subscription;
  private getItemsSub: Subscription;
  public notifyChanged? = new EventEmitter<void>();

  public constructor(observable: Observable<T[]>) {
    // Subscribe once during creation and unsubscribe during cleanup so that we're not subscribing and unsubscribing
    // during `getItems` and potentially missing out values.
    this.mainSub = observable
      .pipe(distinctUntilChanged())
      .subscribe(items => {
        this.items.next(items);
        this.notifyChanged?.emit();
      });
  }

  public get allItems() {
    return this.items.value;
  }

  public getItems(_state: GridStateModel, grid: SimpleGridComponent) {
    this.getItemsSub?.unsubscribe();
    return new Promise<DatasourceResult<T>>(resolve => {
      this.getItemsSub = this.items
        .pipe(filter(x => x !== null))
        .subscribe(items => {
          const filteredItems = grid.clientSideFilterSort(items);
          resolve({ items: grid.clientSidePaginate(filteredItems), totalCount: filteredItems.length });
        });
    });
  }

  public dispose(): void {
    this.mainSub.unsubscribe();
    this.getItemsSub?.unsubscribe();
  }
}
