<button
  type="button"
  *ngIf="tooltip && !showAsTooltip"
  (click)="openTooltip()"
  class="btn p-0 m-0 border-0 btn-sm {{ whiteIcon ? 'text-white' : '' }}"
>
  <i class="far {{ smallIcon ? '' : 'fa-lg' }} fa-question-circle pointer"></i>
  <span class="sr-only">{{ title }} additional information</span>
</button>

<ng-container *ngIf="tooltip && showAsTooltip">
  <i
    class="{{ whiteIcon ? 'text-white' : '' }} far {{ smallIcon ? '' : 'fa-lg' }} fa-question-circle pointer"
    [ngbTooltip]="tooltip"
    tooltipClass="custom-tooltip"
    [autoClose]="'outside'"
    triggers="click"
  ></i>
  <span class="sr-only">{{ title }} additional information</span>
</ng-container>
