<div class="type-tooltip">
  <div class="type-tooltip-icon-container align-items-center">
    <i class="fas fa-trophy me-1"></i> <span>: Exclusive Product</span>
  </div>
  <div class="type-tooltip-icon-container align-items-center">
    <i class="fas fa-retweet me-1"></i> <span>: Product Transfer</span>
  </div>
  <div class="py-1 d-flex">
    <div class="type-tooltip-image-anchor">
      <img class="w-100" src="assets/images/2022-ignite/anchor-solid-white.svg">
    </div>
    <div class="ms-1">
      : Direct Only
    </div>
  </div>
  <div class="type-tooltip-icon-container align-items-center">
    <i class="fas fa-gift me-1"></i> <span>: Packager</span>
  </div>
</div>
