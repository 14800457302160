<lib-loading *ngIf="!isAffordability && !isCriteriaOutcomePage && !isProducts"></lib-loading>

<ng-template modal>
  <lib-modal-component></lib-modal-component>
</ng-template>

<lib-toast aria-live="polite" aria-atomic="true"></lib-toast>

<!-- same cookie header banner now injected across all group sites-->
<!-- <app-cookie-policy></app-cookie-policy> -->
<app-header class="d-print-none"></app-header>

<lib-refresh-now-app-banner *ngIf="scriptVersionService.updateRequired"
                            [bannerText]="refreshNowAppBannerText"></lib-refresh-now-app-banner>

<main>
  <router-outlet></router-outlet>
</main>

<lib-footer [showDisclaimer]="true" class="footer"></lib-footer>

<ng-template #userCustomProductSettings>
  <div class="card-body">
    <p class="h2 mt-3">We’ve made some changes to set your default fees in My Account</p>
  </div>
</ng-template>
