import { Component, Input } from '@angular/core';
@Component({
  selector: 'lib-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
})
export class FooterComponent {
  @Input() public showDisclaimer: boolean;
  public year = new Date().getFullYear();
}
