import { Injectable } from '@angular/core';
import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { TariffOfCharges } from '../models/tariff-of-charges';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
export class TariffOfChargesService {

  public constructor(private clubHubDataService: ClubHubDataService) {}

  public save(lenderNameId: number, request: TariffOfCharges): Observable<void> {
    return this.clubHubDataService.post(`TariffOfCharges/${lenderNameId}`, request);
  }

  public get(lenderNameId: number): Observable<TariffOfCharges> {
    return this.clubHubDataService.get<TariffOfCharges>(`TariffOfCharges/${lenderNameId}`);
  }
}
