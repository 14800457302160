import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BridgingProduct, LenderProductDetailsViewModel, Product } from 'apps/shared/src/models';
import { ClubHubDataService } from './clubhub-data.service';

@Injectable({
  providedIn: 'root',
})
export class ClubHubESISService {

  private lendersWithConfiguredEsisInformation: string[] = [];

  public constructor(private clubHubDataService: ClubHubDataService) {}

  public getLenderProductDetails(
    lenderName: string,
    lendingTypeId: number,
    loanAmount: number | null,
    product: Product | BridgingProduct,
    isBdg: boolean,
  ): Observable<LenderProductDetailsViewModel> {
    // Ensure we don't pass an empty string to the endpoint
    if (loanAmount !== null && loanAmount !== undefined && typeof loanAmount !== 'number') {
      loanAmount = null;
    }
    return this.clubHubDataService.post<LenderProductDetailsViewModel>(
      `esisInformation/lender/${lenderName}/${lendingTypeId}`, { loanAmount, product, isBridgingProduct: isBdg });
  }

  public getEsisConfiguredLenders(lenderTypeId: number) {
    this.clubHubDataService.get<string[]>(`esisInformation/configured/${lenderTypeId}`)
      .subscribe(lenders => this.lendersWithConfiguredEsisInformation = lenders);
  }

  public isLenderEsisConfigured(lenderName: string): boolean {
    return this.lendersWithConfiguredEsisInformation.includes(lenderName);
  }
}
