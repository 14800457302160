export const returnUrlType = 'returnUrl';

export const queryParameterNames = {
  returnUrl: returnUrlType,
  message: 'message',
};

export const logoutActions = {
  logoutCallback: 'logout-callback',
  logout: 'logout',
  loggedOut: 'logged-out',
};

export const loginActions = {
  login: 'login',
  loginCallback: 'login-callback',
  loginFailed: 'login-failed',
  profile: 'profile',
  register: 'register',
  marketingPreferences: 'MarketingPreferences',
  crmSystemInformation: 'CrmSystemInformation',
};

interface ApplicationPathsType {
  readonly defaultLoginRedirectPath: string;
  readonly apiAuthorizationClientConfigurationUrl: string;
  readonly login: string;
  readonly loginFailed: string;
  readonly loginCallback: string;
  readonly register: string;
  readonly profile: string;
  readonly logOut: string;
  readonly loggedOut: string;
  readonly logOutCallback: string;
  readonly marketingPreferences: string;
  readonly crmSystemInformation: string;
  readonly loginPathComponents: string[];
  readonly loginFailedPathComponents: string[];
  readonly loginCallbackPathComponents: string[];
  readonly registerPathComponents: string[];
  readonly profilePathComponents: string[];
  readonly logOutPathComponents: string[];
  readonly loggedOutPathComponents: string[];
  readonly logOutCallbackPathComponents: string[];
  readonly identityRegisterPath: string;
  readonly identityManagePath: string;
  readonly identityMarketingPreferences: string;
  readonly identityCrmSystemInformation: string;
}

let appPaths: ApplicationPathsType = {
  defaultLoginRedirectPath: '/',
  apiAuthorizationClientConfigurationUrl: '/_configuration/',
  login: `authentication/${loginActions.login}`,
  loginFailed: `authentication/${loginActions.loginFailed}`,
  loginCallback: `authentication/${loginActions.loginCallback}`,
  register: `authentication/${loginActions.register}`,
  profile: `authentication/${loginActions.profile}`,
  logOut: `authentication/${logoutActions.logout}`,
  loggedOut: `authentication/${logoutActions.loggedOut}`,
  logOutCallback: `authentication/${logoutActions.logoutCallback}`,
  marketingPreferences: `authentication/${loginActions.marketingPreferences}`,
  crmSystemInformation: `authentication/${loginActions.crmSystemInformation}`,
  loginPathComponents: [],
  loginFailedPathComponents: [],
  loginCallbackPathComponents: [],
  registerPathComponents: [],
  profilePathComponents: [],
  logOutPathComponents: [],
  loggedOutPathComponents: [],
  logOutCallbackPathComponents: [],
  identityRegisterPath: '/Identity/Account/Register',
  identityManagePath: '/Identity/Account/ChangePassword',
  identityMarketingPreferences: `/Identity/Account/${loginActions.marketingPreferences}`,
  identityCrmSystemInformation: `/Identity/Account/${loginActions.crmSystemInformation}`,
};

appPaths = {
  ...appPaths,
  loginPathComponents: appPaths.login.split('/'),
  loginFailedPathComponents: appPaths.loginFailed.split('/'),
  registerPathComponents: appPaths.register.split('/'),
  profilePathComponents: appPaths.profile.split('/'),
  logOutPathComponents: appPaths.logOut.split('/'),
  loggedOutPathComponents: appPaths.loggedOut.split('/'),
  logOutCallbackPathComponents: appPaths.logOutCallback.split('/'),
};

export const applicationPaths: ApplicationPathsType = appPaths;
