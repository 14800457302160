import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthorizeService } from '../authorize.service';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
  public urlPropertyNames = ['clubHubApiUrl', 'lenderHubApiUrl', 'identityServerUrl'];

  public constructor(private authorize: AuthorizeService, private configService: ConfigService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authorize.getAccessToken().pipe(mergeMap(token => this.processRequestWithToken(token, request, next)));
  }

  // Checks if there is an access_token available in the authorize service
  // and adds it to the request in case it's targeted at the same origin as the
  // single page application.
  private processRequestWithToken(token: string | null, request: HttpRequest<unknown>, next: HttpHandler) {
    if (!!token && this.isSameOriginUrl(request)) {
      request = request.clone({
        setHeaders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request);
  }

  private isSameOriginUrl(request: any) {
    return this.urlPropertyNames.some(property => this.checkUrlAgainstProperty(property, request.url));
  }

  private checkUrlAgainstProperty(property: string, url: string): boolean {
    return this.configService.config.hasOwnProperty(property) &&
      url.startsWith(`${(this.configService.config as any)[property]}/`);
  }
}
