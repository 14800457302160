/**
 * Rounds the given value to the nearest multiple of `nearest`.
 * @example
 * roundToNearest(23, 10); // => 20
 * roundToNearest(13.45, 0.1); // => 13.5
 */
export function roundToNearest(value: number, nearest: number) {
  return Math.round(value / nearest) * nearest;
}

/**
 * Rounding function that resolves tie-breakers by rounding towards the nearest even number.
 * Also known as "gaussian rounding" or "banker's rounding".
 */
export function roundToEven(x: number) {
  return roundToNearest(x, 2);
}

/**
 * Rounds the value to the nearest pence value (nearest 0.01).
 */
export function roundToPence(x: number) {
  return roundToNearest(x, 0.01);
}
