import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { Observable, OperatorFunction, from, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { ToastService } from './toast.service';
import {
  BaseResponse,
  EMAIL_VERIFICATION_SERVICE,
  HttpRequestOptions,
  IEmailVerificationService,
  ILoadingOptions,
  LoadingOverlayMode,
  isBaseResponse,
  loadingImageHeader,
  loadingOverlayModeHeader,
} from '../models';

const verificationEmailTokenRequestHeaderName = 'X-Email-Verification-Token';
const requiresVerificationEmailErrorType = 'RequiresEmailVerification';

@Injectable()
export abstract class DataService {
  public baseUrl: string;

  protected constructor(
    private http: HttpClient,
    protected toastService: ToastService,
    protected configService: ConfigService,
    @Inject(String) baseUrl: string,
    @Inject(EMAIL_VERIFICATION_SERVICE) private emailVerificationService: IEmailVerificationService,
  ) {
    this.baseUrl = `${baseUrl}/api`;
  }

  // this function returns an observable of type T in the form of JSON
  // the advantage of an observable : if it changes then this will fire off the changed detection as the DOM has changed
  public get<T>(url: string, loadingOptions: ILoadingOptions | null = null, opt: HttpRequestOptions = {})
    : Observable<T> {
    return this.http.get<T | BaseResponse<T>>(
      `${this.baseUrl}/${url}`, { headers: this.getHeaders(loadingOptions) },
    ).pipe(
      this.extractResult(url),
      this.handleUnverifiedEmail(() => this.get<T>(url, loadingOptions, opt)),
      this.handleError(opt),
    );
  }

  public getForText(url: string, opt: HttpRequestOptions = {}): Observable<string | undefined> {
    return this.http.get<string | BaseResponse<string>>(`${this.baseUrl}/${url}`)
      .pipe(this.extractResult(url), this.handleError(opt));
  }

  public put<T>(url: string, itemToUpdate: unknown, opt: HttpRequestOptions = {}): Observable<T> {
    return this.http.put<T | BaseResponse<T>>(
      `${this.baseUrl}/${url}`, itemToUpdate, { headers: this.getHeaders(null) },
    ).pipe(
      this.extractResult(url),
      this.handleUnverifiedEmail(() => this.put<T>(url, itemToUpdate, opt)),
      this.handleError(opt),
    );
  }

  public post<T>(
    url: string, body: unknown, loadingOptions: ILoadingOptions | null = null, opt: HttpRequestOptions = {},
  ): Observable<T> {
    return this.http.post<T | BaseResponse<T>>(
      `${this.baseUrl}/${url}`, body, { headers: this.getHeaders(loadingOptions) },
    ).pipe(
      this.extractResult(url),
      this.handleUnverifiedEmail(() => this.post<T>(url, body, loadingOptions, opt)),
      this.handleError(opt),
    );
  }

  public postForText(url: string, body: unknown, opt: HttpRequestOptions = {}): Observable<string | undefined> {
    return this.http.post<string | BaseResponse<string>>(`${this.baseUrl}/${url}`, body)
      .pipe(this.extractResult(url), this.handleError(opt));
  }

  public patch<T>(url: string, body: unknown, opt: HttpRequestOptions = {}): Observable<T> {
    return this.http.patch<T | BaseResponse<T>>(
      `${this.baseUrl}/${url}`, body, { headers: this.getHeaders(null) },
    ).pipe(
      this.extractResult(url),
      this.handleUnverifiedEmail(() => this.patch<T>(url, body, opt)),
      this.handleError(opt),
    );
  }

  public delete<T>(url: string, opt: HttpRequestOptions = {}): Observable<T> {
    return this.http.delete<T | BaseResponse<T>>(`${this.baseUrl}/${url}`, { headers: this.getHeaders(null) })
      .pipe(
        this.extractResult(url),
        this.handleUnverifiedEmail(() => this.delete<T>(url, opt)),
        this.handleError(opt),
      );
  }

  public getFile(url: string, fileType: string | null = null): Observable<Blob> {
    return this.http
      .get(`${this.baseUrl}/${url}`, {
        responseType: 'blob',
        observe: 'response',
      })
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .pipe(map((res) => new Blob([res.body!], { type: fileType ?? res.headers.get('content-type') ?? undefined })));
  }

  public postFileBlob(url: string, body: unknown, fileType: string | null = null): Observable<Blob> {
    return this.http
      .post(`${this.baseUrl}/${url}`, body, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(map((res) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        new Blob([res.body!], { type: fileType ?? res.headers.get('content-type') ?? undefined }),
      ));
  }

  public postFileArrayBuffer(url: string, body: unknown, opt: HttpRequestOptions = {}): Observable<ArrayBuffer> {
    return this.http
      .post(`${this.baseUrl}/${url}`, body, {
        responseType: 'arraybuffer',
        observe: 'response',
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        map(res => res.body!),
        this.handleError(opt),
      );
  }

  private getHeaders(loadingOptions: ILoadingOptions | null): HttpHeaders {
    let headers = new HttpHeaders();

    // Loading spinner and image settings
    if (loadingOptions?.loadingImage) {
      headers = headers.set(loadingImageHeader, loadingOptions.loadingImage);
    }
    if (loadingOptions?.loading === false) {
      headers = headers.set(loadingOverlayModeHeader, LoadingOverlayMode.Disable);
    }

    // 'Requires email verification' header
    const verifiedEmailToken = this.emailVerificationService.verificationToken;
    if (verifiedEmailToken) {
      headers = headers.set(verificationEmailTokenRequestHeaderName, verifiedEmailToken);
    }

    return headers;
  }

  /**
   * Extracts the T value from a BaseResponse<T>, throwing an error if the response
   * contained an error.
   */
  private extractResult<T>(url: string): OperatorFunction<T | BaseResponse<T>, T | undefined> {
    return map((r: T | BaseResponse<T>) => {
      // TEMPORARILY CHECK TO SEE IF THE RESPONSE IS A BASERESPONSE<T>
      // THIS IS ONLY HERE WHILE THE TRANSITIONS IS MADE TO MAKE CONTROLLERS
      // RETURN A BASERESPONSE.
      if (!isBaseResponse(r)) {
        // eslint-disable-next-line no-console
        console.warn(`[Dev] Request made to '${url}' did not return a BaseResponse.`);
        return r;
      }
      if (r.error) {
        throw new Error(r.error.message);
      }
      return r.result;
    });
  }

  /**
   * Handles a Forbidden error where the header indicates that the endpoint requires the user to verify their email.
   * @param onVerified Callback that will be invoked once the user has verified their email (i.e. to repeat the request)
   */
  private handleUnverifiedEmail<T>(onVerified: () => Observable<T>): OperatorFunction<T, T> {
    return catchError((error: HttpErrorResponse) =>
      error.status as HttpStatusCode === HttpStatusCode.Forbidden &&
        error.error?.error?.type === requiresVerificationEmailErrorType
        // If the failure is due to the SSO user not having their email verified, begin the flow to verify their email.
        // If that errors (i.e. failed or cancelled), throw the original (forbidden) error back to dataservice.
        // If verification was successfully, retry the request (which will have the email token in the headers)
        ? from(this.emailVerificationService.beginEmailVerificationFlow())
          .pipe(
            catchError(() => throwError(() => error)),
            switchMap(onVerified),
          )
        : throwError(() => error),
    );
  }

  /**
   * Handles an error in the Http pipeline. Shows a toast notification of the error
   * and rethrows the error message forwards.
   */
  private handleError<T>(options: HttpRequestOptions): OperatorFunction<T, T> {
    return catchError((err: any) => {
      if (options?.logErrorToConsole ?? true) {
        // eslint-disable-next-line no-console
        console.error('Caught unsuccessful HTTP response: ', err);
      }

      let message = 'An unknown error occurred.';
      let requestId;

      // If the response type is an array buffer, try convert it to JSON
      if (err.error instanceof ArrayBuffer) {
        try {
          err.error = JSON.parse(new TextDecoder().decode(err.error as ArrayBuffer));
        } catch {
          // Not a valid JSON, ignore it
        }
      }

      // Check if err.error is a BaseResponseError
      if (isBaseResponse(err.error)) {
        message = (err.error as BaseResponse<T>).error?.message ?? '';
        requestId = (err.error as BaseResponse<T>).error?.requestId ?? '';
      }

      // Handling other error types, such as 401 Unauthorized.
      if (err instanceof HttpErrorResponse) {
        if (isBaseResponse((err as HttpErrorResponse).error)) {
          message = (err.error as BaseResponse<T>).error?.message ?? '';
          requestId = (err.error as BaseResponse<T>).error?.requestId ?? '';
        } else {
          message = (err as HttpErrorResponse).statusText;
        }
      }

      if (options?.showErrorToast ?? true) {
        const toast = `${message} requestId: ${requestId}`;
        this.toastService.danger(toast, {
          error: true,
          autoHide: false,
          header: 'An error occurred while trying to complete the request',
          message: message,
          requestId: requestId,
        });
      }

      return throwError(() => err);
    });
  }
}
