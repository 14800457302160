import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { AuthorizeService, IUser } from '../authorize.service';

export const roleGuard: CanActivateFn =
  (route: ActivatedRouteSnapshot) => {
    const authorize = inject(AuthorizeService);
    const router = inject(Router);

    return authorize.getUser().pipe(
      map((user: IUser) => {
        const roleFound = route.data?.roles.some((role: string) =>
          Array.isArray(user.role) ? user.role.some((userRole: string) => userRole === role) : user.role === role,
        );

        if (user.role && !roleFound) {
          router.navigate(['error']);
        }

        return true;
      }),
    );
  };
