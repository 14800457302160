<div class="modal-header">
  <h3 class="modal-title">Please verify your identity</h3>
  <button type="button" aria-label="Close" class="btn-close" (click)="activeModal.dismiss()">
  </button>
</div>

<div class="modal-body bg-light text-center" [ngSwitch]="page">
  <form (submit)="verify()" *ngSwitchCase="pageEnum.CodeEntry">
    <p>
      To access this functionality within Legal & General Ignite, you will need to verify your identity by entering the 6 digit code we
      have sent to your email address.
    </p>

    <ng-container *ngIf="!requiresEmailResend; else requiresResendTplRef">
      <input
        type="text"
        class="form-control form-control-lg text-center mb-3"
        maxLength="6"
        [(ngModel)]="verificationCode"
        [ngModelOptions]="{ standalone: true }"
      />
      <p *ngIf="codeErrorMessage" class="text-danger">{{ codeErrorMessage }}</p>
      <button type="submit" class="btn btn-2022--secondary">
        Verify
      </button>
    </ng-container>

    <ng-template #requiresResendTplRef>
      <p *ngIf="codeErrorMessage" class="text-danger">{{ codeErrorMessage }}</p>
      <button
        *ngIf="requiresEmailResend"
        type="button"
        class="btn btn-2022--secondary"
        [disabled]="!emailVerificationService.canRequestVerificationEmail"
        (click)="resendEmail()"
      >
        <ng-container *ngIf="emailVerificationService.canRequestVerificationEmail; else requiresWaitTplRef">
          Re-send code
        </ng-container>
        <ng-template #requiresWaitTplRef>
          Please wait {{ emailVerificationService.canRequestVerificationEmailIn }} seconds
        </ng-template>
      </button>
    </ng-template>
  </form>

  <ng-container *ngSwitchCase="pageEnum.Confirmed">
    <p><strong>Verification successful</strong></p>
    <p><strong>Remember me for 30 days?</strong></p>
    <p>Are you using a shared device?</p>
    <div class="mb-3">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="isSharedDevice" id="isSharedDevice_true" [(ngModel)]="isSharedDevice" [value]="true">
        <label class="form-check-label" for="isSharedDevice_true">Yes</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="isSharedDevice" id="isSharedDevice_false" [(ngModel)]="isSharedDevice" [value]="false">
        <label class="form-check-label" for="isSharedDevice_false">No</label>
      </div>
    </div>
    <p class="text-danger" [hidden]="isSharedDevice !== true">
      Unfortunately this option is not available on shared devices.
    </p>
    <button
      class="btn btn-2022--secondary"
      [hidden]="isSharedDevice === undefined"
      (click)="completeVerification()"
    >
      {{ isSharedDevice ? 'Close Window' : 'Remember Me' }}
    </button>
  </ng-container>
</div>
