import { GridStateModel } from '../../../models';
import { SimpleGridComponent } from '../simple-grid.component';
import { Datasource, DatasourceResult } from './datasource';

export class FunctionDatasource<T = unknown> implements Datasource<T> {

  public allowRapidFiltering = false;

  public constructor(
    private fetchItems: (state: GridStateModel, grid: SimpleGridComponent) => Promise<DatasourceResult<T>>,
  ) { }

  public getItems(state: GridStateModel, grid: SimpleGridComponent): Promise<DatasourceResult<T>> {
    return this.fetchItems(state, grid);
  }
}
