import { Injectable } from '@angular/core';
import { ClubHubDataService } from './clubhub-data.service';
import { PropertyType } from 'apps/shared/src/models';
import { Address, AddressExtended } from '../models/address';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PostcodeService {
  public constructor(private clubHubDataService: ClubHubDataService) {}

  public findAddress(postcode: string, lines = 1): Observable<Address[]> {
    return this.clubHubDataService.post<Address[]>(`Address/PostcodeSearch?addressLines=${lines}`, {
      postcode: postcode,
    });
  }

  public findAddressExtended(postcode: string) {
    return this.clubHubDataService.post<AddressExtended[]>('Address/PostcodeSearch?extended=true', {
      postcode: postcode,
    });
  }

  public findPropertyType(postcode: string, address: string) {
    return this.clubHubDataService.get<PropertyType | null>(
      `Address/PropertyTypeSearch?postcode=${postcode}&address=${address}`,
    );
  }
}
