<div [formGroup]="formGroup" [class]="config.cssClass ? 'mt-3 d-grid gap-2 ' + config.cssClass: 'mt-3 d-grid gap-2'">
  <button class="btn btn-2022--grey" (click)="config?.onCancel()" [disabled]="config.disabled" type="button"
          [attr.data-testid]="config.cancelButtonLabel || 'Cancel' + 'Btn'">
    {{ config.cancelButtonLabel || 'Cancel' }}
  </button>
  <button class="btn btn-2022--secondary" (click)="submit($event)" [disabled]="config.disabled" type="submit"
          [attr.data-testid]="config.label || 'Submit' + 'Btn'">
    {{ config.label || 'Submit' }}
  </button>
</div>
