import { Component, Inject } from '@angular/core';
import { AuthorizeService, ModalStateService, PageNavMessageService } from '@msslib/services';
import { IgniteService } from 'apps/clubhub/src/app/ignite/services';
import { NavMessageKeys, NavPosition, PageNavMessage } from '@msslib/models';

@Component({
  selector: 'app-case-link-modal',
  templateUrl: 'case-link-modal.component.html',
  styleUrls: ['case-link-modal.component.scss'],
})
export class CaseLinkModalComponent {
  public constructor(
    @Inject(ModalStateService) private state,
    @Inject(AuthorizeService) private authService,
    @Inject(IgniteService) private igniteService,
    @Inject(PageNavMessageService) private pageNavMessageService,
  ) {}

  public get s365Data() {
    return this.igniteService.s365Data;
  }

  public linkCase() {
    this.pageNavMessageService.pushToMessages(this.navigationMessage);
    this.state.topmostModal?.close('confirmed');
    if (this.state.modalStack?.length > 0) {
      this.state.modal = this.state.topmostModal;
    }
  }

  public unlinkCase() {
    this.igniteService.s365DataReset().subscribe(() => {
      this.pageNavMessageService.removeFromMessages(NavMessageKeys.CaseLink);
      this.state.topmostModal?.close('confirmed');
      if (this.state.modalStack?.length > 0) {
        this.state.modal = this.state.topmostModal;
      }
    });
  }

  public get navigationMessage() : PageNavMessage {
    const navMsg = this.s365Data.sourcingCallBack ?
      `Linked to case ${this.reference} - ${this.forename} ${this.surname} in ${this.clientId}` :
      `Using case ${this.reference} - ${this.forename} ${this.surname} to auto populate forms`;
    return {
      key: NavMessageKeys.CaseLink,
      navItem: {
        textWithoutLink: navMsg,
      },
      position: NavPosition.Left,
    };
  }

  public get clientId() {
    return this.authService.user?.client_id;
  }

  private get forename() {
    return this.s365Data.clientDetails?.forename;
  }

  private get surname() {
    return this.s365Data.clientDetails?.surname;
  }

  private get reference() {
    return this.s365Data.reference;
  }

  private get hideClientDetails() {
    return !this.s365Data.clientDetails?.forename || !this.s365Data.clientDetails?.surname;
  }

  public get modalHeader() : string {
    return this.s365Data.sourcingCallBack ?
      `Link to ${this.clientId} case ${this.reference}${this.hideClientDetails ?
        '' : ` - ${this.forename} ${this.surname}`}?` :
      `Use ${this.clientId} data from case ${this.reference}${this.hideClientDetails ?
        '' : ` - ${this.forename} ${this.surname}`}?`;
  }

  public get modalText() : string {
    return this.s365Data.sourcingCallBack ?
      `Do you want to link this Legal & General Ignite session to case ${this.reference}${this.hideClientDetails ?
        '' : ` - ${this.forename} ${this.surname}`} in ${this.clientId}? If you choose Yes your sourcing history
        in ${this.clientId} will be updated each time you generate product documentation during this session.` :
      `Some case data has been automatically provided to us by your broker platform. Would you like us to use
        this data to auto populate forms? Your reference for this data is
        ${this.reference}${this.hideClientDetails ? '' : ` - ${this.forename} ${this.surname}`}.`;
  }
}
