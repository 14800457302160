<div class="switch">
  <input
    type="checkbox"
    [id]="id"
    class="form-check-input"
    [disabled]="disabled"
    [checked]="checked"
    (change)="changeHandler($event)"
    [attr.data-testid]="'toggle_switch_'+id"
  />
  <label class="form-check-label" [for]="id">
    <div class="switch-slider me-2"></div>
    <span class="label text-white" *ngIf="label">{{ label }}</span>
  </label>
</div>
