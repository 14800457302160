export enum StepName {
  PropertyAndLoan,
  Applicants,
  IncomeAndExpenditure,
  OtherProperties,
  Results,
  MortgageRequirements,
}

export interface IStep {
  stepName: StepName;
  labelText: string;
  rightText?: string;
  anchorId?: string;
  isValid: boolean;
  isResults: boolean;
}
