import { Injectable } from '@angular/core';
import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { IEmbedConfig, IMiReport } from './mi-generic.model';

@Injectable({ providedIn: 'root' })
export class MiGenericService {
  public constructor(private dataService: ClubHubDataService) {}

  public getReports(endpoint: string) {
    return this.dataService.get<IMiReport[]>(`powerbi/${endpoint}`);
  }

  public getReportConfiguration(reportId: string, endpoint: string) {
    return this.dataService.get<IEmbedConfig>(`powerbi/${endpoint}/${reportId}`);
  }
}
