import { Injectable } from '@angular/core';
import { ProductsSort } from '../models/products/products-sort';

@Injectable({
  providedIn: 'root',
})
export class ProductsResultSettingsService {
  private defaultSort: ProductsSort = { sortOn: '', sortDir: 'asc'};
  private appliedSort: ProductsSort;

  public get productsSort(): ProductsSort {
    return !!this.appliedSort?.sortOn
      ? this.appliedSort
      : this.defaultSort;
  }

  public resetSort(): void {
    this.appliedSort = { sortOn: '', sortDir: 'asc'};
  }

  public setDefaultSort(sort: ProductsSort): void {
    this.defaultSort = sort;
  }

  public setSort(sort: ProductsSort): void {
    this.appliedSort = sort;
  }
}
