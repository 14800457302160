import { Component, OnInit } from '@angular/core';
import { ExternalLoginService } from 'apps/clubhub/src/app/services';

@Component({
  selector: 'app-external-login',
  templateUrl: 'external-login.component.html',
  styleUrls: ['external-login.component.scss'],
})
export class ExternalLoginComponent implements OnInit {
  public constructor(private externalAuthService: ExternalLoginService) {}

  public ngOnInit() {
    this.externalAuthService.loginExternalUser();
  }
}
