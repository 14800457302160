export interface BaseResponseError {
  type: string;
  message: string;
  requestId: string;
  data: Record<string, unknown>;
}
export type BaseResponse<T> = { result: T; error: null } | { result: null; error: BaseResponseError };


/**
 * Determines if an object is likely to be a BaseResponse.
 */
export function isBaseResponse(response: unknown): response is BaseResponse<unknown> {
  if (response === null || typeof (response) !== 'object') {
    return false;
  }
  return 'error' in response && 'result' in response;
}
