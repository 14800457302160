import { Component, Input } from '@angular/core';

import { IListItem } from '../../models';

@Component({
  selector: 'lib-list',
  styleUrls: ['list.component.scss'],
  templateUrl: 'list.component.html',
})
export class ListComponent {
  @Input() public list: IListItem[];

  public trackByFn(index: number) {
    return index;
  }
}
