<!-- Body section -->
<div class="row">
  <div class="col">
    <h1 class="text-center"><u>Licence Agreement</u></h1>
    <br />
    <p class="fw-bold">PLEASE READ CAREFULLY BEFORE ACCESSING ANY SOFTWARE FROM THE LENDER HUB PORTAL:</p>
    <p>This Licence Agreement is a legal agreement between the Lender and L&amp;G for the use of the Relevant Software
      (as defined below). The use of
      the Relevant Software is provided on a licensed basis only and L&amp;G remains the owner or licensor of the
      Relevant
      Software.</p>
    <p><strong>IMPORTANT NOTICES FOR ALL USERS:</strong></p>
    <ul>
      <li>BY CLICKING ON THE &ldquo;ACCEPT&rdquo; BUTTON BELOW YOU:
        <ul>
          <li>CONFIRM THAT YOU (ON BEHALF OF THE LENDER) HAVE READ THE TERMS AND CONDITIONS OF THIS LICENCE AGREEMENT,
            WHICH WILL BIND YOU AND THE LENDER.&nbsp;</li>
          <li>WARRANT, REPRESENT AND AGREE THAT YOU:
            <ul>
              <li>ARE AUTHORISED TO BIND THE LENDER IN ENTERING INTO THIS LICENCE AGREEMENT; OR</li>
              <li>HAVE WRITTEN PERMISSION TO USE THE RELEVANT SOFTWARE ON BEHALF OF THE LENDER (BY EMAIL ACCEPTABLE)
                GIVEN
                TO
                YOU BY
                AN INDIVIDUAL WHO MEETS THE CONDITION ABOVE AND HAS ACCEPTED THE TERMS OF THIS LICENCE AGREEMENT.</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>THIS LICENCE AGREEMENT INCLUDES, IN PARTICULAR, <strong>LIMITATIONS ON LIABILITY IN
          CLAUSE 3.7</strong> AND <strong>INDEMNITIES
          IN CLAUSE 6</strong>.
      </li>
      <li>IF YOU (OR THE LENDER) DO NOT AGREE TO THIS LICENCE AGREEMENT OR IF YOU DO NOT HAVE AUTHORITY (AS ABOVE) TO
        BIND THE LENDER THEN YOU
        MUST CLICK ON THE &ldquo;REJECT&rdquo; BUTTON BELOW AND YOU (AND THE LENDER) MAY NOT ACCESS THE RELEVANT
        SOFTWARE.
      </li>
      <li>YOU SHOULD PRINT A COPY OF THIS LICENCE AGREEMENT FOR FUTURE REFERENCE.</li>
    </ul>
    <ol>
      <li><strong><u>Definitions and Interpretation</u></strong>
        <ol>
          <li>In this Licence Agreement, the following terms shall have the following meanings:</li>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;Affiliate&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>in respect of a
                    person, any persons that Control, are Controlled by or are under common Control with that person
                    from
                    time
                    to
                    time and for this purpose &ldquo;<strong>Control</strong>&rdquo; means, in relation to a person, the
                    power
                    (whether direct or indirect) to direct or cause the direction of its affairs, whether by means of
                    holding
                    shares, possessing voting power, exercising contractual powers or otherwise and
                    &ldquo;<strong>Controlled</strong>&rdquo; will be construed accordingly;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;Authorised Purpose&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>means the use of
                    the
                    Relevant Software solely for the purpose of supplying, maintaining and updating Lender&rsquo;s
                    information,
                    lending criteria and product information
                    related
                    to
                    mortgage business in the United Kingdom in order to facilitate intermediaries&rsquo; and
                    brokers&rsquo;
                    research on lending criteria, loan suitability and product suitability;&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;Authorised User&rdquo;</strong> or&nbsp;<strong>&ldquo;You&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>means:</p>
                  <p>(a) those
                    employees of
                    the Lender; and&nbsp;</p>
                  <p>(b) any such other
                    persons, who are authorised by the Lender, to use the Relevant Software on the Lender&rsquo;s behalf
                    and
                    who
                    in the case of both (a) and (b) is presently registered with the Lender Hub Portal and who has
                    accepted
                    this
                    Licence Agreement to use the Relevant Software for the Authorised Purpose on the Lender&rsquo;s
                    behalf
                    and
                    at
                    all times excluding any persons who L&amp;G do not permit or have deregistered from access to the
                    Relevant
                    Software (as updated from time to time);</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;Compatibility Requirements&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>means using the following browsers in the versions set out below or in any subsequent versions:
                    Chrome
                    v40+,&nbsp;Safari v11.1+, Firefox v44+ or Edge v17+;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;ESIS&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>means the European
                    Standardised Information Sheet that is required to be provided to a consumer in accordance with the
                    requirements of the Financial Conduct Authority&rsquo;s Mortgage and Home Finance Conduct of
                    Business
                    Sourcebook;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;Financial Conduct Authority&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>means the conduct
                    regulator of financial services in the UK currently known as the Financial Conduct Authority and any
                    replacement organisation(s) from time to time;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;Financial Crime&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>means&nbsp;all
                    applicable legislation, statutes, statutory instruments, orders, rules, regulations and codes of
                    practice
                    (whether or not having the force of law) in force from time to time relating to prevention of
                    financial
                    crime
                    and tax evasion, including without limitation, the Terrorism Act 2000 (as amended by the
                    Anti-Terrorism
                    Crime
                    and Security Act 2001), the Criminal Justice Act 1993, the Money Laundering, Terrorist Financing and
                    Transfer
                    of Funds (Information on the Payer) Regulations 2017, the Proceeds of Crime Act 2002, The Fraud Act
                    2006,
                    the
                    Enterprise Act 2002, the Terrorist Asset-Freezing Act 2010, The Bribery Act 2010, and applicable
                    requirements
                    of the Financial Conduct Authority;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;Force
                      Majeure Event&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>means any event or
                    circumstance which prevents or delays a Party from performing any of its obligations under this
                    Licence
                    Agreement provided always that the relevant event or circumstance is beyond the reasonable control
                    of
                    the
                    Party claiming relief, including (a) cyber-attacks; (b) security incidents; (c) acts of God; (d)
                    fire;
                    (e)
                    flood; (e) electric outages; and (f) pandemic;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;Ignite Tools&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>means the
                    current and future online system currently known as Ignite that includes the criteria search engine,
                    the affordability calculator, the products display and the sourcing system (current and future, as
                    may be made available, amended and updated by L&amp;G from time to time) that L&amp;G (or any of
                    its licensors) makes available to brokers, intermediaries and/or third parties;
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;Intellectual Property Rights&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>means all
                    intellectual
                    and industrial property rights of any kind whatsoever including patents, supplementary protection
                    certificates, rights in know-how, registered trade marks, registered designs, utility models,
                    unregistered
                    design rights, unregistered trade marks, rights to prevent passing off or unfair competition and
                    copyright
                    (whether in drawings, plans, specifications, designs and computer software or otherwise), database
                    rights,
                    topography rights, any rights in any invention, discovery or process, and applications for and
                    rights
                    to
                    apply
                    for any of the foregoing, in each case in the United Kingdom and all other countries in the world
                    and
                    together
                    with all renewals, extensions, continuations, divisions, reissues, re-examinations and
                    substitutions;
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;L&amp;G&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>means Legal and
                    General Partnership Services Limited (company number 05045000), whose registered office is at One
                    Coleman
                    Street, London, EC2R 5AA;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;Lender&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>means the
                    organisation
                    that:</p>
                  <p>(a) you represent
                    when
                    accepting this Licence Agreement;</p>
                  <p>(b) you are acting
                    on
                    behalf of or using the Relevant Software and/or Lender Hub Portal on behalf of; or</p>
                  <p>(c) that has
                    authorised your use of the Relevant Software and/or the Lender Hub Portal;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;Lender
                      Hub Portal&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>means the portal
                    developed by L&amp;G and made available to the Lender by L&amp;G for use by the Lender for the
                    Authorised
                    Purpose which is currently https://www.legalandgeneral.com/adviser/mortgage-club/ and may be updated
                    by or
                    on
                    behalf of L&amp;G from time to time;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;Liability&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>means liability
                    arising out of or in connection with this Licence Agreement, whether in contract, tort,
                    misrepresentation,
                    restitution, under statute or otherwise (but excluding any liability under an indemnity contained in
                    this
                    Licence Agreement) and/or arising from a breach of, or a failure to perform or defect or delay in
                    performance
                    of, any of a Party&rsquo;s obligations under this Licence Agreement and/or any defect in the
                    Relevant
                    Software
                    or any of it or any part of it, in each case howsoever caused including if caused by negligence;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;Licence
                      Agreement&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>means this licence
                    agreement;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;Party&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>means each of the
                    Lender and L&amp;G, and &ldquo;Parties&rdquo; shall be construed accordingly;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;Regulatory Requirements and Applicable Laws&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p>means all
                    applicable
                    legislation, statutes, statutory instruments, orders, rules, regulations and codes of practice
                    (whether or
                    not
                    having the force of law) in force from time to time relating to the matters contemplated by this
                    Agreement,
                    and in particular but without limitation Financial Crime, Data Protection Laws, Modern Slavery Act
                    2015
                    and
                    the requirements, rules, regulations, guidance and codes of practice of and under FSMA and issued by
                    the
                    Prudential Regulation Authority, Financial Conduct Authority, and/or any Data Protection Supervisory
                    Authority
                    (as defined in the Data Protection Policy); and</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>&ldquo;Relevant Software&rdquo;</strong>
                  </p>
                </td>
                <td>
                  <p><span>means the Lender Hub Portal and all software (including
                      without
                      limitation all software items, programmes, applications and hosted services) that L&amp;G (or any
                      of
                      its
                      licensors) makes available to the Lender through or via the Lender Hub Portal (including any part
                      thereof
                      and all data supplied with software and any associated media). This may include a facing view of
                      the
                      Ignite Tools which would allow the Lender to test and view how its information may appear
                      externally
                      to
                      users of the Ignite Tools and to other lenders.</span></p>
                </td>
              </tr>
            </tbody>
          </table>
          <li><span>Any words following the words &ldquo;include&rdquo;,
              &ldquo;includes&rdquo;,
              &ldquo;including&rdquo;, &ldquo;in particular&rdquo; or any similar words or expressions will be construed
              without
              limitation and accordingly will not limit the meaning of the words preceding them.</span></li>
          <li><span style="font-family:Arial;">Words in the singular include the plural and vice versa.</span></li>
        </ol>
      </li>
      <li><span class="fw-bold">Application of these Terms</span>
        <ol>
          <li>Each Party hereby acknowledges receipt of the sum of &pound;1 received from the other Party and paid
            in
            consideration of entering into this Licence Agreement. In consideration for the aforementioned sums and
            the
            Parties&rsquo; agreement to abide by the terms of this Licence Agreement, L&amp;G grants to the Lender a
            licence
            to use the Relevant Software on the terms of this Licence Agreement.</li>
          <li>L&amp;G may amend this Licence Agreement from time to time by presenting a revised version of the
            Licence
            Agreement, to an Authorised User on log in and an Authorised User clicking to accept on behalf of the
            Lender
            (and such amendments shall be effective from the time that such Authorised User clicks accept).
            &nbsp;L&amp;G&rsquo;s ability to introduce amendments will include the ability to introduce charges
            relating
            to
            the use of the Lender Hub Portal and/or the Relevant Software. Every time You wish to use the Lender Hub
            Portal
            or the Relevant Software, please check the terms of this Licence Agreement to ensure You understand the
            terms
            that apply at that time. &nbsp;</li>
        </ol>
      </li>
      <li><span class="fw-bold">Use of the Relevant Software</span>
        <ol>
          <li>With effect from the date that this Licence Agreement is accepted by the Lender, L&amp;G grants to the
            Lender
            a revocable, non-exclusive, non-transferable right, without the right to grant sublicences, to permit
            Authorised
            Users to use the Relevant Software solely for the Authorised Purpose in the United Kingdom in accordance
            with
            this Licence Agreement.&nbsp;</li>
          <li>L&amp;G shall use reasonable endeavours to make the Relevant Software available between 9 am and 6 pm
            United
            Kingdom time on days that are not Saturday, Sunday, or public bank holidays in the United Kingdom,
            however
            L&amp;G shall have no obligation to make the Relevant Software available for use by the Lender at any
            given
            time
            or during any scheduled or emergency maintenance.</li>
          <li>Without prejudice to L&amp;G&rsquo;s other rights and remedies, the Lender&rsquo;s right to access and
            use
            (and to permit Authorised Users to access and use) the Relevant Software will commence on the date that
            these
            terms are accepted and automatically cease on the termination of this Licence Agreement.&nbsp;</li>
          <li>The Lender will not (and will procure that none of the Authorised Users will):&nbsp;<ol>
              <li>download, install, copy or reproduce in any way the whole or any part&nbsp;of the Relevant
                Software or
                attempt to ascertain or list the source programs or source code relating to the Relevant Software;
              </li>
              <li>modify, alter, adapt, make error corrections to, create derivative works from, or in any way
                interfere
                with the whole or any part&nbsp;of the Relevant Software or merge it with or incorporate it into
                other
                data,
                programs or systems;</li>
              <li>(save to the extent that such a prohibition is expressly prohibited by law) decompile, reverse
                engineer,
                decode or disassemble the whole or any part&nbsp;of the Relevant Software or translate it into any
                other
                computer language;</li>
              <li>permit any other person to access or use the whole or any part&nbsp;of the Relevant Software save
                for
                the
                Authorised Users;</li>
              <li>use the whole or any part&nbsp;of the Relevant Software for a purpose other than the Authorised
                Purpose
                including to provide services to third parties or for the benefit of any third party;</li>
              <li>integrate or attempt to integrate all or any part of the Relevant Software into any third party
                systems or
                websites;</li>
              <li>remove or alter any copyright notices or similar proprietary devices, including any electronic
                watermarks
                or other identifiers, that may be incorporated in the Relevant Software;</li>
              <li>take any action to build or support or assist in building or supporting a product or service which
                competes with the Relevant Software, whether by a third party or itself, including any access to or
                any
                part
                of the Relevant Software or the provision of any information related to any Relevant Software;</li>
              <li>bypass or breach any security device or protection used for or contained in the Relevant Software;
              </li>
              <li>assign, novate, sub-license, rent, lease, sell, pledge, charge, transfer, distribute, display,
                disclose or
                otherwise dispose of, grant rights over or out of or commercially exploit the licence granted by
                this
                Licence Agreement or the Relevant Software;&nbsp;</li>
              <li>permit or allow any usage of the Relevant Software outside of the Authorised Purpose; or</li>
              <li>purport or attempt to do any of the above.</li>
            </ol>
          </li>
          <li>The Lender will notify L&amp;G in writing as soon as it becomes aware of any unauthorised access or
            use of
            any
            Relevant Software by any person.</li>
          <li>The Lender will comply with and procure that all Authorised Users comply with all applicable
            technology
            control or export laws and regulations.</li>
          <li>The Lender acknowledges and agrees that:<ol>
              <li>L&amp;G has no responsibility for any of the outputs of the Relevant Software and does not verify
                any
                output of the Relevant Software and it would not be reasonable to rely on L&amp;G for the accuracy
                of
                any
                information, illustrations or output by the Relevant Software;</li>
              <li>L&amp;G under this Licence Agreement is acting as a facilitator of the Relevant Software to
                Lenders,
                and
                L&amp;G is not arranging for any persons entering into a mortgage product or varying any obligations
                that
                such persons may have under a mortgage product; and</li>
              <li>L&amp;G does not act on behalf of any brokers or intermediaries or any other persons
                notwithstanding
                that
                brokers, intermediaries or other persons may approach the Lender following their use of software
                facilitated
                by L&amp;G.</li>
            </ol>
          </li>
        </ol>
      </li>
      <li><span class="fw-bold">Liability</span>
        <ol>
          <li>Subject to clause&nbsp;<span style='font-family:"Times New Roman",serif;'>&lrm;</span>4.4, the Lender
            acknowledges and agrees that:<ol>
              <li>all warranties, representations, conditions and all other terms or duties of any kind whatsoever
                implied
                by law (whether by statute, common law or otherwise) are, to the fullest extent permitted by law,
                excluded
                from this Licence Agreement;</li>
              <li>the Relevant Software is made available to the Lender on an &ldquo;as is&rdquo; basis, for
                convenience
                only, and would not be made available in the absence of these exclusions or limitations of
                liability;
              </li>
              <li>L&amp;G makes no guarantee and will have no Liability for the accuracy of any outputs of the
                Relevant
                Software, that the Relevant Software meets the Lender&rsquo;s requirements or the availability of
                the
                Relevant Software;&nbsp;</li>
              <li>L&amp;G will have no Liability to the Lender for any output of the Relevant Software, or for any
                decisions, action or inaction taken by any party based on such output; and</li>
              <li>it shall decide at its absolute discretion whether to lend and the terms on which it is prepared
                to
                lend.
              </li>
            </ol>
          </li>
          <li>Subject to clause&nbsp;<span style='font-family:"Times New Roman",serif;'>&lrm;</span><span
              style='font-family:"Times New Roman",serif;'>&lrm;</span>4.4<span>,
              L&amp;G&nbsp;</span>will have no Liability arising out of or in connection with this Agreement.&nbsp;
          </li>
          <li>Subject to clause&nbsp;<span style='font-family:"Times New Roman",serif;'>&lrm;</span>4.4&nbsp;and
            without
            prejudice to clause&nbsp;<span style='font-family:"Times New Roman",serif;'>&lrm;</span>4.2<span>,
              L&amp;G&nbsp;</span>will have no Liability:<ol class="decimal_type" style="list-style-type: undefined;">
              <li>for any use of Relevant Software that is third party software or open source software; or</li>
              <li>for any of the losses listed below in this clause&nbsp;<strong><span
                    style='font-family:"Times New Roman",serif;'>&lrm;</span></strong>4.3.2<strong>&nbsp;</strong>(in
                each
                case whether direct, indirect or consequential):<ol class="decimal_type"
                  style="list-style-type: undefined;">
                  <li>loss of profit, loss of revenue, loss of business, loss of anticipated savings or loss of
                    margin
                  </li>
                  <li>loss of use, loss of goodwill, loss of reputation or loss of opportunity;</li>
                </ol>
              </li>
              <li>for any indirect or consequential loss; or</li>
              <li>for any Liability arising out of the use of the Relevant Software by the Lender or its Authorised
                Users in
                breach of this Agreement.&nbsp;</li>
            </ol>
          </li>
          <li>Nothing in this Licence Agreement excludes or limits the liability of any Party for:<ol>
              <li>death or personal injury caused by negligence;</li>
              <li>for fraud or fraudulent misrepresentation; or</li>
              <li>for any matter for which it is not permitted by law to exclude or limit, or to attempt to exclude
                or
                limit, its liability.&nbsp;</li>
            </ol>
          </li>
          <li>Subject to clause&nbsp;<span style='font-family:"Times New Roman",serif;'>&lrm;</span><span
              style='font-family:"Times New Roman",serif;'>&lrm;</span>4.4, the Lender acknowledges and agrees that
            the
            Relevant Software has not been developed to meet its individual requirements, including any particular
            cybersecurity requirements it might be subject to under law or otherwise and that it is therefore the
            Lender&rsquo;s responsibility to ensure that the facilities, functions and outputs of the Relevant
            Software
            meet
            its requirements.</li>
          <li>Subject to clause&nbsp;<span style='font-family:"Times New Roman",serif;'>&lrm;</span>4.4, a Party
            will
            not be
            in breach of this Licence Agreement or otherwise liable to the other Party for any failure to perform or
            delay
            in performing its obligations under this Licence Agreement to the extent that such failure or delay is
            due
            to a
            Force Majeure Event or the direct effects of a Force Majeure Event.&nbsp;</li>
        </ol>
      </li>
      <li><span class="fw-bold">Lender&rsquo;s Obligations</span>
        <ol>
          <li>The Lender will, and will ensure that the Authorised Users will, use the Relevant Software in
            accordance
            with
            this Licence Agreement and all Regulatory Requirements and Applicable Laws and will be responsible for
            any
            Authorised User&rsquo;s breach of this Licence Agreement or any Regulatory Requirements and Applicable
            Laws&nbsp;and any Authorised User&rsquo;s acts, errors or omissions as though they were breaches, acts,
            errors
            or omissions of the Lender.</li>
          <li>The Lender will make its lending criteria and product information available on the Relevant Software and ensure that it is updated
            from
            time
            to time.<span>&nbsp;Lender acknowledges and consents that
              Lender&rsquo;s
              information, as may appear externally to users of the Ignite Tools, may be viewed by other lenders
              using
              the Relevant Software under similar terms. The Lender further agrees that it will view the information
              made
              available by other lenders (as may appear externally to users of the Ignite Tools) strictly for the
              Authorised Purpose on the terms of this Licence Agreement, subject to applicable laws</span>.&nbsp;
          </li>
          <li>The Lender is responsible for ensuring that it and its Authorised Users hold the required regulatory
            permissions and comply with all Regulatory Requirements and Applicable Laws in force from time to time
            relating
            to the matter contemplated by this Licence Agreement (including without limitation the use of the
            Relevant
            Software), without affecting its other obligations under this Licence Agreement and with respect to
            their
            activities under this Licence Agreement. The Lender will ensure that it does not facilitate, allow or
            permit
            the
            access or use of the Relevant Software by any person that is not an Authorised User at the time of such
            access
            or use. The Lender will procure that any persons who at any time cease to be one of its Authorised Users
            immediately ceases use of the Relevant Software.</li>
          <li>The Lender and its Authorised Users are responsible for verifying and ensuring that any information
            facilitated to the Relevant Software is complete, accurate and suitable for the purpose used. The Lender
            acknowledges and agrees that the Relevant Software, the Ignite Tools, and the information facilitated
            or
            generated by them (including the information contained in any mortgage illustration or any ESIS) may be
            subject
            to errors and inaccuracies for which L&amp;G has no responsibility or Liability.</li>
          <li>The Lender will (a) procure and maintain its network connections and telecommunications links from its
            systems
            to L&amp;G data centres, (b) meet the Compatibility Requirements, and (c) be solely responsible for all
            problems, conditions, delays, delivery failures and all other loss or damage caused by the internet or
            arising
            from or relating to the Lender&rsquo;s network connections or telecommunications links which subject to
            clause&nbsp;<span style='font-family:"Times New Roman",serif;'>&lrm;</span>4.5&nbsp;L&amp;G will have no
            Liability for.</li>
          <li>The Lender will not and will procure that its Authorised Users will not and will not encourage,
            promote,
            facilitate or instruct others to: (a) use the Relevant Software for any illegal, harmful, fraudulent,
            infringing
            or offensive use; or (b) upload any content or data to the Relevant Software which may be considered
            illegal,
            harmful, fraudulent, infringing or offensive.</li>
          <li>The Lender will not introduce and will procure that no Authorised Users will introduce any content,
            code,
            virus or malware which has the intent or effect of compromising the security or otherwise harming the
            Relevant
            Software or any systems, hardware, data, networks or infrastructure of L&amp;G, its group companies or
            its
            or
            their suppliers.&nbsp;</li>
          <li>The Lender will not introduce and will procure that no Authorised Users will introduce any device,
            software or
            routine that interferes or attempts to interfere with the proper working of the Relevant Software
            (including
            any
            other person&rsquo;s use of the Relevant Software) or any systems, hardware, data, networks or
            infrastructure of
            L&amp;G, its group companies or its or their suppliers.</li>
          <li>The Lender undertakes to and agrees to procure that each Authorised User will: (a) keep a secure
            password
            for
            their use of the Relevant Software; and (b) keep their password(s) confidential and secure at all times
            and
            not
            share any user name, password or any other credentials which may be used to access the Relevant Software
            with
            any other person or entity (including other Authorised Users).</li>
          <li>The Lender will notify L&amp;G immediately if any person with access to the Relevant Software is under
            investigation for, or suspected of (a) breaching Financial Crime (b) any act, error or omission, which
            gives
            rise to any conduct, financial crime or regulatory concerns.&nbsp;</li>
          <li>The Lender will immediately notify L&amp;G of any persons who at any time cease to be one of its
            Authorised
            Users and will procure that they immediately cease all use of the Relevant Software<span
              style='font-family:"Times New Roman",serif;font-size:16px;'>&nbsp;</span></li>
          <li>The Lender will use all reasonable endeavours to prevent any unauthorised access to, or use of, the
            Relevant
            Software and, in the event of any such unauthorised access or use, promptly notify L&amp;G.</li>
          <li>The Lender will notify L&amp;G if any Authorised User is to be deregistered from the Relevant Software
            (for
            example, due to role change, or change in employment) and L&amp;G will deregister such Authorised User
            from
            the
            Relevant Software and the Lender will procure that such person ceases their use of the Relevant
            Software.&nbsp;
          </li>
          <li>The Lender will not permit any person who may previously have been an Authorised User to access the
            Relevant
            Software if they are no longer engaged by or employed by the Lender for whatever reason (including,
            following
            the suspension or termination of their employment or engagement).</li>
        </ol>
      </li>
      <li><span class="fw-bold">Indemnity</span>
        <ol>
          <li>The Lender will defend, indemnify against and hold harmless L&amp;G from all losses, liabilities,
            costs,
            damages and expenses that L&amp;G and its Affiliates does or will incur or suffer (including all claims
            or
            proceedings made, brought or threatened against L&amp;G and/or its Affiliates and all losses,
            liabilities,
            costs, damages and expenses incurred in defending or settling any such claims or proceedings) in each
            case
            arising out of or in connection with:<ol>
              <li>any unauthorised use or distribution of the Relevant Software or part thereof;</li>
              <li>the Lender&rsquo;s breach of clauses&nbsp;<span
                  style='font-family:"Times New Roman",serif;'>&lrm;</span>3.4&nbsp;to&nbsp;<span
                  style='font-family:"Times New Roman",serif;'>&lrm;</span>3.6&nbsp;or clauses&nbsp;<span
                  style='font-family:"Times New Roman",serif;'>&lrm;</span>5.1&nbsp;to&nbsp;<span
                  style='font-family:"Times New Roman",serif;'>&lrm;</span>5.14;</li>
              <li>any claims (third party or otherwise) arising out of any inaccurate or out of date information
                made
                available through the Relevant Software by the Lender;</li>
              <li>any illegal, harmful or unacceptable content (including discrimination, harassment, defamation);
                and/or
              </li>
              <li>the Lender&rsquo;s or any Authorised User&rsquo;s use of the Relevant Software other than in
                accordance
                with this Licence Agreement.</li>
            </ol>
          </li>
        </ol>
      </li>
      <li><span class="fw-bold">Proprietary Rights</span>
        <ol>
          <li>The Lender acknowledges and agrees that title to and all Intellectual Property Rights in or relating
            to
            the
            Relevant Software and any related deliverables or services (but excluding any underlying intellectual
            property
            rights in the information provided by Lenders for use in the Relevant Software) are and will remain the
            exclusive property of L&amp;G or its group companies and its or their licensors. This Licence Agreement
            does
            not
            grant the Lender or any Authorised Users any rights to, under or in, any Intellectual Property Rights in
            the
            Relevant Software or any related deliverables or services except for the right to use set out in
            clause&nbsp;<span style='font-family:"Times New Roman",serif;'>&lrm;</span>3&nbsp;above (subject to the
            terms of
            this Licence Agreement).&nbsp;</li>
          <li>The Lender grants to L&amp;G a perpetual, irrevocable, non-exclusive, royalty-free licence to use,
            store,
            share, configure and aggregate, the Lender&rsquo;s information (including any information that has been
            input
            into or provided through the Relevant Software (including lending criteria and product information) and
            Intellectual Property Rights for (a) the purpose of
            facilitating
            access to and the operation of the Relevant Software, (b) the performance of L&amp;G&rsquo;s other
            obligations
            under this Agreement, (c) any other purpose L&amp;G deems appropriate. (including for its own commercial
            purposes such as the development of products and the provision of services to third parties).
            &nbsp;L&amp;G will also be entitled to
            share such information with brokers, intermediaries and third parties for facilitating research and
            suitability
            analysis in respect of Lender products and in relation to the generation of mortgage illustrations and
            ESISs,
            whether through the Ignite Tools or otherwise. &nbsp;</li>
          <li>The Lender acknowledges and agrees that it has no right to access any Relevant Software in source code
            form.
          </li>
          <li>The Lender acknowledges and agrees that L&amp;G is not prevented from entering&nbsp;<span
              style="font-family:Arial;">into similar agreements with third parties, or from independently
              developing,
              using, selling or licensing products or services which are similar to those provided through the
              Lender
              Hub
              Portal.</span></li>
        </ol>
      </li>
      <li><span class="fw-bold">Term and Termination</span>
        <ol>
          <li>This Licence Agreement will commence on the date of acceptance by the Lender and continues until
            terminated in
            accordance with the terms of this Licence Agreement.</li>
          <li>L&amp;G may terminate this Licence Agreement or require the Lender to cease the use of the Relevant
            Software
            immediately for whatever reason in its absolute discretion.</li>
          <li>On termination of this Licence Agreement, all licences granted hereunder shall immediately terminate
            and
            the
            Lender will procure that all Authorised Users will immediately cease all use of the Relevant Software
            and
            L&amp;G shall have the right to disable the Lender&rsquo;s and all Authorised Users&rsquo; access to the
            Relevant Software.</li>
        </ol>
      </li>
      <li><span class="fw-bold">Suspension and Deregistration</span>
        <ol>
          <li>L&amp;G may suspend the Relevant Software or any part thereof at its discretion, including when
            L&amp;G
            needs
            to perform repairs, maintenance or updates or in the event of the failure of any machine, data
            processing
            system
            or transmission link or in the event of a security threat or incident or anything else beyond
            L&amp;G&rsquo;s
            (or its suppliers&rsquo;) control.&nbsp;</li>
          <li>L&amp;G retains the right to withdraw, suspend or modify the Relevant Software or any part thereof at
            any
            time
            without any Liability to the Lender.&nbsp;</li>
          <li>L&amp;G may immediately: (a) deregister an Authorised User or a Lender; or (b) terminate the use of
            the
            Relevant Software by an Authorised User or a Lender, in each case in L&amp;G&rsquo;s sole discretion
            without
            notice.</li>
        </ol>
      </li>
      <li><span class="fw-bold">General</span>
        <ol>
          <li>This Licence Agreement constitutes the entire agreement between the Parties in respect of the
            licensing of
            the
            Relevant Software and replaces any prior agreement or arrangement between L&amp;G and the Lender
            governing
            the
            use of Relevant Software but only to the extent such prior agreement or arrangement relates to the use
            of
            the
            Relevant Software. All other prior agreements or arrangements (or parts thereof) between the Lender and
            L&amp;G
            will otherwise continue in full force and effect.</li>
          <li>Neither Party has entered into this Licence Agreement&nbsp;in reliance upon, and it will have no
            remedy in
            respect of, any misrepresentation, representation or statement (whether made by the other Party or any
            other
            person and whether made to the first Party or any other person) which is not expressly set out in this
            Licence
            Agreement. The only remedies available for any misrepresentation or breach of any representation or
            statement
            which was made prior to entry into this Licence Agreement and which is expressly set out in this Licence
            Agreement will be for breach of contract. Nothing in this <span class="fw-bold">clause&nbsp;</span><span
              class="fw-bold"><span style='font-family:"Times New Roman",serif;'>&lrm;</span></span><span
              class="fw-bold">10.2</span><span class="fw-bold">&nbsp;sha</span>ll be interpreted
            or
            construed as limiting or excluding the liability of any person for fraud or fraudulent
            misrepresentation.
          </li>
          <li>The Parties do not intend that any term of this Licence Agreement will be enforceable under the
            Contracts
            (Rights of Third Parties) Act 1999 by any person.&nbsp;</li>
          <li>The following provisions will continue in force following the expiry or termination of this Licence
            Agreement
            for whatever reason: <span class="fw-bold">clauses&nbsp;</span><span class="fw-bold"><span
                style='font-family:"Times New Roman",serif;'>&lrm;</span></span><span class="fw-bold">1</span><span
              class="fw-bold">,&nbsp;</span><span class="fw-bold"><span
                style='font-family:"Times New Roman",serif;'>&lrm;</span></span><span class="fw-bold">3.7</span><span
              class="fw-bold">&nbsp;to&nbsp;</span><span class="fw-bold"><span
                style='font-family:"Times New Roman",serif;'>&lrm;</span></span><span class="fw-bold">7</span><span
              class="fw-bold">&nbsp;(inclusive),&nbsp;</span><span class="fw-bold"><span
                style='font-family:"Times New Roman",serif;'>&lrm;</span></span><span class="fw-bold">8.3</span><span
              class="fw-bold">, and this
              clause&nbsp;</span><span class="fw-bold"><span
                style='font-family:"Times New Roman",serif;'>&lrm;</span></span><span class="fw-bold">10</span> together
            with any other provisions which expressly or impliedly
            continue
            to have effect after expiry or termination of this Licence Agreement.</li>
          <li>Except for any service of any proceedings or other documents in a legal action to which the Civil
            Procedure
            Rules apply, any notice given under or in connection with this Licence Agreement shall be marked for the
            attention of the specified representative of the Party to be given the notice and&nbsp;sent by e-mail to
            the
            contact of the Party who has been ordinarily communicating with the other Party at their e-mail
            address.&nbsp;
          </li>
          <li>A delay in exercising or failure to exercise a right or remedy under or in connection with this
            Licence
            Agreement will not constitute a waiver of, or prevent or restrict future exercise of, that or any other
            right or
            remedy, nor will the single or partial exercise of a right or remedy prevent or restrict the further
            exercise of
            that or any other right or remedy. A waiver of any right, remedy, breach or default will only be valid
            if it
            is
            in writing and signed by the Party giving it and only in the circumstances and for the purpose for which
            it
            was
            given and will not constitute a waiver of any other right, remedy, breach or default.</li>
          <li>The Lender will not be entitled to assign, transfer, charge, hold on trust for any person or deal in
            any
            other
            manner with any of its rights under this Licence Agreement.</li>
          <li>L&amp;G will be entitled to assign, transfer, charge, hold on trust for any person and deal in any
            other
            manner with any of its rights under this Licence Agreement.</li>
          <li>If any term or any part thereof of this Licence Agreement is found by any court or body or authority
            of
            competent jurisdiction to be illegal, unlawful, void or unenforceable, such term or such part thereof
            (as
            applicable) will be deemed to be severed from this Licence Agreement and this will not affect the
            remainder
            of
            this Licence Agreement which will continue in full force and effect.&nbsp;</li>
          <li>This Licence Agreement and any non-contractual obligations arising out of or in connection with it
            will be
            governed by the law of England and Wales.</li>
          <li>The courts of England and Wales have exclusive jurisdiction to determine any dispute arising out of or
            in
            connection with this Licence Agreement (including in relation to any non-contractual obligations) and
            each
            Party
            waives any objection to, and agrees to submit to, the jurisdiction of the courts of England and Wales.
            Each
            Party agrees that a judgment or order of any such court is binding upon it and may be enforced against
            it in
            the
            courts of any other jurisdiction.</li>
        </ol>
      </li>
    </ol>
  </div>
</div>
