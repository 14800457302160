import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, UntypedFormArray,
  UntypedFormGroup, ValidationErrors, Validators }
  from '@angular/forms';
import { FeeCalculationScenario, ProductFeeType, feeDisplayModel } from '@msslib/constants/product-fees';
import { CustomFee, CustomFeeDisplayModel, CustomProductCalculations }
  from '@msslib/models/custom-product-calculations';
import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { ModalStateService } from '@msslib/services/modal-state.service';
import { ModalService } from '@msslib/services/modal.service';
import { ToastService } from '@msslib/services/toast.service';
import { CustomFeeCalculationScenarioRequest, CustomFeeRequest,
  CustomProductSettingsRequest } from 'apps/shared/src/models/broker-panel';
import { WhenPayable, WhenRefundable } from 'apps/shared/src/models/esis';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';

type FormControlPath = Parameters<UntypedFormGroup['get']>[0];

@Component({
  selector: 'lib-user-custom-product-settings',
  templateUrl: 'user-custom-product-settings.component.html',
  styleUrls: ['user-custom-product-settings.component.scss'],
})
export class UserCustomProductSettingsComponent implements OnInit {
  private nonDigitNumericChars = ['e','E', '+', '-', '.'];
  public eFeeCalculationScenarios = FeeCalculationScenario;
  private disabledFees: ProductFeeType[] = [ProductFeeType.Arrangement, ProductFeeType.Chaps];
  public whenPayable = WhenPayable;
  public whenRefundable = WhenRefundable;
  private legalFeeName: string = 'Assumed Legal Fee';
  public displayBrokerFeeForm: boolean = false;
  public form: FormGroup;
  public customFeeForm: FormGroup;
  public customFees: CustomFee[] = [];
  public model: CustomProductCalculations | null = null;
  public allFees: CustomFeeDisplayModel[] = [];
  public enableCustomTrueCostPeriod: boolean = false;
  private readonly defaultTrueCostPeriod = 24;
  private readonly brokerFeeName: string = 'Broker Fee';

  @ViewChild('customFeesTemplate', { static: true }) public customFeesTemplate: TemplateRef<unknown>;

  @Input() public customProductCalculations: CustomProductCalculations;

  public constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private modalStateService: ModalStateService,
    private clubHubDataService: ClubHubDataService,
    private toastService: ToastService,
  ) { }

  public ngOnInit(): void {
    if (this.form?.touched || this.customFeeForm?.touched) {
      return;
    }
    this.customFees = [];
    this.allFees = [];
    this.form = this.formBuilder.group({
      deductCashback: [true],
      fees: this.formBuilder.array([]),
      trueCostPeriod:[{value: this.defaultTrueCostPeriod, disabled: !this.enableCustomTrueCostPeriod}],
      brokerFees: this.formBuilder.group({
        amounts: this.formBuilder.array([]),
        calculationScenario: [FeeCalculationScenario.OneOffCost],
      }),
    });

    this.clubHubDataService
      .get<CustomProductCalculations>('CustomProductSettings/Users')
      .subscribe((customProductSettings: CustomProductCalculations) => {
        this.model = customProductSettings;
        this.patchFormValues();
      });
  }

  public get customTrueCostPeriodControl(): FormControl {
    return this.form?.get('trueCostPeriod') as FormControl;
  }

  public get feeArrayControl(): FormArray {
    return this.form?.get('fees') as FormArray;
  }

  public get brokerFeesControl(): FormGroup {
    return this.form?.get('brokerFees') as FormGroup;
  }
  public get brokerFeeAmountsArray(): FormArray {
    return this.brokerFeesControl.get('amounts') as FormArray;
  }

  public get addCustomFeesDisabled() {
    return !this.model?.areCustomFeesOverridable;
  }

  private patchFormValues() {
    const brokerFees = this.model?.customFees?.filter(fee=>fee.feeName === this.brokerFeeName);
    const brokerFeeCalculationScenario = this.model?.customFeeCalculationScenarios
      ?.find(fee=>fee.feeName?.toString() === this.brokerFeeName)?.feeCalculationScenario;
    this.form = this.formBuilder.group({
      deductCashback: [{value: this.model?.deductCashback ?? true, disabled: !this.model?.isDeductCashbackOverridable}],
      fees: this.formBuilder.array([]),
      trueCostPeriod:[{ value: this.model?.calculateInitialTrueCostOverMonths === 0 ? this.defaultTrueCostPeriod :
        (this.model?.calculateInitialTrueCostOverMonths ?? this.defaultTrueCostPeriod),
      disabled: !this.model?.isCalculateInitialTrueCostOverMonthsOverridable  || !this.enableCustomTrueCostPeriod}],
      brokerFees: this.formBuilder.group({
        amounts: this.formBuilder.array([]),
        calculationScenario: brokerFeeCalculationScenario ?? [FeeCalculationScenario.OneOffCost],
      }),
    });

    brokerFees?.forEach((brokerFee: CustomFee) => {
      this.brokerFeeAmountsArray.push(this.formBuilder.group({
        feeName:[this.brokerFeeName],
        addToLoan: [false],
        feeAmount: [brokerFee ? (brokerFee.feeAmount > 2 ? brokerFee.feeAmount : '') : ''],
        feeAmountPct: [brokerFee ? (brokerFee.feeAmount < 2 ? (brokerFee.feeAmount * 100) : '') : '',
          [Validators.min(0.2), Validators.max(2)]],
        amountRefundable: [brokerFee?.amountRefundable ?? '', this.amountRefundableValidator.bind(this)],
        whenPayable: [brokerFee?.whenPayable ?? '', this.requiredIfBrokerFeeDisplayed.bind(this)],
        whenRefundable: [brokerFee?.whenRefundable ?? '', this.requiredIfBrokerFeeDisplayed.bind(this)],
      },
      {
        validators: [this.requiredFeeAmountBrokerFee.bind(this)],
      }));
    });

    if (this.brokerFeeAmountsArray.length > 0) {
      this.displayBrokerFeeForm = true;
    }

    this.form?.get('assumedLegalFeeEnabled')?.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(
        () => {
          if (this.form?.get('assumedLegalFeeEnabled')?.value === false) {
            this.form?.get('assumedLegalFeeCost')?.disable();
            return;
          }
          this.form?.get('assumedLegalFeeCost')?.enable();
        },
      );
    this.allFees = feeDisplayModel
      .filter(fee =>fee.type !== ProductFeeType.Broker && fee.type !== ProductFeeType.Custom);

    this.allFees.forEach(fee => {
      const scenario = this.model?.customFeeCalculationScenarios
        ?.find(scenario => scenario.feeType === fee.type);
      this.feeArrayControl.push(this.formBuilder.group({
        feeCalculationScenario: [{ value: scenario?.feeCalculationScenario ?? FeeCalculationScenario.OneOffCost,
          disabled: !scenario?.canOverride}],
        feeName:[''],
        feeType:[fee.type],
      }));
    });

    this.model?.customFees?.filter(customFee => customFee.feeName !== this.brokerFeeName)?.forEach(customFee => {
      this.allFees.push({type: ProductFeeType.Custom, customName: customFee.feeName,
        displayName: customFee.feeName, isCustom: true});
      this.customFees.push({
        addToLoan: customFee.addToLoan,
        feeAmount: customFee.feeAmount > 2 ? customFee.feeAmount : null,
        feeAmountPct: customFee.feeAmount <= 2 ? (customFee.feeAmount * 100) : null,
        amountRefundable: customFee.amountRefundable,
        feeName: customFee.feeName,
        whenPayable: customFee.whenPayable,
        whenRefundable: customFee.whenRefundable,
        payableTo: customFee.payableTo,
      } as CustomFee);
      const scenario = this.model?.customFeeCalculationScenarios
        .find(scenario => scenario.feeName === customFee.feeName);
      this.feeArrayControl.push(this.formBuilder.group({
        feeCalculationScenario: [{ value: scenario?.feeCalculationScenario ?? FeeCalculationScenario.OneOffCost,
          disabled: !scenario?.canOverride}],
        feeName:[customFee.feeName],
        feeType:[ProductFeeType.Custom],

      }));
    });

    if (this.model?.assumedLegalFeeCost) {
      const scenario = this.model?.customFeeCalculationScenarios
        .find(scenario => scenario.feeName === this.legalFeeName);
      this.allFees.push({type: ProductFeeType.Custom, customName: this.legalFeeName,
        displayName: this.legalFeeName, isCustom: false});
      this.feeArrayControl.push(this.formBuilder.group({
        feeCalculationScenario: [{ value: scenario?.feeCalculationScenario ?? FeeCalculationScenario.OneOffCost,
          disabled: !scenario?.canOverride}],
        feeName:[this.legalFeeName],
      }));
    }

    if (this.model?.calculateInitialTrueCostOverMonths !== this.defaultTrueCostPeriod &&
      this.model?.isCalculateInitialTrueCostOverMonthsOverridable) {
      this.customTrueCostPeriodControl.enable();
      this.enableCustomTrueCostPeriod = true;
    }
  }

  public preventUnwanted(event) {
    if (this.nonDigitNumericChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  public selectAllFees(scenario: FeeCalculationScenario) {
    this.feeArrayControl.controls.forEach(control => {
      if ((this.disabledFees.includes(control.value.feeType) &&
            scenario === FeeCalculationScenario.Exclude) ||
            !this.model?.customFeeCalculationScenarios
              ?.find(x=>x.feeType === control.value.feeType && x.feeName === control.value.feeName)?.canOverride) {
        return;
      }
      control.setValue({
        feeCalculationScenario: scenario,
        feeName: control.value.feeName,
        feeType: control.value.feeType,
      });
    });
    this.form.markAsTouched();
  }

  public addCustomFee() {
    this.customFeeForm = this.formBuilder.group({
      feeName: ['', Validators.required],
      feeAmount: [''],
      feeAmountPct: ['', [Validators.min(0.2), Validators.max(2)]],
      whenPayable: [null, [Validators.required, Validators.min(1)]],
      addToLoan: [false],
      amountRefundable: [null, [Validators.required]],
      whenRefundable: [null, [Validators.required, Validators.min(1)]],
      payableTo: ['', Validators.maxLength(100)],
    },
    {
      validators: [this.requiredFeeAmount, this.uniqueFeeAmount],
    });

    this.customFeeForm.get('whenRefundable')?.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(
        () => {
          if (+(this.customFeeForm.get('whenRefundable')?.value) === WhenRefundable.NotRefundable) {
            this.customFeeForm.get('amountRefundable')?.disable();
            return;
          }
          this.customFeeForm.get('amountRefundable')?.enable();
        },
      );
    this.modalService.open({
      title: 'Add custom fee',
      template: this.customFeesTemplate,
      size: 'sm',
      data: {edited: false},
      hideTopClose: true,
      sticky: true,
    }).then(() => null, () => null);
  }

  public editCustomFee(index: number) {
    if (index >= 0 && index < this.allFees.length) {
      const control = this.feeArrayControl.at(index);
      const feeName = control?.get('feeName')?.value;

      const model = this.customFees.find(customFee => {
        return customFee.feeName === feeName;
      });

      this.customFeeForm = this.formBuilder.group({
        feeName: [model?.feeName ?? '', Validators.required],
        feeAmount: [model?.feeAmount ?? ''],
        feeAmountPct: [model?.feeAmountPct !== 0 ? model?.feeAmountPct ?? '' : '',
          [Validators.min(0.2), Validators.max(2)]],
        whenPayable: [model?.whenPayable, [Validators.required, Validators.min(1)]],
        addToLoan: [model?.addToLoan ?? false],
        amountRefundable: [model?.amountRefundable, [Validators.required]],
        whenRefundable: [model?.whenRefundable, [Validators.required, Validators.min(1)]],
        payableTo: [model?.payableTo ?? '', Validators.maxLength(100)],
      },
      {
        validators: [this.requiredFeeAmount, this.uniqueFeeAmount],
      });

      if (+(this.customFeeForm.get('whenRefundable')?.value) === WhenRefundable.NotRefundable) {
        this.customFeeForm.get('amountRefundable')?.disable();
      }

      this.customFeeForm.get('whenRefundable')?.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(
          () => {
            if (+(this.customFeeForm.get('whenRefundable')?.value) === WhenRefundable.NotRefundable) {
              this.customFeeForm.get('amountRefundable')?.disable();
              return;
            }
            this.customFeeForm.get('amountRefundable')?.enable();
          },
        );
      this.modalService.open({
        title: `Edit custom fee - ${model?.feeName}`,
        template: this.customFeesTemplate,
        size: 'sm',
        data: {edited: true, feeName: feeName, feeControl: control},
        hideTopClose: true,
        sticky: true,
      }).then(() => null, () => null);
    }
    this.form.markAsTouched();
  }

  public removeCustomFee(index: number) {
    if (index >= 0 && index < this.allFees.length) {
      const control = this.feeArrayControl.at(index);
      const feeName = control?.get('feeName')?.value;
      const indexToRemove = this.customFees.findIndex(customFee => {
        return customFee.feeName === feeName;
      });
      this.customFees.splice(indexToRemove,1);
      this.feeArrayControl.removeAt(index);
      this.allFees.splice(index, 1);
      this.form.markAsTouched();
    }
  }
  public validCustomFees(path: FormControlPath) {
    const ctrl = this.customFeeForm.get(path);
    return ctrl?.valid && ctrl.touched;
  }

  public validForm(path: FormControlPath) {
    const ctrl = this.form.get(path);
    return ctrl?.valid && ctrl.touched;
  }

  public errorsCustomFees(path: FormControlPath) {
    return this.customFeeForm.get(path)?.errors;
  }

  public errorsForm(path: FormControlPath) {
    return this.form.get(path)?.errors;
  }

  public invalidCustomFees(path: FormControlPath) {
    return this.invalid(path, this.customFeeForm);
  }

  public invalidForm(path: FormControlPath) {
    return this.invalid(path, this.form);
  }

  public invalid(path: FormControlPath, form: FormGroup) {
    const ctrl = form.get(path);

    if (!ctrl || !('controls' in ctrl)) {
      return ctrl?.invalid && ctrl.touched;
    }

    const pathArray = Array.isArray(path) ? path : path.split('.');
    if (Array.isArray(ctrl.controls)) {
      (ctrl as UntypedFormArray).controls.some((_, index) => this.invalid([...pathArray, index], form));
    } else {
      return Object.keys((ctrl as UntypedFormGroup).controls)
        .some(controlName => this.invalid([...pathArray, controlName], form));
    }
  }

  public validityClassesCustomFees(path: FormControlPath) {
    return {
      'is-valid': this.validCustomFees(path),
      'is-invalid': this.invalidCustomFees(path),
    };
  }

  public validityClassesForm(path: FormControlPath) {
    return {
      'is-valid': this.validForm(path),
      'is-invalid': this.invalidForm(path),
    };
  }

  public valueForm(path: FormControlPath) {
    return this.form.get(path)?.value;
  }

  public trackByKey<T extends { key: number } = any>(_index: number, item: T) {
    return item.key;
  }

  public onSubmitCustomFees() {
    this.customFeeForm.markAllAsTouched();
    this.customFeeForm.markAsTouched();
    this.customFeeForm.updateValueAndValidity();

    if (this.customFeeForm.invalid) {
      return;
    }
    if (this.modalStateService.options.data?.edited) {
      const feeName = this.modalStateService.options.data?.feeName;
      const existingFeeIndex = this.customFees.findIndex((fee) => fee.feeName === feeName);

      if (existingFeeIndex !== -1) {
        this.customFees[existingFeeIndex] = this.customFeeForm.value;
      }

      const newFeeName = this.customFeeForm.get('feeName')?.value;
      this.feeArrayControl.controls.
        find(control => control?.get('feeName')?.value === feeName)?.get('feeName')?.setValue(newFeeName);

      const existingFeeCalculationScenarioIndex = this.allFees.findIndex((fee) => fee.displayName === feeName);
      this.allFees[existingFeeCalculationScenarioIndex] = {type: ProductFeeType.Custom,
        displayName: newFeeName, customName: newFeeName, isCustom: true};
      this.modalService.close();

      return;
    }

    this.customFees.push(this.customFeeForm.value);
    this.addCustomFeeSetting(this.customFeeForm.get('feeName')?.value);
    this.modalService.close();

  }

  public addCustomFeeSetting(feeName: string, isCustom: boolean = true) {
    this.feeArrayControl.push(this.formBuilder.group({
      feeCalculationScenario: [FeeCalculationScenario.OneOffCost],
      feeName:[feeName],
      feeType:ProductFeeType.Custom,
    }));

    this.allFees.push(
      {type: ProductFeeType.Custom, customName: feeName, displayName: feeName, isCustom: isCustom},
    );
  }

  public cancelAddCustomFeeToPanel() {
    this.modalService.close();
  }

  private requiredFeeAmount(control: AbstractControl): ValidationErrors | null {
    const amount = control.get('feeAmount');
    const amountPct = control.get('feeAmountPct');

    if (!amount?.value && !amountPct?.value && (amount?.touched || amountPct?.touched)) {
      return { requiredFeeAmountError: true };
    }
    return null;
  }

  private uniqueFeeAmount(control: AbstractControl): ValidationErrors | null {
    const amount = control.get('feeAmount');
    const amountPct = control.get('feeAmountPct');

    if (amount?.value && amountPct?.value && (amount?.touched || amountPct?.touched)) {
      return { uniqueFeeAmountError: true };
    }
    return null;
  }

  private requiredFeeAmountBrokerFee(control: AbstractControl): ValidationErrors | null {
    const amount = control.get('feeAmount');
    const amountPct = control.get('feeAmountPct');

    if (!this.displayBrokerFeeForm) {
      return null;
    }

    if (!amount?.value && !amountPct?.value && (amount?.touched || amountPct?.touched)) {
      return { requiredFeeAmountError: true };
    }
    return null;
  }

  private uniqueFeeAmountBrokerFee(control: AbstractControl): ValidationErrors | null {
    const amount = control.get('feeAmount');
    const amountPct = control.get('feeAmountPct');

    if (!this.displayBrokerFeeForm) {
      return null;
    }

    if (amount?.value && amountPct?.value && (amount?.touched || amountPct?.touched)) {
      return { uniqueFeeAmountError: true };
    }
    return null;
  }

  public toggleBrokerFee(evt: any): void {
    if (evt.target.checked) {
      this.addBrokerFee();
    } else {
      this.brokerFeeAmountsArray.clear();
    }
    this.form.markAsTouched();
  }

  public addBrokerFee(): void {
    this.brokerFeeAmountsArray.push(this.formBuilder.group({
      feeName:[this.brokerFeeName],
      addToLoan: [false],
      feeAmount: [''],
      feeAmountPct: ['', [Validators.min(0.2), Validators.max(2)]],
      amountRefundable: ['', this.amountRefundableValidator.bind(this)],
      whenPayable: [null, this.requiredIfBrokerFeeDisplayed.bind(this)],
      whenRefundable: [null, this.requiredIfBrokerFeeDisplayed.bind(this)],
    },
    {
      validators: [this.requiredFeeAmountBrokerFee.bind(this), this.uniqueFeeAmountBrokerFee.bind(this)],
    }));
  }

  public removeBrokerFee(): void {
    this.brokerFeeAmountsArray.removeAt(1);
    this.form.markAsTouched();
  }

  public amountRefundableValidator(control: AbstractControl): ValidationErrors | null {
    if (!this.displayBrokerFeeForm) {
      return null;
    }
    if (
      +(control.parent?.getRawValue())?.whenRefundable as WhenRefundable !==
      WhenRefundable.NotRefundable
    ) {
      return (Validators.compose([Validators.required, Validators.min(0.01)])?.(control) ?? null);
    }
    return null;
  }

  public whenRefundableChange(newValue: WhenRefundable) {
    if (!this.displayBrokerFeeForm) {
      return null;
    }
    const amountControl = this.form.get('brokerFee.amountRefundable');
    amountControl?.setValue(+newValue as WhenRefundable === WhenRefundable.NotRefundable ? 0 : +amountControl.value);
  }

  public requiredIfBrokerFeeDisplayed() {
    if (!this.displayBrokerFeeForm ?? false) {
      return null;
    }
    return Validators.required;
  }

  public onSubmit():void {
    this.form.markAsTouched();
    this.form.markAllAsTouched();
    this.updateValueAndValidity(this.form);
    if (this.form.invalid) {
      return;
    }
    const request = {} as CustomProductSettingsRequest;
    this.mapFormValues(this.form?.value, request);
    this.clubHubDataService.post('CustomProductSettings/Users', request)
      .subscribe(() => this.toastService.success('Custom product settings saved'));
  }

  public isCustomFeeDisabled(index: number): boolean {
    if (index >= 0 && index < this.allFees.length) {
      const control = this.feeArrayControl?.at(index);
      const feeName = control?.get('feeName')?.value;
      const scenario = this.model?.customFeeCalculationScenarios
        ?.find(scenario => scenario.feeName === feeName);
      if (!scenario) {
        return false;
      }
      return !scenario?.canOverride ?? false;
    }
    return false;
  }

  private mapFormValues(formValues: any, request: CustomProductSettingsRequest) {
    if (this.form?.touched || this.customFeeForm?.touched) {
      request.deductCashback = formValues.deductCashback;
      request.isDeductCashbackOverridable = !formValues.isDeductCashbackNotOverridable;
      request.areCustomFeesOverridable = !formValues.areCustomFeesNotOverridable;
      request.assumedLegalFeeCost = this.model?.assumedLegalFeeCost;

      const trueCostPeriod = formValues.trueCostPeriod ?? this.model?.calculateInitialTrueCostOverMonths;
      request.calculateInitialTrueCostOverMonths =
        !trueCostPeriod || trueCostPeriod === 0 || !this.enableCustomTrueCostPeriod ?
          this.defaultTrueCostPeriod : trueCostPeriod;

      request.customFeeCalculationScenarios = formValues.fees.map(fee => {
        const feeCalculationScenarioFromModel = this.model?.customFeeCalculationScenarios
          ?.find(model => model.feeType === fee.feeType && model.feeName === fee.feeName);
        return {
          feeCalculationScenario: fee.feeCalculationScenario ?? feeCalculationScenarioFromModel?.feeCalculationScenario
            ?? FeeCalculationScenario.OneOffCost,
          feeName: fee.feeName,
          feeType: fee.feeType,
          canOverride: !fee.cantBeOverridden ?? feeCalculationScenarioFromModel?.canOverride ?? true,
        } as CustomFeeCalculationScenarioRequest;
      });
      request.customFees = this.customFees.map((fee: CustomFee) => ({
        feeName: fee.feeName,
        whenPayable: fee.whenPayable,
        addToLoan: fee.addToLoan,
        feeAmount: +fee.feeAmount + (fee.feeAmountPct / 100),
        whenRefundable: fee.whenRefundable,
        amountRefundable: fee.amountRefundable,
        payableTo: fee.payableTo,
      }));

      if (formValues.brokerFees?.amounts?.length > 0) {
        request.customFeeCalculationScenarios.push({
          feeName: this.brokerFeeName,
          feeType: ProductFeeType.Custom,
          feeCalculationScenario: formValues.brokerFees.calculationScenario,
          canOverride: null,
        } as CustomFeeCalculationScenarioRequest);
      } else {
        const brokerFeeIndex = request.customFeeCalculationScenarios.findIndex(
          (fee) => fee.feeName === this.brokerFeeName);
        if (brokerFeeIndex !== -1) {
          request.customFeeCalculationScenarios.splice(brokerFeeIndex, 1);
        }
      }

      formValues.brokerFees.amounts.forEach(brokerFee => {
        request.customFees.push({
          feeName: brokerFee.feeName,
          addToLoan: brokerFee.addToLoan,
          amountRefundable: +brokerFee.amountRefundable,
          whenPayable: +brokerFee.whenPayable,
          whenRefundable: +brokerFee.whenRefundable,
          feeAmount: +brokerFee.feeAmount + (brokerFee.feeAmountPct / 100),
        } as CustomFeeRequest);
      });
    } else {
      const settings = this.model;
      request.deductCashback = settings?.deductCashback ?? true;
      request.isDeductCashbackOverridable = settings?.isDeductCashbackOverridable ?? false;
      request.areCustomFeesOverridable = settings?.areCustomFeesOverridable ?? false;
      request.assumedLegalFeeCost = settings?.assumedLegalFeeCost;
      request.customFeeCalculationScenarios = settings?.customFeeCalculationScenarios.map(fee => {
        return {
          feeCalculationScenario: fee.feeCalculationScenario,
          feeName: fee.feeName,
          canOverride: fee.canOverride,
        } as CustomFeeCalculationScenarioRequest;
      }) ?? [];
      request.customFees = settings?.customFees?.map((fee: CustomFee) => ({
        feeName: fee.feeName,
        whenPayable: fee.whenPayable,
        addToLoan: fee.addToLoan,
        feeAmount: +fee.feeAmount + (fee.feeAmountPct / 100),
        whenRefundable: fee.whenRefundable,
        amountRefundable: fee.amountRefundable,
      })) ?? [];
    }
  }

  public toggleTrueCost(selected: boolean) {
    this.enableCustomTrueCostPeriod = selected;
    if (selected) {
      this.customTrueCostPeriodControl.enable();
    } else {
      this.customTrueCostPeriodControl.disable();
      this.customTrueCostPeriodControl.setValue(this.defaultTrueCostPeriod);
    }
  }

  private updateValueAndValidity(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];

      if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
        this.updateValueAndValidity(abstractControl);
      } else {
        abstractControl.updateValueAndValidity();
      }
    });
  }

  public isFeeDisabled(feeType: ProductFeeType): boolean {
    return this.disabledFees.includes(feeType);
  }
}
