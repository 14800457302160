import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from 'oidc-client-ts';

import { AuthorizeService } from '@msslib/services/authorize.service';
import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { keys } from 'apps/clubhub/src/app/constants';
import { ClientApps } from '@msslib/models';
import { DisclaimerService } from '@msslib/services/disclaimer.service';

@Injectable({
  providedIn: 'root',
})
export class ExternalLoginService {
  public constructor(
    private authService: AuthorizeService,
    private clubHubDataService: ClubHubDataService,
    private activatedRoute: ActivatedRoute,
    private disclaimerService: DisclaimerService,
  ) {}

  public loginExternalUser() {
    const tokenParam = this.activatedRoute.snapshot.queryParams.key;
    const apiKey = this.activatedRoute.snapshot.queryParams.apiKey;
    if (tokenParam) {
      this.setupExternalAuth(tokenParam);
    }
    if (apiKey) {
      this.clubHubDataService.post<{ token: string }>('ExternalLogin', { apiKey })
        .subscribe(response => this.setupExternalAuth(response.token));
    }
  }

  public login(): void {
    const params = {
      /* eslint-disable @typescript-eslint/naming-convention */
      'grant_type': 'client_credentials',
      'scope': 'any_scopes',
      'client_id': 'client_id',
      'client_secret': 'client_secret',
      /* eslint-enable @typescript-eslint/naming-convention */
    };

    const searchParams = Object.keys(params)
      .map((key: string) => {
        return `${ encodeURIComponent(key) }=${ encodeURIComponent(params[key]) }`;
      })
      .join('&');

    fetch(this.authService.tokenEndpoint, {
      method: 'POST',
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: searchParams,
    }).then(async (response) => {
      const token = await response.json();
      this.setupExternalAuth(token);
    }, this.onError);
  }

  public setupExternalAuth(token: string) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const userSettings = { 'access_token': token, 'token_type': 'Bearer' } as User;
    sessionStorage.clear();
    sessionStorage.setItem(keys.termsDisclaimer, 'false');
    sessionStorage.setItem(this.authService.tokenKey, JSON.stringify(userSettings));
    this.authService.setUser(new User(userSettings) as any).subscribe(() => {
      this.disclaimerService.hasUserDisclaimerBeenRead(ClientApps.ClubHub).subscribe(({ disclaimerRead }) => {
        sessionStorage.setItem(keys.termsDisclaimer, disclaimerRead.toString());
        location.replace('../ignite');
      });
    });
  }

  private onError(error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}
