import { Injectable, OnDestroy } from '@angular/core';
import { format } from 'date-fns';
import { AuthorizeService } from '@msslib/services/authorize.service';
import { Subscription } from 'rxjs';

declare let window: Window &
  typeof globalThis & {
    appName: string;
    pageLoad: { getPageLoadTime: string };
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _satellite: {
      track: (arg0: string, arg1: { type: string; name: string; details?: string; lender?: string }) => void;
    };
  };

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService implements OnDestroy {
  private userSubscription: Subscription;

  public constructor(authService: AuthorizeService) {
    this.userSubscription = authService.getUser().subscribe((user) => {
      if (window.digitalData) {
        window.digitalData.customer.customerID = user ? user.sub : '';
      }
    });
  }

  public ngOnDestroy() {
    this.userSubscription?.unsubscribe();
  }

  public trackClick(name: string) {
    const eventType = 'ButtonClick';
    if (window.digitalData) {
      window.digitalData.interaction = {
        type: eventType,
        name: name,
      };

      window._satellite?.track('digitalData.interaction', {
        type: eventType,
        name: name,
      });
    }
  }

  public trackEvent(eventType: string, name: string) {
    if (window.digitalData) {
      window.digitalData.interaction = {
        type: eventType,
        name: name,
      };

      window._satellite?.track('digitalData.interaction', {
        type: eventType,
        name: name,
      });
    }
  }

  public trackClickWithDetails(name: string, details: string) {
    const eventType = 'ButtonClick';
    if (window.digitalData) {
      window.digitalData.interaction = {
        type: eventType,
        name: name,
        details: details,
      };

      window._satellite?.track('digitalData.interaction', {
        type: eventType,
        name: name,
        details: details,
      });
    }
  }

  public trackClickWithLender(name: string, lender: string) {
    const eventType = 'ButtonClick';
    if (window.digitalData) {
      window.digitalData.interaction = {
        type: eventType,
        name: name,
        lender: lender,
      };

      window._satellite?.track('digitalData.interaction', {
        type: eventType,
        name: name,
        lender: lender,
      });
    }
  }

  public trackDropdown(name: string, details: string) {
    const eventType = 'Dropdown';
    if (window.digitalData) {
      window.digitalData.interaction = {
        type: eventType,
        name: name,
        details: details,
      };

      window._satellite?.track('digitalData.interaction', {
        type: eventType,
        name: name,
        details: details,
      });
    }
  }

  public trackDownload(name: string) {
    const eventType = 'Download';
    if (window.digitalData) {
      window.digitalData.interaction = {
        type: eventType,
        name: name,
      };

      window._satellite?.track('digitalData.interaction', {
        type: eventType,
        name: name,
      });
    }
  }

  public trackFaqClick(name: string) {
    const eventType = 'FAQClick';
    if (window.digitalData) {
      window.digitalData.interaction = {
        type: eventType,
        name: name,
      };

      window._satellite?.track('digitalData.interaction', {
        type: eventType,
        name: name,
      });
    }
  }

  public trackFieldChanged(name: string) {
    const eventType = 'FieldChanged';
    if (window.digitalData) {
      window.digitalData.interaction = {
        type: eventType,
        name: name,
      };
      window._satellite?.track('digitalData.interaction', {
        type: eventType,
        name: name,
      });
    }
  }

  public log(pageName: string | undefined, syndicate = 'Undefined syndicate') {
    this.logEvent(pageName, syndicate);
  }

  private logEvent(pageName: string | undefined, syndicate = 'Undefined syndicate') {
    const { pathname, href, host } = window.location;
    const appName: string = window.appName;
    if (appName && window.digitalData) {
      window.digitalData.page = {
        timestamp: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        appName,
        loadTime: window.pageLoad.getPageLoadTime,
        path: pathname,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        URL: href,
        hostName: host,
        syndicate,
      };
      window.digitalData.cms.pageName = pageName ?? 'Page name was undefined';
      const el = window._satellite as { track?: (arg0: string) => void };
      if (el?.track) {
        el.track('pageLoad');
      }
    }
  }
}
