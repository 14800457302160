import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { BridgingProduct, BridgingProductFee, BridgingProductFeeType } from 'apps/shared/src/models';

@Component({
  selector: 'lib-bridging-product-fees-tooltip',
  templateUrl: 'bridging-product-fees-tooltip.component.html',
  styleUrls: ['bridging-product-fees-tooltip.component.scss'],
})
export class BridgingProductFeesTooltipComponent {
  @Input() public product: BridgingProduct;
  public constructor(private cd: ChangeDetectorRef) { }

  public get total() {
    this.cd.markForCheck();
    return this.product?.totalFee;
  }

  public feeToString(fee: BridgingProductFee): string {
    return !!fee
      ? BridgingProductFeeType[fee.type]
      : '';
  }

  public get hasReferToLenderFee(): boolean {
    return this.product.fees?.some(f => f.isReferToLender);
  }
}
