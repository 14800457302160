import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { CMSService } from '@msslib/services';
import { Banner } from '../models/banner.model';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  private igniteBannersData: unknown;

  public constructor(
    private cmsService: CMSService,
  ) { }

  public getIgniteBannerByName(bannerName: string): Observable<Banner> {
    return new Observable<Banner>((subscriber) => {
      this.getIgniteBannersData().subscribe((bannersData: unknown) => {
        if (!bannersData) {
          subscriber.next(undefined);
          subscriber.complete();
          return;
        }

        const bannerResponse = bannersData[bannerName];

        if (!bannerResponse) {
          subscriber.next(undefined);
          subscriber.complete();
          return;
        }

        const banner = {
          name: bannerName,
          altText: '',
          imageUrl: bannerResponse[`${bannerName}-img`],
          link: bannerResponse[`${bannerName}-url`],
        } as Banner;

        subscriber.next(banner);
        subscriber.complete();
      });
    });
  }


  private getIgniteBannersData(): Observable<unknown> {
    if (!this.igniteBannersData) {
      return this.cmsService.getIgniteBanners().pipe(tap((data: unknown) => {
        this.igniteBannersData = data;
      }));
    }

    return of(this.igniteBannersData);
  }
}
