import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthorizeService, ModalService } from '@msslib/services';
import { IFieldConfig } from '@msslib/models/forms';
import { format, formatISO, parseISO } from 'date-fns';
import {
  FeeLendingTypeDocumentTypeViewModel,
  ISimpleGridColumn,
  ProductCollectionType,
  ResiBtlProductFee,
  ResiBtlProductRate,
  SimpleGridColumnType,
  UploadedProductsResult,
} from '../../models';
import { EventScheduleComponent } from '../event-schedule/event-schedule.component';
import { VariableRateCodesEditorComponent, VariableRateCodesEditorComponentFormType }
  from '@msslib/components/variable-rate-codes-editor/variable-rate-codes-editor.component';
import { ProductService } from 'apps/lenderhub/src/app/services';
import { VariableRateCodeEditorModel, VariableRateCodeUpdateRequest } from '@msslib/models/variable-rate-codes';
import {
  ArrangementFeeType,
  Availability,
  BridgingInterestMethod,
  BridgingProductFeeType,
  MortgageType,
  ProductFeeStructure,
  RateType,
  RepaymentMethod,
} from 'apps/shared/src/models';
import { ProductFeeType, roles } from '@msslib/constants';

export interface PreviewUploadModalComponentResult {
  confirmUpload: boolean;
  schedule: string | null;
  variableRateCodes?: VariableRateCodeUpdateRequest[];
  hasMissingVariableRateCodes?: boolean;
}

export enum PreviewUploadModalPage {
  Preview,
  VariableRateCodes,
}

@Component({
  selector: 'lib-preview-upload-modal',
  styleUrls: ['preview-upload-modal.component.scss'],
  templateUrl: 'preview-upload-modal.component.html',
})
export class PreviewUploadModalComponent implements OnInit {
  @Input() public uploadResult: UploadedProductsResult;
  @Input() public statusMessage: string;
  @Input() public errorOccurred: boolean;
  @Input() public validationErrors: string[];
  @Input() public feesMissingInEsis: FeeLendingTypeDocumentTypeViewModel[] = [];
  @Input() public productCollectionType: ProductCollectionType;
  @Output() public amendEsisFeesSelected = new EventEmitter<string[]>();

  public currentPage = PreviewUploadModalPage.Preview;
  public previewUploadModalPage = PreviewUploadModalPage;

  public config: IFieldConfig[];

  public previewTableColumns: ISimpleGridColumn[];

  public inputsLoaded = false;
  public scheduleForm = EventScheduleComponent.buildScheduleForm();

  public variableRateCodes: VariableRateCodeEditorModel[];
  public variableRateCodeForm: VariableRateCodesEditorComponentFormType;
  public existingVariableRateScheduledDate: string | null;
  public hasVariableRateCodes: boolean;

  public constructor(
    private modalService: ModalService,
    private productService: ProductService,
    private authService: AuthorizeService,
  ) {
  }

  public ngOnInit() {
    this.inputsLoaded = true;

    // Determine columns to show based on product type
    this.previewTableColumns = this.getProductColumns();

    // If the lender needs to update the variable rates, build the form for it. This needs to include current rates
    // (which we fetch in the background), and any new rates that are required from the uploaded sheet.
    // Get rid of any existing codes that are no longer required.
    // This only applies to lenders, packagers do not set variable rate codes
    if (!this.isPackager && this.uploadResult?.additionalFileProcessingResult?.requiredVariableRateCodes?.length) {
      this.productService.getVariableRateCodes({ loading: false }).subscribe(existingCodes => {
        const requiredCodes = this.uploadResult.additionalFileProcessingResult.requiredVariableRateCodes;
        this.variableRateCodes = [
          ...existingCodes.codes
            .filter(c => requiredCodes.includes(c.code)),
          ...requiredCodes
            .filter(c => !existingCodes.codes.map(({ code }) => code).includes(c))
            .map(code => ({
              code,
              description: '',
              isTracker: false,
              currentRate: null,
              scheduledRate: null,
              isNew: true,
            }) satisfies VariableRateCodeEditorModel),
        ];

        this.hasVariableRateCodes = this.variableRateCodes.length > 0;

        if (this.hasVariableRateCodes) {
          const discountProductRates = this.uploadResult?.products?.flatMap((product: any) =>
            product?.rates?.filter(rate => rate.rateType === RateType.Discount)?.map(rate => {
              return {
                rate: rate.rate,
                code: rate.variableRateCode,
              };
            }));

          this.variableRateCodeForm =
            VariableRateCodesEditorComponent.buildForm(this.variableRateCodes, true, discountProductRates);
          this.existingVariableRateScheduledDate = existingCodes.scheduledDate;
        }
      });
    }
  }

  private get isPackager() {
    return this.authService.hasRole(roles.packager);
  }

  // The final page will have different continue button label, and will show the schedule input
  public get isFinalPage() {
    // We are on the final page if:
    // - The lender does not use any variable rate codes (they will be on the preview page)
    // - Or, the lender does use variable rate codes, and they are on the variable rate page
    return this.currentPage === PreviewUploadModalPage.VariableRateCodes || !this.hasVariableRateCodes;
  }

  public get continueButtonLabel() {
    return this.isFinalPage ? 'Confirm' : 'Continue';
  }

  public get existingRevertRateScheduledDateFormatted() {
    return this.existingVariableRateScheduledDate
      ? format(parseISO(this.existingVariableRateScheduledDate), 'dd/MM/yyyy HH:mm')
      : '';
  }

  public continue() {
    // If we're on the preview page, and the lender uses variable rate codes, go to the variable rate code tab
    if (this.currentPage === PreviewUploadModalPage.Preview && this.hasVariableRateCodes
    ) {
      this.currentPage = PreviewUploadModalPage.VariableRateCodes;
      return;
    }

    // If we're on the revert rate page, ensure that the variable rate form is valid
    if (this.currentPage === PreviewUploadModalPage.VariableRateCodes) {
      this.variableRateCodeForm.markAllAsTouched();
      if (!this.variableRateCodeForm.valid) {
        return;
      }
    }

    // Otherwise, submit the upload
    const scheduleDate = EventScheduleComponent.getScheduleFormDate(this.scheduleForm);

    if (!this.scheduleForm.valid) {
      return;
    }

    const result = {
      confirmUpload: true,
      schedule: scheduleDate ? formatISO(scheduleDate) : null,
      variableRateCodes: this.variableRateCodeForm?.getRawValue() as VariableRateCodeUpdateRequest[],
      hasMissingVariableRateCodes: this.uploadResult.additionalFileProcessingResult?.hasMissingVariableRateCodes,
    } satisfies PreviewUploadModalComponentResult;

    this.modalService.close(result);
  }

  public goBack() {
    // If we're on the first page (preview), then close the modal
    if (this.currentPage === PreviewUploadModalPage.Preview) {
      const result = {
        confirmUpload: false,
        schedule: null,
      } satisfies PreviewUploadModalComponentResult;

      this.modalService.close(result);
      return;
    }

    // Otherwise, go back to the previous page
    this.currentPage = this.currentPage - 1;
  }

  public close() {
    this.modalService.dismiss();
  }

  public get missingEsisLendingTypes(): string[] {
    return [...new Set(this.feesMissingInEsis.map(x => x.lendingTypeDocumentType))];
  }

  public amendEsisFees(): void {
    this.modalService.dismiss();
    this.amendEsisFeesSelected.emit(this.missingEsisLendingTypes);
  }

  private getProductColumns(): ISimpleGridColumn[] {
    switch (this.productCollectionType) {
      case ProductCollectionType.ResiBtl:
        return this.getResiBtlProductColumns();
      case ProductCollectionType.Bridging:
        return this.getBridgingProductColumns();
    }
  }

  private getResiBtlProductColumns(): ISimpleGridColumn[] {
    const renderAvailability = (v: Availability | undefined) => v ? Availability[v] : '-';
    const renderArrangementFeeType = (v: ArrangementFeeType | undefined) => v ? ArrangementFeeType[v] : '-';

    const renderFee = (type: ProductFeeType) => (_: never, { fees }: { fees: ResiBtlProductFee[] | undefined }) => {
      const fee = fees?.find(fee => fee.type === type);
      return fee
        ? fee.structure === ProductFeeStructure.Flat ? `£${fee.value}` : `${fee.value}%`
        : '-';
    };

    const renderRate = <T extends keyof ResiBtlProductRate>
      (ordinal: number, property: T, render?: (v: NonNullable<ResiBtlProductRate[T]>) => any) =>
        (_: never, { rates }: { rates: ResiBtlProductRate[] | undefined }) => {
          const rate = rates?.find(r => r.ordinal === ordinal);
          const value = rate && rate[property];
          return value ? render ? render(value) : value : '-';
        };

    return [
      { name: 'lenderName' },
      { name: 'lendingTypeName', label: 'Lending Type' },
      { name: 'description' },
      { name: 'code' },
      { name: 'isExclusive', type: SimpleGridColumnType.Boolean },
      { name: 'productAvailability' },
      {
        name: 'repaymentTypes', classList: 'ws-nowrap',
        render: (v: { repaymentType: RepaymentMethod }[]) => v
          .map(({ repaymentType }) => {
            switch (repaymentType) {
              case RepaymentMethod.CapitalAndInterest:
                return 'CI';
              case RepaymentMethod.InterestOnly:
                return 'IO';
              case RepaymentMethod.InterestOnlyPartAndPart:
                return 'PP';
            }
          })
          .join(', '),
      },
      { name: 'initialRateType', render: renderRate(1, 'rateType', v => RateType[v]) },
      { name: 'initialRate', render: renderRate(1, 'rate', v => `${v}%`) },
      { name: 'variableRateCode', render: renderRate(1, 'variableRateCode') },
      { name: 'initialPeriodUntil', render: renderRate(1, 'until', v => format(parseISO(v), 'dd/MM/yyyy')) },
      { name: 'initialPeriod', render: renderRate(1, 'initialPeriod', v => `${v} months`) },
      { name: 'rate2RateType', render: renderRate(2, 'rateType', v => RateType[v]) },
      { name: 'rate2', render: renderRate(2, 'rate'), suffix: '%' },
      { name: 'rate2VariableRateCode', render: renderRate(2, 'variableRateCode') },
      { name: 'until2', render: renderRate(2, 'until', v => format(parseISO(v), 'dd/MM/yyyy')) },
      { name: 'rate2Period', render: renderRate(2, 'initialPeriod', v => `${v} months`) },
      { name: 'revertRateCode' },
      { name: 'revertRateMargin', suffix: '%' },
      {
        name: 'ercPeriods', label: 'ERC Rates', classList: 'ws-nowrap',
        render: (v: { ordinal: number; rate: number }[]) => v
          ?.sort((a, b) => a.ordinal - b.ordinal)
          .map(e => `${e.rate}%`)
          .join(', ') ?? '',
      },
      {
        name: 'mortgageTypes', classList: 'ws-nowrap',
        render: (v: { mortgageType: MortgageType }[]) => v.map(t => MortgageType[t.mortgageType]).join(', '),
      },
      { name: 'floorRate', suffix: '%' },
      { name: 'minLoanPolicy', type: SimpleGridColumnType.Currency0 },
      { name: 'maxLoanPolicy', type: SimpleGridColumnType.Currency0 },
      { name: 'minLtv', suffix: '%' },
      { name: 'maxLtv', suffix: '%' },
      { name: 'maxLtsLtd', suffix: '%' },
      { name: 'firstTimeBuyer', render: renderAvailability },
      { name: 'productTransfer', render: renderAvailability },
      { name: 'furtherAdvance', render: renderAvailability },
      { name: 'existingCustomer', render: renderAvailability },
      { name: 'purchaseCashback', prefix: '£' },
      { name: 'remortgageCashback', prefix: '£' },
      { name: 'purchaseFreeLegal', type: SimpleGridColumnType.Boolean },
      { name: 'remortgageFreeLegal', type: SimpleGridColumnType.Boolean },
      { name: 'purchaseFreeVal', type: SimpleGridColumnType.Boolean },
      { name: 'remortgageFreeVal', type: SimpleGridColumnType.Boolean },
      { name: 'valRefundProductIncentive', type: SimpleGridColumnType.Boolean },
      { name: 'valRefundProductIncentiveAmount', type: SimpleGridColumnType.Currency0 },
      { name: 'arrangementFee', render: renderFee(ProductFeeType.Arrangement) },
      { name: 'arrangementFeeLoanOrProperty', render: renderArrangementFeeType },
      { name: 'bookingFee', render: renderFee(ProductFeeType.Booking) },
      { name: 'packagerFee', render: renderFee(ProductFeeType.Packager) },
      { name: 'expatNotInUk', render: renderAvailability },
      { name: 'secondResidential', render: renderAvailability },
      { name: 'jointBorrowerSoleProprietor', render: renderAvailability },
      { name: 'helpToBuy', render: renderAvailability },
      { name: 'sharedOwnership', render: renderAvailability },
      { name: 'rightToBuy', render: renderAvailability },
      { name: 'selfBuild', render: renderAvailability },
      { name: 'limitedCompanyBuyToLet', render: renderAvailability },
      { name: 'holidayLetAirBb', render: renderAvailability },
      { name: 'regulatedBuyToLet', render: renderAvailability },
      { name: 'hmo', render: renderAvailability },
      { name: 'multiUnitFreeholdBlock', render: renderAvailability },
      { name: 'letToBuy', render: renderAvailability },
      { name: 'portfolioLandlord', render: renderAvailability },
      { name: 'newBuild', render: renderAvailability },
      { name: 'sharedEquity', render: renderAvailability },
      { name: 'offset', render: renderAvailability },
      { name: 'greenEco', render: renderAvailability },
      { name: 'overpaymentsAllowed', type: SimpleGridColumnType.Boolean },
      { name: 'overpaymentsDescription' },
      { name: 'portable', type: SimpleGridColumnType.Boolean },
      { name: 'paymentHolidayAllowed', type: SimpleGridColumnType.Boolean },
      { name: 'additionalBorrowingAllowed', type: SimpleGridColumnType.Boolean },
      { name: 'retirementInterestOnly', type: SimpleGridColumnType.Boolean },
      { name: 'lendingIntoRetirement', type: SimpleGridColumnType.Boolean },
    ] satisfies ISimpleGridColumn[];
  }

  private getBridgingProductColumns(): ISimpleGridColumn[] {

    const renderFee = (type: BridgingProductFeeType) => (_: never, model: any) => {
      const fees: { type: BridgingProductFeeType; structure: ProductFeeStructure; value: number }[] = model.fees;
      const fee = fees?.find(fee => fee.type === type);
      return fee
        ? fee.structure === ProductFeeStructure.Flat ? `£${fee.value}` : `${fee.value}%`
        : '-';
    };

    const renderRate = (ordinal: number, property: string) => (_: never, { rates }: any) => {
      const rate = rates?.find(r => r.ordinal === ordinal);
      return rate
        ? rate[property]
        : '-';
    };

    return [
      { name: 'description' },
      { name: 'code' },
      { name: 'notes' },
      { name: 'isRegulated', type: SimpleGridColumnType.Boolean },
      { name: 'minTerm', suffix: ' months' },
      { name: 'maxTerm', suffix: ' months' },
      { name: 'security' },
      {
        name: 'interestMethodTypes', classList: 'ws-nowrap',
        render: (v: { interestMethod: BridgingInterestMethod }[]) => v
          .map(t => BridgingInterestMethod[t.interestMethod])
          .join(', '),
      },
      { name: 'capitalRestPeriod' },
      { name: 'productType', render: v => BridgingProductFeeType[v] },
      { name: 'maxLtv', suffix: '%' },
      { name: 'monthlyRate1', render: renderRate(1, 'rate'), suffix: '%' },
      { name: 'rate1NumMonths', render: renderRate(1, 'numMonths'), suffix: ' months' },
      { name: 'monthlyRate2', render: renderRate(2, 'rate'), suffix: '%' },
      { name: 'rate2NumMonths', render: renderRate(2, 'numMonths'), suffix: ' months' },
      { name: 'monthlyRate3', render: renderRate(3, 'rate'), suffix: '%' },
      { name: 'rate3NumMonths', render: renderRate(3, 'numMonths'), suffix: ' months' },
      { name: 'monthlyRate4', render: renderRate(4, 'rate'), suffix: '%' },
      { name: 'rate4NumMonths', render: renderRate(4, 'numMonths'), suffix: ' months' },
      { name: 'exitFee', type: SimpleGridColumnType.Boolean },
      { name: 'exitFeeDescription' },
      { name: 'dualLegalRepresentation', type: SimpleGridColumnType.Boolean },
      { name: 'overpay' },
      { name: 'freeLegal', type: SimpleGridColumnType.Boolean },
      { name: 'minLoanPolicy', type: SimpleGridColumnType.Currency0 },
      { name: 'maxLoanPolicy', type: SimpleGridColumnType.Currency0 },
      { name: 'arrangementFee', render: renderFee(BridgingProductFeeType.Arrangement) },
      { name: 'chapsFee', render: renderFee(BridgingProductFeeType.Chaps) },
      { name: 'freeVal', type: SimpleGridColumnType.Boolean },
      { name: 'valuationFee', render: renderFee(BridgingProductFeeType.Valuation) },
      { name: 'avmAccepted', type: SimpleGridColumnType.Boolean },
      { name: 'legalFees', render: renderFee(BridgingProductFeeType.Legal) },
      { name: 'adminFee', render: renderFee(BridgingProductFeeType.Admin) },
      { name: 'purchaserType' },
      { name: 'marketValueCurrent', type: SimpleGridColumnType.Boolean },
      { name: 'marketValue90', type: SimpleGridColumnType.Boolean },
      { name: 'marketValue180', type: SimpleGridColumnType.Boolean },
      { name: 'minIncome', type: SimpleGridColumnType.Boolean },
      { name: 'bridgeToLet', type: SimpleGridColumnType.Boolean },
      { name: 'letToBuy', type: SimpleGridColumnType.Boolean },
      { name: 'portfolioLandlord', type: SimpleGridColumnType.Boolean },
      { name: 'expatNotInUk', type: SimpleGridColumnType.Boolean },
    ] satisfies ISimpleGridColumn[];
  }
}
