import { CriteriaType } from 'apps/shared/src/models';

export enum CriteriaOutcomeResult {
  No = 0,
  Yes = 1,
  Refer = 2,
}

export interface CriteriaOutcomeResultViewModel {
  criteriaContextId: number;
  result: CriteriaOutcomeResult;
}

export enum IntuitiveCriteriaPropertyStatus {
  Property,
  NoProperty,
  Both,
}

export class LenderCriteriaOutcomeContextNote {
  public title: string;
  public note: string;
}

export interface OutcomeDetailsCardV2 {
  issue: string;
  context: string | undefined;
  criteriaType: CriteriaType;
  questionsAndAnswers: {
    question: string | undefined;
    answer: string | undefined;
    answerUnits: string | undefined;
  }[];
}
