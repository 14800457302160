import { Injectable } from '@angular/core';
import { Observable, shareReplay, tap } from 'rxjs';
import { ContextQuestion, ContextQuestions, CriteriaIssue } from '@msslib/models/criteria-issue';
import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import {
  LenderCriteriaSaveRequest,
  LenderCriteriaTitleSaveRequest,
} from 'apps/lenderhub/src/app/pages/criteria-v2/models';
import { LenderHubDataService } from '@msslib/services';

@Injectable({
  providedIn: 'root',
})
export class CriteriaV2Service {
  public criteria: CriteriaIssue[];
  private contextQuestionObservables = new Map<number, Observable<ContextQuestion[]>>();

  public constructor(
    private clubHubDataService: ClubHubDataService,
    private lenderHubDataService: LenderHubDataService,
  ) { }

  public getIssues(lenderTypeId: number): Observable<CriteriaIssue[]> {
    return this.clubHubDataService.get<CriteriaIssue[]>(`CriteriaV2/Issues?lendingTypeId=${lenderTypeId}`)
      .pipe(tap(criteria => this.criteria = criteria));
  }

  public getContextQuestions(contextId: number): Observable<ContextQuestion[]> | undefined {
    if (!this.contextQuestionObservables.has(contextId)) {
      this.contextQuestionObservables.set(
        contextId,
        this.clubHubDataService
          .get<ContextQuestion[]>(`CriteriaV2/Context/${contextId}/Questions`, { loading: false })
          .pipe(shareReplay(1)),
      );
    }
    return this.contextQuestionObservables.get(contextId);
  }

  public getLenderContextQuestions(contextId: number, lenderId: number):  Observable<ContextQuestions> {
    return this.lenderHubDataService.get(`Criteria/Context/${contextId}/Lender/${lenderId}/Questions`);
  }

  public getLiveContextQuestions(contextId: number, lenderId: number):  Observable<ContextQuestions> {
    return this.lenderHubDataService.get(`Criteria/Context/${contextId}/Lender/${lenderId}/LiveQuestions`);
  }

  public saveLenderCriteria(body: LenderCriteriaSaveRequest) {
    return this.lenderHubDataService.post('Criteria/LenderCriteria', body);
  }

  public getSearchSynonyms(): Observable<[string, string][]> {
    return this.clubHubDataService.get<[string, string][]>('CriteriaV2/SearchSynonyms');
  }

  public saveLenderCriteriaTitle(request: LenderCriteriaTitleSaveRequest) {
    return this.lenderHubDataService.post('Criteria/LenderCriteriaTitle', request);
  }

  public verifyLenderContext(criteriaContextLendingTypeId: number, lenderId?: number) {
    return this.lenderHubDataService.patch(
      `Criteria/VerifyContext/${criteriaContextLendingTypeId}/Lender/${lenderId}`, {});
  }
}
