import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ILoadingOptions } from '../../models';
import { LoadingService } from '../../services';

@Component({
  selector: 'lib-loading',
  styleUrls: ['loading.component.scss'],
  templateUrl: 'loading.component.html',
})
export class LoadingComponent implements AfterViewInit, OnDestroy {
  public loadingSubscription: Subscription;
  public loadingOptions: ILoadingOptions;

  public constructor(
    private loadingScreenService: LoadingService,
    private elmRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  public ngAfterViewInit(): void {
    this.elmRef.nativeElement.style.display = 'none';
    this.loadingSubscription =
      this.loadingScreenService.loading$.pipe().subscribe((loadingOptions: ILoadingOptions) => {
        this.loadingOptions = loadingOptions;
        this.elmRef.nativeElement.style.display = loadingOptions.loading ? 'block' : 'none';
        this.changeDetectorRef.detectChanges();
      });
  }

  public ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}
