import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Banner } from '../../models/banner.model';

@Component({
  selector: 'lib-banner',
  templateUrl: 'banner.component.html',
  styleUrls: ['banner.component.scss'],
})
export class BannerComponent {
  @Input() public maxWidth: string;
  @Input() public maxHeight: string;
  @Input() public banner: Banner;
  @Input() public withCloseButton: boolean;
  @Input() public visible = true;
  @Output() public closeBanner = new EventEmitter<void>();
  @Input() public content: string;

  public close() {
    this.visible = false;
    this.closeBanner.emit();
  }
}
