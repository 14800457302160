/* eslint-disable camelcase */
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ProductFeeType, getFeeDisplayNameNumeric } from '@msslib/constants/product-fees';
import { CustomFee } from '@msslib/models';
import { ProductsSearchService } from 'apps/clubhub/src/app/ignite/services';
import { Product } from 'apps/shared/src/models';
import { ProductsUtils } from 'apps/shared/src/utils/products.utils';

@Component({
  selector: 'lib-product-fees-tooltip',
  templateUrl: 'product-fees-tooltip.component.html',
  styleUrls: ['product-fees-tooltip.component.scss'],
})
export class ProductFeesTooltipComponent {
  @Input() public product: Product;

  public constructor(
    private cd: ChangeDetectorRef,
    private productsSearchService: ProductsSearchService,
    private productsUtils: ProductsUtils,
  ) {}

  public get customFees(): CustomFee[] {
    return this.productsSearchService.customProductCalculations?.customFees || [];
  }

  public get isRefer() {
    return !!this.product?.fees[ProductFeeType.Valuation] &&
    (this.product?.isValuationFeeRefer || this.product?.fees[ProductFeeType.Valuation] === -1);
  }

  public get assumedLegalFee(): number | null {
    return this.productsUtils.getAssumedLegalFee(this.product, this.productsSearchService);
  }

  public get total() {
    this.cd.markForCheck();
    return this.productsUtils.calculateTotalFees(this.product, this.productsSearchService);
  }

  public getCustomFeeAmount(fee: CustomFee): number {
    return this.productsUtils.getCustomFeeAmount(fee, this.productsSearchService);
  }

  public getFeeDisplayName(fee: ProductFeeType): string {
    return getFeeDisplayNameNumeric(fee);
  }
}
