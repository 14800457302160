import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MiGenericService } from '../mi-generic.service';
import { IEmbedConfig, IMiReport } from '../mi-generic.model';
import { AnalyticsService } from '@msslib/services/analytics.service';

@Component({
  selector: 'app-mi-executive-summary',
  styleUrls: ['mi-executivesummary.component.scss'],
  templateUrl: 'mi-executivesummary.component.html',
})
export class MiExecutiveSummaryComponent implements OnInit, AfterViewInit {
  public miReports: IMiReport[];
  public embedConfig: IEmbedConfig;

  public constructor(
    private analyticsService: AnalyticsService,
    private miGenericService: MiGenericService,
  ) { }

  public ngOnInit() {
    this.miGenericService.getReports('ExecutiveMarketSummary').subscribe(reports => {
      this.miReports = reports;
      if (reports.length && reports[0].id) {
        this.reportChange(reports[0].id);
      }
    });
  }

  public ngAfterViewInit() {
    this.analyticsService.log('Executive Market Summary');
  }

  public reportChange(reportId: string) {
    this.miGenericService.getReportConfiguration(reportId, 'ExecutiveMarketSummary').subscribe(config => {
      this.embedConfig = config;
    });
  }
}
