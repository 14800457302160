export const loadingImageHeader = 'x-loading-image';
export const loadingOverlayModeHeader = 'x-loading-overlay-mode';

export interface ILoadingOptions {
  loading: boolean;
  loadingImage?: string | null;
}

export enum LoadingOverlayMode {
  Normal = 'normal',
  Disable = 'disable',
}
