import { Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { IUiPropertySearchModel } from 'apps/clubhub/src/app/ignite/models/property';
import { IUiProductsSearchModel } from 'apps/clubhub/src/app/ignite/models/products';
import { AffordabilityPollResponse, IUiAffordabilityModel } from 'apps/clubhub/src/app/ignite/models/affordability';
import { SearchType } from 'apps/shared/src/models/affordability-enums';
import { IgniteResponse, ToolsInUse } from '../models';
import { CriteriaResponse } from 'apps/shared/src/models/outcome';
import maxBy from 'lodash-es/maxBy';

@Injectable({
  providedIn: 'root',
})
export class IgniteCommonDataService {
  public constructor(private router: Router) {}

  public propertyModel: IUiPropertySearchModel | null;
  public productModel: IUiProductsSearchModel | null;
  public affordabilityModel: IUiAffordabilityModel | null;
  public source: SearchType | null;
  public isInaccuracyReport = false;
  public isOutcomeHistory = false;
  public searchToolsInUse: ToolsInUse = {
    affordability: false,
    products: false,
    property: false,
    criteria: false,
  };
  public affordabilityLendersList: string[] = [];

  private _results = signal<AffordabilityPollResponse | CriteriaResponse | null>(null);

  public get results() {
    return this._results();
  }

  public set results(value: AffordabilityPollResponse | CriteriaResponse | null) {
    this._results.set(value);
  }

  public get allAffordabilityLenders(): string[] | undefined {
    return this.results?.quotes?.map(x => x.lenderName).sort();
  }

  public get maxMalaResult(): IgniteResponse | undefined {
    return maxBy(this.results as any, (o: IgniteResponse) => o.maximumAffordableLoanAmount);
  }

  public get affordabilitySearchId(): string | null {
    return (this.results as AffordabilityPollResponse)?.affordabilitySearchId ?? null;
  }

  public get pollJobId() {
    return this.results?.pollJobID;
  }

  public get hasAffordability(): boolean {
    return !!this.results?.quotes?.length;
  }

  public get productsOnly(): boolean {
    return this.router.url.includes('products');
  }

  public reset() {
    this.propertyModel = null;
    this.productModel = null;
    this.affordabilityModel = null;
    this.source = null;
    this.isInaccuracyReport = false;
    this.isOutcomeHistory = false;
    this.searchToolsInUse = {
      affordability: false,
      products: false,
      property: false,
      criteria: false,
    };
  }

  public get isHistoricResult() {
    return this.isInaccuracyReport || this.isOutcomeHistory;
  }

  public checkCustomLenderError(result: IgniteResponse) {
    if (
      result.maximumAffordableLoanAmount === null &&
      this.affordabilityLendersList.includes(result.lenderName)
    ) {
      return false;
    }
    return this.checkEarlShilton(result) ?? this.checkHinckleyAndRugby(result);
  }

  public checkEarlShilton(result: IgniteResponse) {
    return result.lenderName === 'Earl Shilton' &&
      this.affordabilityModel?.propertyAndLoan.productLength &&
      this.affordabilityModel?.propertyAndLoan.productLength < 5;
  }

  public checkHinckleyAndRugby(result: IgniteResponse) {
    return result.lenderName === 'Hinckley & Rugby' &&
      this.affordabilityModel?.applicants &&
      this.affordabilityModel?.applicants.numberOfApplicants > 2;
  }
}
