import { Component } from '@angular/core';
import { ModalService } from '@msslib/services/modal.service';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'lib-ff-template',
  styleUrls: ['./template.component.scss'],
  templateUrl: './template.component.html',
})
export class TemplateComponent extends FieldType {
  public constructor(private modalService: ModalService) {
    super();
  }

  public openTooltip() {
    this.modalService
      .open({
        title: this.props.label !== undefined ? this.props.label : this.props.addText,
        message: this.props.tooltip,
      }).then(() => null, () => null);
  }

  public close() {
    this.modalService.close();
  }
}
