import { Component } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MssFieldBaseFieldTypeComponent } from '../mss-field-base.component';

@Component({
  templateUrl: 'currency.component.html',
})
export class CurrencyFieldTypeComponent extends MssFieldBaseFieldTypeComponent {
  public static defaultOptions: FormlyFieldConfig = {
    defaultValue: 0,
    props: {
      addonStart: '£',
      min: 0,
      ...MssFieldBaseFieldTypeComponent.focusBlurZeroDefault,
    },
  };
}
