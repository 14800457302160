export interface ISortOption {
  value: unknown;
  label: string;
  icon: string;
  iconColour?: string;
  image?: string;
}

export interface ISortMainImage {
  src: string;
  maxWidth: number;
}

export enum SortOrder {
  Ascending = 1,
  Descending = 2,
}

export enum IgniteOrder {
  Yes = 'Y',
  No = 'N',
  Refer = 'R',
  Unset = 'Z',
}

export enum LendingTypeOrder {
  Residential = 'Residential',
  BuyToLet = 'BuyToLet',
}

export enum InitialPeriodOrder {
  Ascending = 'asc',
  Descending = 'desc',
  // eslint-disable-next-line @typescript-eslint/no-mixed-enums
  Duration1Year = 1,
  Duration2Year = 2,
  Duration3Year = 3,
  Duration4Year = 4,
  Duration5YearPlus = 5,
}

export enum ProductTypeOrder {
  Ascending = 'asc',
  Descending = 'desc',
  Fixed = 'fixed',
  Tracker = 'tracker',
  SteppedFixed = 'stepped fixed',
  // eslint-disable-next-line spellcheck/spell-checker
  TrackerSonia = 'tracker (sonia)',
}

export enum ErcOrder {
  Yes = 'Yes',
  No = 'No',
  Blank = 'Blank',
}
