import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-input-error-description',
  templateUrl: 'input-error-description.component.html',
})
export class InputErrorDescriptionComponent {
  @Input() public errorMessage: string;
  @Input() public visible: boolean;
}
