import { Injectable } from '@angular/core';
import {
  HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,
} from '@angular/common/http';
import { catchError, finalize, tap } from 'rxjs/operators';

import { LoadingService } from '../loading.service';
import { LoadingOverlayMode, loadingImageHeader, loadingOverlayModeHeader } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class LoadingInterceptor implements HttpInterceptor {
  public constructor(private loadingService: LoadingService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    if (request.headers.get(loadingOverlayModeHeader) === LoadingOverlayMode.Disable) {
      return next.handle(request);
    }

    let response: HttpEvent<unknown>;
    let error: HttpErrorResponse;

    this.loadingService.startLoading({ loading: true, loadingImage: request.headers.get(loadingImageHeader) });
    return next.handle(request).pipe(
      tap((res) => {
        response = res;
        if (res instanceof HttpResponse || res instanceof HttpErrorResponse) {
          this.loadingService.stopLoading({ loading: false });
        }
      }),
      catchError((err) => {
        error = err;
        this.loadingService.stopLoading({ loading: false });
        throw err;
      }),
      finalize(() => {
        if (!response || response.type === HttpEventType.Sent && error === null) {
          this.loadingService.stopLoading({ loading: false });
        }
      }),
    );
  }
}
