import { Component } from '@angular/core';

import { FieldBaseComponent } from '../field-base';

@Component({
  selector: 'lib-form-link',
  styleUrls: ['form-link.component.scss'],
  templateUrl: 'form-link.component.html',
})
export class FormLinkComponent extends FieldBaseComponent {}
