import { Injectable } from '@angular/core';

@Injectable()
export class PaginationService {
  public maxPageSize = 5;
  public numberOfItems: number;
  public serverSideMode = false;

  public loadPage: () => undefined;

  public currentPageNumber = 1;

  public get pageNumber() {
    return this.currentPageNumber;
  }

  public set pageNumber(pgNum) {
    this.currentPageNumber = pgNum;

    if (this.serverSideMode) {
      this.loadPage();
    }
  }

  public getItemIndex(rowNumber: number): number {
    if (this.serverSideMode) {
      return rowNumber;
    }

    return rowNumber + this.maxPageSize * (this.currentPageNumber - 1);
  }

  public nextPage() {
    if (this.currentPageNumber !== this.numOfPages) {
      ++this.currentPageNumber;
    }

    if (this.serverSideMode) {
      this.loadPage();
    }
  }

  public previousPage() {
    if (this.currentPageNumber !== 1) {
      --this.currentPageNumber;
    }

    if (this.serverSideMode) {
      this.loadPage();
    }
  }

  public goToPage(pageNr: number) {
    this.currentPageNumber = pageNr;

    if (this.serverSideMode) {
      this.loadPage();
    }
  }

  public get onFirstPage() {
    return this.currentPageNumber === 1;
  }

  public get onLastPage() {
    return this.currentPageNumber === this.numOfPages;
  }

  public get numOfPages() {
    return Math.ceil(this.numberOfItems / this.maxPageSize);
  }

  public get pageRowIndexes() {
    if (this.currentPageNumber !== this.numOfPages) {
      return Array(this.maxPageSize)
        .fill(0)
        .map((x, i) => i);
    }
    if (+this.maxPageSize === 1) {
      return [0];
    }

    let pageRowsCount = this.numberOfItems % this.maxPageSize;

    if (+pageRowsCount === 0) {
      pageRowsCount = this.maxPageSize;
    }

    return Array(pageRowsCount)
      .fill(0)
      .map((x, i) => i);
  }
}
