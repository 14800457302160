import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ClubHubDataService } from '../services/clubhub-data.service';
import { LendingTypeService } from '../services/lending-type.service';
import { FilterIssuesOptions, IssuesContextSearchViewModel } from '../models';

@Injectable({
  providedIn: 'root',
})
export class IssuesService {
  public constructor(private lendingTypeService: LendingTypeService, private clubHubDataService: ClubHubDataService) {}

  public filterIssues(term: string, opt: FilterIssuesOptions | null = null): Observable<IssuesContextSearchViewModel> {
    const filterParam = {
      searchTerm: term,
      lendingType: opt?.allowAnyLendingType ? null : this.lendingTypeService.lendingType?.code,
      isEditMode: opt?.isEditMode ?? false,
      includeSynonym: opt?.includeSynonym ?? true,
      isDeep: !!opt?.isDeep,
      returnParents: opt?.returnParents ?? true,
    };
    return this.clubHubDataService.post('answer/searchissues', filterParam, { loading: false });
  }
}
