import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ModalOptions } from '../models';

/**
 * An internal service allowing to access, from the confirm modal component, the options and the modal reference.
 * It also allows registering the TemplateRef containing the confirm modal component.
 *
 * It must be declared in the providers of the NgModule, but is not supposed to be used in application code
 */
@Injectable({
  providedIn: 'root',
})
export class ModalStateService {
  public modal: NgbModalRef;

  /**
   * The last options passed ConfirmService.confirm()
   */
  public options: ModalOptions;

  public get topmostModal() {
    return this.modalStack[this.modalStack.length - 1];
  }

  /**
   * Stack of opened modals
   */
  public modalStack: NgbModalRef[] = [];

  /**
   * The template containing the confirmation modal component
   */
  public template: unknown;

  public pushModal(modal: NgbModalRef) {
    this.modal = modal;
    this.modalStack.push(modal);
    modal.dismissed.subscribe(() => this.modalStack = this.modalStack.filter(x => x !== modal));
    modal.closed.subscribe(() => this.modalStack = this.modalStack.filter(x => x !== modal));
  }
}
