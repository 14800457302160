import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  template: '',
})
export class MssFieldBaseFieldTypeComponent extends FieldType {
  /** Focus and blur events that will remove/insert a zero on a field with a default value of 0 */
  public static focusBlurZeroDefault: Partial<FormlyFieldConfig['props']> = {
    focus: (field, event) => {
      // If a field with a defaultvalue of 0 is 0 when focused, clear out the zero automatically
      if (field.defaultValue === 0 && +field.formControl?.value === 0) {
        event.target.value = '';
      }
    },
    blur: (field) => {
      // If a field with a defaultvalue of 0 is left empty on blur, default it to a 0 instead
      if (field.defaultValue === 0 && +field.formControl?.value === 0) {
        field.formControl?.setValue(0);
      }
    },
  };
}
