<i *ngIf="props.tooltip" class="far fa-lg fa-question-circle pointer d-md-none" (click)="openTooltip()"></i>
<div class="row justify-content-end">
  <div *ngFor="let button of props.buttons" [ngClass]="'col-' + (12 / numColumns)">
    <button
      [disabled]="props.disabled"
      class="btn w-100"
      [ngClass]="button.className ?? ''"
      [class.disabled]="formControl.disabled"
      [type]="button.type ?? 'button'"
      (click)="!button.onClick || button.onClick(field)"
      [attr.data-testid]="props.testId"
    >
      <i *ngIf="button.iconClass" [ngClass]="button.iconClass"></i>
      {{ button.label }}
    </button>
  </div>
</div>
