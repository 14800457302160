import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImageSaveEvent } from '../../models';
import { ModalService } from '../../services';

@Component({
  selector: 'lib-image-resizer',
  styleUrls: ['image-resizer.component.scss'],
  templateUrl: 'image-resizer.component.html',
})
export class ImageResizerComponent {
  @Input() public width = 200;
  @Input() public height = 100;
  @Input() public title: string;
  @Input() public src: string;
  @Input() public showUploadButton: boolean;
  @Input() public triggerSaveOnSelect: boolean;
  @Output() public save = new EventEmitter<ImageSaveEvent>();
  @Output() public remove = new EventEmitter<string>();

  public widthValid = false;
  public aspectValid = false;

  public isSvg: boolean;
  public fileSrc: string | null;
  public file: File;

  public constructor(private modalService: ModalService, private sanitizer: DomSanitizer) {}

  public get imageSource(): string {
    return this.fileSrc ?? this.src;
  }

  public onSave() {
    this.emitSave();
    this.fileSrc = null;
    this.isSvg = false;
  }

  public onRemove(input: HTMLInputElement) {
    if (this.fileSrc) {
      this.isSvg = false;
      this.fileSrc = null;
      input.value = '';
      return;
    }

    if (this.src) {
      this.modalService
        .warn({
          title: 'Confirm',
          message: 'Are you sure you want to delete?',
          okLabel: 'Yes',
          cancelLabel: 'No',
        })
        .then(
          () => {
            input.value = '';
            this.remove.emit();
          },
        );
    }
  }

  // This is called when the user selects new files from the upload button
  public fileChange(input: { files: any[] }) {
    this.readFiles(input.files[0], () => {
      const url = window.URL || window.webkitURL;
      let file, img;
      if (file = input.files[0]) {
        this.file = file;
        const objectUrl = url.createObjectURL(file);
        img = new Image();

        img.onload = () => {
          this.widthValid = this.width <= 500;
          this.aspectValid = this.width / this.height >= 1.3;

          if (this.aspectValid && this.widthValid) {
            if (this.triggerSaveOnSelect) {
              this.emitSave();
            }
          } else {
            const widthNotice = `The image is ${this.width}px wide. It needs to be less than 500px.`;
            const aspectNotice =
              `The aspect ratio of the image is 1:${(this.width / this.height).toFixed(2)}, ` +
              'it needs to be either wider or less high. ';

            if (!this.widthValid && !this.aspectValid) {
              // eslint-disable-next-line no-alert
              alert(`${ widthNotice } ${ aspectNotice }`);
            } else if (!this.widthValid) {
              // eslint-disable-next-line no-alert
              alert(widthNotice);
            } else if (!this.aspectValid) {
              // eslint-disable-next-line no-alert
              alert(aspectNotice);
            }
          }

          url.revokeObjectURL(objectUrl);
        };

        img.src = objectUrl;
      }
    });
  }

  public readFile(file: File, reader: FileReader, callback) {
    // Set a callback function to fire after the file is fully loaded
    reader.onload = () => {
      this.isSvg = file.type === 'image/svg+xml';

      // callback with the results
      callback(reader.result);
    };

    // Read the file
    reader.readAsDataURL(file);
  }

  public readFiles(file: File, callback: { (): void; (): void }) {
    // Create the file reader
    const reader = new FileReader();

    // If there is a file
    // Start reading this file
    this.readFile(file, reader, (result: string) => {
      // Create an img element and add the image file data to it
      const img = document.createElement('img');
      img.src = result;

      // Send this img to the resize function (and wait for callback)
      this.resize(img, this.width, this.height, file.type, (resized: string | null) => {
        // Add the resized jpeg img source to a list for preview
        // This is also the file you want to upload. (either as a
        // base64 string or img.src = resized_jpeg if you prefer a file).
        this.fileSrc = resized;
        callback();
      });
    });
  }

  public resize(img: HTMLImageElement, maxWidth: number, maxHeight: number, fileType: string, callback: any) {
    // This will wait until the img is loaded before calling this function
    return (img.onload = () => {
      // Get the images current width and height
      let width = img.width;
      let height = img.height;

      // Set the WxH to fit the Max values (but maintain proportions)
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      } else if (width > maxWidth) {
        width = maxWidth;
        height *= maxWidth / width;
      }
      let dataUrl: SafeUrl;
      if (!this.isSvg) {
        // create a canvas object
        const canvas = document.createElement('canvas');

        // Set the canvas to the new calculated dimensions
        this.width = canvas.width = width;
        this.height = canvas.height = height;
        const ctx = canvas.getContext('2d');

        ctx?.drawImage(img, 0, 0, width, height);

        // Get this encoded as a jpeg
        // IMPORTANT: 'jpeg' NOT 'jpg'
        dataUrl = canvas.toDataURL(fileType ? fileType : 'image/jpeg');
      } else {
        dataUrl = this.sanitizer.bypassSecurityTrustUrl(img.src);
      }

      // callback with the results
      callback(dataUrl);
    });
  }

  private emitSave() {
    this.save.emit({
      fileSrc: this.fileSrc ?? '',
      isSvg: this.isSvg,
      fileType: this.file.type,
      file: this.file,
    });
  }
}
