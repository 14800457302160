<div [class]="config.cssClass ? config.cssClass : ''">
  <div [formGroup]="formGroup" class="form-group">
    <label for="{{ config.name }}">
      {{ config.label }}

      <ng-container *ngIf="showAsterisk(config)">
        *
      </ng-container>

      <ng-template #tooltip>
        <div [innerHtml]="config.tooltip"></div>
      </ng-template>

      <ng-container *ngIf="config.tooltip">
        <i class="fas fa-info-circle" [ngbTooltip]="tooltip"></i>
      </ng-container>
    </label>

    <input
      type="{{ config.type === 'email' ? 'email' : config.type === 'number' ? 'number' : config.type === 'password' ? 'password' : 'text' }}"
      [attr.placeholder]="config.placeholder"
      [formControlName]="config.name"
      class="form-control"
      [ngClass]="{ 'is-valid': valid, 'is-invalid': invalid }"
      [id]="config.name"
      [attr.aria-describedby]="config.name + 'err'"
      [attr.data-testid]="'form_'+config.type+'_'+config.name | slugify:'_'"
      />
    <ng-container *ngIf="config.additionalText">
      <small id="{{ config.name + '-text' }}" class="form-text text-muted">
        {{ config.additionalText }}
      </small>
    </ng-container>

    <div libFormFieldError class="invalid-feedback" [fieldConfig]="config"></div>
  </div>
</div>
