import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Banner } from '../../models/banner.model';
import { BannerService } from '../../services/banner.service';

@Component({
  selector: 'lib-ignite-banner',
  templateUrl: 'ignite-banner.component.html',
})
export class IgniteBannerComponent implements OnInit {
  @Input() public maxWidth: string;
  @Input() public maxHeight: string;
  @Input() public bannerName: string;
  @Input() public withCloseButton: boolean;
  @Input() public visible = true;
  @Input() public content: string;
  @Output() public closeBanner = new EventEmitter<void>();
  public banner: Banner;

  public constructor(
    private bannerService: BannerService,
  ) { }

  public ngOnInit(): void {
    this.bannerService.getIgniteBannerByName(this.bannerName).subscribe((banner: Banner) => {
      this.banner = banner;
    });
  }

  public onBannerClose(): void {
    this.visible = false;
    this.closeBanner.emit();
  }
}
