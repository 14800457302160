import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FormsValidators } from '@msslib/components';
import { FormFieldType, WrapperType, yesNoOptions } from '@msslib/components/formly/formly.config';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { IMortgageRequirements } from 'apps/clubhub/src/app/ignite/models/products';
import {
  MortgageLenders,
  MortgageType,
  ProductType, PropertyType,
  PurchaserType,
  RepaymentMethod,
} from 'apps/shared/src/models';
import { errorMessages } from 'apps/clubhub/src/app/ignite/models/affordability/error-messages';
import { ProductsSearchService } from '../products-search.service';
import { IgniteHelperService } from '@msslib/services';
import { LenderService } from 'apps/shared/src/services/lender.service';
import { map } from 'rxjs';

const secondChargeLoanAmountLabel = 'Second Charge Loan Amount';
const loanAmountLabel = 'Loan Amount';

@Injectable({
  providedIn: 'root',
})
export class ProductsFormFieldsResiService {
  public constructor(
    private helperService: IgniteHelperService,
    private productsSearchService: ProductsSearchService,
    private lenderService: LenderService,
  ) { }

  public resetFieldIfEmpty(field: FormlyFieldConfig) {
    if (!field.model) {
      field.formControl?.reset();
    }
  }

  public keyboardEventMarkTouched(field: FormlyFieldConfig, event: KeyboardEvent) {
    const key = event.key;
    if (!['Tab', 'Shift'].includes(key)) {
      field.formControl?.markAsTouched();
    }
  }

  public fields: FormlyFieldConfig[] = [
    {
      key: 'mortgageRequirements',
      wrappers: [WrapperType.FormCard],
      props: {
        title: 'Mortgage Details',
      },
      validators: {
        max500PercentLoanValue: {
          expression: FormsValidators.expression(FormsValidators.ltv(500)),
          message: 'Loan amount should not exceed 500% of property value',
          errorPath: 'loanAmount',
        },
        interestOnlyAmountValidator: {
          expression: (control: AbstractControl) => {
            const repaymentMethodValue = (control as any).controls?.repaymentMethod?.value?.value;
            if (repaymentMethodValue === RepaymentMethod.InterestOnlyPartAndPart) {
              const validator = FormsValidators.expression(FormsValidators
                .greaterThanField<IMortgageRequirements>('interestOnlyAmount', 'loanAmount', true));
              return validator(control);
            }

            return true;
          },
          message: errorMessages.interestOnlyLessThanLoanAmount,
          errorPath: 'interestOnlyAmount',
        },
        productTransferInitialDate: this.helperService.getProductTransferInitialPeriodValidation(),
      },
      fieldGroup: [
        {
          key: 'mortgageType',
          type: 'radio-button',
          props: {
            label: 'Mortgage Type',
            options: this.getMortgageTypeOptions(),
            required: true,
            change: (field: FormlyFieldConfig) => {
              const { mortgageType } = (field?.model ?? {}) as IMortgageRequirements;
              if (mortgageType === MortgageType.Purchase) {
                this.productsSearchService.model.mortgageRequirements.helpToBuy = false;
                this.productsSearchService.updateModel(this.productsSearchService.model);
              }
              const helpToBuyValue = mortgageType === MortgageType.Remortgage || mortgageType === MortgageType.Purchase
                ? false
                : null;
              (field.parent?.formControl as UntypedFormGroup).controls?.helpToBuy?.setValue(helpToBuyValue);

              (field.parent?.formControl as UntypedFormGroup).controls.productTypeExtended
                ?.setValue(ProductType.Standard);
            },
          },
        },
        {
          key: 'productTypeExtended',
          type: 'select',
          props: {
            label: 'Product Type',
            options: [
              { label: 'Standard', value: ProductType.Standard },
              { label: 'Shared Ownership', value: ProductType.SharedOwnership },
              { label: 'Right To Buy', value: ProductType.RightToBuy },
              { label: 'Shared Equity', value: ProductType.SharedEquity },
              { label: 'Self-Build', value: ProductType.SelfBuild },
              { label: 'Retirement Interest Only (RIO)', value: ProductType.RetirementInterestOnly },
              { label: 'Joint Borrower-Sole Proprietor', value: ProductType.JointBorrowerSoleProprietor },
            ],
            change: (field: FormlyFieldConfig, event) => {
              const isRio = this.helperService
                .getEnumValue(event.target.value) as ProductType === ProductType.RetirementInterestOnly;
              if (isRio) {
                (field.parent?.formControl as UntypedFormGroup).controls.repaymentMethod
                  .setValue({ value: RepaymentMethod.InterestOnly });
                (field.parent?.formControl as UntypedFormGroup).controls.helpToBuy
                  ?.setValue(false);
              }
            },
          },
        },
        {
          key: 'newBuild',
          type: FormFieldType.RadioButtons,
          props: {
            label: 'New build',
            options: yesNoOptions,
          },
        },
        {
          key: 'propertyType',
          type: FormFieldType.RadioButtons,
          defaultValue: PropertyType.House,
          props: {
            label: 'Property type',
            options: [
              { label: 'House', value: PropertyType.House },
              { label: 'Flat', value: PropertyType.Flat },
            ],
            required: true,
          },
          expressions: {
            hide: field => !field.model?.newBuild,
          },
        },
        ...this.mortgageLenderFields(),
        {
          key: 'propertyValue',
          type: 'currency',
          props: {
            label: 'Property Value',
            keyup: this.keyboardEventMarkTouched,
            blur: this.resetFieldIfEmpty,
          },
          validators: {
            validation: [FormsValidators.greaterThanOnTouched(0)],
          },
          expressions: {
            'props.readonly': () => this.productsSearchService.propertyValueDisabled,
          },
        },
        {
          key: 'loanAmount',
          type: 'currency',
          props: {
            keyup: this.keyboardEventMarkTouched,
            blur: this.resetFieldIfEmpty,
          },
          validators: {
            validation: [FormsValidators.greaterThanOnTouched(0)],
          },
          expressions: {
            'props.readonly': () => this.productsSearchService.loanAmountDisabled,
            'props.label': field => {
              return field.model?.mortgageType === MortgageType.SecondCharge
                ? secondChargeLoanAmountLabel
                : loanAmountLabel;
            },
          },
        },
        {
          key: 'outstandingBalanceCurrentMortgage',
          type: 'currency',
          props: {
            label: 'Outstanding Balance on the Current Mortgage',
            keyup: this.keyboardEventMarkTouched,
            blur: this.resetFieldIfEmpty,
          },
          validators: {
            validation: [FormsValidators.greaterThanOnTouched(0)],
          },
          expressions: {
            hide: field => field.model?.mortgageType !== MortgageType.SecondCharge,
            'props.readonly': () => this.productsSearchService.loanAmountDisabled,
          },
        },
        {
          key: 'ltv',
          type: 'disabled',
          props: {
            label: 'LTV',
            addonEnd: '%',
          },
          expressions: {
            'props.value': field =>
              this.helperService.calculateLtv((field.parent?.model ?? {}) as IMortgageRequirements),
          },
        },
        {
          key: 'purchaserType',
          type: 'radio-button',
          props: {
            label: 'Purchaser type',
            options: [
              { label: 'First time buyer', value: PurchaserType.FirstTimeBuyer },
              { label: 'Homemover', value: PurchaserType.HomeMover },
            ],
          },
          expressions: {
            hide: field => field.parent?.model?.mortgageType !== MortgageType.Purchase,
          },
        },
        {
          key: 'helpToBuy',
          type: 'radio-button',
          resetOnHide: true,
          props: {
            label: 'Help to buy',
            tooltip: 'Only select if your customer is an existing Help to Buy scheme user',
            options: yesNoOptions,
            change: (field) => (field.form as AbstractControl).patchValue({
              repaymentMethod: { value: field.model.helpToBuy ? RepaymentMethod.CapitalAndInterest : null },
            }),
          },
          expressions: {
            hide: this.isHelpToBuyHidden,
            'props.disabled': (field) => field.model.productTypeExtended === ProductType.RetirementInterestOnly,
          },
        },
        {
          key: 'repaymentMethod',
          props: {
            label: 'Method of repayment',
          },
          fieldGroup: [
            {
              key: 'value',
              type: 'select',
              props: {
                allowNull: true,
                options: [
                  { label: 'Capital and interest', value: RepaymentMethod.CapitalAndInterest },
                  { label: 'Interest only', value: RepaymentMethod.InterestOnly },
                  { label: 'Interest only part and part', value: RepaymentMethod.InterestOnlyPartAndPart },
                ],
                collapseMargin: true,
              },
              expressions: {
                'props.readonly': field => this.isHelpToBuySelected(field.parent) ||
                  (field.parent?.parent?.formControl as UntypedFormGroup).controls?.productTypeExtended
                    ?.value === ProductType.RetirementInterestOnly,
              },
            },
          ],
        },
        {
          key: 'interestOnlyAmount',
          type: 'currency',
          props: {
            label: 'Amount on interest only',
            required: true,
            keyup: this.keyboardEventMarkTouched,
            blur: this.resetFieldIfEmpty,
          },
          expressions: {
            hide: field => (field.parent?.model as IMortgageRequirements)?.repaymentMethod?.value
              !== RepaymentMethod.InterestOnlyPartAndPart,
          },
          validators: {
            validation: [FormsValidators.greaterThanOnTouched(0)],
          },
        },
        {
          key: 'mortgageTerm',
          fieldGroupClassName: 'row',
          wrappers: ['horizontal-field'],
          props: {
            label: 'Mortgage term',
            suppressErrors: true,
          },
          fieldGroup: [
            {
              key: 'years',
              type: 'number',
              className: 'col',
              wrappers: [WrapperType.NoWrappers],
              props: {
                min: 0,
                max: 40,
                addonEnd: 'years',
                addonEndClass: 'fw-light',
                groupedField: true,
                collapseMargin: true,
              },
              validation: [FormsValidators.integer],
            },
            {
              key: 'months',
              type: 'number',
              className: 'col',
              wrappers: [WrapperType.NoWrappers],
              props: {
                min: 0,
                max: 11,
                addonEnd: 'months',
                addonEndClass: 'fw-light',
                groupedField: true,
                collapseMargin: true,
              },
              validators: {
                validation: [FormsValidators.integer],
              },
            },
          ],
          validators: {
            max40Years: {
              expression: (control: AbstractControl) => {
                const { years, months } = control.value ?? {};
                return 12 * (years ?? 0) + (months ?? 0) <= 40 * 12;
              },
              message: 'Mortgage term has a maximum of 40 years',
            },
          },
        },
        {
          key: 'getResultsBtn',
          type: 'buttons',
          props: {
            buttons: [
              { label: 'Get results', type: 'submit', className: 'btn-2022--secondary' },
            ],
          },
        },
      ],
    },
  ];

  private getMortgageTypeOptions() {
    return [
      { label: 'Purchase', value: MortgageType.Purchase },
      { label: 'Remortgage', value: MortgageType.Remortgage },
      { label: 'Second Charge', value: MortgageType.SecondCharge },
    ];
  }

  private mortgageLenderFields(): FormlyFieldConfig[] {
    const mortgageLenderHidden = (field) => {
      const mortgageType = field?.model?.mortgageType;
      return mortgageType !== MortgageType.Remortgage && mortgageType !== MortgageType.Purchase;
    };

    const initialPeriodHidden = (field) => field?.model?.mortgageType !== MortgageType.Remortgage;

    return [
      {
        key: 'mortgageLender',
        wrappers: [WrapperType.NoWrappers],
        props: {
          suppressErrors: true,
        },
        fieldGroup: [
          {
            key: 'value',
            type: FormFieldType.Select,
            props: {
              label: 'Who is the current mortgage Lender?',
              allowNull: true,
            },
            expressions: {
              hide: field => mortgageLenderHidden(field.parent?.parent),
              'props.options': this.lenderService.getUnarchivedLendersNames(this.helperService.getResLendingType())
                .pipe(map(lenderNames => this.helperService.getMortgageLendersList(lenderNames))),
            },
          },
        ],
      },
      {
        key: 'currentMortgageLender',
        type: FormFieldType.Input,
        expressions: {
          hide: field => {
            if (mortgageLenderHidden(field)) {
              return true;
            }

            const mortgageLenderValue = field.model?.mortgageLender?.value;
            return !mortgageLenderValue || mortgageLenderValue !== MortgageLenders.Other;
          },
        },
      },
      {
        key: 'productTransferInitialDate',
        type: FormFieldType.Date,
        props: {
          label: 'What is the end date for the Initial period?',
        },
        validators: {
          validation: [FormsValidators.dateOrEmpty],
        },
        expressions: {
          hide: field => {
            if (initialPeriodHidden(field)) {
              return true;
            }

            return field.model?.mortgageLender?.value === MortgageLenders.Other;
          },
        },
      },
    ];
  }

  private isHelpToBuyHidden(field: FormlyFieldConfig | undefined): boolean {
    const mortgageType = field?.parent?.model?.mortgageType;
    return mortgageType !== MortgageType.Remortgage && mortgageType !== MortgageType.Purchase;
  }

  private isHelpToBuySelected(field: FormlyFieldConfig | undefined) {
    const helpToBuy = !!field?.parent?.model?.helpToBuy;
    return !this.isHelpToBuyHidden(field) && helpToBuy;
  }
}
