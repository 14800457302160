<ng-container [ngSwitch]="row.status">
  <ng-container *ngSwitchCase="auditStatus.Created">
    <p class="mb-0">Created new {{ entityTypeName }} with:</p>
    <ul class="my-1 ps-3">
      <li *ngFor="let change of enumerateChanges(row)">
        <em>{{ change.name }}</em>: <code>{{ change.to }}</code>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngSwitchCase="auditStatus.Modified">
    <ul class="my-1 ps-3">
      <li *ngFor="let change of enumerateChanges(row)">
        Changed <em>{{ change.name }}</em> from <code>{{ change.from }}</code> to <code>{{ change.to }}</code>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngSwitchCase="auditStatus.Deleted">
    <p class="mb-0">Deleted {{ entityTypeName }} with:</p>
    <ul class="my-1 ps-3">
      <li *ngFor="let change of enumerateChanges(row)">
        <em>{{ change.name }}</em>: <code>{{ change.from }}</code>
      </li>
    </ul>
  </ng-container>
</ng-container>
