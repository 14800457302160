import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GeneratedLendingDocumentType, HistoricalEsisEorDocument, OutcomeHistoryViewModel }
  from 'apps/shared/src/models';
import { BasketService, OutcomeService } from 'apps/clubhub/src/app/services';
import { AnalyticsService } from '@msslib/services/analytics.service';
import { MiSaveOutcomeModel } from '@msslib/models/mi';
import { appName, roles } from '@msslib/constants';
import { AuthorizeService, ModalService, ToastService } from '@msslib/services';
import { EsisEorDocumentService } from '@msslib/services/esis-eor-document.service';
import { LendingTypeCode } from 'apps/shared/src/models/affordability-enums';

type Deletable<T> = T & { deleteSelected: boolean };

@Component({
  selector: 'app-outcome-history',
  styleUrls: ['outcome-history.component.scss'],
  templateUrl: 'outcome-history.component.html',
})
export class OutcomeHistoryComponent implements OnInit {
  @ViewChild('saveOutcomeModalTemplateRef', { static: true }) public saveOutcomeModalTemplateRef;
  public outcomeHistorySearchForm: UntypedFormGroup;
  public documentSearchForm: UntypedFormGroup;
  public allOutcomeHistory = new BehaviorSubject<OutcomeHistoryViewModel[]>([]);
  public allDocuments = new BehaviorSubject<Deletable<HistoricalEsisEorDocument>[]>([]);
  public filteredOutcomeHistory: Observable<OutcomeHistoryViewModel[]>;
  public filteredDocuments: Observable<Deletable<HistoricalEsisEorDocument>[]>;
  public editItem: MiSaveOutcomeModel;
  public deletingRow: number | undefined;
  public loading = true;
  public generatedLendingDocumentType = GeneratedLendingDocumentType;

  public constructor(
    private outcomeService: OutcomeService,
    private esisEorDocumentService: EsisEorDocumentService,
    private router: Router,
    private modalService: ModalService,
    private toastService: ToastService,
    private formBuilder: UntypedFormBuilder,
    private analyticsService: AnalyticsService,
    private basketService: BasketService,
    public authorizeService: AuthorizeService,
  ) {}

  public ngOnInit() {
    this.analyticsService.trackClick('Outcome History (Get Result)');
    this.basketService.clearBasket();
    this.getOutcomeHistory();
    this.getHistoricalDocuments();
    this.setupSearch();
  }

  public get documents(): Observable<Deletable<HistoricalEsisEorDocument>[]> {
    return this.filteredDocuments;
  }

  public get isClubHubGenerateEsis() {
    return this.hasClubHubGenerateEsisRole || this.hasClubHubGenerateEsisBuyToLetRole;
  }

  private get hasClubHubGenerateEsisRole() {
    return this.authorizeService.hasRole(roles.clubHubGenerateEsisResidential);
  }

  private get hasClubHubGenerateEsisBuyToLetRole() {
    return this.authorizeService.hasRole(roles.clubHubGenerateEsisBuyToLet);
  }

  public getOutcomeHistory() {
    this.outcomeService.getOutcomeHistory().subscribe(results => {
      this.allOutcomeHistory.next(results);
    });
  }

  private getHistoricalDocuments(): void {
    if (!this.isClubHubGenerateEsis) {
      return;
    }

    this.esisEorDocumentService.getDocuments().subscribe({
      next: (results: HistoricalEsisEorDocument[]) => {
        this.allDocuments.next(
          results
            .map(x => ({ ...x, deleteSelected: false }))
            .sort((a, b) => +(new Date(b.createdDateUtc)) - +(new Date(a.createdDateUtc))),
        );
        this.setupSearch();
        this.loading = false;
      },
      error: () => this.loading = false,
    });
  }

  public openReport(outcomeHistory: OutcomeHistoryViewModel) {
    this.analyticsService.trackClick('View Report');
    let outcomeUrl = `ignite/outcomeHistory?searchId=${outcomeHistory.id}` +
      `&criteriaV2=${!!outcomeHistory.criteriaV2Search}&isSso=${this.isSso}&savedReport=true`;
    // for sso user we need to pass the firm number as the agency number param in the url
    // sso users mi criteria is saved against the users firm number, this because not all sso users have agency numbers
    if (this.isSso) {
      outcomeUrl += `&agencyNumber=${this.frnNumber}`;
    }
    this.router.navigateByUrl(outcomeUrl);
  }

  public isCriteria(item: OutcomeHistoryViewModel): boolean {
    return item.appNames.includes(appName.clubHub) ||
      (
        item.appNames.includes(appName.criteria) && item.hasManualAdded
      ) ||
      (
        item.appNames.includes(appName.propertyCriteria) && item.hasManualAdded
      );
  }

  public isIgnite(item: OutcomeHistoryViewModel): boolean {
    return item.appNames.includes(appName.igniteIntuitive);
  }

  public isProductsCriteria(item: OutcomeHistoryViewModel): boolean {
    return item.appNames.includes(appName.productCriteria);
  }

  public isProperty(item: OutcomeHistoryViewModel): boolean {
    return (
      item.appNames.includes(appName.propertyCriteria) || item.appNames.includes(appName.criteria)
    ) && item.autoAdded;
  }

  public edit(item: OutcomeHistoryViewModel) {
    this.editItem = {
      criteriaSearchId: item.criteriaSearchId,
      affordabilitySearchId: item.affordabilitySearchId,
      productsSearchId: item.productsSearchId,
      surname: item.surname,
      postcode: item.postcode,
      notes: item.notes,
      isEditMode: true,
      source: item.source,
    };

    this.modalService
      .open({
        title: 'Edit Outcome Details',
        template: this.saveOutcomeModalTemplateRef,
      })
      .then(
        ({ notes }) => {
          this.editItem.notes = notes;
          this.saveOutcome(this.editItem);
        },
        () => null,
      );
  }

  private saveOutcome(model: MiSaveOutcomeModel, action = 'updated') {
    this.outcomeService.saveMiDetails(model).subscribe(() => {
      this.toastService.success(`Outcome ${action} successfully`);
      this.getOutcomeHistory();
    });
  }

  public setDeletingRow(row: number | undefined = undefined) {
    this.deletingRow = row;
  }

  public get deleteCount() {
    return this.allDocuments.value.filter(doc => doc.deleteSelected).length;
  }

  public deleteHistoryRow(item: OutcomeHistoryViewModel) {
    const model = {
      affordabilitySearchId: item.affordabilitySearchId,
      criteriaSearchId: item.criteriaSearchId,
      productsSearchId: item.productsSearchId,
    } as MiSaveOutcomeModel;

    this.saveOutcome(model, 'deleted');
    this.deletingRow = undefined;
  }

  public confirmDeleting() {
    if (this.deleteCount > 0) {
      this.modalService
        .open({
          title: `Delete ${this.deleteCount === 1 ? ' Record' : ' Records'}?`,
          message: `Are you sure you want to delete ${this.deleteCount === 1 ? 'this record' : 'these records'}?`,
          showButtons: true,
          sticky: true,
          okLabel: 'Yes',
          cancelLabel: 'No',
        })
        .then(
          () => this.deleteDocuments(),
          () => null,
        );
    }
  }

  public deleteDocuments() {
    const docsToRemove = this.allDocuments.value.filter(doc => doc.deleteSelected);
    // Make an observable that completes when all the selected documents have been deleted
    forkJoin(docsToRemove.map(doc => {
      const personId = '00000000-0000-0000-0000-000000000000';
      return this.esisEorDocumentService.archiveDocument(personId, doc.documentId);
    })).subscribe(() => {
      // When all docs are deleted, updated the local view to only show documents that are marked as to-be-deleted
      this.allDocuments.next(this.allDocuments.value.filter(d => !docsToRemove.includes(d)));
    });
  }

  public downloadDocument(type: GeneratedLendingDocumentType, documentId: string) {
    const personId = '00000000-0000-0000-0000-000000000000';
    this.esisEorDocumentService.downloadDocument(type, personId, documentId).subscribe((res) => {
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL);
    });
  }

  private setupSearch(): void {
    // Forms
    this.outcomeHistorySearchForm = this.formBuilder.group({
      surname: '',
      postcode: '',
    });

    this.documentSearchForm = this.formBuilder.group({
      surname: '',
    });

    // Filtering observables
    this.filteredOutcomeHistory = combineLatest([
      this.allOutcomeHistory,
      this.outcomeHistorySearchForm.valueChanges.pipe(startWith({})),
    ]).pipe(map(([history, { surname, postcode }]) => {
      if (surname?.length) {
        history = history.filter(h => h.surname?.toLowerCase().includes(surname.toLowerCase()));
      }
      if (postcode?.length) {
        history = history.filter(h => h.postcode?.toLowerCase().includes(postcode.toLowerCase()));
      }
      return history;
    }));

    this.filteredDocuments = combineLatest([
      this.allDocuments,
      this.documentSearchForm.valueChanges.pipe(startWith({})),
    ]).pipe(map(([documents, { surname }]) => {
      if (surname?.length) {
        documents = documents.filter(d =>
          d.clientSurname?.toLowerCase().includes(surname.toLowerCase()) ||
          d.client2Surname?.toLowerCase().includes(surname.toLowerCase()));
      }

      if (!this.hasClubHubGenerateEsisRole) {
        documents = documents.filter(d => (d.lendingType as LendingTypeCode) !== LendingTypeCode.Res);
      }

      if (!this.hasClubHubGenerateEsisBuyToLetRole) {
        documents = documents.filter(d => (d.lendingType as LendingTypeCode) !== LendingTypeCode.Btl);
      }

      return documents;
    }));
  }

  public hasRole(role: string) {
    return this.authorizeService.hasRole(role);
  }

  public getClientNamesDisplay(item: HistoricalEsisEorDocument): string {
    const client1Name = `${item.clientForename} ${item.clientSurname}`;
    return item.client2Forename && item.client2Surname
      ? `${client1Name} & ${item.client2Forename} ${item.client2Surname}`
      : client1Name;
  }

  private get frnNumber() {
    return this.authorizeService.user?.frnNumber;
  }


  private get isSso(): boolean {
    return this.authorizeService.isSsoUser;
  }
}
