import { Component, DoCheck, Input } from '@angular/core';

@Component({
  selector: 'lib-progress-circle',
  styleUrls: ['progress-circle.component.scss'],
  templateUrl: 'progress-circle.component.html',
})
export class ProgressCircleComponent implements DoCheck {
  @Input() public percent: number;
  public leftStyle = '';
  public rightStyle = '';

  public ngDoCheck(): void {
    if (this.percent <= 50) {
      this.rightStyle = `transform:rotate(${this.percentageToDegrees(this.percent)}deg)`;
    } else {
      this.rightStyle = 'transform:rotate(180deg)';
      this.leftStyle = `transform:rotate(${this.percentageToDegrees(this.percent - 50)}deg)`;
    }
  }

  private percentageToDegrees(percentage) {
    return (percentage / 100) * 360;
  }

  public get percentageColor() {
    if (this.percent >= 66.66) {
      return 'border-success';
    } else if (this.percent >= 33.33) {
      return 'border-warning';
    }
    return 'border-danger';
  }
}
