import { Injectable } from '@angular/core';
import {
  OutcomeResponse,
  OutcomeResponseContextResult,
} from 'apps/shared/src/models';
import {
  IgniteService,
} from 'apps/clubhub/src/app/ignite/services';
import {
  AuthorizeService,
} from '@msslib/services';
import { CriteriaV2OutcomeService } from 'apps/clubhub/src/app/ignite/pages/criteria-v2/services';

@Injectable({
  providedIn: 'root',
})
export class CriteriaChecksService {
  public constructor(
    private igniteService: IgniteService,
    private authService: AuthorizeService,
    public criteriaV2OutcomeService: CriteriaV2OutcomeService,
  ) { }

  public canDelete(outcome: OutcomeResponseContextResult): boolean {
    if (this.igniteService.criteriaV2Enabled) {
      return this.authService.isLoggedIn;
    }

    return (
      this.authService.isLoggedIn &&
      (
        this.igniteService.additionalOutcomeIds?.some((outcomeId: number) => outcomeId === outcome.outcomeId) ||
        this.igniteService.propertyOutcomeIds?.some((outcomeId: number) =>
          outcomeId === outcome.outcomeId &&
          !outcome.context.includes('Loan To Value/Maximum Loan/Property Type/Region'),
        )
      )
    );
  }

  public getOtherCriteriaOutcomes(additionalOutcome: OutcomeResponse): OutcomeResponseContextResult[] {
    return additionalOutcome?.contextResults.filter(
      (contextResult: OutcomeResponseContextResult) =>
        !this.igniteService.applicantOutcomeIds?.includes(contextResult.outcomeId),
    );
  }

  public getOtherPropertyCriteriaOutcomes(propertyOutcome: OutcomeResponse): OutcomeResponseContextResult[] {
    return propertyOutcome?.contextResults.filter(
      (contextResult: OutcomeResponseContextResult) =>
        !this.igniteService.applicantOutcomeIds?.includes(contextResult.outcomeId),
    );
  }
}
