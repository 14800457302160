function escapeCsvValue(value: string) {
  return value && (value.includes(',') || value.includes('"'))
    ? `"${value.replace(/"/g, '""')}"`
    : value;
}

type CsvOptions<T> = Partial<{
  includeHeader: boolean;
  headerFormatter: (header: string) => string;
  cellFormatter: <H extends keyof T>(data: T[H], header: H, row: T) => any;
}>;

export function toCsv<T>(data: T[], columns: (keyof T)[], options: CsvOptions<T> = {}) {
  const body = data
    .map(row => columns
      .map(column => typeof options.cellFormatter === 'function'
        ? options.cellFormatter(row[column], column, row)?.toString()
        : row[column]?.toString() ?? '')
      .map(escapeCsvValue)
      .join(','),
    )
    .join('\n');

  if (!(options.includeHeader ?? true)) {
    return body;
  }

  const headerFormatter = options.headerFormatter ?? (s => s);
  const header = columns.map(c => escapeCsvValue(headerFormatter(c.toString()))).join(',');
  return `${header}\n${body}`;
}
