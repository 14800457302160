import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ModalStateService } from './modal-state.service';
import { ModalOptions } from '../models';
import { Observable } from 'rxjs';

/**
 * A confirmation service, allowing to open a confirmation modal from anywhere and get back a promise.
 */
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public constructor(private ngbModalService: NgbModal, private state: ModalStateService) {}

  public get onModalDismissed(): Observable<unknown> {
    return this.state.modal.dismissed;
  }

  public get onModalClosed(): Observable<unknown> {
    return this.state.modal.closed;
  }

  public get options() {
    return this.state.options;
  }

  /**
   * Opens a confirmation modal
   * @param options the options for the modal (title and message)
   * @returns {Promise<any>} a promise that is fulfilled when the user chooses to confirm, and rejected when
   * the user chooses not to confirm, or closes the modal
   */
  public open<T = any>(options: ModalOptions): Promise<T> {
    this.state.options = options;
    const size: any =
      options.size ? options.size : this.state.options.template || this.state.options.component ? 'lg' : 'sm';
    this.state.pushModal(this.ngbModalService.open(this.state.options.component || this.state.template, {
      centered: true,
      windowClass: `modal-holder ${options.windowClass}`,
      size,
      // Whether a backdrop element should be created for a given modal (true by default).
      // Alternatively, specify 'static' for a backdrop which doesn't close the modal on click.
      backdrop: options.sticky ? 'static' : true,
      keyboard: options.sticky ? false : true,
      beforeDismiss: options.beforeDismiss,
    }));

    return this.state.modal.result;
  }

  /**
   * Opens a warning modal
   * @param options the options for the modal (title and message)
   * @returns {Promise<any>} a promise that is fulfilled when the user chooses to confirm, and rejected when
   * the user chooses not to confirm, or closes the modal
   */
  public warn(options: ModalOptions): Promise<unknown> {
    options.showButtons = true;
    options.okLabel = options.okLabel ?? 'Ok';
    options.cancelLabel = options.cancelLabel ?? 'Close';
    this.state.options = options;
    const size: any =
      options.size ? options.size : this.state.options.template || this.state.options.component ? 'lg' : 'sm';
    this.state.pushModal(this.ngbModalService.open(this.state.template, {
      centered: true,
      size,
      // Whether a backdrop element should be created for a given modal (true by default).
      // Alternatively, specify 'static' for a backdrop which doesn't close the modal on click.
      // backdrop: options.sticky ? "static" : true,
      // keyboard: options.sticky ? false : true
    }));

    return this.state.modal.result;
  }

  public close(data?: unknown) {
    this.state.topmostModal?.close(data);
    if (this.state.modalStack?.length > 0) {
      this.state.modal = this.state.topmostModal;
    }
  }

  public dismiss(data?: unknown) {
    this.state.topmostModal?.dismiss(data);
    if (this.state.modalStack?.length > 0) {
      this.state.modal = this.state.topmostModal;
    }
  }

  public closeAll() {
    this.state.modalStack.forEach(modal => modal.close());
  }

  public get hasOpenModals(): boolean {
    return this.ngbModalService.hasOpenModals();
  }
}
