import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScriptVersionService {
  private currentScriptFileName: string;
  public updateRequired: boolean = false;

  public constructor(private http: HttpClient, private router: Router) {
    this.getCurrentScriptFileName();
    this.monitorNavigation();
  }

  private getCurrentScriptFileName(): void {
    const script = document.querySelector('script[src*="runtime"]') as HTMLScriptElement;
    this.currentScriptFileName = script ? script.getAttribute('src') || '' : '';
  }

  private monitorNavigation(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.checkForScriptUpdate();
      });
  }

  private checkForScriptUpdate(): void {
    if (!this.currentScriptFileName) {
      return;
    }

    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
    });
    this.http.get(this.currentScriptFileName, { headers, observe: 'response', responseType: 'text' })
      .subscribe({
        next: (response) => {
          const contentTypeHeader = response.headers.get('content-type');
          this.updateRequired = !contentTypeHeader?.includes('application/javascript');
        },
        error: () => {
          this.updateRequired = true;
        },
      });
  }
}
