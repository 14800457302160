import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { tap } from 'rxjs/operators';

import { lendingTypes } from '@msslib/constants/lending-types';
import { LendingType } from '@msslib/models/lending-type';
import { LendingTypeService } from '@msslib/services/lending-type.service';
import { UtilsService } from '@msslib/services/utils.service';

export const lendingTypeResolver: ResolveFn<LendingType[]> =
  (route: ActivatedRouteSnapshot) => {
    const lendingTypeService = inject(LendingTypeService);
    const utilsService = inject(UtilsService);

    return lendingTypeService.getLendingTypes().pipe(
      tap((types: LendingType[]) => {
        lendingTypeService.lendingTypes = types;
        lendingTypeService.lendingType =
          types.find((x) => x.id === +utilsService.routeParams(route).lendingTypeId
            || x.code.toUpperCase() === utilsService.routeParams(route).lendingType?.toUpperCase(),
          ) ??
          types.find((t) => t.code === lendingTypes.residential);
      }),
    );
  };
