<div *ngIf="product" class="d-flex justify-content-center align-items-center">
  <div *ngIf="product.isExclusive">
    <i class="pt-1 fas fa-trophy type-icon"></i>
  </div>
  <div *ngIf="isProductTransfer">
    <i class="pt-1 fas fa-retweet type-icon"></i>
  </div>
  <div *ngIf="product.direct" ngbTooltip="Direct Only Product - not available to intermediaries">
    <div class="type-icon mb-1">
      <img class="w-100 h-100" src="assets/images/2022-ignite/anchor-solid.svg">
    </div>
  </div>
  <div *ngIf="!!product.packager" [ngbTooltip]="'Packager: ' + product.packager">
    <i class="pt-1 fas fa-gift type-icon"></i>
  </div>
</div>
