import { Availability, Product } from 'apps/shared/src/models/matched-product';

export function productAvailabilityFilterFor(property: keyof Product)
  : Record<'isMatchFunc' | 'isUnknownFunc' | 'isExcludeFunc', ((p: Product) => boolean)> {
  return {
    isMatchFunc: p => ([Availability.AlsoAvailable, Availability.OnlyAvailable])
      .includes(p[property] as Availability),
    isUnknownFunc: p => [Availability.NotApplicable, null].includes(p[property] as Availability),
    isExcludeFunc: p => ([Availability.AlsoAvailable, Availability.No])
      .includes(p[property] as Availability),
  };
}
