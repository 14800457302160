import { Component, Input } from '@angular/core';
import { AuditStatus } from '@msslib/models/audits';
import { StartCasePipe } from '@msslib/pipes';

interface AuditLogPartial {
  status: AuditStatus;
  to: Record<string, unknown> | null;
  from: Record<string, unknown> | null;
}

type AuditLogChanges = Record<'name' | 'from' | 'to', string>[];

export type AuditLogFieldNameFormatter = (fieldName: string) => string | null;

export type AuditLogValueFormatter = (fieldName: string, value: unknown) => string;

@Component({
  selector: 'lib-audit-log-change-list',
  templateUrl: 'audit-log-change-list.component.html',
})
export class AuditLogChangeListComponent {
  @Input() public row: AuditLogPartial;
  @Input() public entityTypeName = 'entity';
  @Input() public fieldNameFormatter = AuditLogChangeListComponent.defaultFieldNameFormatter;
  @Input() public valueFormatter = AuditLogChangeListComponent.defaultValueFormatter;
  public readonly auditStatus = AuditStatus;

  public constructor(
    private readonly startCasePipe: StartCasePipe,
  ) { }

  public enumerateChanges(row: AuditLogPartial): AuditLogChanges {
    return Object.keys(row.from ?? row.to ?? {}).map(prop => ({
      name: this.fieldNameFormatter(prop) ?? this.startCasePipe.transform(prop),
      from: this.valueFormatter(prop, row.from?.[prop]),
      to: this.valueFormatter(prop, row.to?.[prop]),
    }));
  }

  public static defaultFieldNameFormatter: AuditLogFieldNameFormatter = fieldName => {
    return new StartCasePipe().transform(fieldName);
  };

  public static defaultValueFormatter: AuditLogValueFormatter = (_, value) => {
    switch (true) {
      case value === null: return '-Nothing-';
      case typeof value === 'number': return (value as number).toString();
      case typeof value === 'boolean': return value ? 'Yes' : 'No';
      default: return `"${value}"`;
    }
  };

  public static enumValueFormatter = (enumType: object, value: unknown): string => {
    return typeof value === 'string' || typeof value === 'number'
      ? new StartCasePipe().transform(enumType[value])
      : '-Nothing-';
  };
}
