<hr/>
<div class="form-group position-relative row mt-4">
  <div class="col">
    <h2 class="fw-light" *ngIf="props.header">{{ props.header }}</h2>
  </div>

  <div class="template-tooltip col-1" *ngIf="props.headerTooltip">
    <i class="col-1 far fa-question-circle my-auto" (click)="openTooltip()"></i>
  </div>
</div>


