import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDigitOnly]',
})
export class DigitOnlyDirective {

  private pattern = /^[0-9]*\.?[0-9]*$/;
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): any {
    if (this.navigationKeys.indexOf(event.key) > -1 || // Allow: navigation keys
      ((event.key === 'a' || event.code === 'KeyA') && event.ctrlKey) || // Allow: Ctrl+A
      ((event.key === 'c' || event.code === 'KeyC') && event.ctrlKey) || // Allow: Ctrl+C
      ((event.key === 'v' || event.code === 'KeyV') && event.ctrlKey) || // Allow: Ctrl+V
      ((event.key === 'x' || event.code === 'KeyX') && event.ctrlKey) || // Allow: Ctrl+X
      ((event.key === 'a' || event.code === 'KeyA') && event.metaKey) || // Allow: Cmd+A (Mac)
      ((event.key === 'c' || event.code === 'KeyC') && event.metaKey) || // Allow: Cmd+C (Mac)
      ((event.key === 'v' || event.code === 'KeyV') && event.metaKey) || // Allow: Cmd+V (Mac)
      ((event.key === 'x' || event.code === 'KeyX') && event.metaKey) // Allow: Cmd+X (Mac)
    ) {
      return;
    }
    if (!this.isValidInput(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    const pastedData = event.clipboardData?.getData('text/plain');
    if (pastedData && !this.isValidInput(pastedData)) {
      event.preventDefault();
    }
  }

  private isValidInput(value: string): boolean {
    return this.pattern.test(value);
  }
}
