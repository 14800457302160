import { Component } from '@angular/core';
import { FieldBaseComponent } from '../field-base';

@Component({
  selector: 'lib-form-toggle',
  styleUrls: ['form-toggle.component.scss'],
  templateUrl: 'form-toggle.component.html',
})
export class FormToggleComponent extends FieldBaseComponent {

  public changeValue(name: string): void {
    const value: Record<string, any> = {};
    value[name] = !this.formGroup.value[name];
    this.formGroup.patchValue(value);
    this.formGroup.updateValueAndValidity();
  }

  public onChange($event: Event) {
    if (this.config.onToggleChange) {
      this.config.onToggleChange(($event.target as HTMLInputElement).checked);
    }
  }
}
