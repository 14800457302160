import {
  ISortOption,
  OutcomeResults,
  SortOrder,
  YesNoResults,
} from '@msslib/models';

export const outcomeList: ISortOption[] = [
  { value: OutcomeResults.Yes, label: 'Yes', icon: 'far fa-check-circle text-success', iconColour: 'text-success' },
  {
    value: OutcomeResults.Refer,
    label: 'Refer', icon: 'fas fa-exchange-alt text-warning', iconColour: 'text-warning',
  },
  { value: OutcomeResults.No, label: 'No', icon: 'far fa-times-circle text-danger', iconColour: 'text-danger' },
  { value: OutcomeResults.Unset, label: 'Restricted', icon: 'fas fa-ban text-dark', iconColour: 'text-dark' },
];

export const igniteSortOptions: ISortOption[] = [
  { value: SortOrder.Ascending, label: 'Low to High', icon: 'fa-sort-numeric-up-alt fas', iconColour: 'text-dark' },
  {
    value: SortOrder.Descending, label: 'High to Low', icon: 'fa-sort-numeric-down-alt fas', iconColour: 'text-dark',
  },
];

export const productsMatched: ISortOption[] = [
  { value: YesNoResults.Yes, label: 'Yes', icon: 'far fa-check-circle text-success', iconColour: 'text-success' },
  { value: YesNoResults.No, label: 'No', icon: 'far fa-times-circle text-danger', iconColour: 'text-danger' },
];

export const outcomeSortOrder = [
  OutcomeResults.Yes,
  OutcomeResults.Refer,
  OutcomeResults.No,
  OutcomeResults.Unset,
  undefined,
];
