import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public toasts: unknown[] = [];

  public success(message: string | TemplateRef<unknown>) {
    this.show(message, { classname: 'bg-success' });
  }

  public info(message: string | TemplateRef<unknown>) {
    this.show(message, { classname: 'bg-info' });
  }

  public warn(message: string | TemplateRef<unknown>) {
    this.show(message, { classname: 'bg-warning' });
  }

  public warnCenter(message: string | TemplateRef<unknown>, delay: number | null = null) {
    this.show(message, { classname: 'bg-warning center-msg center-msg-vertical', delay: delay });
  }

  public danger(message: string | TemplateRef<unknown>, options: object = {}) {
    this.show(message, { classname: 'bg-danger', ...options });
  }

  public dangerCenter(message: string | TemplateRef<unknown>) {
    this.show(message, { classname: 'bg-danger center-msg' });
  }

  private show(textOrTpl: string | TemplateRef<unknown>, options: object = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  public remove(toast: unknown) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
