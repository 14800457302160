<div [formGroup]="formGroup" class="form-group">
  <label for="{{ config.name }}">
    {{ config.label }}

    <ng-container *ngIf="showAsterisk(config)">
      *
    </ng-container>
  </label>
  <input
    type="file"
    libdFileInput
    [formControlName]="config.name"
    [id]="config.name"
  />

  <div libFormFieldError class="invalid-feedback" [fieldConfig]="config"></div>
</div>
