import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { IInputCheckedModel, IInputList } from '../../models';

@Component({
  selector: 'lib-checkbox-list',
  styleUrls: ['./checkbox-list.component.scss'],
  templateUrl: './checkbox-list.component.html',
})
export class CheckboxListComponent {
  @Input() public list: IInputList[];
  @Input() public labelRightTemplate: TemplateRef<unknown>;
  @Input() public direction: 'vertical' | 'horizontal' = 'vertical';
  @Input() public size: 'small' | 'normal' = 'normal';
  @Input() public model: Record<number, boolean> = {};
  @Output() public valueChange = new EventEmitter();

  public get getDirectionClass(): string {
    return this.direction === 'horizontal' ? 'form-check-inline' : '';
  }

  public get getSizeCss(): string {
    return this.size === 'small' ? 'small' : '';
  }

  public trackByFn(index: number) {
    return index;
  }

  public onChange(key: unknown, event: Event) {
    this.valueChange.next({ key, checked: (event.target as HTMLInputElement).checked } as IInputCheckedModel);
  }
}
