import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-clubhub-home-section',
  templateUrl: 'clubhub-home-section.component.html',
  styleUrls: ['clubhub-home-section.component.scss'],
})
export class ClubHubHomeSectionComponent {
  @Input() public image: string;
  @Input() public imageLink: string;
  @Input() public title: string;
  @Input() public content: string;
  @Input() public contentSecondParagraph: string;
  @Input() public contentThirdParagraph: string;
  @Input() public contentFourthParagraph: string;
  @Input() public igniteBulletPoints: boolean;
  @Input() public button: string;
  @Input() public buttonLink: string;
  @Input() public content2: string;
  @Input() public button2: string;
  @Input() public button2Link: string;
  @Input() public content2SecondParagraph: string;

  // Note that this is implemented as a function input rather than an EventEmitter<MouseEvent> output because if done
  // in the latter way, using `evt.preventDefault()` does not work as the EventEmitter call is queued instead of
  // being immediately dispatched, and so the event is resolved before the EventEmitter's handlers are even called.
  @Input() public button2OnClick: (evt: MouseEvent) => void;

  public isExternalLink(buttonLink: string): boolean {
    return !!buttonLink && buttonLink.startsWith('http');
  }

  public isFileLink(buttonLink: string): boolean {
    return !!buttonLink && buttonLink.includes('assets');
  }

  public hasClickHandler(f: unknown) {
    return typeof f === 'function';
  }

  public getFileName(buttonLink: string): string {
    const index = buttonLink.lastIndexOf('/');
    return index === -1
      ? buttonLink
      : buttonLink.substring(index + 1);
  }
}
