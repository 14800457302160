<div ngbDropdown #dropdownFilter="ngbDropdown">
  <button
    class="btn {{ textClass || 'text-white' }} {{ hideIcon && 'hide-icon' }}"
    [ngClass]="{ 'image-button': image, 'fw-bold': boldText }"
    [id]="name"
    ngbDropdownAnchor
    (click)="dropdownFilter.toggle()"
    [attr.aria-label]="ariaText ? (dropdownFilter.isOpen() ? 'Close ' : 'Open ') + ariaText : null"
  >
    <img *ngIf="image?.src" [src]="image.src" class="img-fluid me-2 filter-img-block" [style.max-width.px]="image.maxWidth" height="80px"/>
    <span *ngIf="!image?.src && ariaText">{{ ariaText }}</span>
    <br *ngIf="image?.src || ariaText">
    <span *ngIf="label">{{ label }}</span>
    <i class="{{ active.icon }} {{ active?.iconColour }}" *ngIf="active"></i>
  </button>
  <div ngbDropdownMenu [attr.aria-labelledby]="name" role="listbox">
    <button
      *ngFor="let item of list; trackBy: trackByFn"
      ngbDropdownItem
      class="{{ active?.value === item.value ? 'active' : '' }}"
      (click)="itemChange.next(item.value)"
      role="option"
    >
      <i class="filter-icon {{ item.icon }}"></i>
      {{ item.label }}
    </button>
    <button *ngIf="!hideClearOption" ngbDropdownItem [hidden]="activeValue === undefined" (click)="itemChange.next(null)">
      <i class="filter-icon fas fa-times"></i>
      Clear
    </button>
  </div>
</div>
