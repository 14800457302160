import { EmailOutcomeRequestViewModel } from './../models/mi';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { MiSaveOutcomeModel } from '@msslib/models/mi';
import {
  OutcomeHistoryViewModel,
  OutcomeLoginModel,
  OutcomeLoginModelResponse,
} from 'apps/shared/src/models';
import { Mi } from '../models';

@Injectable({
  providedIn: 'root',
})
// Outcome service
export class OutcomeService {
  public constructor(private clubHubDataService: ClubHubDataService) {}

  public saveMi(mi: Mi): Observable<any> {
    return this.clubHubDataService.post<any>('Outcome/SaveMi', mi);
  }

  public saveMiDetails(model: MiSaveOutcomeModel): Observable<unknown> {
    return this.clubHubDataService.put<unknown>('Outcome/SetMiDetails', model);
  }

  public getOutcomeHistory() {
    return this.clubHubDataService.get<OutcomeHistoryViewModel[]>('outcome/GetOutcomeHistory');
  }

  public verifyForOutcome(model: OutcomeLoginModel) {
    return this.clubHubDataService.post<OutcomeLoginModelResponse>('outcomehistory/VerifyForOutcome', model);
  }

  public checkOutcomeExpiry(key: string, isSso: boolean) {
    return this.clubHubDataService.get<boolean>(`outcomehistory/CheckExpiry/${key}?isSso=${isSso}`);
  }

  public sendEmail(model: EmailOutcomeRequestViewModel): Observable<unknown> {
    return this.clubHubDataService.post<unknown>('outcomehistory/sendEmail', model);
  }
}
