<strong
  *ngIf="product"
  class="fees"
  [ngbTooltip]="feesTooltip"
  placement="left"
  tooltipClass="custom-tooltip fees-tooltip"
>
  {{ total | currency: 'GBP':'symbol':'1.0-0' }}
  <span *ngIf="hasReferToLenderFee">*</span>
</strong>

<ng-template #feesTooltip>
  <div
    *ngFor="let fee of product.fees"
    class="row"
  >
    <div class="col-6 text-start">
      {{feeToString(fee)}}
    </div>
    <div *ngIf="fee.value !== -1; else referFee" class="col-6 text-start">
      {{ fee.value | currency: 'GBP':'symbol':'1.0-0' }}
      <span *ngIf="fee.isReferToLender">
        - Refer to Lender
      </span>
    </div>
    <ng-template #referFee>Refer to lender</ng-template>
  </div>
  <div class="row">
    <div class="col-12">
      <hr />
    </div>
  </div>
  <div class="row">
    <div class="col-6 text-start"><strong>Total Fees:</strong></div>
    <div class="col-6 text-start">
      <strong>{{ total | currency: 'GBP':'symbol':'1.0-0' }}</strong>
    </div>
  </div>
</ng-template>
