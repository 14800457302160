<nav class="navbar navbar-expand-lg mx-3 pb-0 mb-1">
  <ng-container>
    <div class="navbar-brand">
      <ng-container *ngIf="(branding$ | async) as branding; else placeholder">
        <a class="header-image"
          [attr.target]="branding.logoLink === '#' ? null : '_blank'"
          [href]="branding.logoLink">
          <img
            *ngIf="branding.logoUrl; else placeholder"
            class="header-image-img"
            [src]="branding.logoUrl"
            alt="Legal and General"
          />
        </a>
      </ng-container>
      <ng-template #placeholder>
        <div class="header-image-img-placeholder d-flex align-items-center justify-content-center">
          <div class="spinner-border text-2022--primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </ng-template>
    </div>

    <button
      class="navbar-toggler"
      type="button"
      (click)="toggleNavbar()"
      aria-controls="navbarSupportedContent"
      [attr.aria-expanded]="navbarOpen"
      aria-label="Toggle navigation"
    >
      <span class="fa fa-navicon"></span>
    </button>
  </ng-container>

  <div class="collapse navbar-collapse" [ngClass]="{ show: navbarOpen }" id="navbarSupportedContent" *ngIf="showNav">
    <ng-content></ng-content>
    <lib-login-menu [client]="client"></lib-login-menu>
  </div>
</nav>
