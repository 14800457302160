import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AnalyticsService, AuthorizeService, BannerService, ModalService } from '@msslib/services';
import { contactUsPhone } from '@msslib/constants/phone';
import { agencyNumbers, roles } from '@msslib/constants';
import { MarketingPreferencesService } from 'apps/clubhub/src/app/services/marketing-preferences.service';
import { keys } from 'apps/clubhub/src/app/constants/session-keys';
import { CrmSystemInformationService } from 'apps/clubhub/src/app/services/crm-system-information.service';

@Component({
  selector: 'app-home',
  styleUrls: ['home.component.scss'],
  templateUrl: 'home.component.html',
})
export class HomeComponent implements OnInit, AfterViewInit {
  public contactUsPhone = contactUsPhone;
  public imageUrl: string;
  public imageLink: string;
  public constructor(
    private router: Router,
    public authService: AuthorizeService,
    private analyticsService: AnalyticsService,
    private modalService: ModalService,
    private marketingPreferencesService: MarketingPreferencesService,
    private crmSystemInformationService: CrmSystemInformationService,
    private bannerService: BannerService,
  ) {}

  public ngOnInit() {
    this.authService.getUser().subscribe(() => {
      if (this.authService.hasRole(roles.clubHub) || this.authService.hasRole(roles.ignite)) {
        // stay on home page
      } else if (this.authService.hasRole(roles.procFeePreview)) {
        this.router.navigate(['procfee', 'validate']);
      } else if (this.authService.hasRole(roles.broker) || this.authService.hasRole(roles.staff)) {
        this.router.navigate(['ignite', 'criteria' , 'landing', 'home']);
      }
    });
    if (!this.marketingPreferencesSaved && this.authService.hasRole(roles.clubHub)) {
      this.modalService
        .open({
          title: 'Marketing Preferences',
          message:
            'Would you like to set or update your marketing preferences?<br>You can update these later in the ' +
            '"My account" section.',
          showButtons: true,
          sticky: true,
          okLabel: 'Update Now',
          cancelLabel: 'Update Later',
        })
        .then(
          () => {
            this.updateMarketingPreferences();
          },
          () => {
            this.setMarketingPreferencesAsRead();
          },
        );
    }
    if (!this.crmSystemInformationSaved && this.authService.hasRole(roles.clubHub)) {
      this.modalService
        .open({
          message:
            'We have made some changes to your My Account page. ' +
            'Please could you review and update your information so that we retain an accurate record?',
          showButtons: true,
          sticky: true,
          okLabel: 'Proceed',
          size: 'sm',
          hideCancel: true,
          windowClass: 'crm-information-modal',
          hideTopClose: true,
        })
        .then(
          () => {
            this.updateCrmSystemInformationAsRead();
          },
          () => null,
        );
    }
    this.bannerService.getIgniteBannerByName('ch-homepage-banner').subscribe((banner) => {
      this.imageLink = banner.link;
      this.imageUrl = banner.imageUrl;
    });
  }

  public ngAfterViewInit() {
    this.analyticsService.log(
      'ClubHub Home',
      'L&G',
    );
  }

  public get isClubHub() {
    return this.authService.hasRole(roles.clubHub);
  }

  public get isIgnite() {
    return this.authService.hasRole(roles.ignite);
  }

  public get marketingPreferencesSaved() {
    return this.authService.agencyNumber === agencyNumbers.lenderHubUserAgencyNumber || // Don't show to LenderHub users
      this.authService.user?.marketingPreferencesSaved?.toLowerCase() === 'true' ||
      sessionStorage.getItem(keys.marketingPreferencesSaved) === 'true';
  }

  public get crmSystemInformationSaved() {
    return this.authService.agencyNumber === agencyNumbers.lenderHubUserAgencyNumber || // Don't show to LenderHub users
      this.authService.user?.crmSystemInformationSaved?.toLowerCase() === 'true' ||
      sessionStorage.getItem(keys.crmSystemInformationSaved) === 'true';
  }

  public setMarketingPreferencesAsRead() {
    this.marketingPreferencesService.setMarketingPreferencesAsRead().subscribe(() => {
      sessionStorage.setItem(keys.marketingPreferencesSaved, 'true');
    });
  }

  public updateCrmSystemInformationAsRead() {
    this.crmSystemInformationService.setMarketingPreferencesAsRead().subscribe(() => {
      sessionStorage.setItem(keys.crmSystemInformationSaved, 'true');
      this.router.navigate(['/authentication/profile']);
    });
  }

  public updateMarketingPreferences() {
    this.marketingPreferencesService.setMarketingPreferencesAsRead().subscribe(() => {
      sessionStorage.setItem(keys.marketingPreferencesSaved, 'true');
      this.router.navigate(['/authentication/profile']);
    });
  }
}
