<div class="modal-header">
  <h3 class="modal-title">
    {{ options.title }}
  </h3>
  <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="closeModal()">
  </button>
</div>

<div class="modal-body">
  <p>This area of the site will allow you to submit and view procuration fee claims with Legal and General Mortgage Club.
    To continue please enter your Legal and General Agency Number and this will then be linked to your Legal & General Ignite account.</p>
  <form #agencyNumberForm="ngForm">
    <div class="d-flex mt-3">
      <div class="col">
        <label class="form-label" for="lenderText">Agency Number</label>
        <input class="form-control" required maxlength="7" type="text" name="agencyNumber" #agencyNumberField="ngModel" id="agencyNumber"
          [(ngModel)]="agencyNumber" [class.is-valid]="valid(agencyNumberField)"
          [class.is-invalid]="invalid(agencyNumberField)">
        <ng-container
          *ngTemplateOutlet="errorTemplate; context: { field: agencyNumberField, errorId: 'agencyNumberError' }">
        </ng-container>
      </div>
    </div>
    <button class="btn btn-2022--grey w-100 mt-3" (click)="closeModal()">Cancel</button>
    <button class="btn btn-2022--secondary w-100 mt-2" type="submit" id="submitAgencyNumberForm" (click)="submit()">Save</button>
  </form>
</div>

<ng-template #errorTemplate let-field="field" let-errorId="errorId">
  <div class="invalid-feedback" *ngIf="invalid(field)" [id]="errorId">
    {{ getErrorMessage(field) }}
  </div>
</ng-template>
