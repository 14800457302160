<div
  class="custom-radio-group btn-group btn-group-toggle"
  [ngClass]="containerClass"
>
  <label
    *ngFor="let option of options; index as index"
    class="btn btn-2022--secondary d-flex align-items-center justify-content-center custom-radio-label w-100"
    [class.active]="selectedValue === option.value"
  >
    <input
      [id]="id + '-' + index"
      type="radio"
      [attr.required]="required ? 'required' : null"
      [(ngModel)]="selectedValue"
      (click)="selectValue(option.value)"
    />
    <div class="icon-wrapper">
      <i class="fas fa-2x" [ngClass]="option.icon"></i>
    </div>
  </label>
</div>
