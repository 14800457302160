import { Component, Input } from '@angular/core';
import { Product } from 'apps/shared/src/models';

@Component({
  selector: 'lib-product-erc-tooltip',
  templateUrl: 'product-erc-tooltip.component.html',
  styleUrls: ['product-erc-tooltip.component.scss'],
})
export class ProductErcTooltipComponent {

  @Input() public product: Product;
  @Input() public isProductDetail: boolean;

  public get hasErc(): boolean | string {
    return this.product.earlyRepaymentChargesErcApplied ?? false;
  }

  public get ercStatus(): string {
    return this.product.earlyRepaymentChargesErcApplied ? 'Yes' : 'No';
  }

  public get chargePeriods() {
    return this.product.ercRanks?.filter(x => x !== null) || [];
  }
}
