/* eslint-disable no-empty-function */
import { Directive, ElementRef, HostListener, Input, Renderer2, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const APP_FILE_INPUT_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line no-use-before-define
  useExisting: forwardRef(() => AppFileInputDirective),
  multi: true,
};

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[libdFileInput][type=file]',
  providers: [APP_FILE_INPUT_ACCESSOR],
})
export class AppFileInputDirective implements ControlValueAccessor {
  @Input() public name: string;

  public file: File | null = null;

  @HostListener('focus')
  public onFocus() {
    this.focused = true;
  }

  @HostListener('blur')
  public onBlur() {
    this.focused = false;
  }

  @HostListener('change', ['$event.target.files'])
  public emitFiles(files: FileList) {
    const file = files?.item(0);
    this._onChange(file);
    this._onTouched();
    this.file = file;
  }

  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  public _onChange = (_: unknown) => {};
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-function
  public _onTouched = () => {};

  public constructor(private elementRef: ElementRef<HTMLInputElement>, private renderer: Renderer2) {}

  public writeValue() {
    // clear file input
    this.elementRef.nativeElement.value = '';
    this.file = null;
  }

  public set focused(isFocused: boolean) {
    if (!isFocused) {
      this._onTouched();
    }
  }

  public registerOnChange(fn: (value: unknown) => unknown): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => unknown): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }
}
