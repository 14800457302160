import { RulesEngineDataType, TypeMetadata } from '@msslib/models/rules-engine';

/**
 * Determines if the value is valid for the given type metadata, returning undefined if it is valid or an error message
 * if not.
 */
export function isInvalid(value: any, type: TypeMetadata, allowNullable = false): string | undefined {
  if (value === null || value === undefined) {
    return allowNullable ? undefined : 'Value is required';
  }

  switch (type.dataType) {
    case RulesEngineDataType.String:
      return typeof value === 'string' ? undefined : 'Expected text';

    case RulesEngineDataType.Integer:
      return typeof value === 'number' && Math.round(value) === value ? undefined : 'Expected whole number';

    case RulesEngineDataType.Decimal:
      return typeof value === 'number' ? undefined : 'Expected number';

    case RulesEngineDataType.Boolean:
      return typeof value === 'boolean' ? undefined : 'Expected yes/no';

    case RulesEngineDataType.Enum:
      return type.choices?.some(c => c.value === value) ? undefined : `Expected ${type.name}`;

    default:
      return 'Unrecognised type';
  }
}

/** Attempts to coerce a value into a valid value for the given type metadata. */
export function coerceType(value: any, type: TypeMetadata, allowNullable = false): any {
  switch (type.dataType) {
    case RulesEngineDataType.String:
      if (value === null || value === undefined) {
        return allowNullable ? null : '';
      }
      return value.toString();

    case RulesEngineDataType.Integer:
      switch (typeof value) {
        case 'number': return Math.floor(value);
        default:
          const i = parseInt(value?.toString() ?? '');
          return isNaN(i) ? allowNullable ? null : 0 : i;
      }

    case RulesEngineDataType.Decimal:
      switch (typeof value) {
        case 'number': return value;
        default:
          const f = parseFloat(value?.toString() ?? '');
          return isNaN(f) ? allowNullable ? null : 0 : f;
      }

    case RulesEngineDataType.Boolean:
      if (value === null || value === undefined) {
        return allowNullable ? null : false;
      }
      switch (typeof value) {
        case 'boolean': return value;
        case 'string': return ['yes', 'true'].includes(value.toLowerCase());
        default: return Boolean(value);
      }

    case RulesEngineDataType.Enum:
      if (!type.choices) {
        throw new Error(`No value options defined for enum type '${type.name}'`);
      }
      if (value === null || value === undefined) {
        return allowNullable ? null : type.choices[0].value;
      }
      const enumTypedValue = coerceType(value,
        {
          dataType: typeof type.choices[0].value === 'number'
            ? RulesEngineDataType.Integer
            : RulesEngineDataType.String,
        } as any,
        true);
      if (type.choices.find(c => c.value === enumTypedValue)) {
        return enumTypedValue;
      }
      return allowNullable ? null : type.choices[0].value;

    default:
      return value;
  }
}
