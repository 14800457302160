import { Injectable } from '@angular/core';
import { LenderHubDataService } from '@msslib/services/lenderhub-data.service';
import { CreateLenderAuditLogRequest, LenderAuditLog, LenderAuditResult } from '../models/lender-audit';

@Injectable({
  providedIn: 'root',
})
export class LenderAuditService {
  public constructor(private lenderHubDataService: LenderHubDataService) {}

  public getAuditHistory() {
    return this.lenderHubDataService.get<LenderAuditLog[]>('LenderAudit');
  }

  public addLenderAudit(model: CreateLenderAuditLogRequest) {
    return this.lenderHubDataService.post('LenderAudit', model);
  }

  public updateLenderAudit(logReferenceId: string, newResult: LenderAuditResult) {
    return this.lenderHubDataService.put(`LenderAudit/${logReferenceId}/result`, newResult);
  }
}
