<div
  class="input-group"
  [ngClass]="props.inputClass"
  [class.is-invalid]="showError"
  [class.disabled]="formControl.disabled"
>
  <span
    *ngIf="props.value"
    class="input-text"
  >
    {{ props.value }}
  </span>
</div>
