import { InaccuracyReportCategory, InaccuracyReportSubCategory } from './enums';
import { SearchType } from 'apps/shared/src/models';


export interface IInaccuracyReport {
  groupId?: number;
  categoryId: InaccuracyReportCategory;
  subCategoryId?: InaccuracyReportSubCategory;
  report?: string;
  lenderName: string;
  productDescription?: string;
}

export enum InaccuracyReportType
{
  Ignite = 0,
  Audit = 1,
  Products = 2,
  ProductsAudit = 3,
}

export interface IInaccuracyReportRequest {
  affordabilitySearchId?: string | undefined | null;
  productsSearchId?: string | undefined | null;
  criteriaSearchId?: string | undefined | null;
  propertySearchId?: string | undefined | null;
  // lenderName?: string;
  reports?: IInaccuracyReport[];
  reportType?: InaccuracyReportType;
  lendingType?: string;
  source: SearchType;
  agencyNumber?: number | null;
  criteriaV2: boolean;
}
