export enum AnswerType {
  Answer = 'Answer',
  Outcome = 'Outcome',
}

export interface IUiCriteriaAnswer {
  answerId: number;
  text: string;
  type: string; // AnswerType
}

export interface IUiCriteriaQuestion {
  questionId?: number;
  question: string;
  answerId?: number;
  answers: IUiCriteriaAnswer[];
  skipList: string[];
}

export interface IUiCriteria {
  questions: IUiCriteriaQuestion[];
}

export interface IContextGroupContext {
  contextId: number;
  description: string;
}

export interface IContextGroup {
  contexts: IContextGroupContext[];
  description: string;
}

export interface ICriteriaVisibilityChange {
  context: string;
  contextGroup: string;
  applicantNumber: number;
  hidden: boolean;
}
