<div class="form-group position-relative row mt-4">
  <div class="col">
    <label class="col fw-bold d-block" *ngIf="props.label">{{ props.label }}</label>
    <div *ngIf="props.template" [class]="props.className" [innerHtml]="props.template"></div>
  </div>

  <div class="template-tooltip col-1 mt-3" *ngIf="props.tooltip">
    <i class="col-1 far fa-question-circle my-auto" (click)="openTooltip()"></i>
  </div>
</div>
