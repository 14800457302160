<input
    class="form-select form-select-sm"
    [ngModel]="selectedProperty"
    (ngModelChange)="propertyNameChange.emit($event?.name)"
    [ngbTypeahead]="typeaheadOperator"
    container="body"
    popupClass="property-selector-dropdown"
    [inputFormatter]="formatter"
    [resultFormatter]="formatter"
    [editable]="false"
    (focus)="openTypeahead($event)"
    (click)="openTypeahead($event)"
    (blur)="closeTypeahead($event)"
    #typeahead="ngbTypeahead"
    [attr.data-testid]="'default_rules_property_'+index"
    >
