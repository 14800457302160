import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISortMainImage, ISortOption } from '../../models';

@Component({
  selector: 'lib-dropdown-filter-sorter',
  styleUrls: ['dropdown-filter-sorter.component.scss'],
  templateUrl: 'dropdown-filter-sorter.component.html',
})
export class DropdownFilterSorterComponent {
  @Input() public name = 'filterdropdown';
  @Input() public label: string;
  @Input() public textClass: string;
  @Input() public boldText = true;
  @Input() public hideIcon = false;
  @Input() public image: ISortMainImage;
  @Input() public list: ISortOption[];
  @Input() public activeValue: unknown;
  @Input() public hideClearOption: boolean;
  @Input() public ariaText: string;
  @Output() public itemChange = new EventEmitter<ISortOption>();

  public get active(): ISortOption | undefined {
    return this.list?.find((l) => l.value === this.activeValue);
  }

  public trackByFn(index: number) {
    return index;
  }
}
