import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import * as Sentry from '../../../../sentry';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MssLibModule } from '@msslib/msslib.module';
import { ConfigService } from '@msslib/services/config.service';
import { routes } from './app.routes';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import {
  ClubHubHomeSectionComponent,
  ConsumerDutyComponent,
  CookiePolicyComponent,
  DocumentPreferencesPageComponent,
  ExternalLoginComponent,
  FirmDetailsPageComponent,
  HeaderComponent,
  HomeComponent,
  OutcomeHistoryComponent,
  UserCustomProductSettingsPageComponent,
} from './components';
import { MiBrokerComponent as MiBrokerComponent,
  MiExecutiveSummaryComponent,
  MiGenericComponent,
  MiIntegrationComponent,
  MiKeyPartnerComponent,
  MiPageNavComponent} from './mi';
import { LenderHubService } from 'apps/shared/src/services/lenderhub.service';
import { MiGenericService } from './mi/mi-generic.service';

@NgModule({
  declarations: [
    // Common components
    AppComponent,
    ClubHubHomeSectionComponent,
    CookiePolicyComponent,
    ConsumerDutyComponent,
    DocumentPreferencesPageComponent,
    ExternalLoginComponent,
    FirmDetailsPageComponent,
    HeaderComponent,
    HomeComponent,
    MiGenericComponent,
    MiIntegrationComponent,
    MiKeyPartnerComponent,
    MiPageNavComponent,
    MiExecutiveSummaryComponent,
    MiBrokerComponent,
    OutcomeHistoryComponent,
    UserCustomProductSettingsPageComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MssLibModule.forRoot(environment.mssLibConfig),
    ReactiveFormsModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  providers: [
    LenderHubService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => () => configService.load(),
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    MiGenericService,
  ],
  bootstrap: [AppComponent],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AppModule {}
