<ng-container *ngIf="isMatchedProductsView">
  <p>
    Based on your Affordability & Criteria inputs, where available, we have returned products which match against the following features:
  </p>
  <div class="row m-0 mb-2 text-center" role="grid">
    <div
      *ngFor="let feature of features; index as i"
      class="col-4 d-flex align-items-center justify-content-center border-right border-bottom py-1 feature-cell"
      [class.border-top]="i < featuresColumnCount"
      [class.border-left]="i % featuresColumnCount === 0"
      role="gridcell"
      [innerHTML]="feature">
    </div>
  </div>
</ng-container>
<p>
  Lenders may launch a new Product or withdraw a Product at any time without prior notice.
  Please check directly with the lender regarding product availability and how criteria may affect this.
</p>
