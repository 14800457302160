import { Component } from '@angular/core';

import { MssFieldBaseFieldTypeComponent } from '../mss-field-base.component';

@Component({
  styleUrls: ['radio-list.component.scss'],
  templateUrl: 'radio-list.component.html',
})
export class RadioListComponent extends MssFieldBaseFieldTypeComponent {
  public defaultOptions = {
    props: {
      options: [] as unknown[],
      formCheck: 'custom', // 'custom' | 'stacked' | 'inline'
    },
  };
}
