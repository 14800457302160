<ng-container [ngSwitch]="elementType">
  <!-- For input based types (e.g. string, number) -->
  <input
    *ngSwitchCase="'input'"
    class="form-control form-control-sm"
    [placeholder]="label ?? ''"
    [appAttrFromObj]="inputAttributes"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)"
    (keydown)="onInputKeyDown($event)"
    [attr.data-testid]="'default_rules_right_hand_input_'+index"
    >

  <!-- For dropdown based types (e.g. enums) -->
  <select
    *ngSwitchCase="'select'"
    class="form-select form-select-sm"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)"
    [attr.data-testid]="'default_rules_right_hand_select_'+index"

  >
    <option *ngFor="let option of selectOptions" [ngValue]="option.value">
      {{option.displayName}}
    </option>
  </select>
</ng-container>
