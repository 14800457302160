import { Injectable } from '@angular/core';
import { IdentityServerDataService } from '@msslib/services/identityserver-data.service';

@Injectable({
  providedIn: 'root',
})
export class MarketingPreferencesService {
  public constructor(private identityServerDataService: IdentityServerDataService) {}

  public setMarketingPreferencesAsRead() {
    return this.identityServerDataService.post('marketingpreferences/setMarketingPreferencesAsRead', {});
  }
}
