import { OutcomeResults, YesNoResults } from '@msslib/models/enums';
import { SortOrder } from '@msslib/models/filter-sort';

export interface IIgniteFilterOption {
  lenderName?: any[] | null;
  lendersOrder?: SortOrder;
  igniteResultsOrder?: SortOrder;
  outcomeResult?: OutcomeResults;
  maxValueOrder?: SortOrder;
  propertyResult?: OutcomeResults;
  productsMatched?: YesNoResults;
  initialRateOrder?: SortOrder;
  trueCostInitialPeriodOrder?: SortOrder;
  monthlyPaymentOrder?: SortOrder;
}

export enum FilterKey {
  LenderName = 'lenderName',
  LendersOrder = 'lendersOrder',
  IgniteResultsOrder = 'igniteResultsOrder',
  OutcomeResult = 'outcomeResult',
  MaxValueOrder = 'maxValueOrder',
  PropertyResult = 'propertyResult',
  ProductMatched = 'productsMatched',
  InitialRateOrder = 'initialRateOrder',
  TrueCostInitialPeriodOrder = 'trueCostInitialPeriodOrder',
  MonthlyPaymentOrder = 'monthlyPaymentOrder',
}
