import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IPropertyAndLoan,
} from 'apps/clubhub/src/app/ignite/models/affordability';
import { IgniteHelperService } from '@msslib/services/ignite.helper';
import {
  LoanSecurity,
  MethodOfRepayment,
} from 'apps/shared/src/models';

import { FormFieldType, WrapperType, yesNoOptions } from '@msslib/components/formly/formly.config';
import { FormsValidators } from '@msslib/components';

@Injectable({
  providedIn: 'root',
})
export class ProductFormFieldsBridgingService {
  public constructor(
    private helperService: IgniteHelperService,
  ) { }

  public fields: FormlyFieldConfig[] = [
    {
      key: 'bridging',
      wrappers: [WrapperType.FormCard],
      props: {
        title: 'Mortgage Details',
      },
      fieldGroup: [
        {
          key: 'regulated',
          type: FormFieldType.RadioButtons,
          props: {
            label: 'Regulated',
            options: yesNoOptions,
            required: true,
          },
        },
        {
          key: 'propertyValue',
          type: FormFieldType.Currency,
          defaultValue: 0,
          props: {
            label: 'Property value',
          },
        },
        {
          key: 'loanAmount',
          type: FormFieldType.Currency,
          props: {
            label: 'Loan amount',
            tooltip: 'Please enter net loan amount.' +
            'The amount you need to receive before any fees or interest are added to the loan',
          },
        },
        {
          key: 'loanSecurity',
          type: FormFieldType.Select,
          defaultValue: null,
          props: {
            label: 'Loan Security',
            options: [
              this.helperService.defaultSelectOptionNotDisabled,
              { label: 'First Charge', value: LoanSecurity.FirstCharge },
              { label: 'Second Charge', value: LoanSecurity.SecondCharge },
            ],
          },
        },
        {
          key: 'currentMortgageBalanceOutstanding',
          type: FormFieldType.Currency,
          defaultValue: 0,
          props: {
            label: 'Current Mortgage Balance Outstanding',
          },
          expressions: {
            hide: field => field.model?.loanSecurity !== LoanSecurity.SecondCharge,
          },
        },
        {
          key: 'ltv',
          type: FormFieldType.Disabled,
          props: {
            label: 'Net LTV',
            addonEnd: '%',
          },
          expressions: {
            'props.value': (field) => this.helperService.calculateLtv(field.model as IPropertyAndLoan) ?? 0,
          },
        },
        {
          key: 'loanTermMonths',
          type: FormFieldType.Number,
          defaultValue: 0,
          props: {
            label: 'Loan Term (Months)',
          },
          validators: {
            validation: [FormsValidators.greaterThan(-1), FormsValidators.lessThan(37)],
          },
        },
        {
          key: 'methodOfRepayment',
          type: FormFieldType.Select,
          defaultValue: null,
          props: {
            label: 'Method of repayment',
            options: this.helperService.buildSelectOptionsFromEnum(MethodOfRepayment, true, false),
          },
        },
        {
          key: 'limitedCompany',
          type: FormFieldType.RadioButtons,
          props: {
            label: 'Limited Company',
            options: yesNoOptions,
          },
        },
        {
          key: 'getResultsBtn',
          type: 'buttons',
          props: {
            buttons: [
              { label: 'Get Results', type: 'submit', className: 'btn-2022--secondary' },
            ],
          },
        },
      ],
    },
  ];
}
