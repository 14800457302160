import { Injectable } from '@angular/core';

import { LenderHubDataService } from '@msslib/services/lenderhub-data.service';
import { LenderPackagers } from '../models/lender-packagers';
import { UpdatePackagerRelationshipsRequest } from '../models/update-packager-relationships-request';

@Injectable({
  providedIn: 'root',
})
export class PackagerRelationshipsService {
  public constructor(private lenderHubDataService: LenderHubDataService) {}

  public getAvailablePackersForLender(lenderNameId: number) {
    return this.lenderHubDataService.get<LenderPackagers>(`LenderName/AvailablePackagers/${lenderNameId}`);
  }

  public updatePackagerRelationships(lenderNameId: number, request: UpdatePackagerRelationshipsRequest[]) {
    return this.lenderHubDataService.post(`LenderName/PackagerRelationships/${lenderNameId}`, request);
  }
}
