import { IDateInput } from '../components/formly/models';

function daysInMonth(m: number, y: number): number {
  // m is 1-12
  switch (m) {
    case 2:
      return (y % 4 === 0 && y % 100 > 0) || y % 400 === 0 ? 29 : 28;
    case 4:
    case 6:
    case 9:
    case 11:
      return 30;
    default:
      return 31;
  }
}

function dateObjectIsValid(d: number, m: number, y: number): boolean {
  return m >= 1 && m <= 12 && d >= 1 && d <= daysInMonth(m, y) && /[0-9]{4}/.test(y?.toString());
}

export function getAge(dob: Date): number {
  const now = new Date();
  const years = now.getFullYear() - dob.getFullYear();
  const monthInYearDiff = now.getMonth() - dob.getMonth();
  const dayInMonthDiff = now.getDate() - dob.getDate();

  return monthInYearDiff > 0 || (monthInYearDiff === 0 && dayInMonthDiff >= 0) ? years : years - 1;
}

export function mapDate(value: any): Date | null {
  const date =
    typeof value === 'string'
      ? new Date(value)
      : value instanceof Date
        ? value
        : typeof value === 'object' && value?.day && value?.month && value?.year
          && dateObjectIsValid(value.day, value.month, value.year)
          ? new Date(value.year, value.month - 1, value.day)
          : null;
  return date;
}

export function mapDateToUtc(value: IDateInput): Date {
  const date = new Date(Date.UTC(value.year, value.month - 1, value.day, 0, 0, 0));
  return date;
}

export function dateIsValid(value: unknown): value is Date {
  return mapDate(value) instanceof Date;
}

export function min18Years(value: unknown) {
  const date = mapDate(value);
  return dateIsValid(date) && getAge(date) >= 18;
}

export function isAgeOver(value: unknown, years: number): boolean {
  const date = mapDate(value);
  return dateIsValid(date) && getAge(date) >= years;
}

export function addDays(date: Date | string, daysNumber: number): Date {
  const updateDate = new Date(date);
  return new Date(updateDate.setUTCDate(updateDate.getUTCDate() + daysNumber));
}

export function getCurrentDate() {
  const currentDate = new Date();
  return new Date(
    Date.UTC(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate(),
      0,
      0,
      0,
      0,
    ),
  );
}

export function getDateString(date: Date | null): string {
  if (!date) {
    return '';
  }

  return `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
}

export function getDatepickerStringFromDate(date: Date | null): string {
  if (!date) {
    return '';
  }

  return `${date.getUTCFullYear()}/${date.getUTCMonth() + 1}/${date.getUTCDate()}`;
}

export function getDatepickerString(stringDate: string | null): string {
  if (!stringDate) {
    return '';
  }

  return stringDate.split('T')[0] ?? '';
}


export function dateInputToString(dateInput: IDateInput | undefined): string {
  if (!dateInput) {
    return '';
  }

  return `${dateInput.day}-${dateInput.month}-${dateInput.year}`;
}

export function dateInputToDisplayString(dateInput: IDateInput | undefined): string {
  if (!dateInput || !dateInput.year) {
    return '';
  }

  return `${dateInput.day}/${dateInput.month}/${dateInput.year}`;
}

export function stringToDateInput(dateString: string): IDateInput | null {
  if (!dateString) {
    return null;
  }

  const splitDate = dateString.split('-');
  if (splitDate.length !== 3) {
    return null;
  }

  return {
    day: +splitDate[0],
    month: +splitDate[1],
    year: +splitDate[2],
  };
}
