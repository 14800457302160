export enum LenderNameCreditType {
  CreditScore = 1,
  CreditSearch = 2,
}

export enum LenderNameTimePeriod {
  Hours = 1,
  Days = 2,
}

export enum LenderNameApplicationToOfferDefinition {
  DayOfApplicationReceiptToDayOfferIssued = 1,
  DayAllRequiredDocumentationIsProvidedToDayOfferIssued = 2,
  CaseDependent = 3,
  SeeLenderWebsite = 4,
}
