import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { ContextOption } from '@msslib/models/contexts';
import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { CriteriaService } from 'apps/clubhub/src/app/ignite/services';
import { tap } from 'rxjs/operators';

export const criteriaContextResolver: ResolveFn<ContextOption> =
  (route: ActivatedRouteSnapshot) => {
    const clubHubDataService = inject(ClubHubDataService);
    const criteriaService = inject(CriteriaService);

    return clubHubDataService.get<ContextOption>(`Answer/GetIssueAndContext/${route.params.contextId}`).pipe(
      tap((response: ContextOption) => {
        criteriaService.state.currentIssue = response[0];
        criteriaService.state.currentContext = response[1];
      }),
    );
  };
