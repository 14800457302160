import { TemplateRef } from '@angular/core';

export enum NavPosition {
  Left,
  Right,
}

export enum NavMessageKeys {
  CaseLink = 'caseLinkMsg',
}

export interface NavItem {
  text?: string;
  icon?: string;
  hide?: boolean;
  disabled?: boolean;
  link?: string;
  handler?: unknown;
  navItemTemplate?: TemplateRef<unknown>;
  linkTemplate?: TemplateRef<unknown>;
  textWithoutLink?: string;
}

export interface PageNav {
  leftNav: NavItem[];
  rightNav: NavItem[];
}

export interface PageNavMessage {
  key: string;
  navItem: NavItem;
  position: NavPosition;
}
