import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-firm-details-page',
  templateUrl: 'firm-details-page.component.html',
})
export class FirmDetailsPageComponent {
  public constructor(
    public location: Location,
    public router: Router,
  ) {}
}
