/**
 * Function that generates all possible permutations of a phrase with the given synonyms as replacements.
 * @example
 * getPermutations('first, not first, last', [['first', 'initial'], ['last', 'final']]);
 * // => [
 * //    "first, not first, last",
 * //    "initial, not first, last"
 * //    "initial, not initial, last"
 * //    "initial, not initial, final"
 * //    "initial, not first, final"
 * //    "first, not initial, last"
 * //    "first, not initial, final"
 * //    "first, not first, final"
 * // ]
 */
export function getPermutations(phrase: string, synonyms: [string, string][]): string[] {
  const permutations = [phrase];
  function getPermutationsImpl(phrase: string, position: number): void {
    for (const synonym of synonyms) {
      let index = position;
      while (true) {
        index = phrase.indexOf(synonym[0], index);
        if (index === -1) {
          break;
        }
        const newPhrase = phrase.slice(0, index) + synonym[1] + phrase.slice(index + synonym[0].length);
        if (permutations.includes(newPhrase)) {
          break;
        }
        permutations.push(newPhrase);
        getPermutationsImpl(newPhrase, index + synonym[1].length);
        index++;
      }
    }
  }

  getPermutationsImpl(phrase, 0);
  return permutations;
}

/**
 * Joins the given array (as per Array.join), but uses a different separator for the last join.
 * @example
 * join2([1, 2, 3], ', ', ' and' ); // => '1, 2 and 3'
 * join2([1, 2], ', ', ' and '); // => '1 and 2'
 * join2([1], ', ', ' and '); // => '1'
 * join2([], ', ', ' and '); // => ''
 */
export function join2<T>(arr: T[], separator: string, lastSeparator: string): string {
  switch (arr.length) {
    case 0:
      return '';
    case 1:
    case 2:
      return arr.join(lastSeparator);
    default:
      return `${arr.slice(0, -1).join(separator)}${lastSeparator}${arr[arr.length - 1]}`;
  }
}
