<div class="row">
  <div class="col">
    <h1>
      Something went wrong
    </h1>
    <p>
      You do not have permission to view this page. Try logging out and logging in again to see if this resolves the problem.
    </p>
    <p>
      You can contact us Monday to Friday between the hours of 9am-5:30pm on {{contactUsPhone.text}}, please select option 3.
    </p>
    <p>
      Calls may be recorded and monitored (call charges will apply).
    </p>
  </div>
</div>
