<div class="row">
  <label
    [attr.for]="id"
    class="col-md-6 form-label d-flex justify-content-between align-items-center {{ labelIndentCssClass }}"
    *ngIf="props.label"
    [attr.role]="props.group ? 'presentation' : null"
  >
    <span *ngIf="!props.abbr">
      {{ props.label }}
    </span>
    <abbr *ngIf="props.abbr" title="{{ props.abbr }}">
      {{ props.label }}
    </abbr>
  </label>
  <div
    class="col-md-6"
    [attr.role]="props.group ? 'group' : null"
    [attr.aria-labelledby]="id + key?.toString()"
  >
    <div class="sr-only" [id]="id + key?.toString()">
      {{ props.label }}
    </div>

    <ng-template #fieldComponent></ng-template>
  </div>
</div>
