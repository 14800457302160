import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ContextOption } from '@msslib/models/contexts';
import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { UpdateAnswerSortOrder } from '../models';

@Injectable({
  providedIn: 'root',
})
export class NodeService {
  public constructor(private clubHubDataService: ClubHubDataService) {}

  public hasEdits(context: ContextOption): boolean {
    return !!context.editNodeId;
  }

  public moveFlow(contextId: number, newIssueId: number): Observable<unknown> {
    return this.clubHubDataService.post('Node/Move', { contextId, newIssueId });
  }

  public duplicateFlow(contextId: number, newIssueId: number): Observable<unknown> {
    return this.clubHubDataService.post('Node/Duplicate', { contextId, newIssueId });
  }

  public editFlow(liveContextNodeId: number): Observable<unknown> {
    return this.clubHubDataService.post('Node/Edit', { liveContextNodeId });
  }

  public discardFlow(editContextNodeId: number): Observable<unknown> {
    return this.clubHubDataService.post('Node/Discard', { editContextNodeId });
  }

  public publishFlow(editContextNodeId: number): Observable<unknown> {
    return this.clubHubDataService.post('Node/Publish', { editContextNodeId });
  }

  public toggleInternal(contextId: number) {
    return this.clubHubDataService.post<unknown>(`Answer/ToggleContextInternal/${contextId}`, {});
  }

  public toggleBrokerVisible(contextId: number) {
    return this.clubHubDataService.post<unknown>(`Answer/ToggleContextBrokerVisible/${contextId}`, {});
  }

  public toggleLenderVisible(contextId: number) {
    return this.clubHubDataService.post<unknown>(`Answer/ToggleContextLenderVisible/${contextId}`, {});
  }

  public toggleIgniteVisible(contextId: number) {
    return this.clubHubDataService.post<unknown>(`Answer/ToggleContextIgniteVisible/${contextId}`, {});
  }

  public getIssues(lendingType: string, isAdmin: boolean) {
    return this.clubHubDataService.get<ContextOption[]>(`Answer/GetIssues/${lendingType}?isEditMode=${isAdmin}`);
  }

  public getContexts(issueId: number, isAdmin: boolean) {
    return this.clubHubDataService.get<ContextOption[]>(`Answer/GetContexts?issueId=${issueId}&isEditMode=${isAdmin}`);
  }

  public getInternalContext() {
    return this.clubHubDataService.get<ContextOption[]>('Answer/GetInternalContext');
  }

  public updateAnswerSortOrder(answers: UpdateAnswerSortOrder[]) {
    return this.clubHubDataService.post('Answer/UpdateAnswerSortOrder', { answers });
  }
}
