import { Injectable } from '@angular/core';

import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { Synonym } from '@msslib/models/synonym';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  public constructor(private clubHubDataService: ClubHubDataService) {}

  public getSynonyms() {
    return this.clubHubDataService.get<Synonym[]>('Answer/Synonyms');
  }

  public saveSynonym(synonym: Synonym) {
    return this.clubHubDataService.put<Synonym>('Answer/Synonyms', synonym);
  }

  public editSynonym(synonym: Synonym) {
    return this.clubHubDataService.patch<Synonym>('Answer/Synonyms', synonym);
  }

  public deleteSynonym(id: number) {
    return this.clubHubDataService.delete<number>(`Answer/Synonyms/${id}`);
  }
}
