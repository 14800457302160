
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CMSService {
  public lenderAdverts: object;
  private bannerUrl = 'https://strgepisukt1.z33.web.core.windows.net/ignite-banners.json';
  public constructor(private http: HttpClient) {
    this.http.get(this.bannerUrl).subscribe(res => {
      this.lenderAdverts = res;
    });
  }

  public getIgniteBanners(): Observable<object> {
    return this.http.get(this.bannerUrl);
  }

  public get lenderBannersData(): object {
    return this.lenderAdverts;
  }
}
