<ng-container *ngIf="isProductDetail; else standardDisplay">
  <div
    *ngIf="ercStatus"
    [ngbTooltip]="ercTooltip"
    placement="left"
    tooltipClass="custom-tooltip erc-tooltip">
    {{ ercStatus }}
  </div>
</ng-container>
<ng-template #standardDisplay>
  <strong
    [ngbTooltip]="hasErc ? ercTooltip : false"
    class="erc"
    placement="left"
    tooltipClass="custom-tooltip erc-tooltip"
  >
    {{ ercStatus }}
  </strong>
</ng-template>

<ng-template #ercTooltip>
  <div class="row" *ngIf="hasErcYears">
    <div class="col text-start"><b>Year</b></div>
    <div class="col text-end"><b>Charge</b></div>
  </div>
  <ng-container *ngFor="let charge of chargePeriods; index as i">
    <div class="row">
      <div class="col-7 text-start">Year {{ i + 1 }}:</div>
      <div class="col-5 text-start">{{ charge / 100 | percent : '1.2-2' }}</div>
    </div>
  </ng-container>
</ng-template>
