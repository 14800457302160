import {
  ComponentRef, Directive, Input, OnChanges, OnInit, Type, ViewContainerRef,
} from '@angular/core';

import {
  FormButtonComponent,
  FormButtonGroupComponent,
  FormCheckboxComponent,
  FormCheckboxListComponent,
  FormDateComponent,
  FormFileComponent,
  FormFilePathComponent,
  FormHeadingComponent,
  FormInputComponent,
  FormInputGroupComponent,
  FormLinkComponent,
  FormParagraphComponent,
  FormRadioListComponent,
  FormSelectComponent,
  FormTextareaComponent,
  FormTimeComponent,
  FormToggleComponent,
} from '../components';
import { Field, IFieldConfig } from '../../../models';

const components: Record<string, Type<Field>> = {
  button: FormButtonComponent,
  buttongroup: FormButtonGroupComponent,
  input: FormInputComponent,
  inputgroup: FormInputGroupComponent,
  number: FormInputComponent,
  file: FormFileComponent,
  filepath: FormFilePathComponent,
  password: FormInputComponent,
  email: FormInputComponent,
  date: FormDateComponent,
  time: FormTimeComponent,
  textarea: FormTextareaComponent,
  checkbox: FormCheckboxComponent,
  checkboxlist: FormCheckboxListComponent,
  radiolist: FormRadioListComponent,
  select: FormSelectComponent,
  link: FormLinkComponent,
  toggle: FormToggleComponent,
  heading: FormHeadingComponent,
  paragraph: FormParagraphComponent,
};

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[libdFormField]',
})
export class FormFieldDirective implements Field, OnChanges, OnInit {
  @Input() public config: IFieldConfig;
  public component: ComponentRef<Field>;

  public constructor(private container: ViewContainerRef) {}

  public ngOnChanges() {
    if (this.component) {
      this.component.instance.config = this.config;
    }
  }

  public ngOnInit() {
    if (!components[this.config.type]) {
      const supportedTypes = Object.keys(components).join(', ');
      throw new Error(`Trying to use an unsupported type (${this.config.type}). Supported types: ${supportedTypes}`);
    }
    this.component = this.container.createComponent(components[this.config.type]);
    this.component.instance.config = this.config;
  }
}
