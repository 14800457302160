import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'lib-card',
  styleUrls: ['card.component.scss'],
  templateUrl: 'card.component.html',
})
export class CardComponent implements AfterViewInit {
  @ViewChild('footerTemplate', { static: true }) public footerTemplate: ElementRef;
  @Input() public title: string;
  @Input() public body: string | TemplateRef<unknown>;
  @Input() public headerClass: string;
  @Output() public cardClicked = new EventEmitter<unknown>();
  public showFooter: boolean;

  public constructor(private cdRef: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.showFooter =
      this.footerTemplate.nativeElement &&
      this.footerTemplate.nativeElement.children.length > 0;
    this.cdRef.detectChanges();
  }
}
