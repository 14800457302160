import { Injectable, OnDestroy } from '@angular/core';
import { AuthorizeService, IUser } from '@msslib/services/authorize.service';
import { BehaviorSubject, Observable, Subscription, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, share, switchMap, tap } from 'rxjs/operators';
import { ClubHubDataService } from './clubhub-data.service';
import { BrandingData, SsoCustomDataResponse } from '../models';
import { ActivatedRoute } from '@angular/router';

const brandingStorageKey = 'brandingData';
const defaultBranding: BrandingData = {
  logoUrl: '//www.legalandgeneral.com/web_resources/prototype-design/estate/css/landg/images/landg-logo.45b0217c.svg',
  logoLink: '//www.legalandgeneral.com/adviser/',
};

@Injectable({ providedIn: 'root' })
export class BrandingService implements OnDestroy {

  private readonly routeParamSub: Subscription;
  private readonly useDefaultBranding$ = new BehaviorSubject(false);
  public readonly branding$: Observable<BrandingData | undefined>;

  public constructor(
    public authService: AuthorizeService,
    private dataService: ClubHubDataService,
    public route: ActivatedRoute,
  ) {
    const user$ = authService.getUserDistinct();
    this.branding$ = combineLatest([
      user$,
      this.useDefaultBranding$.pipe(distinctUntilChanged()),
    ]).pipe(
      switchMap(([user, useDefaultBranding]) => this.getBranding(user, useDefaultBranding)),
      // If branding data is non-null, store it in the session data
      tap(data => !!data && sessionStorage.setItem(brandingStorageKey, JSON.stringify(data))),
      share(),
    );

    // Watch the route. If there is a 'nobrand' query param, update `useDefaultBranding`
    this.routeParamSub = route.queryParams.subscribe(queryParams => {
      const noBrand = parseInt(queryParams.nobrand);
      if (!isNaN(noBrand)) {
        this.useDefaultBranding = noBrand === 1;
      }
    });
  }

  private getBranding(user: IUser | null, useDefaultBranding: boolean): Observable<BrandingData | undefined> {
    if (useDefaultBranding) {
      // If set to use default branding, simply return the default
      return of(defaultBranding);
    } else if (user) {
      // Else if user is logged in, send a request to the endpoint to try get custom SSO branding data
      return this.fetchSsoBrandingData();
    }
    // if user is not logged in, try get branding data from session (e.g. if they'd previously logged in)
    return of(JSON.parse(sessionStorage.getItem(brandingStorageKey) ?? '{}') as BrandingData | undefined);
  }

  /**
   * - If `true`, will always use the default L&G umbrella.
   * - If `false`, will instead check the custom sso data endpoint, and fall back to the umbrella if no custom data set.
   */
  public set useDefaultBranding(value: boolean) {
    this.useDefaultBranding$.next(value);
  }
  public get useDefaultBranding() {
    return this.useDefaultBranding$.value;
  }

  /**
   * Fetches and parses the custom SSO data from the external login endpoint.
   */
  private fetchSsoBrandingData(): Observable<BrandingData> {
    return this.dataService.get<SsoCustomDataResponse>('ExternalLogin/SsoCustomData', { loading: false })
      .pipe(map(data => ({
        logoUrl: data?.logoUrl ?? defaultBranding.logoUrl,
        logoLink: data?.logoUrl ? '#' : defaultBranding.logoLink,
      })));
  }

  public ngOnDestroy(): void {
    this.routeParamSub.unsubscribe();
  }
}
