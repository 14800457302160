import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ModalService, ToastService } from '@msslib/services';
import { ValuationFeeScale, ValuationFeeScaleEntry } from '@msslib/models/valuation-fee-scale-entry';
import { ProductService } from 'apps/lenderhub/src/app/services';
import { DatePipe } from '@angular/common';
import parseISO from 'date-fns/parseISO';
import { EventScheduleComponent, ScheduleFormType } from '../event-schedule/event-schedule.component';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'lib-valuation-scale',
  styleUrls: ['valuation-fee-scale.component.scss'],
  templateUrl: 'valuation-fee-scale.component.html',
})
export class ValuationFeeScaleComponent implements OnInit {
  @ViewChild('valuationFeeConfirmationModalTemplateRef', { static: true })
  public valuationFeeConfirmationModalTemplateRef: TemplateRef<void>;

  @ViewChild('valuationFeeCancelModalTemplateRef', { static: true })
  public valuationFeeCancelModalTemplateRef: TemplateRef<void>;

  @ViewChild('valuationFeeContinueModalTemplateRef', { static: true })
  public valuationFeeContinueModalTemplateRef: TemplateRef<void>;

  @ViewChild('rescheduleDialogTemplateRef', { static: true })
  public rescheduleDialogTemplateRef: TemplateRef<unknown>;

  @Input() public isValuationModalOpen: boolean;
  @Input() public lendingType: string;
  @Input() public deferredSave = false;
  @Output() public formSubmit = new EventEmitter<() => Observable<any>>();
  @Output() public formCancel = new EventEmitter<void>();

  public valuationFeeScales: ValuationFeeScale[] = [];
  public valuationFeeScaleEntries: ValuationFeeScaleEntry[] = [];
  public deletingValuationFeeScaleEntry: ValuationFeeScaleEntry | null = null;
  public isValidValuationScale = false;
  private nonDigitNumericChars = ['e','E', '+', '-', '.'];
  public scheduleForm: ScheduleFormType;

  public constructor(
    private productService: ProductService,
    private toastService: ToastService,
    public modalService: ModalService,
    private datePipe: DatePipe,
  ) {
    this.scheduleForm = EventScheduleComponent.buildScheduleForm();
  }

  public ngOnInit() {
    this.fetchFeeScales();
  }

  public get hasScheduledScale() {
    return this.valuationFeeScales.some(s => s.isScheduled);
  }

  public get activeScheduleDate() {
    const date = this.valuationFeeScales.find(s => s.isScheduled)?.startDate;
    return date ? parseISO(date) : null;
  }

  public get unsavedChanges() {
    return this.productService.unsavedChanges;
  }

  public get canSave() {
    return this.isValidValuationScale && (this.isValuationModalOpen || this.unsavedChanges);
  }

  public get canCancel() {
    return this.isValuationModalOpen || this.unsavedChanges;
  }

  public get scheduleFormValid() {
    return this.scheduleForm.valid;
  }

  public fetchFeeScales() {
    this.productService.unsavedChanges = false;
    this.productService.getValuationFeeScales(this.lendingType).subscribe(data => {
      this.valuationFeeScales = [...data];
      this.resetForm();
      this.validateValuationFeeScale();
    });
  }

  public resetForm() {
    const currentScale = this.valuationFeeScales.find(s => !s.isScheduled);
    this.valuationFeeScaleEntries = currentScale?.entries ?? [];
  }

  public async submitValuationScale() {
    // If the component is on the product management page (i.e. not part of the modal that is shown if the user has not
    // entered a scale and has uploaded products that require one), ask user to confirm changes and enter the schedule.
    EventScheduleComponent.resetScheduleForm(this.scheduleForm);
    const hasConfirmed = this.isValuationModalOpen
      ? Promise.resolve(true)
      : await this.modalService
        .open<boolean>({
          title: 'Confirm Changes',
          template: this.valuationFeeConfirmationModalTemplateRef,
          size: 'xl',
        })
        .catch(() => false);
    if (!hasConfirmed) {
      return;
    }

    const scheduleDate = EventScheduleComponent.getScheduleFormDate(this.scheduleForm);

    if (this.deferredSave) {
      this.productService.unsavedChanges = false;

      const updateFeeCallback = () => {
        return this.productService
          .updateValuationFeeScale(this.lendingType, this.valuationFeeScaleEntries, scheduleDate)
          .pipe(tap({
            next: () => {
              if (!this.isValuationModalOpen) {
                this.showUpdateConfirmation();
                this.fetchFeeScales();
              }
            },
            error: () => {
              this.toastService.danger('Valuation Fee Scale update failed');
            },
          }));
      };

      this.formSubmit.emit(updateFeeCallback);
      return;
    }

    this.productService.updateValuationFeeScale(this.lendingType, this.valuationFeeScaleEntries, scheduleDate)
      .subscribe({
        next: () => {
          this.toastService.success('Valuation Fee Scale updated successfully');
          this.productService.unsavedChanges = false;
          this.formSubmit.emit();

          if (!this.isValuationModalOpen) {
            this.showUpdateConfirmation();
            this.fetchFeeScales();
          }
        },
        error: () => {
          this.toastService.danger('Valuation Fee Scale update failed');
        },
      });
  }

  private validateValuationFeeScale(): void {
    const validationResults = this.valuationFeeScaleEntries.map(
      entry => !this.isBlank(entry) && !this.isInvalidRange(entry) && !this.isOverlapping(entry),
    );
    this.isValidValuationScale = validationResults.every(v => v);
  }

  public isInvalidRange(entry: ValuationFeeScaleEntry): boolean {
    let isInvalid = false;
    if ((entry === this.valuationFeeScaleEntries[this.valuationFeeScales.length - 1])
      && entry.propertyPriceTo === null) {
      isInvalid = false;
    } else if (entry.propertyPriceTo !== undefined) {
      isInvalid = entry.propertyPriceFrom >= entry.propertyPriceTo;
    }
    if (isInvalid) {
      this.isValidValuationScale = false;
    }
    return isInvalid;
  }

  public isBlankOrZeroValue(value: number | null | undefined): boolean {
    return !value;
  }

  public isBlankValue(value: number | null | undefined): boolean {
    return typeof value === 'undefined' || value === null;
  }

  private isBlank(entry: ValuationFeeScaleEntry): boolean {
    const isBlank = this.isBlankValue(entry.propertyPriceFrom)
      || this.isBlankOrZeroValue(entry.propertyPriceTo)
      || this.isBlankOrZeroValue(entry.fee);
    return isBlank;
  }

  public isOverlapping(entry: ValuationFeeScaleEntry): boolean {
    const index = this.valuationFeeScaleEntries.indexOf(entry);
    const previousEntry = this.valuationFeeScaleEntries[index - 1];

    if (previousEntry?.propertyPriceTo !== undefined) {
      const isOverlapping = entry.propertyPriceFrom <= previousEntry.propertyPriceTo;
      if (isOverlapping) {
        this.isValidValuationScale = false;
      }
      return isOverlapping;
    }
    return false;
  }

  public deleteValuationRow() {
    this.valuationFeeScaleEntries = [
      ...this.valuationFeeScaleEntries.slice(0, this.valuationFeeScaleEntries.length - 1),
    ];
    this.productService.unsavedChanges = true;
    this.validateValuationFeeScale();
  }

  public setUnsavedChanges(value:boolean) {
    this.productService.unsavedChanges = value;
    this.validateValuationFeeScale();
  }

  /** @returns Whether the changes should be discarded. */
  public openCancelModal() {
    return this.modalService.open<never>({
      title: 'Valuation Fee Scale Update',
      template: this.valuationFeeCancelModalTemplateRef,
      size: 'md',
    }).then(() => null, () => null);
  }

  public showUpdateScheduleModal(): void {
    if (!this.activeScheduleDate) {
      return;
    }

    EventScheduleComponent.resetScheduleForm(this.scheduleForm, this.activeScheduleDate);
    this.modalService
      .open<boolean>({
        title: 'Update Schedule',
        component: this.rescheduleDialogTemplateRef,
        size: 'md',
      })
      .catch(() => false)
      .then(shouldUpdate => {
        const newScheduleDate = shouldUpdate && EventScheduleComponent.getScheduleFormDate(this.scheduleForm);
        if (newScheduleDate) {
          this.productService.updateScheduledValuationFeeScaleDate(this.lendingType, newScheduleDate)
            .subscribe(() => {
              this.toastService.success('Updated valuation fee scale schedule date');
              this.fetchFeeScales();
            });
        }
      });
  }

  public discardSchedule() {
    const dateStr = this.datePipe.transform(this.activeScheduleDate, 'dd/MM/yyyy HH:mm');
    this.modalService.warn({
      title: 'Delete Schedule',
      message: `Are you sure you want to delete the valuation fee scale scheduled to go live on ${dateStr}?`,
      size: 'md',
      showButtons: true,
      okLabel: 'Yes, delete schedule',
      cancelLabel: 'No, go back',
    }).then(
      () => this.productService.deleteScheduledValuationFeeScale(this.lendingType)
        .subscribe(() => {
          this.toastService.success('Removed scheduled valuation fee scale');
          this.fetchFeeScales();
        }),
      () => { /* catch unhandled promise rejection */ },
    );
  }

  public cancelUpdate() {
    this.formCancel.emit();
    this.fetchFeeScales();
    this.productService.unsavedChanges = false;
  }

  public showUpdateConfirmation() {
    return this.modalService.open<never>({
      title: 'Valuation Fee Scale Update',
      template: this.valuationFeeContinueModalTemplateRef,
      size: 'md',
    }).then(() => null, () => null);
  }

  public preventUnwanted(event) {
    if (this.nonDigitNumericChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  public closeModal(data: unknown) {
    this.modalService.close(data);
  }

  public showDeleteDialog(entry: ValuationFeeScaleEntry): void {
    this.deletingValuationFeeScaleEntry = entry;
    if (!entry.propertyPriceFrom && !entry.propertyPriceTo && !entry.fee) {
      this.deleteEntry();
    }
  }

  public cancelDeletion(): void {
    this.deletingValuationFeeScaleEntry = null;
  }

  public deleteEntry(): void {
    if (this.deletingValuationFeeScaleEntry) {
      this.valuationFeeScaleEntries = this.valuationFeeScaleEntries
        .filter(r => r !== this.deletingValuationFeeScaleEntry);
      this.deletingValuationFeeScaleEntry = null;
      this.productService.unsavedChanges = true;
    }
  }

  public addRowToTheEnd(): void {
    const index = this.valuationFeeScaleEntries.length - 1;
    this.addRowBelow(index);
  }

  public addRowBelow(index: number): void {
    const newRowIndex = index + 1;
    const updated = [
      ...this.valuationFeeScaleEntries.slice(0, newRowIndex),
      {
        propertyPriceFrom: 0,
        viewId: Math.random(),
      } as ValuationFeeScaleEntry,
      ...this.valuationFeeScaleEntries.slice(newRowIndex),
    ];

    this.valuationFeeScaleEntries = [...updated];

    this.productService.unsavedChanges = true;
    this.validateValuationFeeScale();
  }

  public getEntryPostfix(entry: ValuationFeeScaleEntry): string {
    return `${!!entry.id ? entry.id : entry.viewId}`;
  }

  public hasValue(value: number | null | undefined): boolean {
    return Number.isInteger(value);
  }
}
