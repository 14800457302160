<div [formGroup]="formGroup" [class]="config.cssClass ? config.cssClass: ''">
  <button
    class="btn btn-2022--secondary {{ config.cssClass || '' }}"
    (click)="config?.onSubmit(formGroup)"
    [disabled]="config.disabled"
    type="submit"
  >
    {{ config.label }}
  </button>
</div>
