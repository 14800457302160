<div class="form-group">
  <div [ngClass]="{ 'input-group': showClearButton }">
    <span class="input-group-text"><i class="fa fa-search"></i></span>
    <input
      id="{{ id || 'searchinput' }}"
      [placeholder]="placeholder || 'Search'"
      type="text"
      class="form-control"
      [(ngModel)]="model"
      (selectItem)="onSelectItem($event)"
      [ngbTypeahead]="search"
      [inputFormatter]="key && formatter"
      [resultFormatter]="key && formatter"
      [resultTemplate]="resultTemplate"
    />
    <button *ngIf="showClearButton" class="btn bg-2022--secondary" type="button" (click)="clear()">
      Clear
    </button>
  </div>
</div>
