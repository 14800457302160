<div [formGroup]="formGroup" [class]="config.cssClass ? 'mb-4 ' + config.cssClass: 'form-group'">
  <label for="{{ config.name }}">
    {{ config.label }}
    <ng-container *ngIf="showAsterisk(config)">
      *
    </ng-container>
  </label>

  <textarea
    class="form-control"
    [ngClass]="{ 'is-valid': valid, 'is-invalid': invalid }"
    [formControlName]="config.name"
    [id]="config.name"
    [attr.rows]="config.rows || null"
    [attr.aria-describedby]="config.name + 'err'"
  ></textarea>

  <div libFormFieldError class="invalid-feedback" [fieldConfig]="config"></div>
</div>
