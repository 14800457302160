import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-document-preferences-page',
  templateUrl: 'document-preferences-page.component.html',
})
export class DocumentPreferencesPageComponent {
  public constructor(
    public location: Location,
    public router: Router,
  ) {}
}
