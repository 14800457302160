<app-mi-page-nav></app-mi-page-nav>
<div class="col-10 offset-1">
    <div *ngIf="miReports?.length > 1" class="row mt-3">
      <div class="col">
        <div class="float-end mb-0">
          <select name="powerbi-reports" id="powerbi-reports" class="form-select" (change)="reportChange($event.target.value)">
            <option value="{{ item.id }}" *ngFor="let item of miReports" [attr.data-testid]="'option_' + item.name">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <lib-powerbi
        *ngIf="embedConfig"
        type="report"
        [id]="embedConfig.id"
        [embedUrl]="embedConfig.embedUrl"
        [accessToken]="embedConfig.embedToken.token"
        [options]="{ filterPaneEnabled: false }"
      >
      </lib-powerbi>
    </div>
  </div>
