<div [formGroup]="formGroup" [class]="config.cssClass ? 'mb-4 ' + config.cssClass: 'form-group'">
  <label for="{{ config.name }}">
    {{ config.label }}
  </label>

  <ul class="list-group" [formArrayName]="config.name">
    <li class="list-group-item-check" *ngFor="let control of controls; let i = index">
      <div class="form-check">
        <input
          class="form-check-input"
          [formControlName]="i"
          type="checkbox"
          name="{{ config.name + i }}"
          id="{{ config.name + i }}"
          [value]="config.options[i].value"
          [checked]="config.options[i].checked"
          (change)="config.options[i].checked = $event.target.checked"
        />
        <label class="form-check-label" for="{{ config.name + i }}">
          {{ config.options[i].text }}
        </label>
      </div>
    </li>
  </ul>
  <div *ngIf="!validation && config.clicked" class="invalid-feedback">Please select one of the option above</div>
</div>
