import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { clientIds } from '@msslib/constants';
import { AuthorizeService, IUser, ModalService, NewFeatureService, UtilsService } from '@msslib/services';
import { appName } from '@msslib/constants/app-name';
import { ClientApps, NewFeatures } from '@msslib/models';
import { takeWhile } from 'rxjs';
import { ScriptVersionService } from '@msslib/services/script-version.service';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/ban-types
  interface Window { Genesys: Function }
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  public newFeatureOpened = false;
  public userCustomProductSettingsOpened = false;
  public constructor(
    private authService: AuthorizeService,
    private router: Router,
    private title: Title,
    @Inject(DOCUMENT) private document: Document,
    private utilService: UtilsService,
    private newFeatureService: NewFeatureService,
    private modalService: ModalService,
    public scriptVersionService: ScriptVersionService,
  ) { }

  @ViewChild('userCustomProductSettings') private userCustomProductSettings: ElementRef<HTMLElement>;
  public refreshNowAppBannerText = 'We have made some updates to Ignite.';

  public ngOnInit() {
    (window as any).appName = appName.clubHub;
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.setTitle();
  }

  public get isAffordability() {
    return this.router.isActive('/ignite/affordability', {
      paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored',
    });
  }

  public get isProducts() {
    return this.router.isActive('/ignite/products', {
      paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored',
    });
  }

  public get isCriteriaOutcomePage() {
    return /^\/criteria\/landing\/[0-9]{1}\/issues/.test(this.router.url);
  }

  public userCustomProductSettingsPopup() {
    this.authService.isAuthenticated().pipe(takeWhile((isAuthenticated) =>
      !this.userCustomProductSettingsOpened || !isAuthenticated))
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.userCustomProductSettingsOpened = true;
          setTimeout(() => {
            this.newFeatureService.checkIfNewFeatureHasBeenSeen(NewFeatures.UserCustomProductSettings)
              .subscribe((res) => {
                if (!res.seen) {
                  this.modalService.open({
                    title: 'My Account Updates',
                    template: this.userCustomProductSettings,
                    showButtons: true,
                    okLabel: 'Go to My Account',
                    cancelLabel: 'Continue to Ignite',
                    client: ClientApps.ClubHub,
                  }).then(
                    () => {
                      this.router.navigateByUrl('productpreferences');
                    },
                    () => null,
                  ).finally(() => {
                    this.newFeatureService.setUserHasSeenNewFeature(NewFeatures.UserCustomProductSettings).subscribe();
                  });
                }
              });
          }, 2000);
        }
      });
  }

  public ngAfterViewInit() {
    this.userCustomProductSettingsPopup();
    this.setupGenesys();
  }

  private setupGenesys() {
    setTimeout(() => {
      this.authService.getUser().subscribe((user: IUser) => {

        // SimplyBiz do not get the Genesys chat window
        if (user?.client_id === clientIds.simplyBiz) {
          return;
        }

        const script = this.document.createElement('script');
        const src = 'assets/js/genesys.js';
        script.type = 'text/javascript';
        script.src = src;
        if (!this.utilService.scriptExists(src)) {
          this.document.getElementsByTagName('head')[0].appendChild(script);
        }
      });
    }, 2000);
  }

  private setTitle() {
    this.authService.getUser().subscribe(() => {
      const title = 'ClubHub';
      this.title.setTitle(title);
    });
  }
}
