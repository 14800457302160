import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICardEvent } from '../../models';

@Component({
  selector: 'lib-card-deck',
  styleUrls: ['card-deck.component.scss'],
  templateUrl: 'card-deck.component.html',
})
export class CardDeckComponent {
  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public hasSidebar: boolean;
  @Input() public items: unknown[];
  @Input() public titleKey: string;
  @Input() public subtitleKey: string;
  @Input() public descriptionKey: string;
  @Input() public bodyTemplate: unknown;
  @Input() public footerTemplate: unknown;
  @Input() public showFooterArrow = true;
  @Input() public headerClass: unknown;
  @Output() public cardClicked = new EventEmitter<ICardEvent>();

  public hasMultipleBodyItems(description: string): boolean {
    return this.bodyItems(description).length > 1;
  }

  public selectCard($event: MouseEvent, item: unknown) {
    this.cardClicked.emit({ $event, data: item });
  }

  public bodyItems(description: string): string[] {
    if (!description) {
      return [];
    }
    return description.split(';').map((x) => x.trim());
  }
}
