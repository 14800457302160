
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormStateService {

  public hideGrossSalaryPreviousFields = true;
  public hideGrossDividendsPreviousFields = true;
  public hideNetProfitBeforeDividendsPreviousFields = true;

  public resetExpandedFields() {
    this.hideGrossSalaryPreviousFields = true;
    this.hideGrossDividendsPreviousFields = true;
    this.hideNetProfitBeforeDividendsPreviousFields = true;
  }

  public toggleExpandedField(variable: 'hideGrossSalaryPreviousFields' |
    'hideGrossDividendsPreviousFields' |
    'hideNetProfitBeforeDividendsPreviousFields',
  ) {
    this[variable] = !this[variable];
  }
}
