import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';

import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { NewFeatures } from '@msslib/models/new-features';
import { AuthorizeService } from '@msslib/services/authorize.service';

@Injectable({
  providedIn: 'root',
})
export class NewFeatureService {
  private featuresCache: Map<NewFeatures, boolean> = new Map<NewFeatures, boolean>();

  public constructor(private clubHubDataService: ClubHubDataService, private authService: AuthorizeService) {}

  public checkIfNewFeatureHasBeenSeen(feature: NewFeatures): Observable<{ seen: boolean }> {
    if (this.authService.isLoggedIn) {
      if (this.featuresCache.has(feature)) {
        return of({
          seen: !!this.featuresCache.get(feature),
        });
      }

      return this.clubHubDataService.get<{ seen: boolean }>(`NewFeature/${feature}`);
    }

    return of({ seen: true });
  }

  public setUserHasSeenNewFeature(feature: NewFeatures, cacheFeature = false): Observable<void> {
    if (this.authService.isLoggedIn) {
      return this.clubHubDataService.post<never>(`NewFeature/${feature}`, {}).pipe(
        tap(() => {
          if (cacheFeature) {
            this.featuresCache.set(feature, true);
          }
        }),
      );
    }

    return of();
  }
}
