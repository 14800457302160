<div class="modal-header">
  <h3 class="modal-title">
    {{ options.title }}
  </h3>
  <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="cancel()">
  </button>
</div>

<div class="modal-body" (scroll)="onScroll($event)">
  <lib-disclaimer-content-lenderhub *ngIf="isLenderHub"></lib-disclaimer-content-lenderhub>
  <lib-disclaimer-content-clubhub *ngIf="isClubHub"></lib-disclaimer-content-clubhub>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-2022--grey" (click)="cancel()">
    Cancel
  </button>
  <button type="button" class="btn btn-2022--secondary" (click)="yes()" [disabled]="isDisabled">
    Accept
  </button>
</div>
