<ng-template #intuitiveTooltip>
  <p class="ws-nowrap" *ngFor="let intuitiveCondition of intuitiveConditions">
    <strong>{{intuitiveCondition.type}}:</strong> when {{intuitiveCondition.condition}}
  </p>
</ng-template>
<span
  *ngIf="isIntuitive"
  class="badge intuitive-badge text-bg-2022--secondary-darkest"
  [ngbTooltip]="intuitiveTooltip"
  tooltipClass="intuitive-tooltip">
  Intuitive
</span>
