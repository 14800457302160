export const rioTooltipMessages = {
  annualNonEscalatingAnnuity:
    'Include total amount of income from all your client\'s annuities, if any have any death benefits ' +
    'paid to dependants after they die, add each annuity separately and state the percentage of the annuity ' +
    'that will continue to be paid to their dependants for each annuity.',
  annualEscalatingAnnuity: 'Include total amount of income from all your client\'s annuities, if any have any death ' +
    'benefits paid to dependants after their death, add each annuity separately and state the percentage of the ' +
    'annuity that will continue to be paid to their dependants for each annuity.',
  drawdownPot: 'To calculate the annual income equivalent from a drawdown facility please use 3.5% of the current ' +
    'fund value after the tax-free lump sum has been taken. A current statement (no older than 3 months) will ' +
    'need to be produced to evidence the fund value.',
  definedBenefitFinalSalaryPension: 'The income your client receives from a final salary, also known as a ' +
    'Defined Benefit Pension.',
  annualStatePension: 'The full annual state pension they\'re currently receiving. To be evidenced ' +
    'with DWP (Department of Work and Pension) letter issued within the last 12 months or the last ' +
    'three month\'s bank statements.',
  warWidowsPension: 'A pension paid to surviving spouses and partners of those who died as a result of their ' +
    'service before 6 April 2005.',
  warDisablementPension: 'The War Disablement Pension (WDP) is a payment made to people who\'ve been injured ' +
    'or disabled as a result of any service in Her Majesty\'s Armed Forces.',
  forecastDefinedBenefitFinalSalaryPension: 'Workplace pensions paying a set income based on the client\'s ' +
    'salary and number of years they\'ve worked for the company.  Evidenced with the latest company statement ' +
    're-valued to the customers normal/selected retirement age. To be dated ' +
    'within the last three months. If the client wants to retire earlier than the date on the statement, ' +
    'we will require one with the correct ' +
    'expected retirement date in case the scheme applies a reduction for early retirement.',
  definedContribution: 'Workplace pensions that are based on how much the client and their employer have ' +
    'contributed over the years. Evidenced with the latest company statement confirming the current fund ' +
    'value - dated within the last three months. To calculate ' +
    'the annual equivalent income use 75% of the Fund value and times by 3.5%.',
  expectedAnnualStatePension: 'Evidence required of a State Pension projection from GOV.UK ' +
    '(Check your State Pension forecast), with the client\'s name and address, dated in the last 12 months.',
  personalPension: 'Personal pensions are pension pots that your client has arranged themselves and ' +
    'decided how much to pay into and when.  Evidenced with the latest pension provider statement confirming ' +
    'the current fund value, dated within the last three months. To calculate the annual equivalent income ' +
    'use 75% of the fund value and times by 3.5%.',
  guaranteedInvestmentIncome: 'This is income that\'s guaranteed by either the UK government or an insurance ' +
    'company. Guaranteed investments like certificates ' +
    'of deposit, Life Insurance Investment, With-Profits and Investment bonds and treasury securities. ' +
    'Please add income based on what you received last year after tax.',
  nonGuaranteedInvestmentIncome: 'Interest income from corporate bonds and dividend income from stocks, ' +
    'for example, where you have two years record of these earnings. ' +
    'Please add income based on what you received last year after tax.',
  monthlySecuredLoans: 'Total payments for mortgages on second properties, including Buy to Let properties, ' +
    'or holiday homes (monthly). Any associated costs and outgoings ' +
    'other than the gross mortgage should be included in the other regular monthly payments. ' +
    'Don\'t include any mortgages or secured loans which are due to end within six ' +
    'months. For joint applications where there is a joint mortgage or secured loan only ' +
    'include the monthly mortgage/secured loan payment against applicant one. Where joint ' +
    'applicants, if either borrower is on another mortgage, this needs to be included here.',
  monthlyUnsecuredLoans: 'Total monthly payments for any unsecured lending including Hire Purchase. ' +
    'Don\'t include any unsecured loans which are due to end within six months. ' +
    'For joint applications where there is a joint commitment only include the monthly payment against applicant one.',
};
