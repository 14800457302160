<div
  class="input-group"
  [ngClass]="props.inputClass"
  [class.is-invalid]="showError"
  [class.disabled]="formControl.disabled"
>
  <span
    *ngIf="props.addonStart"
    class="input-group-text"
    [ngClass]="props.addonStartClass"
  >
    {{ props.addonStart }}
  </span>

  <input
    type="number"
    class="form-control"
    [formControl]="formControl"
    [placeholder]="props.placeholder"
    [formlyAttributes]="field"
    (keydown)="preventUnwanted($event)"
    [attr.data-testid]="props.testId"
  />

  <span
    *ngIf="props.addonEnd"
    class="input-group-text"
    [ngClass]="props.addonEndClass"
  >
    {{ props.addonEnd }}
  </span>
</div>
<div
  *ngIf="showError && props.groupedField"
  class="d-flex gap-3 align-items-center mt-2 alert alert-danger "
>
  <i class="fas fa-times-circle"></i>
  <span>
    <formly-validation-message [field]="field"></formly-validation-message>
  </span>
</div>
