<strong
  [ngbTooltip]="tooltip"
  placement="right"
  tooltipClass="custom-tooltip overrides-tooltip"
>
  <u>{{ format(currentValue) }}</u>
</strong>

<ng-template #tooltip>
  <div class="row">
    <div class="col-4 offset-8 text-center">
      <strong>{{ label ?? property }}</strong>
    </div>
  </div>

  <div class="row">
    <div class="col-8 text-start">Product</div>
    <div class="col-4 text-center">{{ format(baseValue) }}</div>
  </div>

  <ng-container *ngIf="overrides.length > 0">
    <hr class="my-2" />

    <div class="row">
      <div class="col-8 text-start">
        <strong>Criteria</strong>
      </div>
    </div>

    <div class="row" *ngFor="let override of overrides; trackBy: trackByLabel">
      <div class="col-8 text-start">{{ override.label }}</div>
      <div class="col-4 text-center">{{ format(override.value) }}</div>
    </div>
  </ng-container>

  <p class="mt-3 mb-0 text-center"><small>Click for more details</small></p>
</ng-template>
