<div [formGroup]="formGroup" [class]="config.cssClass ? 'mb-4 ' + config.cssClass: 'form-group'">
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      [formControlName]="config.name"
      [id]="config.name"
      [name]="config.name"
      [ngClass]="{ 'is-valid': valid, 'is-invalid': invalid }"
      [attr.data-testid]="config.name"
    />
    <label class="form-check-label" for="{{ config.name }}">
      {{ config.label }}
    </label>

    <div libFormFieldError class="invalid-feedback" [fieldConfig]="config"></div>
  </div>
</div>
