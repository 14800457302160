import { Component, Input } from '@angular/core';
import { IAccordionItem } from '../../models';

@Component({
  selector: 'lib-accordion',
  styleUrls: ['accordion.component.scss'],
  templateUrl: 'accordion.component.html',
})
export class AccordionComponent {
  @Input() public items: IAccordionItem[];
}
