import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { Observable, Subscription, of } from 'rxjs';
import { ResultTemplateContext } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead-window';

@Component({
  selector: 'lib-search-input-typeahead',
  styleUrls: ['search-input-typeahead.component.scss'],
  templateUrl: 'search-input-typeahead.component.html',
})
export class SearchInputTypeaheadComponent implements OnInit, OnDestroy {
  @Input() public id: string;
  @Input() public key: string;
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public model = '';
  @Input() public showClearButton: boolean;
  @Input() public initialData: unknown[];
  @Input() public staticAction: (arg0: string) => string | unknown[];
  @Input() public dynamicAction: any;
  @Input() public resultTemplate: TemplateRef<ResultTemplateContext>;
  @Output() public selectItem = new EventEmitter<unknown>();
  @Output() public clearSearch = new EventEmitter<unknown>();

  public subscription: Subscription;
  public debounceTime = 300;
  // https://github.com/ng-bootstrap/ng-bootstrap/issues/1119
  public listSize = 10;
  public formatter = (result: Record<string, unknown>) => result[this.key] || this.model;

  public ngOnInit(): void {
    if (this.model) {
      this.subscription = this.search(of(this.model)).subscribe();
    }
  }

  public search = (text$: Observable<string>) => {
    const textObservable = text$.pipe(
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
    );

    if (this.staticAction) {
      return textObservable.pipe(
        map(term => {
          return term.length ? this.staticAction(term).slice(0, this.listSize) : this.staticAction(term).slice(0, 0);
        }),
      );
    } else if (this.dynamicAction) {
      return textObservable.pipe(
        switchMap(term => {
          return this.dynamicAction(term).pipe(
            map(x => (x as any).slice(0, this.listSize)),
            catchError(() => {
              return of([]);
            }),
          );
        }),
      );
    }

    throw Error('Provide either "staticAction" OR "dynamicAction" to perform search');
  };

  public onSelectItem(ngbItem: NgbTypeaheadSelectItemEvent) {
    this.selectItem.next(ngbItem.item);
  }

  public clear = () => {
    this.model = '';
    if (this.staticAction) {
      this.staticAction('');
    }
    if (this.dynamicAction) {
      this.dynamicAction('').subscribe();
    }
    this.clearSearch.emit();
  };

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
