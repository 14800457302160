import { Inject, Injectable } from '@angular/core';
import { BasketService } from 'apps/clubhub/src/app/services/basket.service';
import { ClubHubDataService, LendingTypeService } from '@msslib/services';
import {
  OutcomeDetails,
  OutcomeResponse,
} from 'apps/shared/src/models';
import {
  Observable,
  forkJoin,
  of,
} from 'rxjs';
import {
  ProductsBtlModelRequest,
  ProductsResidentialModelRequest,
} from 'apps/clubhub/src/app/ignite/models/products';
import {
  tap,
} from 'rxjs/operators';
import { CriteriaV2OutcomeService } from '../pages/criteria-v2/services';
import { CriteriaService } from './criteria.service';
import { appName } from '@msslib/constants/app-name';
import { CriteriaSwitcherService } from 'apps/clubhub/src/app/ignite/services/criteria-switcher.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsIntuitiveOutcomeService {
  public criteriaLoadingInProgress: boolean;
  public criteriaSearchId: string | null;

  public constructor(
    @Inject(BasketService) private basketService,
    @Inject(ClubHubDataService) private dataService: ClubHubDataService,
    @Inject(LendingTypeService) private lendingTypeService,
    private criteriaSwitcherService: CriteriaSwitcherService,
    private criteriaService: CriteriaService,
    private criteriaV2OutcomeService: CriteriaV2OutcomeService,
  ) { }

  public getIntuitiveOutcome(
    productsModelRequest: ProductsResidentialModelRequest | ProductsBtlModelRequest,
  ): Observable<any> {
    return this.criteriaSwitcherService.criteriaV2Enabled
      ? this.getProductsIntuitiveOutcomesV2(productsModelRequest)
      : this.getProductsIntuitiveOutcomesV1(productsModelRequest);
  }

  private get lendingTypeId() {
    return this.lendingTypeService.lendingType?.id ?? -1;
  }

  private getProductsLendersOutcomes(
    productsModelRequest: ProductsResidentialModelRequest | ProductsBtlModelRequest,
  ): Observable<OutcomeDetails> {
    const allOutcomeIds = [
      ...this.basketService.answerIds,
    ];
    const distinctIds = [...new Set(allOutcomeIds)];
    return allOutcomeIds.length > 0
      ? this.dataService.post<OutcomeDetails>('Product/GetProductsLendersOutcomes', {
        outcomeIds: distinctIds,
        appName: appName.criteria,
        productsSearchId: productsModelRequest.productsSearchId,
      })
      : of({
        dateTime: '',
        contexts: [],
        details: [],
        lendingType: '',
        autoAddedOutcomeIds: [],
        criteriaSearchId: null,
      });
  }

  private getProductsIntuitiveOutcomesV1(productsModelRequest: any): Observable<any> {
    this.criteriaLoadingInProgress = true;
    return forkJoin([
      this.dataService.post<OutcomeResponse[]>('Product/GetIntuitiveOutcomes', productsModelRequest),
      this.getProductsLendersOutcomes(productsModelRequest),
    ]).pipe(
      tap(([intuitiveOutcomes, criteriaOutcomes]: [OutcomeResponse[], OutcomeDetails]) => {
        this.criteriaSearchId = criteriaOutcomes.criteriaSearchId;
        const intuitiveContextCards = intuitiveOutcomes?.length
          ? intuitiveOutcomes[0].outcomeContextCards ?? []
          : [];
        this.criteriaService.outcomeDetails.details = criteriaOutcomes.details;

        let contexts = criteriaOutcomes.contexts;
        if (contexts?.length > 0 && intuitiveContextCards?.length > 0) {
          // remove duplicates
          contexts = contexts.filter(c => intuitiveContextCards.every(ic => ic.answerId !== c.answerId));
        }
        this.criteriaService.outcomeDetails.contexts =
            [...contexts, ...intuitiveContextCards];

        this.criteriaLoadingInProgress = false;
      }),
    );
  }

  private getProductsIntuitiveOutcomesV2(productsModelRequest): Observable<any> {
    this.criteriaLoadingInProgress = true;
    return this.criteriaV2OutcomeService.getProductsIntuitiveOutcome(this.lendingTypeId, productsModelRequest)
      .pipe(tap((criteriaOutcomes) => {
        this.criteriaSearchId = criteriaOutcomes.criteriaSearchId;
        this.criteriaLoadingInProgress = false;
      }));
  }
}
