import {
  ApplicantCountry,
  BenefitIncomeType,
  BtlType,
  ContractType,
  EmploymentStatus,
  ExpenditureForSubjectPropertyType,
  Frequency,
  HolidayLetFrequency,
  LendingTypeCode,
  LoanSecurity,
  MethodOfRepayment,
  MortgageType,
  OtherIncomeType,
  ProductLength,
  ProductType,
  PropertyType,
  PropertyUse,
  PurchaserType,
  RemortgageReason,
  RemortgageType,
  RepaymentMethod,
  RepaymentVehicle,
  TaxStatusBtl,
  UkLocation,
  WorkRelatedIncomeType,
  WorkRelatedIncomeTypeBtl,
} from 'apps/shared/src/models';
import {
  IPropertyBtl,
  IRio,
  IUiAffordabilityModel,
  IUiApplicant,
  IUiApplicantAge,
  IUiMainIncome,
  IUiOtherMortgage,
  IUiYearMonth,
} from './affordability-ui';
import { IDateInput } from '@msslib/components/formly/models';
import { mapDateToUtc } from '@msslib/helpers';
import { UntypedFormGroup } from '@angular/forms';

function getTotalMonths(yearMonths: IUiYearMonth | undefined): number {
  return !yearMonths ? 0 : yearMonths.years * 12 + yearMonths.months;
}

function mapIncome<T, TReturn>(
  options: { option: { category: T; amount: number; frequency: Frequency } }[],
): TReturn[] {
  return options
    .map((x) => x.option)
    .map(
      (option: { category: T; amount: number; frequency: Frequency }) =>
        ({
          category: option.category,
          amount: option.amount,
          frequency: option.frequency,
        } as any),
    );
}

export interface OtherMortgage {
  propertyUse?: PropertyUse;
  propertyValue?: number;
  totalMortgageBalance?: number;
  repaymentMethod?: RepaymentMethod;
  repaymentBalance?: number;
  interestOnlyBalance?: number;
  remainingTermYears?: number;
  remainingTermMonths?: number;
  monthlyMortgagePayments: number;
  monthlyUtilities?: number;
  monthlyCouncilTax?: number;
  interestRate?: number;
  tenancyInPlace?: boolean;
  grossMonthlyRental?: number;
}

export interface OtherMortgageBtl {
  totalBTLMortgageBalanceOutstanding?: number;
  monthlyBTLMortgageRepayment?: number;
  repaymentMethod?: RepaymentMethod;
  repaymentVehicle?: RepaymentVehicle;
  equityRemainsInProperty?: number;
  amountInterestOnly?: number;
  repaymentAmount?: number;
  interestRate?: number;
  mortgageTermYears?: number;
  mortgageTermMonths?: number;
  rentalAmountReceived?: number;
}

export class MainIncome {
  public employmentStatus: EmploymentStatus;
  public shareholding: number;
  public contractType?: ContractType;
  public treatedAsEmployee?: boolean;
  public timeInCurrentJobMonths?: number;
  public timeRemainingOnContractMonths?: number;
  public grossAnnualIncome: number;
  public netProfitForLatestPeriod?: number;
  public netProfitForPreviousPeriod?: number;
  public netProfitForThirdPeriod?: number;
  public grossSalaryForLatestPeriod?: number;
  public grossSalaryForPreviousPeriod?: number;
  public grossSalaryForThirdPeriod?: number;
  public grossDividendsForLatestPeriod?: number;
  public grossDividendsForPreviousPeriod?: number;
  public grossDividendsForThirdPeriod?: number;
  public netProfitBeforeDividendsForLatestPeriod?: number;
  public netProfitBeforeDividendsForPreviousPeriod?: number;
  public netProfitBeforeDividendsForThirdPeriod?: number;
  public previousAnnualIncome?: number | null;
  public static mapMainIncome(
    mainIncome: IUiMainIncome,
    affordabilityType: LendingTypeCode,
  ) {
    const income = {
      employmentStatus: mainIncome.employmentStatus,
      shareholding: mainIncome.shareholding,
      contractType: mainIncome.contractType,
      treatedAsEmployee: mainIncome.treatedAsEmployee,
      timeInCurrentJobMonths: getTotalMonths(mainIncome.timeInCurrentJobMonths),
      timeRemainingOnContractMonths: getTotalMonths(mainIncome.timeRemainingOnContractMonths),
      grossAnnualIncome: mainIncome.grossAnnualIncome,
      netProfitForLatestPeriod: mainIncome.netProfitForLatestPeriod,
      netProfitForPreviousPeriod: mainIncome.netProfitForPreviousPeriod,
      netProfitForThirdPeriod: mainIncome.netProfitForThirdPeriod,
      grossSalaryForLatestPeriod: mainIncome.grossSalaryForLatestPeriod,
      grossSalaryForPreviousPeriod: mainIncome.grossSalaryForPreviousPeriod,
      grossSalaryForThirdPeriod: mainIncome.grossSalaryForThirdPeriod,
      grossDividendsForLatestPeriod: mainIncome.grossDividendsForLatestPeriod,
      grossDividendsForPreviousPeriod: mainIncome.grossDividendsForPreviousPeriod,
      grossDividendsForThirdPeriod: mainIncome.grossDividendsForThirdPeriod,
      netProfitBeforeDividendsForLatestPeriod: mainIncome.netProfitBeforeDividendsForLatestPeriod,
      netProfitBeforeDividendsForPreviousPeriod: mainIncome.netProfitBeforeDividendsForPreviousPeriod,
      netProfitBeforeDividendsForThirdPeriod: mainIncome.netProfitBeforeDividendsForThirdPeriod,
    } as MainIncome;

    if (affordabilityType === LendingTypeCode.Btl) {
      income.previousAnnualIncome = mainIncome.previousAnnualIncome;
    }

    return income;
  }
}

export class WorkRelatedIncome {
  public category: WorkRelatedIncomeType;
  public amounts: number[];
  public frequency: Frequency;
  public static mapWorkRelatedIncome(applicant: IUiApplicant) {
    const workRelatedIncomes: WorkRelatedIncome[] = [];
    const mainIncomeTypes: WorkRelatedIncome[] = [
      {
        category: WorkRelatedIncomeType.Bonus,
        frequency: applicant[1],
        amounts: applicant['1Amounts'],
      },
      {
        category: WorkRelatedIncomeType.Commission,
        frequency: applicant[2],
        amounts: applicant['2Amounts'],
      },
      {
        category: WorkRelatedIncomeType.Overtime,
        frequency: applicant[3],
        amounts: applicant['3Amounts'],
      },
    ];

    workRelatedIncomes.push(
      ...mainIncomeTypes.filter((x) => x.frequency),
      ...(applicant.workAdditionalIncome
        ?.map((x) => x.option)
        .map(
          (option) =>
            ({
              category: option.category,
              amounts: [option.amount],
              frequency: option.frequency,
            } as WorkRelatedIncome),
        ) ?? []
      ),
    );

    return workRelatedIncomes;
  }
}

export class WorkRelatedBtlIncome {
  public category: WorkRelatedIncomeTypeBtl;
  public amounts: number[];
  public frequency: Frequency;
  public static mapWorkRelatedBtlIncome(applicant: IUiApplicant) {
    const workRelatedIncomes: WorkRelatedBtlIncome[] = [];
    const mainIncomeTypes: WorkRelatedBtlIncome[] = [
      {
        category: WorkRelatedIncomeTypeBtl.Bonus,
        frequency: applicant[1],
        amounts: applicant['1Amounts'],
      },
      {
        category: WorkRelatedIncomeTypeBtl.Commission,
        frequency: applicant[2],
        amounts: applicant['2Amounts'],
      },
      {
        category: WorkRelatedIncomeTypeBtl.Overtime,
        frequency: applicant[3],
        amounts: applicant['3Amounts'],
      },
    ];

    workRelatedIncomes.push(
      ...mainIncomeTypes.filter((x) => x.frequency),
      ...(applicant.workAdditionalBtlIncome
        ?.map((x) => x.option)
        .map(
          (option) =>
            ({
              category: option.category,
              amounts: [option.amount],
              frequency: option.frequency,
            } as WorkRelatedBtlIncome),
        ) ?? []
      ),
    );

    return workRelatedIncomes;
  }
}

export interface BenefitIncome {
  category: BenefitIncomeType;
  amount: number;
  frequency: Frequency;
}

export interface OtherIncome {
  category: OtherIncomeType;
  amount: number;
  frequency: Frequency;
}

export enum AffordabilityServiceLoadingViewMode {
  None = 0,
  Full = 1,
  CriteriaOnly = 2,
}

export interface OtherExpenditure {
  category: ExpenditureForSubjectPropertyType;
  amount: number;
  frequency: Frequency;
}

export interface Applicant {
  applicantNumber: number;
  dateOfBirth: Date | string;
  plannedRetirementAge: number;
  primaryIncome?: MainIncome;
  secondaryIncome?: MainIncome;
  workAdditionalIncome?: WorkRelatedIncome[];
  pensionAmount: number;
  benefitAdditionalIncome?: BenefitIncome[];
  otherAdditionalIncome?: OtherIncome[];
  creditCardBalance: number;
  creditCardBalanceToRepay: number;
  totalMonthlyCreditPayments: number;
  otherCommittedExpenditure: number;
  studentLoanContributions?: number;
  pensionContributions?: number;
  householdExpenses?: number;
  gasAndElectricity?: number;
  water?: number;
  tvAndInternet?: number;
  mobileAndLandlines?: number;
  councilTax?: number;
  buildingsAndContentsInsurance?: number;
  groundRentAndServiceCharge?: number;
  lifeAssuranceAndPrivateHealthPremiums: number;
  travel: number;
  childcareAndSchoolCollegeUniversityFees: number;
  childMaintenance: number;
  carUpkeep: number;
  personalEssentials: number;
  recreational: number;
  other: number;
  rio?: IRio;
}

export interface BtlApplicant {
  applicantNumber: number;
  dateOfBirth: Date | string;
  countryResident: ApplicantCountry;
  grossAnnualIncome: number;
  grossAnnualIncomeUkLandProperty: number;
  taxStatus: TaxStatusBtl;
  // Top Slicing
  primaryIncome?: MainIncome;
  workAdditionalBtlIncome?: WorkRelatedBtlIncome[];
  pensionAmount?: number;
  benefitAdditionalIncome?: BenefitIncome[];
  otherAdditionalIncome?: OtherIncome[];
  creditCardBalance?: number;
  creditCardBalanceToRepay?: number;
  totalMonthlyCreditPayments?: number;
  studentLoanContributions?: number;
  pensionContributions?: number;
  householdExpenses?: number;
  gasAndElectricity?: number;
  water?: number;
  tvAndInternet?: number;
  mobileAndLandlines?: number;
  councilTax?: number;
  buildingsAndContentsInsurance?: number;
  groundRentAndServiceCharge?: number;
  lifeAssuranceAndPrivateHealthPremiums?: number;
  travel?: number;
  childcareAndSchoolCollegeUniversityFees?: number;
  childMaintenance?: number;
  carUpkeep?: number;
  personalEssentials?: number;
  recreational?: number;
  other?: number;
  monthlyRentForResidentialProperty?: number;
  groundRentServiceCharge?: number;
  otherCommittedExpenditure?: number;
  repaymentVehicle?: number;
}

export class BdgAffordabilityRequest {
  public affordabilitySearchId: string | null;
  public pollJobId: number | null | undefined;
  public productType: LendingTypeCode;
  public regulated: boolean;
  public propertyValue?: number;
  public loanAmount?: number;
  public loanSecurity?: LoanSecurity;
  public currentMortgageBalanceOutstanding?: number;
  public methodOfRepayment?: MethodOfRepayment;
  public loanTerm?: number;
  public limitedCompany?: boolean;
  public repaymentMethod?: RepaymentMethod;
  public mortgageTermYears?: number;
  public mortgageTermMonths?: number;
  public productLength?: number;
  public propertyType?: PropertyType;
  public applicants?: Applicant[];
  public outcomeIds?: number[];
  public interestOnlyAmount: number;
}

export class AffordabilityRequest {
  public affordabilitySearchId: string | null;
  public pollJobId: number | null | undefined;
  public productType: LendingTypeCode;
  public productTypeExtended: ProductType;
  public outcomeIds: number[];
  public numberOfApplicants: number;
  public numberOfIncomesNeeded: number;
  public dependantsAged0To5: number;
  public dependantsAged6To11: number;
  public dependantsAged12To17: number;
  public dependantsAged18AndOver: number;
  public applicants: Applicant[];
  public location: UkLocation;
  public propertyType: PropertyType;
  public newBuild: boolean;
  public mortgageType: MortgageType;
  public purchaserType: PurchaserType;
  public capitalRaising?: boolean;
  public amountTransferredFromOtherLender: number;
  public remortgageReason?: RemortgageReason;
  public propertyValue: number;
  public loanAmount: number;
  public helpToBuy: boolean;
  public equityValue: number;
  public equityLoanValue: number;
  public equityLoanStartDate: Date | string;
  public repaymentMethod: RepaymentMethod;
  public repaymentVehicle?: RepaymentVehicle;
  public interestOnlyAmount?: number;
  public mortgageTermYears: number;
  public mortgageTermMonths: number;
  public productLength: ProductLength;
  public otherMortgages?: OtherMortgage[];
  public currentMortgageLender?: string;
  public productTransferInitialDate?: Date | string;
  public static mappedModelResi(
    uiModel: IUiAffordabilityModel,
    rioForm: UntypedFormGroup | null = null,
    newGuid: string,
    allOutcomeIds: number[],
    currentMortgageLender: string | null,
    getDateOrNull: { (dateInput: IDateInput | undefined): Date | null; (arg0: IDateInput | undefined): any },
    mapMainIncome: { (mainIncome: IUiMainIncome, affordabilityType: LendingTypeCode): MainIncome },
  ) {
    const {
      propertyAndLoan: propertyAndLoanStep,
      applicants: applicantsStep,
      incomeAndExpenditure: incomeAndExpenditureStep,
      otherMortgages,
      mortgages,
    } = uiModel;

    const rioApplicants = rioForm?.value?.applicants as IRio[];
    return {
      affordabilitySearchId: newGuid,
      pollJobId: -1,
      productType: LendingTypeCode.Res,
      outcomeIds: allOutcomeIds,
      currentMortgageLender: currentMortgageLender,
      productTransferInitialDate: getDateOrNull(propertyAndLoanStep.productTransferInitialDate),
      location: propertyAndLoanStep.location,
      propertyType: propertyAndLoanStep.propertyType,
      newBuild: propertyAndLoanStep.newBuild,
      mortgageType: propertyAndLoanStep.mortgageType,
      productTypeExtended: propertyAndLoanStep.productTypeExtended,
      purchaserType: propertyAndLoanStep.purchaserType,
      capitalRaising: propertyAndLoanStep.capitalRaising?.value,
      amountTransferredFromOtherLender: propertyAndLoanStep.amountTransferredFromOtherLender,
      remortgageReason: propertyAndLoanStep.remortgageReason,
      remortgageType: propertyAndLoanStep.remortgageType
        ?? propertyAndLoanStep.capitalRaising?.value ? RemortgageType.CapitalRaising : null,
      propertyValue: propertyAndLoanStep.propertyValue,
      loanAmount: propertyAndLoanStep.loanAmount,
      helpToBuy: !!propertyAndLoanStep.helpToBuy,
      equityLoanValue: propertyAndLoanStep.helpToBuy ?
        propertyAndLoanStep.equityLoanValue : 0,
      equityLoanStartDate: propertyAndLoanStep.helpToBuy
        ? mapDateToUtc(propertyAndLoanStep.equityLoanStartDate)
        : undefined,
      equityValue: propertyAndLoanStep.equityValue,
      repaymentMethod: propertyAndLoanStep.repaymentMethod?.value,
      repaymentVehicle: propertyAndLoanStep.repaymentVehicle,
      interestOnlyAmount: propertyAndLoanStep.interestOnlyAmount,
      mortgageTermYears: propertyAndLoanStep.mortgageTerm.years,
      mortgageTermMonths: propertyAndLoanStep.mortgageTerm.months,
      productLength: propertyAndLoanStep.productLength,
      numberOfApplicants: applicantsStep.numberOfApplicants,
      numberOfIncomesNeeded: applicantsStep.numberOfIncomesNeeded ?? applicantsStep.numberOfApplicants,
      dependantsAged0To5: applicantsStep.dependantsAged0To5,
      dependantsAged6To11: applicantsStep.dependantsAged6To11,
      dependantsAged12To17: applicantsStep.dependantsAged12To17,
      dependantsAged18AndOver: applicantsStep.dependantsAged18AndOver,
      applicants: applicantsStep.applicantAges?.map((applicant: IUiApplicantAge, index: number) => {
        const applicantIncome = incomeAndExpenditureStep?.applicants?.[index];
        return {
          applicantNumber: index + 1,
          dateOfBirth: applicant.dateOfBirth ? mapDateToUtc(applicant.dateOfBirth) : null,
          plannedRetirementAge: applicant.plannedRetirementAge,
          primaryIncome: applicantIncome?.primaryIncome
            ? mapMainIncome(applicantIncome.primaryIncome, LendingTypeCode.Res)
            : null,
          secondaryIncome: applicantIncome?.secondaryIncome && applicantIncome?.secondJob
            ? mapMainIncome(applicantIncome.secondaryIncome, LendingTypeCode.Res)
            : null,
          workAdditionalIncome: applicantIncome?.workAdditionalIncome
            ? WorkRelatedIncome.mapWorkRelatedIncome(applicantIncome)
            : null,
          pensionAmount: applicantIncome?.pensionIncome?.amount,
          benefitAdditionalIncome: applicantIncome?.benefitAdditionalIncome
            ? mapIncome<BenefitIncomeType, BenefitIncome>(applicantIncome.benefitAdditionalIncome)
            : null,
          otherAdditionalIncome: applicantIncome?.otherAdditionalIncome
            ? mapIncome<OtherIncomeType, OtherIncome>(applicantIncome.otherAdditionalIncome)
            : null,
          creditCardBalance: applicantIncome?.creditCardBalance,
          creditCardBalanceToRepay: applicantIncome?.creditCardBalanceToRepay,
          totalMonthlyCreditPayments: applicantIncome?.totalMonthlyCreditPayments,
          studentLoanContributions: applicantIncome?.studentLoanContributions,
          pensionContributions: applicantIncome?.pensionContributions,
          householdExpenses: applicantIncome?.householdExpenses,
          gasAndElectricity: applicantIncome?.gasAndElectricity,
          water: applicantIncome?.water,
          tvAndInternet: applicantIncome?.tvAndInternet,
          mobileAndLandlines: applicantIncome?.mobileAndLandlines,
          councilTax: applicantIncome?.councilTax,
          buildingsAndContentsInsurance: applicantIncome?.buildingsAndContentsInsurance,
          lifeAssuranceAndPrivateHealthPremiums: applicantIncome?.lifeAssuranceAndPrivateHealthPremiums,
          groundRentAndServiceCharge: applicantIncome?.groundRentAndServiceCharge,
          travel: applicantIncome?.travel,
          childcareAndSchoolCollegeUniversityFees: applicantIncome?.childcareAndSchoolCollegeUniversityFees,
          childMaintenance: applicantIncome?.childMaintenance,
          carUpkeep: applicantIncome?.carUpkeep,
          personalEssentials: applicantIncome?.personalEssentials,
          recreational: applicantIncome?.recreational,
          other: applicantIncome?.other,
          rio: rioApplicants ? rioApplicants[index] : null,
        } as Applicant;
      }),
      otherMortgages: otherMortgages
        ? mortgages.map(
          (m: IUiOtherMortgage) =>
            ({
              propertyUse: m.propertyUse,
              propertyValue: m.propertyValue,
              totalMortgageBalance: m.totalMortgageBalance,
              repaymentMethod: m.repaymentMethod,
              repaymentBalance: m.repaymentBalance,
              interestOnlyBalance: m.interestOnlyBalance,
              remainingTermYears: m.remainingTermOfLoan?.years,
              remainingTermMonths: m.remainingTermOfLoan?.months,
              monthlyMortgagePayments: m.monthlyMortgagePayments,
              monthlyUtilities: m.monthlyUtilities,
              monthlyCouncilTax: m.monthlyCouncilTax,
              interestRate: m.interestRate,
              tenancyInPlace: m.tenancyInPlace,
              grossMonthlyRental: m.grossMonthlyRental,
            } as OtherMortgage),
        )
        : [],
    } as AffordabilityRequest;
  }
}

export class BtlAffordabilityRequest {
  // STEP 1: Property & Loan
  public affordabilitySearchId: string | null;
  public pollJobId: number | null | undefined;
  public productType: LendingTypeCode;
  public productTypeExtended: ProductType;
  public outcomeIds: number[];
  public location: UkLocation;
  public propertyType: PropertyType;
  public newBuild: boolean;
  public mortgageType: MortgageType;
  public firstTimeBuyer?: boolean;
  public firstTimeLandlord?: boolean;
  public ownerOccupier?: boolean;
  public remortgageType?: RemortgageType;
  public amountTransferredFromOtherLender?: number;
  public propertyValue: number;
  public loanAmount: number;
  public equityLoanValue: number;
  public remortgageReason?: RemortgageReason;
  public repaymentMethod: RepaymentMethod;
  public repaymentVehicle?: RepaymentVehicle;
  public equityValue?: number;
  public interestOnlyAmount?: number;
  public rentalAmountReceivedExpected: number;
  public lettingAgentUsed: boolean;
  public monthlyLettingAgentFee?: number;
  public otherExpenditure?: OtherExpenditure[];
  public mortgageTermYears: number;
  public mortgageTermMonths: number;
  public productLength: ProductLength;
  public btlType: BtlType;
  // holiday let income
  public holidayLetIncomeLow: number;
  public holidayLetIncomeMedium: number;
  public holidayLetIncomeHigh: number;
  public holidayLetIncomeFrequency: HolidayLetFrequency;

  public limitedCompanyPurchase: boolean;
  public limitedCompanySpecialPurposeVehicle: boolean;
  public portfolioLandlord: boolean;
  public numberOfProperties?: number;
  public numberOfBedrooms?: number;
  public numberOfBlocks?: number;
  public propertySelfContainedFlats?: boolean;
  public totalGrossMonthlyRent?: number;
  public totalPortfolioMortgageBalance?: number;
  public totalPortfolioValuation?: number;
  public currentMortgageLender?: string;
  public productTransferInitialDate?: Date | string;
  // STEP 2: Applicants
  public numberOfApplicants: number;
  public applicants: BtlApplicant[];
  // TOP SLICING
  public topSlicing: boolean;
  public dependantsAged0To5: number;
  public dependantsAged6To11: number;
  public dependantsAged12To17: number;
  public dependantsAged18AndOver: number;

  //Other Properties
  public otherMortgages?: OtherMortgage[];
  public otherMortgagesBtl?: OtherMortgageBtl[];
  public static mappedModelBtl(
    uiModel: IUiAffordabilityModel,
    newGuid: string,
    allOutcomeIds: number[],
    currentMortgageLender: string | null,
    getDateOrNull: { (dateInput: IDateInput | undefined): Date | null; (arg0: IDateInput | undefined): any },
    mapMainIncome: { (mainIncome: IUiMainIncome, affordabilityType: LendingTypeCode): MainIncome },
  ) {
    const {
      propertyAndLoan: propertyAndLoanStep,
      applicants: applicantsStep,
      incomeAndExpenditure: incomeAndExpenditureStep,
      otherProperties,
    } = uiModel;

    return {
      affordabilitySearchId: newGuid,
      pollJobId: -1,
      productType: LendingTypeCode.Btl,
      outcomeIds: allOutcomeIds,
      currentMortgageLender: currentMortgageLender,
      productTransferInitialDate: getDateOrNull(propertyAndLoanStep.productTransferInitialDate),
      location: propertyAndLoanStep.location,
      propertyType: propertyAndLoanStep.propertyType,
      productLength: propertyAndLoanStep.productLength,
      newBuild: propertyAndLoanStep.newBuild,
      mortgageType: propertyAndLoanStep.mortgageType,
      productTypeExtended: ProductType.Standard, //We default to standard as there are no other option for BTL
      firstTimeBuyer: propertyAndLoanStep.firstTimeBuyer,
      firstTimeLandlord: propertyAndLoanStep.firstTimeLandlord,
      ownerOccupier: propertyAndLoanStep.ownerOccupier,
      remortgageType: propertyAndLoanStep.remortgageType,
      amountTransferredFromOtherLender: propertyAndLoanStep.amountTransferredFromOtherLender,
      remortgageReason: propertyAndLoanStep.remortgageReason,
      propertyValue: propertyAndLoanStep.propertyValue,
      loanAmount: propertyAndLoanStep.loanAmount,
      equityLoanValue: propertyAndLoanStep.equityLoanValue,
      equityValue: propertyAndLoanStep.equityValue,
      repaymentMethod: propertyAndLoanStep.repaymentMethod?.value,
      repaymentVehicle: propertyAndLoanStep.repaymentVehicle,
      interestOnlyAmount: propertyAndLoanStep.interestOnlyAmount,
      rentalAmountReceivedExpected: propertyAndLoanStep.rentalAmountReceivedExpected,
      lettingAgentUsed: propertyAndLoanStep.lettingAgentUsed,
      otherExpenditure: propertyAndLoanStep.otherExpenditure
        ? mapIncome(propertyAndLoanStep.otherExpenditure)
        : null,
      monthlyLettingAgentFee: propertyAndLoanStep.monthlyLettingAgentFee,
      mortgageTermYears: propertyAndLoanStep.mortgageTerm.years,
      mortgageTermMonths: propertyAndLoanStep.mortgageTerm.months,
      btlType: propertyAndLoanStep.btlType,
      holidayLetIncomeLow: propertyAndLoanStep.holidayLetIncome?.holidayLetIncomeLow,
      holidayLetIncomeMedium: propertyAndLoanStep.holidayLetIncome?.holidayLetIncomeMedium,
      holidayLetIncomeHigh: propertyAndLoanStep.holidayLetIncome?.holidayLetIncomeHigh,
      holidayLetIncomeFrequency: propertyAndLoanStep.holidayLetIncome?.holidayLetIncomeFrequency,
      limitedCompanyPurchase: propertyAndLoanStep.limitedCompanyPurchase,
      portfolioLandlord: propertyAndLoanStep.portfolioLandlord,
      numberOfProperties: propertyAndLoanStep.numberOfProperties,
      numberOfBedrooms: propertyAndLoanStep.numberOfBedrooms,
      numberOfBlocks:  propertyAndLoanStep.numberOfBlocks,
      propertySelfContainedFlats: propertyAndLoanStep.propertySelfContainedFlats,
      limitedCompanySpecialPurposeVehicle: propertyAndLoanStep.limitedCompanySpecialPurposeVehicle,
      totalPortfolioMortgageBalance: propertyAndLoanStep.totalPortfolioMortgageBalance,
      totalPortfolioValuation: propertyAndLoanStep.totalPortfolioValuation,
      totalGrossMonthlyRent: propertyAndLoanStep.totalGrossMonthlyRent,
      topSlicing: applicantsStep.topSlicing,
      numberOfApplicants: applicantsStep.numberOfApplicants,
      dependantsAged0To5: applicantsStep.dependantsAged0To5,
      dependantsAged6To11: applicantsStep.dependantsAged6To11,
      dependantsAged12To17: applicantsStep.dependantsAged12To17,
      dependantsAged18AndOver: applicantsStep.dependantsAged18AndOver,
      applicants: applicantsStep.applicantAges?.map((applicant: IUiApplicantAge, index: number) => {
        const applicantIncome = incomeAndExpenditureStep?.applicants?.[index];
        return {
          applicantNumber: index + 1,
          dateOfBirth: applicant.dateOfBirth ? mapDateToUtc(applicant.dateOfBirth) : null,
          countryResident: applicant.countryResident,
          grossAnnualIncome: applicant.grossAnnualIncome,
          grossAnnualIncomeUkLandProperty: applicant.grossAnnualIncomeUkLandProperty,
          taxStatus: applicant.taxStatusBtl,
          primaryIncome: applicantIncome?.primaryIncome
            ? mapMainIncome(applicantIncome.primaryIncome, LendingTypeCode.Btl)
            : null,
          workAdditionalBtlIncome: applicantIncome?.workAdditionalBtlIncome
            ? WorkRelatedBtlIncome.mapWorkRelatedBtlIncome(applicantIncome)
            : null,
          pensionAmount: applicantIncome?.pensionIncome?.amount,
          benefitAdditionalIncome: applicantIncome?.benefitAdditionalIncome
            ? mapIncome<BenefitIncomeType, BenefitIncome>(applicantIncome.benefitAdditionalIncome)
            : null,
          otherAdditionalIncome: applicantIncome?.otherAdditionalIncome
            ? mapIncome<OtherIncomeType, OtherIncome>(applicantIncome.otherAdditionalIncome)
            : null,
          creditCardBalance: applicantIncome?.creditCardBalance,
          creditCardBalanceToRepay: applicantIncome?.creditCardBalanceToRepay,
          totalMonthlyCreditPayments: applicantIncome?.totalMonthlyCreditPayments,
          studentLoanContributions: applicantIncome?.studentLoanContributions,
          pensionContributions: applicantIncome?.pensionContributions,
          householdExpenses: applicantIncome?.householdExpenses,
          gasAndElectricity: applicantIncome?.gasAndElectricity,
          water: applicantIncome?.water,
          tvAndInternet: applicantIncome?.tvAndInternet,
          mobileAndLandlines: applicantIncome?.mobileAndLandlines,
          councilTax: applicantIncome?.councilTax,
          buildingsAndContentsInsurance: applicantIncome?.buildingsAndContentsInsurance,
          lifeAssuranceAndPrivateHealthPremiums: applicantIncome?.lifeAssuranceAndPrivateHealthPremiums,
          groundRentAndServiceCharge: applicantIncome?.groundRentServiceCharge,
          travel: applicantIncome?.travel,
          childcareAndSchoolCollegeUniversityFees: applicantIncome?.childcareAndSchoolCollegeUniversityFees,
          childMaintenance: applicantIncome?.childMaintenance,
          carUpkeep: applicantIncome?.carUpkeep,
          personalEssentials: applicantIncome?.personalEssentials,
          recreational: applicantIncome?.recreational,
          other: applicantIncome?.other,
          repaymentVehicle: applicantIncome?.repaymentVehicle,
          otherCommittedExpenditure: applicantIncome?.otherCommittedExpenditure,
          monthlyRentForResidentialProperty: applicantIncome?.monthlyRentForResidentialProperty,
        } as BtlApplicant;
      }),
      numberOfOtherMortgages: otherProperties?.numberOfOtherMortgages,
      otherMortgages: otherProperties
        ? otherProperties.properties?.map(
          (m: IUiOtherMortgage) =>
            ({
              propertyUse: PropertyUse.OwnUse,
              propertyValue: m.propertyValue,
              totalMortgageBalance: m.totalMortgageBalance,
              repaymentMethod: m.repaymentMethod,
              repaymentBalance: m.repaymentBalance,
              interestOnlyBalance: m.interestOnlyBalance,
              remainingTermYears: m.remainingTermOfLoan?.years,
              remainingTermMonths: m.remainingTermOfLoan?.months,
              monthlyMortgagePayments: m.monthlyMortgagePayments,
              monthlyUtilities: m.monthlyUtilities,
              monthlyCouncilTax: m.monthlyCouncilTax,
              interestRate: m.interestRate,
              tenancyInPlace: m.tenancyInPlace,
              grossMonthlyRental: m.grossMonthlyRental,
              repaymentVehicle: m.repaymentVehicle,
              equityRemainsInProperty: m.equityRemains,
            } as OtherMortgage),
        )
        : [],
      numberOfOtherBTLMortgages: otherProperties?.numberOfOtherBTLMortgages,
      otherMortgagesBtl: otherProperties
        ? otherProperties.propertiesBtl?.map(
          (m: IPropertyBtl) =>
            ({
              mortgageTermYears: m.mortgageTerm?.years,
              mortgageTermMonths: m.mortgageTerm?.months,
              interestRate: m.interestRate,
              rentalAmountReceived: m.rentalAmountReceived,
              totalBTLMortgageBalanceOutstanding: m.totalBTLMortgageBalanceOutstanding,
              monthlyBTLMortgageRepayment: m.monthlyBTLMortgageRepayment,
              repaymentMethod: m.repaymentMethod,
              repaymentVehicle: m.repaymentVehicle,
              equityRemainsInProperty: m.equityRemainsInProperty,
              amountInterestOnly: m.amountInterestOnly,
              repaymentAmount: m.repaymentAmount,
            } as OtherMortgageBtl),
        )
        : [],
    } as BtlAffordabilityRequest;
  }
}

export interface AffordabilityFormRequest {
  reference: string;
  affordabilityInput : AffordabilityRequest | BtlAffordabilityRequest;
}

export interface AffordabilityAndUiRequest {
  affordabilityRequest: AffordabilityRequest | BtlAffordabilityRequest | BdgAffordabilityRequest | null;
  uiModelJson: string;
}
