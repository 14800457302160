import { Injectable } from '@angular/core';

import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { Broker } from '../models';

@Injectable({
  providedIn: 'root',
})
export class BrokerService {
  public broker: Broker;

  public constructor(private clubHubDataService: ClubHubDataService) {}

  public getBrokerByAgencyNumber(agencyNumber) {
    return this.clubHubDataService.get<Broker>(`Broker/GetBroker/${agencyNumber}`);
  }

  public getBrokersByFCANumber(fcaNumber) {
    return this.clubHubDataService.get<Broker[]>(`Broker/GetBrokers/${fcaNumber}`);
  }
}
