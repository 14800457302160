import { Component, Input } from '@angular/core';
import { Product } from 'apps/shared/src/models';
import { ProductsUtils } from 'apps/shared/src/utils/products.utils';

@Component({
  selector: 'lib-revert-rate-column',
  templateUrl: 'revert-rate-column.component.html',
})
export class RevertRateColumnComponent {
  @Input() public product: Product;

  public constructor(
    private productsUtils: ProductsUtils,
  ) { }

  public get revertRateValue(): string {
    const revertRate = this.productsUtils.getRevertRateColumnValue(this.product);

    return revertRate !== null ? `${revertRate}%` : '';
  }
}
