import { Component, Input } from '@angular/core';
import { ModalService } from '@msslib/services';

@Component({
  selector: 'lib-refresh-now-app-banner',
  templateUrl: './refresh-now-app-banner.component.html',
  styleUrls: ['./refresh-now-app-banner.component.scss'],
})
export class RefreshNowAppBannerComponent {
  @Input() public bannerText: string;

  public constructor(private modalService: ModalService) {
  }

  public refreshNow() {
    this.modalService
      .open({
        title: 'Confirm',
        message: 'Any unsaved work will be lost. Do you want to continue?',
        showButtons: true,
        sticky: true,
        okLabel: 'Refresh now',
        cancelLabel: 'Cancel',
      })
      .then(() => window.location.reload());
  }
}
