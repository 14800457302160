<lib-header [showNav]="showNav" [client]="clientApp">
  <ul class="nav navbar-nav flex-grow-1 justify-content-center">
    <ng-container *ngIf="hasRole(roles.broker) || hasRole(roles.ignite)">
      <li *ngIf="hasRole(roles.clubHub) || hasRole(roles.ignite)" class="nav-item">
        <a class="nav-link" routerLink="/" routerLinkActive="tab-active" [routerLinkActiveOptions]="{ exact: true }">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/ignite" routerLinkActive="tab-active">
          Ignite
        </a>
      </li>
      <li *ngIf="hasRole(roles.broker) || hasRole(roles.ignite)" class="nav-item">
        <a class="nav-link" routerLink="/outcomehistory" routerLinkActive="tab-active">
          Outcome History
        </a>
      </li>
      <li *ngIf="hasAccessToMi" class="nav-item">
        <a class="nav-link" [routerLink]="firstMiLink" routerLinkActive="tab-active">
          MI & Insights
        </a>
      </li>
      <li *ngIf="hasRole(roles.clubHub)" class="nav-item">
        <a
          class="nav-link"
          href="https://www.legalandgeneral.com/adviser/mortgage-club/referralpro/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Referral Pro
        </a>
      </li>
      <li *ngIf="hasRole(roles.clubHub) && !hasRole(roles.broker)" class="nav-item">
        <a class="nav-link" [href]="smartrSurveyLink" target="_blank" rel="noopener noreferrer">
          SmartrSurvey
        </a>
      </li>
      <li *ngIf="(hasRole(roles.procFeeHistory) || hasRole(roles.claims)) && !isSimplyBiz" class="nav-item">
        <a class="nav-link" routerLink="/procfee" routerLinkActive="tab-active">
          Proc Fee
        </a>
      </li>
      <li *ngIf="hasRole(roles.clubHub)" class="nav-item">
        <a
          class="nav-link"
          href="https://www.legalandgeneral.com/adviser/mortgage-club/events/?cid=PartnerSite18342"
          target="_blank"
          rel="noopener noreferrer"
        >
          Events
        </a>
      </li>
      <li *ngIf="hasRole(roles.clubHub)" class="nav-item">
        <a
          class="nav-link"
          href="https://www.legalandgeneral.com/adviser/mortgage-club/exclusives/?cid=PartnerSite18343"
          target="_blank"
          rel="noopener noreferrer"
        >
          Exclusives
        </a>
      </li>
      <li *ngIf="hasRole(roles.viewConsumerDuty)" class="nav-item">
        <a
          class="nav-link"
          routerLink="/consumer-duty"
          routerLinkActive="tab-active"
        >
          Fair Value
        </a>
      </li>
      <li *ngIf="!isS365" class="nav-item">
        <a routerLink="/contact" class="nav-link" routerLinkActive="tab-active">Contact</a>
      </li>
    </ng-container>
  </ul>
</lib-header>
