<!-- If has valuation fee scale scheduled: -->
<ng-container *ngIf="hasScheduledScale; else valuationFeeScaleForm">
  <div class="alert alert-primary mb-3">
    <h4 class="alert-heading mb-2">Scheduled Changes</h4>
    <p>
      A valuation fee scale update is scheduled to go live on {{ activeScheduleDate | date : 'dd/MM/yyyy' }} at {{ activeScheduleDate | date : 'HH:mm' }}.
    </p>
    <div class="text-end">
      <button class="btn btn-outline-secondary me-2" (click)="showUpdateScheduleModal()">Reschedule</button>
      <button class="btn btn-outline-danger" (click)="discardSchedule()">Delete</button>
    </div>
  </div>

  <div class="row">
    <div class="col-6" *ngFor="let scale of valuationFeeScales">
      <table>
        <thead>
          <tr>
            <th colspan="3">{{ scale.isScheduled ? 'Scheduled' : 'Currently Live' }}</th>
          </tr>
          <tr>
            <th>Property Value From</th>
            <th>Property Value To</th>
            <th>Valuation Fee</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let entry of scale.entries">
            <td>£{{ entry.propertyPriceFrom }}</td>
            <td><span *ngIf="hasValue(entry.propertyPriceTo)">£{{ entry.propertyPriceTo }}</span></td>
            <td><span *ngIf="hasValue(entry.fee)">£{{ entry.fee }}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>

<!-- If no valuation fee scale scheduled: -->
<ng-template #valuationFeeScaleForm>
  <form>
    <table>
      <thead>
        <tr>
          <th>Property Value From</th>
          <th>Property Value To</th>
          <th>Valuation Fee</th>
          <th class="actions-column">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let entry of valuationFeeScaleEntries; let i = index">
          <td class="input-cell">
            <div
              class="input-group my-1"
              [class.is-invalid]="isBlankValue(entry.propertyPriceFrom) || isOverlapping(entry)"
            >
              <span class="input-group-text">£</span>
              <input
                min="0"
                class="form-control"
                type="number"
                [(ngModel)]="entry.propertyPriceFrom"
                [name]="'propertyPriceFrom_' + getEntryPostfix(entry)"
                (input)="setUnsavedChanges(true)"
                (keydown)="preventUnwanted($event)"
              />
            </div>
            <div class="error-message">
              <span class="me-1" *ngIf="isBlankValue(entry.propertyPriceFrom)">
                Value is required.
              </span>
              <span class="me-1" *ngIf="isOverlapping(entry)">
                Overlapping range.
              </span>
            </div>
          </td>
          <td class="input-cell">
            <div
              class="input-group my-1"
              [class.is-invalid]="isBlankOrZeroValue(entry.propertyPriceTo) || isInvalidRange(entry)"
            >
              <span class="input-group-text">£</span>
              <input
                min="0"
                class="form-control"
                type="number"
                [(ngModel)]="entry.propertyPriceTo"
                [name]="'propertyPriceTo_' + getEntryPostfix(entry)"
                (input)="setUnsavedChanges(true)"
                (keydown)="preventUnwanted($event)"
              />
            </div>
            <div class="error-message">
              <span class="me-1" *ngIf="isBlankOrZeroValue(entry.propertyPriceTo)">
                Must be more than 0.
              </span>
              <span class="me-1" *ngIf="isInvalidRange(entry)">
                Invalid range
              </span>
            </div>
          </td>
          <td class="input-cell">
            <div
              class="input-group my-1"
              [class.is-invalid]="isBlankOrZeroValue(entry.fee)">
              <span class="input-group-text">£</span>
              <input
                min="0"
                class="form-control"
                type="number"
                [(ngModel)]="entry.fee"
                [name]="'fee_' + getEntryPostfix(entry)"
                (input)="setUnsavedChanges(true)"
                (keydown)="preventUnwanted($event)"
              />
            </div>
            <div class="error-message">
              <span class="me-1" *ngIf="isBlankOrZeroValue(entry.fee)">
                Must be more than 0.
              </span>
            </div>
          </td>
          <td>
            <button
              class="btn btn-sm btn-outline-2022--secondary me-2 action-btn"
              (click)="addRowBelow(i)"
            >
              Add row below
            </button>
            <button
              *ngIf="deletingValuationFeeScaleEntry !== entry; else deleteRowDialog"
              class="btn btn-sm btn-outline-danger action-btn"
              (click)="showDeleteDialog(entry)"
              title="Delete row"
            >
              Delete
            </button>
            <ng-template #deleteRowDialog>
              <button class="btn btn-sm btn-outline-danger yes-no-btn" (click)="deleteEntry()" title="Delete row">
                Yes
              </button>
              <button class="btn btn-sm btn-outline-warning ms-2 yes-no-btn" (click)="cancelDeletion()" title="Cancel">
                No
              </button>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
    <button class="btn btn-2022--secondary m-2" (click)="addRowToTheEnd()" type="button"><i class="fa fa-plus"></i> Add Row</button>
    <button class="btn btn-2022--secondary m-2" (click)="deleteValuationRow()" type="button"><i class="fa fa-minus"></i> Delete Row</button>
    <div class="row preview-buttons pt-0">
      <div class="col">
        <button [disabled]="!canCancel" class="preview-cancel btn btn-2022--secondary" (click)="openCancelModal()" type="button">Cancel</button>
        <button [disabled]="!canSave" class="preview-submit btn btn-2022--secondary" (click)="submitValuationScale()" type="button">Continue</button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #valuationFeeConfirmationModalTemplateRef>
  <table>
    <thead>
      <tr>
        <th>Property Value From</th>
        <th>Property Value To</th>
        <th>Valuation Fee</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let entry of valuationFeeScaleEntries">
        <td>£{{ entry.propertyPriceFrom }}</td>
        <td><span *ngIf="hasValue(entry.propertyPriceTo)">£{{ entry.propertyPriceTo }}</span></td>
        <td><span *ngIf="hasValue(entry.fee)">£{{ entry.fee }}</span></td>
      </tr>
    </tbody>
  </table>

  <lib-event-schedule
    [scheduleForm]="scheduleForm"
    [alignItems]="'end'"
  ></lib-event-schedule>
  <div class="row mt-3">
    <div class="col">
      <button type="button" class="btn btn-outline-secondary" (click)="closeModal(false)">Go Back</button>
    </div>
    <div class="col text-end">
      <button type="submit" class="btn btn-2022--secondary" (click)="scheduleFormValid && closeModal(true)" [disabled]="!scheduleFormValid">Save Changes</button>
    </div>
  </div>
</ng-template>

<ng-template #valuationFeeCancelModalTemplateRef>
  <div class="alert alert-danger">
    <p>
      You have made changes to the valuation scale,
      click continue to complete the update or cancel to discard the change
    </p>
    <div class="text-end">
      <a class="btn btn-2022--secondary me-2" (click)="cancelUpdate(); modalService.close()">
        Cancel
      </a>
      <a class="btn btn-2022--secondary" (click)="modalService.close();">
        Continue
      </a>
    </div>
  </div>
</ng-template>

<ng-template #valuationFeeContinueModalTemplateRef>
  <div class="alert alert-success">
    <p>
      Your changes have been successfully updated and will be live in the next
      few minutes for audit or to view on the “Manage Product“ page
    </p>
  </div>
</ng-template>

<ng-template #rescheduleDialogTemplateRef>
  <div class="modal-header">
    <h3 class="modal-title text-left w-100">Update Schedule</h3>
    <button type="button" aria-label="Close" class="btn-close" (click)="closeModal(false)"></button>
  </div>
  <div class="modal-body">
    <lib-event-schedule
      [scheduleForm]="scheduleForm"
      [showSetLiveNowOption]="false"
    ></lib-event-schedule>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn- btn-2022--grey" (click)="closeModal(false)">Cancel</button>
    <button type="submit" class="btn btn-2022--secondary" (click)="scheduleFormValid && closeModal(true)" [disabled]="!scheduleFormValid">Update Schedule</button>
  </div>
</ng-template>
