import {
  BenefitIncomeType,
  ContractType,
  EmploymentStatus,
  Frequency,
  MortgageType,
  OtherIncomeType,
  ProductLength,
  PropertyType,
  PurchaserType,
  RepaymentMethod,
  UkLocation,
  WorkRelatedIncomeType,
} from 'apps/shared/src/models';

import { IUiAffordabilityModel } from 'apps/clubhub/src/app/ignite/models/affordability';

export const mockAffordabilityRequest4Applicants2IncomesNeeded = {
  propertyAndLoan: {
    propertyType: PropertyType.House,
    newBuild: false,
    helpToBuy: false,
    location: UkLocation.YorkshireAndHumberside,
    mortgageType: MortgageType.Purchase,
    purchaserType: PurchaserType.FirstTimeBuyer,
    capitalRaising: { value: false, showCriteria: false },
    amountTransferredFromOtherLender: 0,
    propertyValue: 200000,
    loanAmount: 120000,
    repaymentMethod: {
      value: RepaymentMethod.CapitalAndInterest,
      showInterestOnlyCriteria: false,
      showInterestOnlyPartAndPartCriteria: false,
    },
    repaymentVehicle: null,
    mortgageTerm: {
      years: 10,
      months: 6,
    },
    equityLoanValue: 0,
    productLength: ProductLength.TwoYears,
  },
  applicants: {
    numberOfApplicants: 4,
    numberOfIncomesNeeded: 2,
    financialDependants: false,
    dependantsAged0To5: 0,
    dependantsAged6To11: 0,
    dependantsAged12To17: 0,
    dependantsAged18AndOver: 0,
    applicantAges: [
      {
        dateOfBirth: { year: 1980, month: 1, day: 1 },
        plannedRetirementAge: 65,
      },
      {
        dateOfBirth: { year: 1980, month: 9, day: 1 },
        plannedRetirementAge: 65,
      },
      {
        dateOfBirth: { year: 1980, month: 1, day: 3 },
        plannedRetirementAge: 65,
      },
      {
        dateOfBirth: { year: 1980, month: 1, day: 4 },
        plannedRetirementAge: 65,
      },
    ],
  },
  incomeAndExpenditure: {
    applicants: [
      {
        applicantNumber: 1,
        primaryIncome: {
          employmentStatus: EmploymentStatus.Employed,
          contractType: ContractType.Permanent,
          treatedAsEmployee: true,
          timeInCurrentJobMonths: { years: 5, months: 2 },
          timeRemainingOnContractMonths: null,
          grossAnnualIncome: 80000,
        },
        secondJob: false,
        additionalIncome: true,
        [WorkRelatedIncomeType.Bonus]: Frequency.Annually,
        ['1Amounts']: [1000, 1000],
        [WorkRelatedIncomeType.Commission]: Frequency.Annually,
        ['2Amounts']: [500, 500],
        [WorkRelatedIncomeType.Overtime]: Frequency.Annually,
        ['3Amounts']: [400, 400],
        workAdditionalIncome: [
          {
            option: {
              category: WorkRelatedIncomeType.CarAllowance, amount: 50, frequency: Frequency.Monthly,
            },
          },
        ],
        pensionIncome: {
          amount: 400,
        },
        benefitAdditionalIncome: [
          {
            option: {
              category: BenefitIncomeType.ChildTaxCredits, amount: 50, frequency: Frequency.Monthly,
            },
          },
        ],
        otherAdditionalIncome: [
          {
            option: {
              category: OtherIncomeType.RentalIncome, amount: 100, frequency: Frequency.Monthly,
            },
          },
        ],
        creditCardBalance: 0,
        totalMonthlyCreditPayments: 0,
        householdExpenses: 50,
        gasAndElectricity: 100,
        water: 40,
        tvAndInternet: 25,
        mobileAndLandlines: 30,
        councilTax: 135,
        buildingsAndContentsInsurance: 10,
        lifeAssuranceAndPrivateHealthPremiums: 20,
        groundRentAndServiceCharge: 0,
        travel: 50,
        childcareAndSchoolCollegeUniversityFees: 0,
        childMaintenance: 0,
        carUpkeep: 0,
        personalEssentials: 0,
        recreational: 200,
        other: 25,
      },
      {
        applicantNumber: 2,
        primaryIncome: {
          employmentStatus: EmploymentStatus.Employed,
          contractType: ContractType.Permanent,
          treatedAsEmployee: true,
          timeInCurrentJobMonths: { years: 8, months: 0 },
          timeRemainingOnContractMonths: null,
          grossAnnualIncome: 50000,
        },
        secondJob: false,
        additionalIncome: true,
        workAdditionalIncome: [],
        pensionIncome: {
          amount: 100,
        },
        benefitAdditionalIncome: [],
        otherAdditionalIncome: [],
        creditCardBalance: 0,
        totalMonthlyCreditPayments: 0,
        householdExpenses: 50,
        gasAndElectricity: 100,
        water: 40,
        tvAndInternet: 25,
        mobileAndLandlines: 30,
        councilTax: 135,
        buildingsAndContentsInsurance: 10,
        lifeAssuranceAndPrivateHealthPremiums: 20,
        groundRentAndServiceCharge: 0,
        travel: 50,
        childcareAndSchoolCollegeUniversityFees: 0,
        childMaintenance: 0,
        carUpkeep: 0,
        personalEssentials: 0,
        recreational: 200,
        other: 25,
      },
    ],
  },
  otherMortgages: 0,
} as unknown as IUiAffordabilityModel;

export const mockAffordabilityRequest4ApplicantsAllNeeded = {
  propertyAndLoan: {
    propertyType: PropertyType.House,
    newBuild: false,
    helpToBuy: false,
    location: UkLocation.YorkshireAndHumberside,
    mortgageType: MortgageType.Purchase,
    purchaserType: PurchaserType.FirstTimeBuyer,
    capitalRaising: { value: false, showCriteria: false },
    amountTransferredFromOtherLender: 0,
    propertyValue: 200000,
    loanAmount: 120000,
    repaymentMethod: {
      value: RepaymentMethod.CapitalAndInterest,
      showInterestOnlyCriteria: false,
      showInterestOnlyPartAndPartCriteria: false,
    },
    repaymentVehicle: null,
    mortgageTerm: {
      years: 10,
      months: 6,
    },
    equityLoanValue: 0,
    productLength: ProductLength.TwoYears,
  },
  applicants: {
    numberOfApplicants: 4,
    numberOfIncomesNeeded: 4,
    financialDependants: false,
    dependantsAged0To5: 0,
    dependantsAged6To11: 0,
    dependantsAged12To17: 0,
    dependantsAged18AndOver: 0,
    applicantAges: [
      {
        dateOfBirth: { year: 1980, month: 1, day: 1 },
        plannedRetirementAge: 65,
      },
      {
        dateOfBirth: { year: 1980, month: 1, day: 2 },
        plannedRetirementAge: 65,
      },
      {
        dateOfBirth: { year: 1980, month: 1, day: 3 },
        plannedRetirementAge: 65,
      },
      {
        dateOfBirth: { year: 1980, month: 1, day: 4 },
        plannedRetirementAge: 65,
      },
    ],
  },
  incomeAndExpenditure: {
    applicants: [
      {
        applicantNumber: 1,
        primaryIncome: {
          employmentStatus: EmploymentStatus.Employed,
          contractType: ContractType.Permanent,
          treatedAsEmployee: true,
          timeInCurrentJobMonths: { years: 5, months: 2 },
          timeRemainingOnContractMonths: null,
          grossAnnualIncome: 80000,
        },
        secondJob: false,
        additionalIncome: true,
        [WorkRelatedIncomeType.Bonus]: Frequency.Annually,
        ['1Amounts']: [1000, 1000],
        [WorkRelatedIncomeType.Commission]: Frequency.Annually,
        ['2Amounts']: [500, 500],
        [WorkRelatedIncomeType.Overtime]: Frequency.Annually,
        ['3Amounts']: [400, 400],
        workAdditionalIncome: [
          {
            option: {
              category: WorkRelatedIncomeType.CarAllowance, amount: 50, frequency: Frequency.Monthly,
            },
          },
        ],
        pensionIncome: {
          amount: 400,
        },
        benefitAdditionalIncome: [
          {
            option: {
              category: BenefitIncomeType.ChildTaxCredits, amount: 50, frequency: Frequency.Monthly,
            },
          },
        ],
        otherAdditionalIncome: [
          {
            option: {
              category: OtherIncomeType.RentalIncome, amount: 100, frequency: Frequency.Monthly,
            },
          },
        ],
        creditCardBalance: 0,
        totalMonthlyCreditPayments: 0,
        householdExpenses: 50,
        gasAndElectricity: 100,
        water: 40,
        tvAndInternet: 25,
        mobileAndLandlines: 30,
        councilTax: 135,
        buildingsAndContentsInsurance: 10,
        lifeAssuranceAndPrivateHealthPremiums: 20,
        groundRentAndServiceCharge: 0,
        travel: 50,
        childcareAndSchoolCollegeUniversityFees: 0,
        childMaintenance: 0,
        carUpkeep: 0,
        personalEssentials: 0,
        recreational: 200,
        other: 25,
      },
      {
        applicantNumber: 2,
        primaryIncome: {
          employmentStatus: EmploymentStatus.Employed,
          contractType: ContractType.Permanent,
          treatedAsEmployee: true,
          timeInCurrentJobMonths: { years: 8, months: 0 },
          timeRemainingOnContractMonths: null,
          grossAnnualIncome: 50000,
        },
        secondJob: false,
        additionalIncome: true,
        workAdditionalIncome: [],
        pensionIncome: {
          amount: 100,
        },
        benefitAdditionalIncome: [],
        otherAdditionalIncome: [],
        creditCardBalance: 0,
        totalMonthlyCreditPayments: 0,
        householdExpenses: 50,
        gasAndElectricity: 100,
        water: 40,
        tvAndInternet: 25,
        mobileAndLandlines: 30,
        councilTax: 135,
        buildingsAndContentsInsurance: 10,
        lifeAssuranceAndPrivateHealthPremiums: 20,
        groundRentAndServiceCharge: 0,
        travel: 50,
        childcareAndSchoolCollegeUniversityFees: 0,
        childMaintenance: 0,
        carUpkeep: 0,
        personalEssentials: 0,
        recreational: 200,
        other: 25,
      },
      {
        applicantNumber: 3,
        primaryIncome: {
          employmentStatus: EmploymentStatus.Employed,
          contractType: ContractType.Permanent,
          treatedAsEmployee: true,
          timeInCurrentJobMonths: { years: 8, months: 0 },
          timeRemainingOnContractMonths: null,
          grossAnnualIncome: 30000,
        },
        secondJob: false,
        additionalIncome: true,
        workAdditionalIncome: [],
        pensionIncome: {
          amount: 100,
        },
        benefitAdditionalIncome: [],
        otherAdditionalIncome: [],
        creditCardBalance: 0,
        totalMonthlyCreditPayments: 0,
        householdExpenses: 50,
        gasAndElectricity: 100,
        water: 40,
        tvAndInternet: 25,
        mobileAndLandlines: 30,
        councilTax: 135,
        buildingsAndContentsInsurance: 10,
        lifeAssuranceAndPrivateHealthPremiums: 20,
        groundRentAndServiceCharge: 0,
        travel: 50,
        childcareAndSchoolCollegeUniversityFees: 0,
        childMaintenance: 0,
        carUpkeep: 0,
        personalEssentials: 0,
        recreational: 200,
        other: 25,
      },
      {
        applicantNumber: 4,
        primaryIncome: {
          employmentStatus: EmploymentStatus.Employed,
          contractType: ContractType.Permanent,
          treatedAsEmployee: true,
          timeInCurrentJobMonths: { years: 8, months: 0 },
          timeRemainingOnContractMonths: null,
          grossAnnualIncome: 60000,
        },
        secondJob: false,
        additionalIncome: true,
        workAdditionalIncome: [],
        pensionIncome: {
          amount: 100,
        },
        benefitAdditionalIncome: [],
        otherAdditionalIncome: [],
        creditCardBalance: 0,
        totalMonthlyCreditPayments: 0,
        householdExpenses: 50,
        gasAndElectricity: 100,
        water: 40,
        tvAndInternet: 25,
        mobileAndLandlines: 30,
        councilTax: 135,
        buildingsAndContentsInsurance: 10,
        lifeAssuranceAndPrivateHealthPremiums: 20,
        groundRentAndServiceCharge: 0,
        travel: 50,
        childcareAndSchoolCollegeUniversityFees: 0,
        childMaintenance: 0,
        carUpkeep: 0,
        personalEssentials: 0,
        recreational: 200,
        other: 25,
      },
    ],
  },
  otherMortgages: 0,
} as unknown as IUiAffordabilityModel;
