import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, UntypedFormControl, Validator } from '@angular/forms';

import { FormsValidators } from '../validators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[greaterThanOnTouched]',
  // eslint-disable-next-line no-use-before-define
  providers: [{ provide: NG_VALIDATORS, useExisting: GreaterThanOnTouchedValidatorDirective, multi: true }],
})
export class GreaterThanOnTouchedValidatorDirective implements Validator {
  @Input() public greaterThanOnTouched: string | number;

  public validate(c: UntypedFormControl) {
    return FormsValidators.greaterThanOnTouched(+this.greaterThanOnTouched)(c);
  }
}
