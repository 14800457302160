import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService, DataService } from '@msslib/services';
import { ToastService } from '@msslib/services/toast.service';
import { EMAIL_VERIFICATION_SERVICE, type IEmailVerificationService } from '@msslib/models/email-verification';

@Injectable({
  providedIn: 'root',
})
export class LenderHubDataService extends DataService {
  public constructor(
    http: HttpClient,
    toastService: ToastService,
    configService: ConfigService,
    @Inject(EMAIL_VERIFICATION_SERVICE) emailVerificationService: IEmailVerificationService,
  ) {
    super(
      http,
      toastService,
      configService,
      configService.config.lenderHubApiUrl,
      emailVerificationService,
    );
  }
}
