<div
  class="input-group"
  [ngClass]="props.inputClass"
  [class.is-invalid]="showError"
  [class.disabled]="formControl.disabled"
>
  <span
    *ngIf="props.addonStart"
    class="input-group-text"
    [ngClass]="props.addonStartClass"
  >
    {{ props.addonStart }}
  </span>

  <input
    [type]="props.inputType ?? 'text'"
    class="form-control"
    [formControl]="formControl"
    [placeholder]="props.placeholder"
    [formlyAttributes]="field"
    [attr.data-testid]="props.testId"
  />

  <span
    *ngIf="props.addonEnd"
    class="input-group-text"
    [ngClass]="props.addonEndClass"
  >
    {{ props.addonEnd }}
  </span>

  <button
    *ngIf="props.addonEndButton"
    class="btn btn-2022--secondary"
    type="button"
    (click)="props.addonEndButton.clickAction()"
  >
    {{ props.addonEndButton.text }}
  </button>
</div>
