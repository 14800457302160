import { Routes } from '@angular/router';

import { applicationPaths } from './constants';
import { LoginComponent, LogoutComponent } from './components';

export const routes: Routes = [
  { path: applicationPaths.register, component: LoginComponent },
  { path: applicationPaths.profile, component: LoginComponent },
  { path: applicationPaths.login, component: LoginComponent },
  { path: applicationPaths.loginFailed, component: LoginComponent },
  { path: applicationPaths.loginCallback, component: LoginComponent },
  { path: applicationPaths.logOut, component: LogoutComponent },
  { path: applicationPaths.loggedOut, component: LogoutComponent },
  { path: applicationPaths.logOutCallback, component: LogoutComponent },
  { path: applicationPaths.marketingPreferences, component: LoginComponent },
  { path: applicationPaths.crmSystemInformation, component: LoginComponent },
];
