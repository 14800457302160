import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'lib-fw-table-row-wrapper',
  templateUrl: './table-row-wrapper.component.html',
  styleUrls: ['./table-row-wrapper.component.scss'],
})
export class TableRowWrapperComponent extends FieldWrapper {

  public get labelIndentCssClass(): string {
    return this.props.labelIndent ? 'ps-5' : '';
  }
}
