/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import { NgClass } from '@angular/common';
import { TemplateRef } from '@angular/core';

export type SortDir = 'asc' | 'desc';

export type SimpleGridColumnRendererTarget = 'cell' | 'filter';

export type SimpleGridColumnRenderer<TModel = Record<string, any>> =
  (value: any, row: TModel, target: SimpleGridColumnRendererTarget, options: Record<string, any>) => any;

export type CellAwareFunc<T, TModel = Record<string, any>> = (value: any, row: TModel, column: ISimpleGridColumn) => T;

/** Flag enum indicating what types (if any) of filters are supported in the dropdown. */
export enum DropdownFilterType {
  None = 0,
  Equal = 1 << 0,
  In = 1 << 1,
  Contains = 1 << 2,
  Comparison = 1 << 3,
}

export interface ISimpleGridColumn<TModel = Record<string, any>> {
  /** Name of the field in the datasource objects. */
  name: string;

  /** Type of field in the datasource objects. Determines sort methods. */
  type?: SimpleGridColumnType;

  /** Whether or not this column is visible or hidden. Defaults to `false`.
   * Hidden columns can be useful to set custom filter/sort rules without showing that column. */
  hidden?: boolean;

  /** Label for the header of this column. */
  label?: string;

  /** Icon after header title of this column. */
  icon?: string;

  /** Prefix that will be inserted in front of non-null values. */
  prefix?: string | ((value: any) => string);
  /** Suffix that will be inserted after non-null values. */
  suffix?: string | ((value: any) => string);

  /** Either a set of options to use for a discrete filter, or true for dynamically created or false for none.
   * Default: `false` */
  discreteFilter?: boolean | ISimpleGridDiscreteOption[];

  /** Whether or not multiple values can be selected with the discrete filter.
   * Default: `false` */
  discreteMultiSelect?: boolean;

  /** Icon class to use for discrete item choices */
  discreteFilterIcon?: string;

  /** A list of pre-defined filters than can be presented to the user. */
  filterPresets?: ISimpleGridFilterPresetOption[];

  /** Whether a control should be presented in the header cell for filtering.
   * Default: `false` */
  headerFilter?: false | 'input' | 'select';

  /** What types of filters are supported in the dropdown. Note that this does NOT include the discrete filter, that is
   * a separate configuration (see `discreteFilter`).
   * Default: DropdownFilterType.Equal */
  dropdownFilter?: DropdownFilterType;

  /** Custom filter handler for this column. */
  filterHandler?: (
    value: any, operation: ISimpleGridFilterOperation, row: TModel
  ) => boolean | undefined;

  /** Whether this column can be sorted on. Default: `true` */
  sortable?: boolean;
  /** Function used to get the value to sort by. */
  sortBy?: (row: TModel) => any;
  /* Labels and icons to use instead of the default sorting labels and icons. */
  sortLabels?: Partial<Record<SortDir, { label?: string; icon?: string }>>;
  /** If specified, restricts the sorting to only be possible in the given direction. */
  sortDirRestrict?: SortDir;

  /** Add "Clear sort" button to undo sorting */
  clearSortButton?: boolean;

  /** Width to be applied to this column. */
  width?: number | string;

  /** Classes to be applied the header (`<th>` element) for this column. */
  headerClassList?: NgClass['ngClass'];
  /** Styles to be applied the header for this column. */
  headerStyle?: Record<string, any>;

  /** Optional value for ngbTooltip that will be applied to the header cell for this column. */
  headerTooltip?: string | TemplateRef<any>;
  /** Styles to be applied the header tooltip for this column. */
  headerTooltipClass?: string;

  /** Classes to be applied to each cell (`<td>` elements) for this column. */
  classList?: NgClass['ngClass'] | CellAwareFunc<NgClass['ngClass'], TModel>;

  /** Styles to be applied to each cell for this column. */
  style?: Record<string, any>;

  /** Custom template reference for this cell. Cannot be used with `render`. */
  template?: TemplateRef<any>;
  /** Custom render function that will be used by default template. */
  render?: SimpleGridColumnRenderer<TModel>;

  /** Available values */
  availableOptions?: any[];

  /** Plural column name */
  pluralLabel?: string;

  /**
   * Additional options that are passed to the column renderer.
   * Some of the default renderers have additional options:
   * - Default renderer for numbers has `minIntegerDigits`, `minFractionDigits` and `maxFractionDigits` options. These
   *   are used in the same way as the options passed to Angular's DecimalPipe.
   */
  rendererOptions?: Record<string, any>;
}

export interface ISimpleGridFilterOperation {
  column: string;
  op: SimpleGridFilterOperator;
  value: any;
}

export interface ISimpleGridRenderContext {
  $implicit: any;
  data: any;
  row: any;
  column: ISimpleGridColumn;
}

export type ISimpleGridDiscreteOption = {
  value: any;
  label: string;
  icon?: string;
}

export type ISimpleGridFilterPresetOption = {
  value: Omit<ISimpleGridFilterOperation, 'column'> | (() => Omit<ISimpleGridFilterOperation, 'column'>);
  isActive?: (filter: ISimpleGridFilterOperation | undefined) => boolean;
  label: string;
  icon?: string;
}

export interface GridStateModel {
  filters: ISimpleGridFilterOperation[];
  sort: { sortOn: string; sortDir: SortDir }[];
  page: number;
  pageSize: number;
}

export interface SimpleGridResponseViewModel<T> {
  items: T[];
  totalCount: number;
  pageNumber: number;
  pageSize: number;
}

export interface SimpleGridFilterChangedEventArgs {
  status: SimpleGridFilterChangeType;
  operation: Readonly<ISimpleGridFilterOperation> | null;
}

export enum SimpleGridColumnType {
  String = 1,
  Number = 2,
  /** Date only in `dd/MM/yyyy` format. */
  Date = 3,
  /** Date and time in `dd/MM/yyyy HH:mm` format. */
  DateTime = 4,
  /** Currency with 0 decimal places (whole pounds). */
  Currency0 = 5,
  /** Currency with 2 decimal places (pence). */
  Currency2 = 6,

  Boolean = 7,
}

export enum SimpleGridFilterOperator {
  Equal = 'eq',
  NotEqual = 'neq',
  In = 'in',
  Contains = 'contains',
  GreaterThan = 'gt',
  GreaterThanOrEqual = 'gte',
  LessThan = 'lt',
  LessThanOrEqual = 'lte',
  Between = 'btw',
}

export enum SimpleGridFilterChangeType {
  Added,
  Changed,
  Removed,

  /** Cleared is similar to `Removed`, but removes ALL filters. In this case, there will be no operation in the args. */
  Cleared,
}

export enum SimpleGridSelectionMode {
  None = 0,
  Single = 1,
  Multiple = 2,
}

export interface CellClickEvent<T = Record<string, any>> {
  row: T;
  /** The index of the clicked row, relative to the page the user is currently on. */
  rowIndex: number;
  /** The index of the clicked row, relative to the entire datasource. */
  fullRowIndex: number;
  column: ISimpleGridColumn;
  /** The index of the clicked column, based on what's visible to the user (not the index in the columns array input).*/
  columnIndex: number;
}
