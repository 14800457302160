<form [formGroup]="form" (submit)="onSubmit()">
    <div class="container">
        <h2 class="mt-3">True Cost Calculation Preferences</h2>
        <div class="row mt-4">
          <div class="col-7 d-flex align-items-center">
            <label class="d-flex">
              <input
                type="checkbox"
                class="me-1"
                (click)="toggleTrueCost($event.target.checked)"
                [checked]="customTrueCostPeriodControl.value !== 24"
                [disabled]="this.model?.isCalculateInitialTrueCostOverMonthsOverridable === false"
                data-testid="calculateTrueCost"
              >
              <span>Calculate true cost over a number of months. This will override the default of 24 months</span>
            </label>
          </div>
          <div class="col-2">
            <input
              type="number"
              class="form-control"
              formControlName="trueCostPeriod"
              data-testid="trueCostPeriod"
            >
          </div>
        </div>
    </div>
    <div class="container">
      <div class="row mb-2 mt-5">
        <div class="col offset-3 d-flex justify-content-between flex-column text-center border-bottom">
          <span class="fw-bold">Don't include in true cost</span>
          <button type="button" class="btn btn-2022--secondary p-1 fw-bold mb-1"
                  data-testid="DontIncludeSelectAllBtn"
                  (click)="selectAllFees(eFeeCalculationScenarios.Exclude)">
            Select All
          </button>
        </div>
        <div class="col d-flex justify-content-between flex-column text-center border-bottom">
          <span class="fw-bold">Include in true cost (don't add to loan)</span>
          <button type="button" class="btn btn-2022--secondary p-1 fw-bold mb-1"
                  data-testid="includeDontAddToLoanSelectAllBtn"
                  (click)="selectAllFees(eFeeCalculationScenarios.OneOffCost)">
            Select All
          </button>
        </div>
        <div class="col d-flex justify-content-between flex-column text-center border-bottom">
          <span class="fw-bold">Include in true cost (add to loan)*</span>
          <button type="button" class="btn btn-2022--secondary p-1 fw-bold mb-1"
                  data-testid="includeAddToLoanSelectAllBtn"
                  (click)="selectAllFees(eFeeCalculationScenarios.AddToLoan)">
            Select All
          </button>
        </div>
        <div class="col-2 d-flex justify-content-center"></div>
      </div>
      <ng-container formArrayName="fees">
        <ng-container *ngFor="let fee of allFees; let i = index" [formGroupName]="i">
          <div class="row py-1">
            <div class="col-3 d-flex align-items-center">
              <span>{{fee.displayName}}</span>
            </div>
            <div class="col d-flex justify-content-center" [class.row-disabled]="isFeeDisabled(fee.type)">
              <input
                type="radio"
                [attr.data-testid]="'exclude_' + fee.displayName"
                formControlName="feeCalculationScenario"
                [value]="eFeeCalculationScenarios.Exclude"
              >
            </div>
            <div class="col d-flex justify-content-center">
              <input
                type="radio"
                [attr.data-testid]="'oneOffCost_' + fee.displayName"
                formControlName="feeCalculationScenario"
                [value]="eFeeCalculationScenarios.OneOffCost"
              >
            </div>
            <div class="col me-4 d-flex justify-content-center">
              <input
                type="radio"
                [attr.data-testid]="'addToLoan_' + fee.displayName"
                formControlName="feeCalculationScenario"
                [value]="eFeeCalculationScenarios.AddToLoan"
              >
            </div>
            <div class="col-2 d-flex justify-content-start custom-fee-btns">
              <button *ngIf="fee.isCustom"
              type="button"
              class="btn btn-2022--secondary small-btn me-1"
              data-testid="editBtn"
              (click)="editCustomFee(i)"
              [disabled]="isCustomFeeDisabled(i)">
                Edit
              </button>
              <button *ngIf="fee.isCustom"
              type="button"
              class="btn btn-2022--danger small-btn"
              data-testid="removeBtn"
              (click)="removeCustomFee(i)"
              [disabled]="isCustomFeeDisabled(i)">
                Remove
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="container mt-5 mb-5">
      <div class="d-flex mb-5 align-items-center">
        <div class="col-2">
          <button type="button" class="btn btn-2022--secondary fw-bold"
                  data-testid="addCustomFeeBtn"
                  (click)="addCustomFee()"
                  [disabled]="addCustomFeesDisabled">
            Add custom fee
          </button>
        </div>
      </div>

      <h2>Cashback Settings</h2>
      <label class="d-flex align-items-center my-3 mb-5">
        <div class="col-2 d-flex align-items-center">
          <input
            type="checkbox"
            class="my-auto me-1"
            data-testid="deductCashback"
            formControlName="deductCashback"
          >
          <span>Deduct Cashback</span>
        </div>
      </label>

      <h2>Broker Firm Fees</h2>
      <label class="d-flex pointer my-3 mb-3">
        <input
          type="checkbox"
          class="my-auto me-1"
          data-testid="applyDefaultBrokerFee"
          (click)="toggleBrokerFee($event)"
          [(ngModel)]="displayBrokerFeeForm"
          [ngModelOptions]="{standalone: true}"
        >
        <span>Apply a default broker fee</span>
      </label>

      <div *ngIf="displayBrokerFeeForm" class="d-flex flex-column">
        <ng-container formGroupName="brokerFees">
          <div class="d-flex mb-3">
            <div class="d-flex justify-content-center me-2">
              <input
                type="radio"
                id="brokerFeeExclude"
                formControlName="calculationScenario"
                [value]="eFeeCalculationScenarios.Exclude"
              >
              <span class="ms-1">Don't include in true cost</span>
            </div>
            <div class="d-flex justify-content-center">
              <input
                type="radio"
                id="brokerFeeOneOffCost"
                formControlName="calculationScenario"
                [value]="eFeeCalculationScenarios.OneOffCost"
              >
              <span class="ms-1">Include in true cost (don't add to loan)</span>
            </div>
          </div>
          <ng-container *ngFor="let amounts of brokerFeeAmountsArray?.controls; let i = index">
            <ng-container [formGroup]="amounts">
              <table class="table align-middle">
                <thead>
                  <tr>
                    <th scope="col">Fee Amount</th>
                    <th scope="col">When refundable?</th>
                    <th scope="col">Amount Refundable</th>
                    <th scope="col">Add fee to loan?</th>
                    <th scope="col">When Payable?</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="d-flex flex-column justify-content-evenly w-300">
                      <div class="input-group mb-2"
                      [ngClass]="errorsForm(['brokerFees','amounts', i])?.requiredFeeAmountError ||
                        errorsForm(['brokerFees','amounts', i])?.uniqueFeeAmountError ? 'is-invalid' : validityClassesForm(['brokerFees.amounts.' + i + '.feeAmount'])"
                      >
                        <span class="input-group-text">£</span>
                        <input
                          type="number"
                          class="form-control"
                          formControlName="feeAmount"
                          placeholder="fee amount"
                          [attr.data-testid]="'feeAmount_' + i"
                          (keydown)="preventUnwanted($event)"
                      >
                      </div>
                      <div
                        class="input-group"
                        [ngClass]="errorsForm(['brokerFees','amounts', i])?.requiredFeeAmountError ||
                          errorsForm(['brokerFees','amounts', i])?.uniqueFeeAmountError ? 'is-invalid' : validityClassesForm(['brokerFees.amounts.' + i + '.feeAmountPct'])"
                      >
                      <span class="input-group-text">%</span>
                        <input
                          type="number"
                          class="form-control"
                          formControlName="feeAmountPct"
                          placeholder="of the loan"
                          [attr.data-testid]="'feeAmountPct_' + i"
                          (keydown)="preventUnwanted($event)"
                        >
                      </div>
                      <div *ngIf="!validForm(['brokerFees','amounts', i]) && (errorsForm(['brokerFees.amounts.' + i + '.feeAmountPct'])?.min ||
                        errorsForm(['brokerFees.amounts.' + i + '.feeAmountPct'])?.max)" class="invalid-feedback">
                        Please enter a % value between 0.2 and 2%.
                      </div>
                      <div *ngIf="errorsForm(['brokerFees','amounts', i])?.requiredFeeAmountError" class="invalid-feedback">
                        Fee amount or Fee amount % is required.
                      </div>
                      <div *ngIf="errorsForm(['brokerFees','amounts', i])?.uniqueFeeAmountError" class="invalid-feedback">
                        You cannot enter values for both Fee amount and Fee amount % simultaneously.
                      </div>
                    <td class="">
                      <select
                        class="form-control"
                        formControlName="whenRefundable"
                        [attr.data-testid]="'whenRefundable_' + i"
                        [ngClass]="{ 'is-valid': validForm('brokerFees.amounts.' + i + '.whenRefundable'), 'is-invalid': invalidForm('brokerFees.amounts.' + i + '.whenRefundable') }"
                        required
                        (change)="whenRefundableChange($event.target.value)"
                        >
                        <option [value]="null" hidden>Please select...</option>
                        <option *ngFor="let opt of whenRefundable | enumvalues; trackBy: trackByKey"
                          [value]="opt.key">
                          {{ opt.value | startcase }}
                        </option>
                      </select>
                      <div *ngIf="invalidForm('brokerFees.amounts.' + i + '.whenRefundable')" class="invalid-feedback">
                        When Refundable is required.
                      </div>
                    </td>
                    <td>
                      <div
                        class="input-group d-flex w-250"
                        [ngClass]="{ 'is-valid': validForm('brokerFees.amounts.' + i + '.amountRefundable'), 'is-invalid': invalidForm('brokerFees.amounts.' + i + '.amountRefundable') }"
                      >
                        <span class="input-group-text">£</span>
                        <input
                          type="number"
                          class="form-control"
                          [attr.data-testid]="'amountRefundable_' + i"
                          formControlName="amountRefundable"
                          placeholder="0.00"
                          [readonly]="+valueForm('brokerFees.amounts.' + i + '.whenRefundable') === whenRefundable.NotRefundable ? 'readonly' : null"
                          (keydown)="preventUnwanted($event)"
                          >
                        </div>
                        <div *ngIf="invalidForm('brokerFees.amounts.' + i + '.amountRefundable') && errorsForm('brokerFees.amounts.' + i + '.amountRefundable')" class="invalid-feedback">
                          Refundable Amount is required when fee is refundable.
                        </div>
                        <div *ngIf="invalidForm('brokerFees.amounts.' + i + '.amountRefundable') && errorsForm('brokerFees.amounts.' + i + '.amountRefundable').min" class="invalid-feedback">
                          Refundable Amount must be at least £0.01.
                        </div>
                    </td>
                    <td>
                      <input
                      class="form-control w-120"
                      type="text"
                      value="No"
                      [attr.data-testid]="'addFeeToLoan_' + i"
                      readonly
                      >
                    </td>
                    <td class="">
                      <select
                      class="form-control w-130"
                      [attr.data-testid]="'whenPayable_' + i"
                      formControlName="whenPayable"
                      [ngClass]="{ 'is-valid': validForm('brokerFees.amounts.' + i + '.whenPayable'), 'is-invalid': invalidForm('brokerFees.amounts.' + i + '.whenPayable') }"
                      required
                      >
                        <option [value]="null" hidden>Please select...</option>
                        <option *ngFor="let opt of whenPayable | enumvalues; trackBy: trackByKey"
                          [value]="opt.key">
                          {{ opt.value | startcase }}
                        </option>
                      </select>
                      <div *ngIf="invalidForm('brokerFees.amounts.' + i + '.whenPayable')" class="invalid-feedback">
                        When Payable is required.
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </ng-container>
        </ng-container>
        <button *ngIf="brokerFeeAmountsArray?.length < 2" class="btn btn-2022--secondary"
                data-testid="addAnotherBrokerFeeBtn"
                (click)="addBrokerFee()">
          Add another broker fee
          <i class="fa fa-plus"></i>
        </button>
        <button *ngIf="brokerFeeAmountsArray?.length >= 2" class="btn btn-danger"
                data-testid="removeBrokerFeeBtn"
                (click)="removeBrokerFee()">
          Remove broker fee
          <i class="fas fa-trash-alt"></i>
        </button>
      </div>
      <ng-container *ngIf="!!this.model?.assumedLegalFeeCost" >
      <label class="d-flex pointer my-3">
        <input
          type="checkbox"
          class="my-auto me-1"
          data-testid="assumedLegalFeeCost"
          checked
          disabled
        >
        <span>Assumed legal fee cost</span>
      </label>
      <div class="d-flex mb-2">
        <label for="assumedLegalFeeCost" class="align-self-center">Amount</label>
          <div class="input-group">
            <span class="input-group-text ms-3">£</span>
            <input
              type="number"
              id="assumedLegalFeeCost"
              class="form-control w-100 me-1 input-with-border-right"
              data-testid="amount"
              disabled
              [value]="this.model?.assumedLegalFeeCost"
              (keydown)="preventUnwanted($event)"
            >
          </div>
      </div>
      <span>Assumed legal fee cost. Used when the process doesn`t have free legal for Remortgage products.
        The estimate legal cost below is added to the true cost calculations
      </span>
      </ng-container>
      </div>
      <button class="btn btn-2022--secondary fw-bold" type="submit">
        Save settings
      </button>
  </form>
    <br>

    <ng-template #customFeesTemplate let-i="index">
      <form (ngSubmit)="onSubmitCustomFees()" [formGroup]="customFeeForm">
        <div class="form-group">
          <label for="feeName">Fee name</label>
          <input
            type="text"
            id="feeName"
            class="form-control"
            formControlName="feeName"
            [ngClass]="validityClassesCustomFees(['feeName'])"
            required
          >
          <div *ngIf="invalidCustomFees(['feeName']) && errorsCustomFees(['feeName']).required" class="invalid-feedback invalid-control">
            Fee name is required.
          </div>
        </div>
        <div class="form-group">
          <label for="feeAmount">Fee amount</label>
          <div class="input-group" [ngClass]="customFeeForm.errors?.requiredFeeAmountError || customFeeForm.errors?.uniqueFeeAmountError ? 'is-invalid' : validityClassesCustomFees(['feeAmount'])">
            <span class="input-group-text">£</span>
            <input
              type="number"
              id="feeAmount"
              class="form-control"
              formControlName="feeAmount"
              placeholder="fee amount"
              (keydown)="preventUnwanted($event)"
            >
          </div>
        </div>
        <div class="form-group">
          <label for="feeAmountPct">Fee amount %</label>
          <div class="input-group"
              [ngClass]="customFeeForm.errors?.requiredFeeAmountError || customFeeForm.errors?.uniqueFeeAmountError ? 'is-invalid' : validityClassesCustomFees(['feeAmountPct'])"
          >
            <span class="input-group-text">%</span>
            <input
              type="number"
              id="feeAmountPct"
              class="form-control"
              formControlName="feeAmountPct"
              placeholder="of loan amount"
              (keydown)="preventUnwanted($event)"
            >
          </div>
          <div *ngIf="invalidCustomFees(['feeAmountPct']) && (errorsCustomFees(['feeAmountPct']).min || errorsCustomFees(['feeAmountPct']).max)" class="invalid-feedback">
            Please enter a % value between 0.2 and 2%.
          </div>
          <div *ngIf="customFeeForm.errors?.requiredFeeAmountError" class="invalid-feedback">
            Fee amount or Fee amount % is required.
          </div>
          <div *ngIf="customFeeForm.errors?.uniqueFeeAmountError" class="invalid-feedback">
            You cannot enter values for both Fee amount and Fee amount % simultaneously.
          </div>
        </div>
        <div class="form-group">
          <label for="whenPayable">When payable?</label>
          <select
            id="whenPayable"
            class="form-select"
            formControlName="whenPayable"
            [ngClass]="validityClassesCustomFees(['whenPayable'])"
            required
          >
            <option [value]="null" hidden>Please select...</option>
            <option *ngFor="let opt of whenPayable | enumvalues; trackBy: trackByKey" [value]="opt.key">
              {{ opt.value | startcase }}
            </option>
          </select>
          <div *ngIf="invalidCustomFees(['whenPayable'])" class="invalid-feedback">
            When Payable is required.
          </div>
        </div>
        <div class="form-group">
          <label for="addToLoan">Add to loan?</label>
          <select
            id="addToLoan"
            class="form-select"
            formControlName="addToLoan"
            [ngClass]="validityClassesCustomFees(['addToLoan'])"
            required
          >
            <option [value]="null" hidden>Please select...</option>
            <option [value]="true">Yes</option>
            <option [value]="false">No</option>
          </select>
          <div *ngIf="invalidCustomFees(['addToLoan'])" class="invalid-feedback">
            Add fee to loan is required.
          </div>
        </div>
        <div class="form-group">
          <label for="whenRefundable">When refundable?</label>
          <select
            id="whenRefundable"
            class="form-select"
            formControlName="whenRefundable"
            [ngClass]="validityClassesCustomFees(['whenRefundable'])"
            required
          >
            <option [value]="null" hidden>Please select...</option>
            <option *ngFor="let opt of whenRefundable | enumvalues; trackBy: trackByKey" [value]="opt.key">
              {{ opt.value | startcase : 'to' }}
            </option>
          </select>
          <div *ngIf="invalidCustomFees(['whenRefundable'])" class="invalid-feedback">
            When Refundable is required.
          </div>
        </div>
        <div class="form-group">
          <label for="amountRefundable">Amount refundable?</label>
          <div class="input-group" [ngClass]="validityClassesCustomFees(['amountRefundable'])">
            <span class="input-group-text">£</span>
            <input
              type="number"
              id="amountRefundable"
              class="form-control"
              formControlName="amountRefundable"
              placeholder="0.00"
              (keydown)="preventUnwanted($event)"
            >
          </div>
          <div *ngIf="invalidCustomFees(['amountRefundable']) && errorsCustomFees(['amountRefundable']).required" class="invalid-feedback">
            Amount refundable is required when fee is refundable.
          </div>
          <div *ngIf="invalidCustomFees(['amountRefundable']) && errorsCustomFees(['amountRefundable']).min" class="invalid-feedback">
            Amount refundable must be at least £0.01.
          </div>
        </div>
        <div class="form-group">
          <label for="payableTo">Payable to</label>
          <input
              type="text"
              id="payableTo"
              class="form-control"
              formControlName="payableTo"
              [ngClass]="validityClassesCustomFees(['payableTo'])"
            >
            <div *ngIf="invalidCustomFees(['payableTo']) && errorsCustomFees(['payableTo']).maxlength" class="invalid-feedback">
              Maximum character length should be 100
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-2022--grey" data-testid="cancelBtn" (click)="cancelAddCustomFeeToPanel()">Cancel</button>
          <button type="button" type="submit" class="btn btn-2022--secondary" data-testid="saveBtn">Save</button>
        </div>
      </form>
  </ng-template>
