import { ConditionalFeeScale } from './fee-scales';

export enum CapitalRestPeriod {
  Daily = 1,
  Monthly = 2,
  Annually = 3,
}

export enum BridgingProductType {
  Fixed = 1,
  Discount = 2,
  Variable = 3,
  Tracker = 4,
  SteppedFixed = 5,
}

export enum BridgingPurchaserType {
  FirstTimeBuyer = 1,
  HomeMover = 2,
  LimitedCompany = 3,
}

export enum BridgingSecurityType {
  FirstCharge = 1,
  SecondCharge = 2,
  EquitableCharge = 3,
  CrossCharge = 4,
}

export enum BridgingInterestMethod {
  Serviced = 1,
  RolledUp = 2,
  Retained = 3,
}

export interface BridgingProductRate {
  ordinal: number;
  rate: number;
  numMonths: number | null;
}

export enum BridgingProductFeeType {
  Admin = 1,
  Arrangement = 2,
  Avm = 3,
  Chaps = 4,
  Legal = 5,
  Valuation = 7,
}

export enum BridgingProductFeeTypeModal {
  Admin = 'admin',
  Arrangement = 'arrangement',
  Avm = 'avm',
  Chaps = 'chaps',
  Legal = 'legal',
  Valuation = 'valuation',
}

export enum ProductFeeStructure {
  Percentage = 0,
  Flat = 1,
  Scale = 2,
}

export interface BridgingProductFee {
  type: BridgingProductFeeType;
  structure: ProductFeeStructure;
  value: number;
  isReferToLender: boolean;
}

export interface BridgingProduct {
  code: string | null;
  description: string;
  lender: string;
  notes: string | null;
  isRegulated: boolean;
  minTerm: number;
  maxTerm: number;
  capitalRestPeriod: CapitalRestPeriod;
  productType: BridgingProductType;
  maxLtv: number;
  grossLtv: number;
  exitFee: boolean;
  exitFeeDescription: string | null;
  dualLegalRepresentation: boolean | null;
  overpay: number | null;
  freeLegal: boolean | null;
  freeVal: boolean | null;
  minLoanPolicy: number | null;
  maxLoanPolicy: number | null;
  avmAccepted: boolean | null;
  marketValueCurrent: boolean | null;
  marketValue90: boolean | null;
  marketValue180: boolean | null;
  minIncome: boolean | null;
  bridgeToLet: boolean | null;
  letToBuy: boolean | null;
  portfolioLandlord: boolean | null;
  expatNotInUk: boolean | null;
  purchaserTypes: BridgingPurchaserType[];
  securityTypes: BridgingSecurityType[];
  interestMethods: BridgingInterestMethod[];
  rates: BridgingProductRate[];
  fees: BridgingProductFee[];
  totalFee: number | null;
  uniqueProductReference: string;
  monthlyRate?: number | null;
  grossLoan?: number | null;
  trueCost?: number | null;
  aprc?: number | null;
  showTrueCost?: boolean;
  initialMonthlyPaymentInterestOnly: number | null;
  monthlyPaymentRevertInterestOnly: number | null;
  trueCostInitialPeriod: number | null;
  totalInterestPayable: number | null;
  id: number | null;
}

export interface BridgingLenderProducts {
  lenderName: string;
  products: BridgingProduct[];
  updatedDate?: string | null;
  lenderFeeScales: ConditionalFeeScale<BridgingProductFeeType>[];
}
