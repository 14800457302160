<div *ngIf="list">
  <div class="form-check {{ getDirectionClass }} {{ getSizeCss }}" *ngFor="let item of list; trackBy: trackByFn">
    <input
      type="checkbox"
      class="form-check-input"
      id="{{ item.value }}"
      name="{{ item.value }}"
      [checked]="model[item.value]"
      (change)="onChange(item.value, $event)"
    />
    <label class="form-check-label" for="{{ item.value }}">
      {{ item.text }}
    </label>
    <span class="float-end" *ngIf="labelRightTemplate">
      <ng-container *ngTemplateOutlet="labelRightTemplate; context: { item: item.item }"></ng-container>
    </span>
  </div>
</div>
