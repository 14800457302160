import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { NgxMaskModule } from 'ngx-mask';
import { formlyComponents, formlyModuleConfig } from './formly.config';

/** Module that exports a module that configures the ClubHub 2022 form style */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyBootstrapModule,
    FormlyModule.forRoot(formlyModuleConfig),
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    ...formlyComponents,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyBootstrapModule,
    ...formlyComponents,
  ],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class MssFormlyModule {}
