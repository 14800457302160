<nav class="navbar navbar-expand-lg navbar-light d-flex d-print-none">
  <ul class="navbar-nav my-1">
    <ng-container *ngFor="let item of leftNav; trackBy: trackByFn">
      <li class="nav-item" *ngIf="!item.hide">
        <a
          *ngIf="!item.navItemTemplate && !item.linkTemplate"
          href="javascript: void(0);"
          class="nav-link text-white {{ item.disabled ? 'disabled' : '' }} {{ getActiveClass(item) }}"
          (click)="item.handler()"
          [attr.data-testid]="'nav_link_'+item.text | slugify:'_'"
        >
          <i *ngIf="item.icon" class="fas fa-{{ item.icon }}"></i>
          {{ item.text }}
        </a>

        <span class="nav-link text-white" *ngIf="item.textWithoutLink">{{item.textWithoutLink}}</span>

        <a href="javascript: void(0);" class="nav-link" *ngIf="item.navItemTemplate">
          <ng-container *ngTemplateOutlet="item.navItemTemplate"></ng-container>
        </a>

        <ng-container *ngIf="item.linkTemplate">
          <ng-container *ngTemplateOutlet="item.linkTemplate"></ng-container>
        </ng-container>
      </li>
    </ng-container>
  </ul>

  <ul class="navbar-nav d-flex justify-content-center my-1" *ngIf="title || (showLendingType && lendingType)">
    <li>
      <h1 id="page-heading" class="text-white m-0" *ngIf="showTitle">
        <ng-container *ngIf="title"> {{ title }} </ng-container>
        <ng-container *ngIf="showLendingType && title && lendingType">-</ng-container>
        <ng-container *ngIf="lenderName"> {{ lenderName }} </ng-container>
        <ng-container *ngIf="showLendingType && lendingType"> {{ lendingType }} </ng-container>
      </h1>
    </li>
  </ul>

  <ul class="navbar-nav d-flex justify-content-end my-1">
    <ng-container *ngFor="let item of rightNav; trackBy: trackByFn">
      <li class="nav-item" *ngIf="!item.hide">
        <a
          *ngIf="!item.navItemTemplate && !item.linkTemplate"
          href="javascript: void(0);"
          class="nav-link text-white {{ item.disabled ? 'disabled' : '' }} {{ getActiveClass(item) }}"
          (click)="item.handler()"
        >
          <i *ngIf="item.icon" class="fas fa-{{ item.icon }}"></i>
          {{ item.text }}
        </a>

        <span *ngIf="item.textWithoutLink">{{item.textWithoutLink}}</span>

        <a href="javascript: void(0);" class="nav-link" *ngIf="item.navItemTemplate">
          <ng-container *ngTemplateOutlet="item.navItemTemplate"></ng-container>
        </a>

        <ng-container *ngIf="item.linkTemplate">
          <ng-container *ngTemplateOutlet="item.linkTemplate"></ng-container>
        </ng-container>
      </li>
    </ng-container>
  </ul>
</nav>
