<div class="row py-2">
  <div class="col-md-4" *ngFor="let option of props.options; let i = index">
    <div class="form-check form-check-inline" [attr.aria-describedby]="errorId">
      <label class="form-check-label" [for]="id + '_' + i">
        {{ option.label }}
      </label>
      <input
        type="radio"
        [id]="id + '_' + i"
        class="form-check-input"
        [name]="field.name || id"
        [class.is-invalid]="showError"
        [attr.value]="option.value"
        [value]="option.value"
        [formControl]="formControl"
        [formlyAttributes]="field"
      />
    </div>
  </div>
</div>
