<div class="clubhub-home-section">
  <figure *ngIf="image">
    <img *ngIf="!imageLink" src="assets/images/{{ image }}" alt="" />
    <a routerLink="{{ imageLink }}" *ngIf="imageLink">
      <img *ngIf="imageLink" src="assets/images/{{ image }}" alt="" />
    </a>
  </figure>
  <div class="body">
    <div class="content">
      <h2 class="pt-3">{{ title }}</h2>
      <p>{{ content }}</p>
      <p *ngIf="contentSecondParagraph">{{ contentSecondParagraph }}</p>
      <p *ngIf="contentThirdParagraph">{{ contentThirdParagraph }}</p>
      <p *ngIf="contentFourthParagraph">{{ contentFourthParagraph }}</p>
      <div *ngIf="igniteBulletPoints">
        Key features:
        <br><br>
        <ul>
          <li>
            Use our tools in insolation or seamlessly blend to build an accurate picture for your client without having
            to log into different systems.
          </li>
          <li>Download compliant ESIS and Evidence of Research documents from our product sourcing tool.</li>
          <li>
            Manage complex cases including self-employed, joint borrowers and sole proprietors and enter income and
            expenditure for up to 4 mortgage applicants.
          </li>
          <li>Make your search greener and access EPC information with ease from our property check tool.</li>
          <li>Access over 130 lenders across all lending types including bridging, second charge and packager.</li>
        </ul>
        <span>
          For a no obligation demonstration, pop on to live chat and request with a member of our friendly MSS team.
        </span>
      </div>
    </div>
    <div class="link-wrapper">
      <a *ngIf="isExternalLink(buttonLink)" [href]="buttonLink" class="btn btn-2022--secondary btn-primary-chevron" [target]="'_blank'">
        <span>{{ button }}</span>
        <i class="fa fa-chevron-right"></i>
      </a>
      <a *ngIf="!isExternalLink(buttonLink)" [routerLink]="buttonLink" class="btn btn-2022--secondary btn-primary-chevron">
        <span>{{ button }}</span>
        <i class="fa fa-chevron-right"></i>
      </a>
    </div>
    <div *ngIf="content2" class="content">
      <p>{{ content2 }}</p>
      <p *ngIf="content2SecondParagraph">{{ content2SecondParagraph }}</p>
    </div>
    <div *ngIf="button2" class="link-wrapper" [ngSwitch]="true">
      <a *ngSwitchCase="isExternalLink(button2Link)"
        [href]="button2Link"
        class="btn btn-2022--secondary btn-primary-chevron"
        (click)="button2OnClick($event)"
      >
        <span>{{ button2 }}</span>
        <i class="fa fa-chevron-right"></i>
      </a>
      <a *ngSwitchCase="isFileLink(button2Link)"
        [href]="button2Link"
        class="btn btn-2022--secondary btn-primary-chevron"
        [target]="'_blank'"
        download="{{ getFileName(button2Link) }}"
      >
        <span>{{ button2 }}</span>
        <i class="fa fa-chevron-right"></i>
      </a>
      <a *ngSwitchDefault
        [routerLink]="button2Link"
        class="btn btn-2022--secondary btn-primary-chevron"
        (click)="button2OnClick($event)"
      >
        <span>{{ button2 }}</span>
        <i class="fa fa-chevron-right"></i>
      </a>
    </div>
  </div>
</div>
