<div [ngSwitch]="size" class="container">
  <h1 *ngSwitchCase="headingSize.H1" class="my-2 text-center">
    {{ text }}
  </h1>
  <h2 *ngSwitchCase="headingSize.H2" class="my-2 text-center">
    {{ text }}
  </h2>
  <h3 *ngSwitchCase="headingSize.H3" class="my-2 text-center">
    {{ text }}
  </h3>
  <h4 *ngSwitchCase="headingSize.H4" class="my-2 text-center">
    {{ text }}
  </h4>
  <h5 *ngSwitchCase="headingSize.H5" class="my-2 text-center">
    {{ text }}
  </h5>
  <h6 *ngSwitchCase="headingSize.H6" class="my-2 text-center">
    {{ text }}
  </h6>
</div>
