import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LendingType } from '@msslib/models';
import { Observable, Subscription, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ContextOption } from '../../models/contexts';
import { ClubHubDataService } from '../../services/clubhub-data.service';
import { IssuesService } from '../../services/issues.service';
import { LendingTypeService } from '../../services/lending-type.service';

@Component({
  selector: 'lib-issues-search-box',
  templateUrl: 'issues-search-box.component.html',
  styleUrls: ['issues-search-box.component.scss'],
})
export class IssuesSearchBoxComponent implements OnInit, OnChanges {
  public itemsLoading = false;
  private issueSubscription: Subscription;

  @Input() public searchTerm = '';
  @Input() public loadedItems: ContextOption[] = [];
  @Input() public filteredItems: ContextOption[] = [];
  @Input() public initialiseItems = true;
  @Input() public isEditMode = false;
  @Input() public isLenderHub = false;
  @Input() public lenderId: number;
  @Input() public marginLeft = true;
  @Input() public actualLendingType: LendingType;

  @Output() public searchTermChange = new EventEmitter<string>();
  @Output() public loadedItemsChange = new EventEmitter<ContextOption[]>();
  @Output() public filteredItemsChange = new EventEmitter<ContextOption[]>();
  @Output() public contextSelected = new EventEmitter<{ issue: ContextOption | undefined; item: ContextOption }>();
  @Output() public clearSearch = new EventEmitter<unknown>();

  public constructor(
    private issuesService: IssuesService,
    private clubHubDataService: ClubHubDataService,
    private lendingTypeService: LendingTypeService,
  ) {}

  public ngOnInit(): void {
    if (this.initialiseItems) {
      this.getIssues();
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.isEditMode?.currentValue !== changes.isEditMode?.previousValue) {
      this.getIssues();
    }
  }

  public get lendingType(): string {
    return this.actualLendingType?.code ?? this.lendingTypeService.lendingType?.code;
  }

  public getIssues() {
    const requestUrl = this.isLenderHub
      ? `Answer/GetIssues/${this.lendingType}?isEditMode=${this.isEditMode}&lenderId=${this.lenderId}`
      : `Answer/GetIssues/${this.lendingType}?isEditMode=${this.isEditMode}`;
    this.issueSubscription?.unsubscribe();
    this.issueSubscription = this.clubHubDataService
      .get<ContextOption[]>(requestUrl)
      .subscribe((res: ContextOption[]) => {
        this.loadedItems = res;
        this.loadedItemsChange.emit(this.loadedItems);
        this.filterItems(this.searchTerm);
      });
  }

  public filterItems = (term: string): Observable<ContextOption[]> => {
    this.searchTerm = term;
    this.searchTermChange.emit(this.searchTerm);
    if (term && term.trim().length >= 3) {
      this.itemsLoading = true;
      return this.issuesService
        .filterIssues(term, { isEditMode: this.isEditMode, isDeep: true, returnParents: true })
        .pipe(
          map((res) => {
            this.filteredItems = res.issues;
            this.filteredItemsChange.emit(this.filteredItems);
            return res.contexts;
          }),
          finalize(() => {
            this.itemsLoading = false;
          }),
        );
    }
    this.filteredItems = this.loadedItems;
    this.filteredItemsChange.emit(this.filteredItems);
    return of([]);
  };

  public selectContext(item: ContextOption) {
    this.filterItems(item.questionText);
    this.itemsLoading = false;
    const issue = this.filteredItems.find((x: ContextOption) => x.id === item.parentId);
    this.contextSelected.emit({ issue, item });
  }

  public clear() {
    this.clearSearch.emit();
  }
}
