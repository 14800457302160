<div class="row">
  <div class="col col-12 col-md-{{ hasSidebar ? '4' : '3' }} mb-3" *ngFor="let item of items">
    <a href="javascript: void(0);" (click)="selectCard($event, item)">
      <div class="card h-100">
        <div class="card-header {{ headerClass && headerClass(item) }}">
          <div class="card-title" [title]="item[titleKey]">
            {{ item[titleKey] }}<small *ngIf="item[subtitleKey]"> - {{ item[subtitleKey] }}</small>
          </div>
        </div>
        <div class="card-body">
          <div *ngIf="!bodyTemplate" class="card-info" [title]="item[descriptionKey]">
            <ng-container *ngIf="hasMultipleBodyItems(item[descriptionKey])">
              <div class="row">
                <div class="col">
                  <ul>
                    <li *ngFor="let item of bodyItems(item[descriptionKey])">
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!hasMultipleBodyItems(item[descriptionKey])">
              {{ item[descriptionKey] }}
            </ng-container>
          </div>
          <div *ngIf="bodyTemplate" class="card-info">
            <ng-container [ngTemplateOutlet]="bodyTemplate" [ngTemplateOutletContext]="{ data: item }"></ng-container>
          </div>
        </div>
        <div class="card-footer d-flex align-items-center justify-content-between" [title]="item[titleKey]" *ngIf="footerTemplate">
          <ng-container [ngTemplateOutlet]="footerTemplate" [ngTemplateOutletContext]="{ data: item }"></ng-container>
          <i *ngIf="showFooterArrow" class="fas fa-arrow-right float-end"></i>
        </div>
      </div>
    </a>
  </div>
</div>
