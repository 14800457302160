<div [formGroup]="formGroup" class="form-group">
  <label for="{{ config.name }}">
    {{ config.label }}
    <ng-container *ngIf="showAsterisk(config)">
      *
    </ng-container>
  </label>

  <div class="input-group">
    <input
      class="form-control"
      [attr.placeholder]="config.placeholder"
      [id]="config.name"
      [formControlName]="config.name"
      [attr.aria-describedby]="config.name + 'err'"
      ngbDatepicker
      [ngClass]="{ 'is-valid': valid, 'is-invalid': invalid }"
      #d="ngbDatepicker"
    />
    <span (click)="d.toggle()" class="input-group-text" [id]="config.name">
      <i class="fa fa-calendar"></i>
    </span>
  </div>

  <div libFormFieldError class="invalid-feedback" [fieldConfig]="config"></div>
</div>
