<div class="toasts">
  <ngb-toast
    *ngFor="let toast of toastService.toasts"
    [class]="toast.classname"
    [autohide]="toast.autoHide ?? true"
    [delay]="toast.delay || 5000"
    (hide)="toastService.remove(toast)"
    [header]="toast.header ?? null"
  >
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>
      <ng-template [ngIf]="toast.error" [ngIfElse]="standardText">
        <p *ngIf="toast.message"> {{ toast.message }}</p>
        <p *ngIf="toast.requestId"> requestId: {{ toast.requestId }}</p>
      </ng-template>
      <ng-template #standardText>
        {{ toast.textOrTpl }}
      </ng-template >
    </ng-template>
  </ngb-toast>
</div>
