import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FormsValidators, yesNoOptions } from '@msslib/components';
import { FormFieldType, WrapperType } from '@msslib/components/formly/formly.config';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IPropertyAndLoan,
  errorMessages,
} from 'apps/clubhub/src/app/ignite/models/affordability';
import { IMortgageRequirements } from 'apps/clubhub/src/app/ignite/models/products';
import {
  BtlType,
  MortgageLenders,
  MortgageType,
  ProductType, PropertyType,
  RemortgageType,
  RepaymentMethod,
} from 'apps/shared/src/models';
import { ProductsSearchService } from '../products-search.service';
import { IgniteHelperService } from '@msslib/services';
import { LenderService } from 'apps/shared/src/services/lender.service';
import { map } from 'rxjs';

const secondChargeLoanAmountLabel = 'Second Charge Loan Amount';
const loanAmountLabel = 'Loan Amount';

@Injectable({
  providedIn: 'root',
})
export class ProductsFormFieldsBtlService {
  public constructor(
    private productsSearchService: ProductsSearchService,
    private helperService: IgniteHelperService,
    private lenderService: LenderService,
  ) { }

  public resetFieldIfEmpty(field: FormlyFieldConfig) {
    if (!field.model) {
      field.formControl?.reset();
    }
  }

  public keyboardEventMarkTouched(field: FormlyFieldConfig, event: KeyboardEvent) {
    const key = event.key;
    if (!['Tab', 'Shift'].includes(key)) {
      field.formControl?.markAsTouched();
    }
  }

  public fields: FormlyFieldConfig[] = [
    {
      key: 'mortgageRequirements',
      wrappers: [WrapperType.FormCard],
      props: {
        title: 'Mortgage Details',
      },
      validators: {
        max500PercentLoanValue: {
          expression: FormsValidators.expression(FormsValidators.ltv(500)),
          message: 'Loan amount should not exceed 500% of property value',
          errorPath: 'loanAmount',
        },
        interestOnlyAmountValidator: {
          expression: (control: AbstractControl) => {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const repaymentMethodValue = control['controls']?.repaymentMethod?.value?.value;
            if (repaymentMethodValue === RepaymentMethod.InterestOnlyPartAndPart) {
              const validator = FormsValidators.expression(FormsValidators
                .greaterThanField<IMortgageRequirements>('interestOnlyAmount', 'loanAmount', true));
              return validator(control);
            }

            return true;
          },
          message: errorMessages.interestOnlyLessThanLoanAmount,
          errorPath: 'interestOnlyAmount',
        },
        productTransferInitialDate: this.helperService.getProductTransferInitialPeriodValidation(),
      },
      fieldGroup: [
        {
          key: 'mortgageType',
          type: 'radio-button',
          props: {
            label: 'Mortgage type',
            options: this.getMortgageTypeOptions(),
            required: true,
          },
        },
        {
          key: 'btlType',
          type: 'select',
          props: {
            label: 'BTL type',
            options: [
              { label: 'Standard', value: BtlType.Standard },
              { label: 'HMO', value: BtlType.HMO },
              { label: 'Holiday let', value: BtlType.HolidayLet },
              { label: 'MUFB', value: BtlType.MUFB },
            ],
            required: true,
          },
        },
        {
          key: 'newBuild',
          type: FormFieldType.RadioButtons,
          props: {
            label: 'New build',
            options: yesNoOptions,
          },
        },
        {
          key: 'propertyType',
          type: FormFieldType.RadioButtons,
          defaultValue: PropertyType.House,
          props: {
            label: 'Property type',
            options: [
              { label: 'House', value: PropertyType.House },
              { label: 'Flat', value: PropertyType.Flat },
            ],
            required: true,
          },
          expressions: {
            hide: field => !field.model?.newBuild,
          },
        },
        {
          key: 'remortgageType',
          type: 'select',
          props: {
            label: 'Remortgage type',
            allowNull: true,
            options: [
              { label: 'Let to buy', value: RemortgageType.LetToBuy },
              { label: 'Like for like', value: RemortgageType.LikeForLike },
              { label: 'Capital raising', value: RemortgageType.CapitalRaising },
            ],
          },
          expressions: {
            hide: field => (field.model as IPropertyAndLoan)?.mortgageType !== MortgageType.Remortgage,
          },
        },
        ...this.mortgageLenderFields(),
        {
          key: 'propertyValue',
          type: 'currency',
          props: {
            label: 'Property value',
            keyup: this.keyboardEventMarkTouched,
            blur: this.resetFieldIfEmpty,
          },
          validators: {
            validation: [FormsValidators.greaterThanOnTouched(0)],
          },
          expressions: {
            'props.readonly': () => this.productsSearchService.propertyValueDisabled,
          },
        },
        {
          key: 'loanAmount',
          type: 'currency',
          props: {
            keyup: this.keyboardEventMarkTouched,
            blur: this.resetFieldIfEmpty,
          },
          validators: {
            validation: [FormsValidators.greaterThanOnTouched(0)],
          },
          expressions: {
            'props.readonly': () => this.productsSearchService.loanAmountDisabled,
            'props.label': field => {
              return field.model?.mortgageType === MortgageType.SecondCharge
                ? secondChargeLoanAmountLabel
                : loanAmountLabel;
            },
          },
        },
        {
          key: 'outstandingBalanceCurrentMortgage',
          type: 'currency',
          props: {
            label: 'Outstanding Balance on the Current Mortgage',
            keyup: this.keyboardEventMarkTouched,
            blur: this.resetFieldIfEmpty,
          },
          validators: {
            validation: [FormsValidators.greaterThanOnTouched(0)],
          },
          expressions: {
            hide: field => (field.model as IPropertyAndLoan)?.mortgageType !== MortgageType.SecondCharge,
            'props.readonly': () => this.productsSearchService.loanAmountDisabled,
          },
        },
        {
          key: 'ltv',
          type: 'disabled',
          props: {
            label: 'LTV',
            addonEnd: '%',
          },
          expressions: {
            'props.value': field =>
              this.helperService.calculateLtv((field.parent?.model ?? {}) as IMortgageRequirements),
          },
        },
        {
          key: 'repaymentMethod',
          props: {
            label: 'Method of repayment',
          },
          fieldGroup: [
            {
              key: 'value',
              type: 'select',
              props: {
                allowNull: true,
                options: [
                  { label: 'Capital and interest', value: RepaymentMethod.CapitalAndInterest },
                  { label: 'Interest only', value: RepaymentMethod.InterestOnly },
                  { label: 'Interest only part and part', value: RepaymentMethod.InterestOnlyPartAndPart },
                ],
                collapseMargin: true,
              },
              expressions: {
                'props.readonly': field => (field.parent?.parent?.formControl as UntypedFormGroup)
                  .controls?.productTypeExtended?.value === ProductType.RetirementInterestOnly,
              },
            },
          ],
        },
        {
          key: 'interestOnlyAmount',
          type: 'currency',
          props: {
            label: 'Amount on interest only',
            required: true,
            keyup: this.keyboardEventMarkTouched,
            blur: this.resetFieldIfEmpty,
          },
          expressions: {
            hide: field => (field.parent?.model as IMortgageRequirements)?.repaymentMethod?.value
              !== RepaymentMethod.InterestOnlyPartAndPart,
          },
        },
        {
          key: 'mortgageTerm',
          fieldGroupClassName: 'row',
          wrappers: ['horizontal-field'],
          props: {
            label: 'Mortgage term',
            suppressErrors: true,
          },
          fieldGroup: [
            {
              key: 'years',
              type: 'number',
              className: 'col',
              wrappers: [WrapperType.NoWrappers],
              props: {
                min: 0,
                max: 40,
                addonEnd: 'years',
                addonEndClass: 'fw-light',
                groupedField: true,
                collapseMargin: true,
              },
              validation: [FormsValidators.integer],
            },
            {
              key: 'months',
              type: 'number',
              className: 'col',
              wrappers: [WrapperType.NoWrappers],
              props: {
                min: 0,
                max: 11,
                addonEnd: 'months',
                addonEndClass: 'fw-light',
                groupedField: true,
                collapseMargin: true,
              },
              validators: {
                validation: [FormsValidators.integer],
              },
            },
          ],
          validators: {
            max40Years: {
              expression: (control: AbstractControl) => {
                const { years, months } = control.value ?? {};
                if (!years) {
                  return true;
                }
                return 12 * years + (months ?? 0) <= 40 * 12;
              },
              message: 'Mortgage term has a maximum of 40 years',
            },
          },
        },
        {
          key: 'limitedCompanyPurchase',
          type: 'radio-button',
          props: {
            label: 'Limited company purchase',
            options: yesNoOptions,
          },
        },
        {
          key: 'portfolioLandlord',
          type: 'radio-button',
          props: {
            tooltip: '4 or more Buy to Let or Consent to Let mortgaged properties (including this application)',
            label: 'Portfolio landlord',
            options: yesNoOptions,
          },
        },
        {
          key: 'getResultsBtn',
          type: 'buttons',
          props: {
            buttons: [
              { label: 'Get results', type: 'submit', className: 'btn-2022--secondary' },
            ],
          },
        },
      ],
    },
  ];

  private getMortgageTypeOptions() {
    return [
      { label: 'Purchase', value: MortgageType.Purchase },
      { label: 'Remortgage', value: MortgageType.Remortgage },
      { label: 'Second Charge', value: MortgageType.SecondCharge },
    ];
  }

  private mortgageLenderFields(): FormlyFieldConfig[] {
    const mortgageLenderHidden = (field) => {
      return field?.model?.mortgageType !== MortgageType.Remortgage
        && field?.model?.mortgageType !== MortgageType.Purchase;
    };

    const initialPeriodHidden = (field) => field?.model?.mortgageType !== MortgageType.Remortgage;

    return [
      {
        key: 'mortgageLender',
        wrappers: [WrapperType.NoWrappers],
        props: {
          suppressErrors: true,
        },
        fieldGroup: [
          {
            key: 'value',
            type: FormFieldType.Select,
            props: {
              label: 'Who is the current mortgage Lender?',
              allowNull: true,
            },
            expressions: {
              hide: field => mortgageLenderHidden(field.parent?.parent),
              'props.options': this.lenderService.getUnarchivedLendersNames(this.helperService.getBtlLendingType())
                .pipe(map(lenderNames => this.helperService.getMortgageLendersList(lenderNames))),
            },
          },
        ],
      },
      {
        key: 'currentMortgageLender',
        type: FormFieldType.Input,
        expressions: {
          hide: field => {
            if (mortgageLenderHidden(field)) {
              return true;
            }

            const mortgageLenderValue = field.model?.mortgageLender?.value;
            return !mortgageLenderValue || mortgageLenderValue !== MortgageLenders.Other;
          },
        },
      },
      {
        key: 'productTransferInitialDate',
        type: FormFieldType.Date,
        props: {
          label: 'What is the end date for the Initial period?',
        },
        validators: {
          validation: [FormsValidators.dateOrEmpty],
        },
        expressions: {
          hide: field => {
            if (initialPeriodHidden(field)) {
              return true;
            }

            return field.model?.mortgageLender?.value === MortgageLenders.Other;
          },
        },
      },
    ];
  }
}
